import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
// import {  useSnackbar } from 'notistack';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';


const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
}))

const ListItemEmail = ({email, onSelectEmail, ...rest}) => {
  // const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const [readyFromName, setReadyFromName] = React.useState('');
  const [readyDate, setReadyDate] = React.useState()
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };
  

  // extract FROM Name only from
  useEffect(() => {
    const date = new Date(email.date).toLocaleDateString()
    setReadyDate(date)
    let i = 0;
    let fromName = [];

    // from: process.env.REACT_APP_DEFAULT_EMAIL,
    // from: ""MyAnalytics" <no-reply@microsoft.com>"

    // let ourArray = email.from.split(' ');
    // ourArray.map( (addr_part, index) => console.log(`${index} = ${addr_part}`));
    // console.log(ourArray[ourArray.length - 1])
    
    while (email.from[i] !== '<' && i < email.from.length ) {

        fromName.push(email.from[i])
        setReadyFromName(fromName.join(''))
        i++;
    }
  
  }, [email.date, email.from])

  return (
    <React.Fragment>
      <ListItem
        {...rest}
        className={clsx(classes.root)}
        button
        onClick={() => onSelectEmail(email)}
      >   
        <ListItemAvatar>
          <Avatar className={classes.listItemAvatar}>
            {readyFromName.replace(/['"]+/g, '').charAt(0)}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          className={classes.listItemText}
          primary={readyFromName.replace(/['"]+/g, '')}
          secondary={
            <React.Fragment>
              <li>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  Subject: {email.subject.substring(0, 22) + '...'}
                </Typography>
              </li>
              <li>
                {readyDate}
              </li>
            </React.Fragment>
          }
        />
        {email.attachments.length > 0 && (
          <AttachmentIcon color="disabled" />
        )}
        {/* <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="more"  onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
      
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
          <MenuItem onClick={handleClose}>{t('Open Existing Case')}</MenuItem>
          <MenuItem onClick={handleClose}>{t('Associate to Case')}</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ListItemEmail;