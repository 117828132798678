import { w3cwebsocket as W3CWebSocket } from "websocket";
import { logger } from "./TM/src/common";

// let client = new W3CWebSocket(`ws://${process.env.REACT_APP_DASH_WEBSOCK}/`, 'json');
let client = undefined;
let connected = false;

let user_id = null;
let username = null;
let ext = null;

let module_callbacks = {};

const DEBUG = false;

/* Func: Attach_callback()
 * Desc: Attach Callbacks for Loaded modules
 *
 * NOTES: Once participating modules have their Callback added they will
 *        be able to receive messages from the Server in Realtime over the
 *        Websocket.
 * 
 * The Callback (in the Module) will know how to handle its own callback.
 * 
 * CallBacks will be received by their *EventId* then redirected to the *PageName*.
 */
const Attach_callback = (pageName, cb) => {
    try {
        if(DEBUG) console.log(`[Attach_callback] Attaching CallBack "${pageName}"`);
        module_callbacks[`${pageName}`] = { "cb" : cb };
    } catch(e) {
        console.log(`[Attach_callback] ERROR during attach CallBack "${cb}" --> ${e}`);
        if(DEBUG) console.log(cb);
    }
};

/* Func: MsgDispatcher()
 * Desc: Dispatch messages to a registered Callback
 * 
 * The Callback (in the Module) will know how to handle its own callback.
 */
const MsgDispatcher = (websock_msg) => {

    if(DEBUG) console.log('======== MsgDispatcher ========');

    let cmd_msg = websock_msg.data;
    // console.log(cmd_msg);

    let jsonMsg = undefined;

    try {
        jsonMsg = JSON.parse(cmd_msg);
        if(typeof jsonMsg == 'string') {
            /* When JSON is Stringified for stoage in Queue it contains additional string conversions.
               In this case JSON.parse removes this and provides a STRING not an OBJECT. Therefore we
               need to parse again to obtain an object.
            */
            jsonMsg = JSON.parse(jsonMsg);
        }
        // console.log(`jsonMsg typeof ${typeof jsonMsg} AND ${jsonMsg instanceof String}`);
        // console.log(jsonMsg);
    } catch(e) {
        if(DEBUG) console.log("[MsgDispatcher] ERROR during parsing Command-Message --> " + e);
        Send_error_msg(String(e), username);
        return;
    }

    // console.log(`jsonMsg.event_id = ${jsonMsg.event_id}`);

    if(jsonMsg.hasOwnProperty("event_id") === false) {
        if(DEBUG) console.log("[MsgDispatcher] Missing event_id");
        Send_error_msg("Missing event_id", username);
        return;
    }
    // if(jsonMsg.data.hasOwnProperty("pageName") === false) {
    //     if(DEBUG) console.log("[MsgDispatcher] Missing pageName");
    //     Send_error_msg("Missing pageName", username);
    //     return;
    // }

    try {
        // E.g: eventId = "screenpop"
        module_callbacks[jsonMsg.event_id].cb(jsonMsg.data.pageName, jsonMsg);
    } catch(e) {
        if(DEBUG) console.log(`[MsgDispatcher] ERROR during execute CallBack for Event "${jsonMsg.event_id}" on Page "${jsonMsg.data.pageName}" --> ${e}`);
        Send_error_msg(String(e), username);
    }
};

/* Func: Send_msg()
 * Desc: Sends a message over the websocket
 * 
 * NOTES: Not Used. The Server is not processing commands from Clients.
 *        Please use HTTP for authenticated or secure data transfer.
 */
const Send_msg = (mod_name, event_id, message) => {
    // console.log(`client.readyState = ${client.readyState}, client.OPEN = ${client.OPEN}`);

    let jsonMsg = {
        eventId : event_id,
        modName : mod_name,
        data : message
    };

    if(client) {
        if(client.readyState === client.OPEN) {
            console.log("calling server ws")
            client.send( JSON.stringify(jsonMsg) );
        } else {
            console.log("Cannot send Command Message (WebSocket not connected)");
        }
    } else {
        console.log("Cannot send Command Message (WebSocket is not initialised)");
    }
}

/* Func: Startup()
 * Desc: Opens the websocket
 */
const Startup = (userName, userExt, userId) => {
    user_id = userId;
    username = userName;
    ext = userExt;

    if(!connected) {
        if(DEBUG) console.log(`CONNECTING ---> ${process.env.REACT_APP_DASH_WEBSOCK}/`);
        client = new W3CWebSocket(`${process.env.REACT_APP_DASH_WEBSOCK}/`, 'json');
        Add_event_handlers(username, ext, user_id);
    } else {
        if(DEBUG) console.log("Cannot open websocket (it's already open)");
    }
}

/* Func: Shutdown()
 * Desc: Closes the websocket
 */
const Shutdown = () => {
    connected = false;
    client.close();
}

/* Func: Add_event_handlers()
 * Desc: Adds handlers for the websocket
 */
const Add_event_handlers = () => {
    if(client !== undefined) {
        client.onopen = () => { 
            connected = true;
            if(DEBUG) console.log("SOCKET OPEN!!");
            Send_user_metadata_to_server();

            // Send_chat_msg("Hello, this is a Chat Message!!", "rich2", "rich2");
        };
        client.onmessage = MsgDispatcher;
        client.onerror = (err) => console.log(err);
        client.onclose = () => {
            connected = false;
            if(DEBUG) console.log("SOCKET CLOSED!!");
        };
    }
}

/* Func: Send_user_metadata_to_server()
 * Desc: Provides an Identity for this Websocket on the Server
 */
const Send_user_metadata_to_server = () => {
    let cmd_msg = {
        ext,
        username,
        user_id
    };
    Send_msg("DASH", "login", cmd_msg);
}

/* Func: Send_chat_msg()
 * Desc: Sends a Chat message over the websocket
 */
const Send_chat_msg = (message, from_username, to_username) => {
    const event_id = "sendmessage";

    let cmd_msg_data = {
        "sentfrom" : from_username,
        "sendto" : to_username,
        "text" : message
    };
    console.log("calling client server ws function", cmd_msg_data)
    Send_msg("DASH", event_id, cmd_msg_data);
};

/* Func: Send_broadcast_chat_msg()
 * Desc: Sends a broadcast chat message over the websocket
 */
const Send_broadcast_chat_msg = (message, from_username, to_username, group) => {
    const event_id = "broadcast";

    let cmd_msg_data = {
        "group" : group,
        "sentfrom" : from_username,
        "sendto" : to_username,
        "text" : message
    };
    console.log("calling client server ws function", cmd_msg_data)
    Send_msg("DASH", event_id, cmd_msg_data);
};

/* Func: Send_error_msg()
 * Desc: Sends an error message over the websocket to backend
 */
const Send_error_msg = (error_message, username) => {
    const event_id = "error";

    let cmd_msg_data = {
        "username" : username,
        "error" : error_message
    };

    Send_msg("DASH", event_id, cmd_msg_data);
};

/* DEBUG */
if(DEBUG && client) {
    console.log(client);
}

export default {
    Attach_callback,
    Send_msg,
    Startup,
    Shutdown,
    Send_chat_msg,
    Send_broadcast_chat_msg,
};