/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Grid,  Typography, Box,  Card, Button, TextField, FormControl, Select, MenuItem} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import PageHeader from './PageHeader'
import {useForm, Controller} from "react-hook-form";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppContext } from '../../../contexts/AppStore'
import axios from 'axios';
// import DialogNewIngredient from '../components/DialogNewIngredient'
import DialogAddIngredient from '../components/DialogAddIngredient'
import Chip from '@material-ui/core/Chip';
import DialogNewSubstance from '../components/DialogNewSubstance';






const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    form_team: {
        margin:"1%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "50%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    btn: {
        textAlign: "center",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    errorMsg : {
        color: "#f44336"
    },
    btnNewIngredient: {
        margin: "1.5%",
        width: "15%",
        maxWidth: "15%"

    }

}))

const EditSubstance = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit, errors, control } = useForm();
    const { enqueueSnackbar } =useSnackbar();
    const { loginUser } = useContext(AppContext);
    const editSubstanceObj = props.location.state.substance

    const [ categories, setCategories] = React.useState([])
    const [ families, setFamilies] = React.useState([])
    const [ chemicals, setChemicals] = React.useState([])
    // const [ ingredients, setIngredients] = React.useState([])
    const [ physicalTypes, setPhysicalTypes] = React.useState([])
    const [ usageTypes, setUsageTypes] = React.useState([])

    // const [ selectedIngredient, setSelectedIngredient] = React.useState(editSubstanceObj.ingredient)
    const [ selectedCategory, setSelectedCategory] = React.useState((editSubstanceObj.group) ? editSubstanceObj.group.group.category_name: "")
    const [ selectedFamily, setSelectedFamily] = React.useState((editSubstanceObj.group) ? editSubstanceObj.group.group.family_name : "")
    const [ selectedChemical, setSelectedChemical] = React.useState((editSubstanceObj.group) ? editSubstanceObj.group.group.chemical_name : "")
    
    const [ selectedPhysicalType, setSelectedPhysicalType] = React.useState((editSubstanceObj.physical_type) ? editSubstanceObj.physical_type.name : "")
    const [ selectedUsageType, setSelectedUsageType] = React.useState((editSubstanceObj.usage_type) ? editSubstanceObj.usage_type.name : "")

    const [ dialogAddIngredientOpen, setDialogAddIngredientOpen] = React.useState(false)
    const [ selectedIngredientChips, setSelectedIngredientChips] = React.useState(editSubstanceObj.ingredient)

    const [ dialogNewSubstanceOpen, setDialogNewSubstanceOpen] = React.useState(false)
    const [ newSubstanceObj] = React.useState(editSubstanceObj)


    const chooseOption = 'choose';
    const notAvailable = 'N/A';

    const sortAlphabetical = (arrayOfObject) => {

        let nameOnly = []
        let sortedNameOnlyArray = []

        arrayOfObject.map((val) => {
            nameOnly.push(val.name)
        })
        sortedNameOnlyArray = nameOnly.sort()

        const sortedObj = sortedNameOnlyArray.map((val) => {
            return arrayOfObject.find((val1) => val1.name == val)
        })
        // console.log('sortedObj',sortedObj)

        return sortedObj

    }


    React.useEffect(() => {

        fetchCategories()
        fetchFamilies(selectedCategory)
        fetchChemcials(selectedFamily, selectedCategory)

        // fetchIngredients()
        fetchGroupName('Physical Type')
        fetchGroupName('Usage Type')



    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, selectedFamily, selectedChemical ])

    const fetchCategories = () => {
        fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=2&sort=name`)
          .then(res => res.json())
          .then((data) => {
            const result = sortAlphabetical(data.data)
            setCategories(result);
          });
    };
    
    const fetchFamilies = (data) => {
        const encodedCategory = encodeURIComponent(data);
        fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=3&group.category_name="${encodedCategory}"&sort=name`)
            .then(res => res.json())
            .then((data) => {
                const result = sortAlphabetical(data.data)
                setFamilies(result);
            });
    };
    
    const fetchChemcials = (data, categoryName) => {
        const encodedCategory = encodeURIComponent(categoryName);
        const encodedFamily = encodeURIComponent(data);
        fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=4&group.category_name="${encodedCategory}"&group.family_name="${encodedFamily}"&sort=name`)
          .then(res => res.json())
          .then((data) => {
            const result = sortAlphabetical(data.data)
            setChemicals(result)
        });
    };
    
    const resetFamilyDropDown = () => {
        setSelectedFamily(chooseOption);
        setFamilies([]);
    };

    const resetChemicalDropDown = () => {
        setSelectedChemical(chooseOption);
        setChemicals([]);
    };
    
    const handleChangeCategory = (event) => {
        setSelectedCategory(event.target.value);
        resetFamilyDropDown();
        resetChemicalDropDown();
    
        if (event.target.value && event.target.value !== chooseOption)
          fetchFamilies(event.target.value);
    };
    
    const handleChangeFamily = (event) => {
        setSelectedFamily(event.target.value);
        resetChemicalDropDown();
    
        if (event.target.value && event.target.value !== chooseOption)
          fetchChemcials(event.target.value, selectedCategory);
    };
    
    const handleChangeChemical = (event) => {
        setSelectedChemical(event.target.value);
    };

    const handleAddIngredient = (bool) => {
        setDialogAddIngredientOpen(bool)
        // fetchIngredients()
    }

    const handleSelectedIngredientChips = (ingredientObj) => {
        setSelectedIngredientChips((prevArray) => [...prevArray, ingredientObj])
    }

    const handleChipRemove = (event , removeIngredientObj) => {

        let result = selectedIngredientChips.filter((ingredient) => ingredient._id != removeIngredientObj._id)
        setSelectedIngredientChips(result)

    }

    const handleNewSubstanceDialog = (bool) => {
        setDialogNewSubstanceOpen(bool)
    }

    // const fetchIngredients = () => {

    //     fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/ingredients`)
    //     .then(res => res.json())
    //     .then((data) => {
    //         setIngredients(data.data)
    //     })
    //     .catch((error) => {
    //         console.error('Error:', error);
    //     })

    // }

    const fetchGroupName = (groupName) => {
        
        let url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs/?groupName=${groupName}&sort=name`;
        let config = {
            method: 'GET',
            timeout: 1000 * 5,
            headers: {
                "Content-Type": "application/json"
            },
        };

        axios( url, config )
            .then(response => {
                if (response.status == 200) {
                    if (groupName == 'Physical Type') {
                        setPhysicalTypes(response.data.data)
                    }
                    else {
                        setUsageTypes(response.data.data)
                    }
                }
            })
            .catch(err => {
                console.log(err.message)
            });

    } 


    async function fetchproductGroupId  (categoryName, familyName, chemicalName)  {
        
        let hello = []
        const findCategoryName = 'group.category_name'
        const findFamilyName = 'group.family_name'
        const findChemicalName = 'group.chemical_name'

        const newCategoryName = encodeURIComponent(categoryName)
        const newFamilyName = encodeURIComponent(familyName)
        const newChemicalName = encodeURIComponent(chemicalName);

        if (categoryName && familyName && chemicalName) {
            const url =  `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?${findCategoryName}="${newCategoryName}"&${findFamilyName}="${newFamilyName}"&${findChemicalName}="${newChemicalName}"&level=4`
        
            await fetch(url)
            .then(res => res.json())
            .then((data) => {
                if (data.data.length > 0) {
                    return hello.push(data.data[0])
                }
            })
        }
        else if (categoryName && familyName) {
            const url =  `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?${findCategoryName}="${newCategoryName}"&${findFamilyName}="${newFamilyName}"&level=3`
        
            await fetch(url)
            .then(res => res.json())
            .then((data) => {
                if (data.data.length > 0) {
                    return hello.push(data.data[0])
                }
            })

        }
        else if (categoryName) {
            const url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?${findCategoryName}="${newCategoryName}"&level=2`
        
            await fetch(url)
            .then(res => res.json())
            .then((data) => {
                if (data.data.length > 0) {
                    return hello.push(data.data[0])
                }
            })
        }
        return hello

 
    }

    const callAPI = (bodyData) => {

        var raw = JSON.stringify(bodyData);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/products/${editSubstanceObj._id}`, {
            method: 'PUT', 
            headers: myHeaders,
            body: raw,
        })
        .then(response => {
            if (response.status >=200 && response.status <=299) {history.push('/substance');    
            return enqueueSnackbar(t('Substance has been edited.'), { variant: 'success' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Substance has fail to edit.'), { variant: 'error' });
        }) 

    }

    const fetchPhysicalTypeObj = async (physicalTypeName) => {

        console.log(physicalTypeName)

        let hello1 = {}
        await fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs/?groupName=Physical Type&name=${physicalTypeName}`)
        .then(res => res.json())
        .then((data) => {
            if (data.data.length > 0) {
                return hello1 = data.data[0]
            }
        })
        return hello1

    }

    const fetchUsageTypeObj = async (usageTypeName) => {

        console.log(usageTypeName)
        const usageType = encodeURIComponent(usageTypeName)
        let hello2 = {}
        await fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs/?groupName=Usage Type&name="${usageType}"`)
        .then(res => res.json())
        .then((data) => {
            if (data.data.length > 0) {
                return hello2 = data.data[0]
            }
        })
        return hello2

    }

    const onSubmit = async (data, e) => {

        let categoryName = (selectedCategory !== chooseOption ? selectedCategory: "")
        let familyName = (selectedFamily !== chooseOption ? selectedFamily: "")
        let chemicalName = (selectedChemical !== chooseOption ? selectedChemical: "")

        var productGroupObj = await fetchproductGroupId(categoryName, familyName, chemicalName)
        var physicalTypeObj = await fetchPhysicalTypeObj(selectedPhysicalType)
        var usageTypeObj = await fetchUsageTypeObj(selectedUsageType)

    
        // no match group type Id
        if (productGroupObj[0] === undefined) {
            return enqueueSnackbar(t('There is no existing group type for selected category, family, chemical.'), { variant: 'error' });
        }

        // match id with user selected category, family, chemical 
        else if (productGroupObj[0]){
    
            const bodyData = {

                // code: 0,
                name: data.name,
                trade_name_th: data.trade_name_th,
                group: productGroupObj[0],
                ingredient: selectedIngredientChips || "",
                physical_type: physicalTypeObj,
                usage_type:  usageTypeObj,
                updated_by: loginUser._id,
                updated_by_name: loginUser.name
                
            }
            console.log('have group id', JSON.stringify(bodyData))
            callAPI(bodyData)
            
        }
     
    } 


    return (
        <Container maxWidth='xl' className={classes.container}>
        <PageHeader
            title= {t("Edit Substance")}
            subtitle= {t("Updating the existing substance for product")}
            buttonName={'New Substance'}
            onClickButton={() => handleNewSubstanceDialog(true)}
        />
        {console.log('selected sub', editSubstanceObj)}
        <Grid item xs={12}  className={classes.top}>
            <Card>
                <form className={classes.form_} id="editForm" onSubmit={handleSubmit(onSubmit)}>

                    {/* <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Product Code')} * </Typography>
                        <div className={classes.parallel}>
                            <TextField
                                fullWidth
                                defaultValue={editSubstanceObj.code}
                                name="code"
                                inputRef={register({required: true})}
                                variant="outlined"
                            />
                            {errors.code &&  <span className={classes.errorMsg}>{t('Product code field is required')}</span>}
                        </div>
                    </Box> */}


                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Name (EN)')} * </Typography>
                        <div className={classes.parallel}>
                            <TextField
                                fullWidth
                                name="name"
                                defaultValue={editSubstanceObj.name}
                                inputRef={register({required: true})}
                                variant="outlined"
                            />
                            {errors.name &&  <span className={classes.errorMsg}>{t('Name (EN) field is required')}</span>}
                        </div>
                    </Box>


                    {/* <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Generic English Name')} </Typography>
                        <TextField
                            fullWidth
                            name="generic_name_en"
                            className={classes.parallel}
                            defaultValue={editSubstanceObj.generic_name_en}
                            inputRef={register}
                            variant="outlined"
                        />
                    </Box> */}

                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Name (TH)')}  </Typography>
                        <TextField
                            fullWidth
                            name="trade_name_th"
                            defaultValue={editSubstanceObj.trade_name_th}
                            className={classes.parallel}
                            inputRef={register}
                            variant="outlined"
                        />
                    </Box>

                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Select Category')} * </Typography>
                        <FormControl className={classes.parallel}   >
                            <Select
                                id="substance-select-category-filled"
                                value={selectedCategory}
                                margin="dense"
                                name="category_name"
                                variant="outlined"
                                defaultValue={chooseOption}
                                onChange={handleChangeCategory}
                            >
                                <MenuItem value={chooseOption} key={chooseOption}>
                                    <em>Choose a Category</em>
                                </MenuItem>

                                {categories && categories.map((category) => (
                                    <MenuItem value={category.name} key={category.name}>
                                    <em>{category.name}</em>
                                    </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>

                    {console.log('select family', selectedFamily)}
                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Select Family')}  </Typography>
                        <FormControl className={classes.parallel} >
                            <Select
                                id="substance-select-family-filled"
                                value={selectedFamily}
                                margin="dense"
                                variant="outlined"
                                name="family_name"
                                defaultValue={chooseOption}
                                onChange={handleChangeFamily}
                            >
                                <MenuItem value={chooseOption} key={chooseOption}>
                                    <em>{selectedCategory && selectedCategory !== chooseOption ? "Choose a Family" : notAvailable}</em>
                                </MenuItem>

                                {families && families.map((family) => (
                                    <MenuItem value={family.name} key={family.name}>
                                        <em>{family.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Box>

                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Select Chemical')}  </Typography>
                        <FormControl className={classes.parallel}   >
                            <Select
                                id="substance-select-chemical-filled"
                                value={selectedChemical}
                                margin="dense"
                                variant="outlined"
                                defaultValue={chooseOption}
                                name="chemical_name"
                                onChange={handleChangeChemical}
                            >
                                <MenuItem value={chooseOption} key={chooseOption}>
                                    <em>{selectedFamily && selectedFamily !== chooseOption ? "Choose a Chemical" : notAvailable}</em>
                                </MenuItem>

                                {chemicals && chemicals.map((chemical) => (
                                    <MenuItem value={chemical.name} key={chemical.name}>
                                        <em>{chemical.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Box>

                   
                    {/* Physical type */}
                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Physical type')} * </Typography>
                        <FormControl className={classes.parallel}   >
                            <Controller
                                name="physical_type"
                                inputRef={register}
                                control={control}
                                defaultValue={selectedPhysicalType}
                                rules={{ required: true }}
                                render={({ onChange, value, name }) => (
                                    <Select
                                        margin="dense"
                                        variant="outlined"
                                        value={selectedPhysicalType}
                                        fullWidth
                                        onChange={(e, data) => {
                                            onChange(e.target.value)
                                            setSelectedPhysicalType(e.target.value)
                                        }}
                                    >
                                        <MenuItem  value="">N/A</MenuItem>
                                        {physicalTypes && physicalTypes.map((physicalType) => (
                                            <MenuItem  value={physicalType.name}>{physicalType.name}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.physical_type &&  <span className={classes.errorMsg}>{t('Physical type field is required')}</span>}

                        </FormControl>
                    </Box>

                    {/* Usage type */}
                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Usage type')} * </Typography>
                        <FormControl className={classes.parallel}   >
                            <Controller
                                name="usage_type"
                                inputRef={register}
                                control={control}
                                defaultValue={selectedUsageType}
                                rules={{ required: true }}
                                render={({ onChange, value, name }) => (
                                    <Select
                                        margin="dense"
                                        variant="outlined"
                                        value={selectedUsageType}
                                        fullWidth
                                        onChange={(e, data) => {
                                            onChange(e.target.value)
                                            setSelectedUsageType(e.target.value)
                                        }}
                                    >
                                        <MenuItem  value="">N/A</MenuItem>
                                        {usageTypes && usageTypes.map((usageType) => (
                                            <MenuItem  value={usageType.name}>{usageType.name}</MenuItem>
                                        ))}
                                    </Select>
                                 )}
                             />
                            {errors.usage_type &&  <span className={classes.errorMsg}>{t('Usage type field is required')}</span>}
 
                        </FormControl>
                    </Box>

                    
                    <Box display="flex" flexDirection="row">
                        <Typography  className={classes.middle}>{t('Select Ingredients')}  </Typography>
                        {/* <FormControl className={classes.parallel}   >
                            <Controller
                                name="ingredient"
                                onChange={([, data]) => data}
                                control={control}
                                defaultValue={selectedIngredient}
                                render={({onChange, value, ...props}) => (
                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        defaultValue={value}
                                        options={ingredients}
                                        getOptionSelected={(value, option) => value._id == option._id}
                                        onChange={(e, data) => onChange(data)}
                                        getOptionLabel={(ingredient) => ingredient.name}
                                        renderOption={(ingredient) => ingredient.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={t('Ingredients')}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </FormControl> */}

                        {/* List Chips */}
                        <div className={classes.parallel}>
                            {selectedIngredientChips.map((selectedIngredientChip) => {
                                return (
                                    <Chip
                                        size="small"
                                        style={{marginTop: '5px', marginBottom:'5px'}}
                                        color="primary"
                                        variant="outlined"
                                        label={selectedIngredientChip.name}
                                        onDelete={(e) => handleChipRemove(e, selectedIngredientChip)}
                                    />
                                )
                            })}

                        </div> 
                        <Button 
                            variant='contained' 
                            color='primary'
                            onClick={() => handleAddIngredient(true)}
                            className={classes.btnNewIngredient}
                        >
                            Add Ingredient
                        </Button>
                    </Box>
 


                    

                    <Box className={classes.btn} >
                        <Button variant="contained" form="editForm" type="submit"  color="primary"> {t('Save')} </Button>
                        <Button variant="contained" color="primary"  onClick={() => history.push('/substance')}> {t('Cancel')} </Button>
                    </Box> 



                </form>

                
                {dialogAddIngredientOpen == true && (
                    <DialogAddIngredient
                        dialogAddIngredientOpen={dialogAddIngredientOpen}
                        handleAddIngredient={handleAddIngredient}
                        handleSelectedIngredientChips={handleSelectedIngredientChips}
                    />
                )}

                {dialogNewSubstanceOpen == true && (
                    <DialogNewSubstance
                        dialogNewSubstanceOpen={dialogNewSubstanceOpen}
                        handleNewSubstanceDialog={handleNewSubstanceDialog}
                        newSubstanceObj={newSubstanceObj}
                    />
                )}
            </Card>

        </Grid>

    </Container>

    )
}
export default EditSubstance;
