import React from 'react';
import {Grid, Card, TextField, CardContent, List, Box, Button} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm} from "react-hook-form";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';


const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    form_team: {
        marginTop: "1%",
        marginLeft: "38%"
    },
    btn: {
        textAlign: "center",
        '& > *': {
          margin: theme.spacing(1),
        },
    }


}))


const Create = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors} = useForm({mode: 'onSubmit'});
    // const [count, setCount] = useState(0)
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();


    const onSubmit= (data,e) => {
      console.log(data);
      var raw = JSON.stringify(data);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      console.log(raw)
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/templates`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >=200 && response.status <=299){history.push('/config');    
        return enqueueSnackbar(t('Template has been created.'), { variant: 'success' });  
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Template has fail to create.'), { variant: 'error' });  
      }) 

    }

    return (
        <Grid container spacing={1} >
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title= {t("Create New Template")}
                    subtitle= {t("Create more detials for the Template")}
                />
            </Container>
            <Grid item xs={8}>
                <Card className={classes.form_team} >
                    <CardContent>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <List>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    name="name" 
                                    inputRef={register({required: true})} 
                                    label= {t("Dialog Name *")}
                                    helperText={errors.name && t("Name field is required")}
                                    error={errors.name}
                                />
    
                            </List>                            
                            <List>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    name="type" 
                                    inputRef={register} 
                                    label= {t("Dialog Type")}
                                ></TextField>
                            </List>
                            <List>
                                <TextField
                                    fullWidth
                                    label= {t("Description *")}
                                    name="desc"
                                    error= {errors.desc}
                                    inputRef={register({required: true})}
                                    helperText={errors.desc && t("Description field is required")}
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                />
                            </List>
                            <List>
                                <TextField
                                    fullWidth
                                    label= {t("Details (Text) *")}
                                    name="text"
                                    error= {errors.text}
                                    inputRef={register({required: true})}
                                    helperText={errors.text && t("Text field is required")}
                                    multiline
                                    rows={10}
                                    variant="outlined"
                                />
                            </List>
                            <Box className={classes.btn} >
                                <Button variant="contained" type="submit"  color="primary"> {t('Create')} </Button>
                                <Button variant="contained" color="primary"  onClick={() => history.push('/config')}> {t('Cancel')} </Button>
                            </Box> 
                        </form>
                    </CardContent>

                </Card>
            </Grid>
        </Grid>
    )
}
export default Create;