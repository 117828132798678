import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, IconButton, Menu, Tooltip, Typography } from '@material-ui/core';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { AppContext } from "../contexts/AppStore";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   '& > *': {
  //     margin: theme.spacing(1),
  //   },
  // },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  text: {
    fontSize: "200px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledBox = withStyles((theme) => ({
  root: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 5,
    marginBottom: 5,
  },
}))(Box);

export function LoggedInDisplay() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loginUser } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log("loginUser ==> ", loginUser);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Tooltip title={loginUser.name}>
        <IconButton
          color="inherit"
          onClick={handleClick}
        >
        <Avatar className={classes.small} alt={loginUser.name} src="/broken-image.jpg" />
        </IconButton>
      {/* <Avatar onClick={handleClick}>H</Avatar> */}
      </Tooltip>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
        disableAutoFocus={true}
      >
        <StyledBox>
          <div>{t("general_name")}: {loginUser.name}</div>
          <div>{t("general_role")}: {loginUser.role.name}</div>
          <div>{t("general_team")}: {loginUser.teamObj.name}</div>
          <div>{t("general_extension")}: {loginUser.extension}</div>
        </StyledBox>
        
      </StyledMenu>
    </div>
  );
}

export function LoggedInDataDisplay() {
  const classes = useStyles();
  const { loginUser } = useContext(AppContext);

  return (
    <div className={classes.root}>
      <Typography>
      <span>[<b>{loginUser.extension}</b>]&nbsp;&nbsp;</span>
      <span><b>{loginUser.username}</b>&nbsp;&nbsp;</span>
      <span>(<b>{loginUser.role.name}</b>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
      <span><b>{loginUser.teamObj.name}</b>)&nbsp;&nbsp;</span>
      </Typography>
    </div>
  );
}