import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) =>
  createStyles({
    inline: {
      display: 'inline',
      fontWeight: 500,
    },
    title: {
      color: '#484848',
    },
  }),
);

const NotificationMenuItem = ({ pathTo, title, sender, notifyAt, onClick }) => {
  const classes = useStyles();

  const handleClickItem = () => {
    if (onClick) onClick();
  }

  return (
    <React.Fragment>
      <Link
        underline="none"
        to={pathTo}
        component={RouterLink}
        onClick={handleClickItem}
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={sender ? sender.charAt(0) : ''} src="#" />
          </ListItemAvatar>
          <ListItemText
            primary=""
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {sender ? `${sender} — ` : ''}
                </Typography>
                <span className={classes.title}>{title}</span>
                <br />
                {notifyAt}
              </React.Fragment>
            }
          />
        </ListItem>
      </Link>
      <Divider component="li" />
    </React.Fragment>
  )
}

NotificationMenuItem.propTypes = {
  pathTo: PropTypes.string,
  title: PropTypes.string,
  sender: PropTypes.string,
  notifyAt: PropTypes.string,
  onClick: PropTypes.func,
}

NotificationMenuItem.defaultProps = {
  pathTo: '',
  title: '',
  sender: '',
  notifyAt: '',
  onClick: undefined,
}

export default NotificationMenuItem
