import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button, Box, TextField, Select, MenuItem, FormControl,  Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {useForm, Controller} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {  useSnackbar } from 'notistack';


const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    showBtn: {
        justifyContent: "space-between"
    },
    parallel: {
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    FormControl: {
        minWidth: 120
    },
    middle: {
        width: "40%",
        maxWidth: "40%"
    },
    errorMsg : {
        color: "#f44336"
    },
    hello: {
        justifyContent: 'flex-end'
    }


}))

const CreateDialogBox = ({ handleCreateDialogClose, createDialogOpen, fetchCustomConfigs, groupNames}) => {
  const { register, handleSubmit, errors, control } = useForm();
  const { t } = useTranslation();
  const classes= useStyles();
  const { enqueueSnackbar } =useSnackbar();
  const [boolAddNewGroup, setBoolAddNewGroup] = React.useState(false);
  const [value, setValue] = React.useState('existing group name')

  const handleChange_radio = (event) => {
    setValue(event.target.value);
    };



  const onSubmit = (data) => {
      console.log(data)

      var raw = JSON.stringify(data);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FDQN}/custom-rama/api/v1/customTMConfigs`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >=200 && response.status <= 299){
            handleCreateDialogClose()
            fetchCustomConfigs()
            return enqueueSnackbar(t('New custom config has been created.'), { variant: 'success' });
        }
      })
      .catch((error) => {
       console.error('Error:', error);
       enqueueSnackbar(t('New custom config has fail to create.'), { variant: 'error' })
      }) 

  }



  return (
    <div>
        <Dialog 
            fullWidth
            maxWidth='xs' 
            onClose={handleCreateDialogClose} 
            disableBackdropClick 
            open={createDialogOpen}
        >
            <DialogTitle id="customized-dialog-title">
                {t("New Ticket Config")}
            </DialogTitle>

            <DialogContent dividers>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form} id="form" >
                    <Box display="flex" flexDirection="row">
                        <Typography className={classes.middle} >{t('GroupName')} * </Typography>
                        <RadioGroup value={value}  onChange={handleChange_radio}className={classes.help_}>
                            <Box display="flex" flexDirection="row">
                                <FormControlLabel onClick={() => setBoolAddNewGroup(false)} value="existing group name" control={<Radio />} label={t("Existing group name")} />
                                <FormControlLabel onClick={() => setBoolAddNewGroup(true)} value="new group name" control={<Radio />} label={t("New group name")} />
                            </Box>
                        </RadioGroup>
                    </Box>

                    <Box display='flex' flexDirection='row' className={classes.hello}>
                        {boolAddNewGroup == false && ( 
                            <Grid item xs={6}>
                            <Controller
                                name="groupName"
                                inputRef={register}
                                control={control}
                                render={({ onChange, value, name }) => (
                                    <Select
                                        margin="dense"
                                        variant="outlined"
                                        value={value}
                                        fullWidth
                                        className={classes.parallel}
                                        onChange={(e, data) => {
                                            onChange(e.target.value)
                                        }}
                                    >
                                        <MenuItem  value=''>N/A</MenuItem>
                                        {groupNames && groupNames.map((groupName) => (
                                            <MenuItem  value={groupName}>{groupName}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            </Grid>
                            // </div>

                        )}

                        {(boolAddNewGroup && (
                            <Box display='flex' flexDirection='row'>
                                <div>
                                <TextField 
                                    inputRef={register({required: true})}
                                    fullWidth
                                    variant="outlined" 
                                    className={classes.parallel}
                                    size="small"
                                    name="groupName"
                                ></TextField>
                                </div>
                            </Box>
                        ))}
                    </Box>

     

                    <Box display="flex" flexDirection="row">
                        <Typography className={classes.middle}  >{t('Name')} * </Typography>
                        <div>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                name="name" 
                                inputRef={register({required: true})}
                                className={classes.parallel} 
                                size="small"
                            />
                            {errors.itemName &&  <span>{t('TM Name field is required')}</span>}
                        </div>
                    </Box>

                    <Box display="flex" flexDirection="row">
                        <Typography className={classes.middle}  >{t('Item Descirption')} </Typography>
                        <div>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                name="desc" 
                                inputRef={register}
                                className={classes.parallel} 
                                size="small"
                                multiline
                                rows="3"
                                size='small'
                            />
                        </div>
                    </Box>            
                </form> 
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleCreateDialogClose} color="secondary">
                    {t('TM Config Cancel')}
                </Button>
                <Button autoFocus type="submit" color="secondary" form="form">
                    {t('TM Config Create')}

                </Button>
            </DialogActions>
        </Dialog>
    </div>
  );

}
export default CreateDialogBox;