import axios from 'axios';
import url from 'url';

const ELASTIC_SEATCH_SUBSTANCE_URL = url.resolve(process.env.REACT_APP_ELASTIC_SEARCH_FQDN, '/elasticsearch/cream.products/_search');
const GET_SOME_OPERATION_METHOD_URL = url.resolve(process.env.REACT_APP_CUSTOM_EXAT_BACKEND_FQDN, '/custom-exat/api/v1/operation-methods');
const GET_SOME_SUBJECT_ISO_URL = url.resolve(process.env.REACT_APP_CUSTOM_EXAT_BACKEND_FQDN, '/custom-exat/api/v1/subject-isos');
const GET_SOME_CCM_RESULT_URL = url.resolve(process.env.REACT_APP_CUSTOM_EXAT_BACKEND_FQDN, '/custom-exat/api/v1/ccm-results');
const PUT_UPDATE_CCM_RESULT_URL = url.resolve(process.env.REACT_APP_CUSTOM_EXAT_BACKEND_FQDN, '/custom-exat/api/v1/ccm-results');
const GET_SOME_HOSPITAL_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/hospitals');
const GET_SOME_SUBSTANCE_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/products');
const GET_SOME_FOLLOWUP_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/followups');
const GET_ONE_FOLLOWUP_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/followups');
const PUT_UPDATE_FOLLOWUP_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/followups');
const POST_CREATE_FOLLOWUP_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/followups');
const GET_SOME_FORMULA_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/formulas');
const GET_SOME_PROVINCE_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/provinces');
const GET_SOME_REGION_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/provinces');
const GET_SOME_INGREDIENT_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/ingredients');
const GET_SOME_SUBSTANCE_CATEGORY_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/productGroups');
const GET_SOME_SUBSTANCE_FAMILY_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/productGroups');
const GET_SOME_SUBSTANCE_CHEMICAL_URL = url.resolve(process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN, '/custom-rama/api/v1/productGroups');

export const getOperationMethods = () => {
  return axios({
    url: GET_SOME_OPERATION_METHOD_URL,
    method: 'GET',
  });
};

export const getSubjectISOs = () => {
  return axios({
    url: GET_SOME_SUBJECT_ISO_URL,
    method: 'GET',
  });
};

export const getCcmResults = (query = '') => {
  return axios({
    url: `${GET_SOME_CCM_RESULT_URL}?${query}`,
    method: 'GET',
  });
};

export const updateCcmResult = (ccmResultId, payload) => {
  return axios({
    url: `${PUT_UPDATE_CCM_RESULT_URL}/${ccmResultId}`,
    method: 'PUT',
    data: payload,
  });
};

export const getHospitals = (query = '') => {
  return axios({
    url: `${GET_SOME_HOSPITAL_URL}?${query}`,
    method: 'GET',
  });
};

export const getProvinces = (query = '') => {
  return axios({
    url: `${GET_SOME_PROVINCE_URL}?${query}`,
    method: 'GET',
  });
};

export const getRegions = (query = '') => {
  return axios({
    url: `${GET_SOME_REGION_URL}?${query}`,
    method: 'GET',
  });
};

export const getSubstances = () => {
  return axios({
    url: GET_SOME_SUBSTANCE_URL,
    method: 'GET',
  });
};

export const getFollowups = (queryString = '') => {
  return axios({
    url: `${GET_SOME_FOLLOWUP_URL}?${queryString}`,
    method: 'GET',
  });
};

export const getFollowup = (followupId) => {
  return axios({
    url: `${GET_ONE_FOLLOWUP_URL}/${followupId}`,
    method: 'GET',
  });
};

export const createFollowup = (payload) => {
  return axios({
    url: POST_CREATE_FOLLOWUP_URL,
    method: 'POST',
    data: payload,
  });
};

export const updateFollowup = (followupId, payload) => {
  return axios({
    url: `${PUT_UPDATE_FOLLOWUP_URL}/${followupId}`,
    method: 'PUT',
    data: payload,
  });
};

export const getFormulas = () => {
  return axios({
    url: GET_SOME_FORMULA_URL,
    method: 'GET',
  });
};

export const searchSubstances = (keyword = '') => {
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('.', ' ');
  return axios({
    url: ELASTIC_SEATCH_SUBSTANCE_URL,
    method: 'POST',
    data: {
      query: {
        filtered: {
          query: {
            query_string: {
              fields: ['code', 'name', 'trade_name_th', 'ingredient.name'],
              query: `*${keyword}*`,
            },
          },
        },
      },
    },
  });
};

export const getSubstanceCategories = (query = '') => {
  return axios({
    url: `${GET_SOME_SUBSTANCE_CATEGORY_URL}?level=2&${query}`,
    method: 'GET',
  });
};

export const getSubstanceFamilies = (query = '') => {
  return axios({
    url: `${GET_SOME_SUBSTANCE_FAMILY_URL}?level=3&${query}`,
    method: 'GET',
  });
};

export const getSubstanceChemicals = (query = '') => {
  return axios({
    url: `${GET_SOME_SUBSTANCE_CHEMICAL_URL}?level=4&${query}`,
    method: 'GET',
  });
};

// https://192.168.102.112/custom-rama/api/v1/ingredients
// ?name=Dicyclomine
// &group.group.category_name=Medical%20Drugs
// &group.group.family_name=Alimentary%20System
// &group.group.chemical_name=Antispasmodics
export const getDuplicateIngredients = (ingredientName, category, family, chemical, excludeId) => {
  return axios({
    url: GET_SOME_INGREDIENT_URL,
    method: 'GET',
    params: {
      name: ingredientName,
      'group.group.category_name': category || 'null',
      'group.group.family_name': family || 'null',
      'group.group.chemical_name': chemical || 'null',
      ...(excludeId && { '_id!': excludeId }),
    },
  });
};
