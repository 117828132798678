import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/AddOutlined";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.5rem 0.75rem 1.25rem 0.25rem",
  },
  title: {
    paddingTop: 0,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.75),
    },
  },
  subtitle: {
    marginTop: theme.spacing(0.25),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.25),
    },
  },
  gridButton: {
    paddingRight: "0px !important",
    margin: theme.spacing(1),
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "6px",
      padding: "4px 10px",
      fontSize: "0.8125rem",
    },
  },
}));

const PageHeader = ({
  title,
  subtitle,
  buttonName,
  url,
  toSetTrigger
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fromName, setFromName] = useState();
  const [toName, setToName] = useState();
  const [ext, setExt] = useState();
  const [phone, setPhone] = useState();
  const [csq, setCsq] = useState();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bodyData = {
    from: fromName,
    to: toName,
    ext: ext,
    phone: phone,
    csq: csq,
    dateTimeStart: new Date(),
    dateTimeEnd: new Date()
  };

  const handleEnterAndClose = async() => {
    await setOpen(false);
    var raw = await JSON.stringify(bodyData);
    var myHeaders = await new Headers();
    await myHeaders.append("Content-Type", "application/json");
    await fetch(`${url}/tsm/api/v1/screenpop/`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
    }).then(() => console.log("Data is successfully updated."))
    .catch(error => console.log(error))
    await toSetTrigger()
    await setFromName('');
    await setToName('');
    await setExt('');
    await setPhone('');
    await setCsq('');
    await setOpen('');
  };

  const handleOnChange = async (func, setTo) => {
    await func(setTo);
    await console.log(setTo);
  };

  return (
    <div className={classes.root}>
      <Grid
        alignItems="flex-start"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
        </Grid>
        <Grid item className={classes.gridButton}>
          {/* <IconButton
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={onClickUpload}
          >
            <CloudUploadIcon/>
          </IconButton>

          <IconButton
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={onClickDown}
          >
            <CloudDownloadIcon/>
          </IconButton> */}

          {buttonName && (
            <>
              <Tooltip title={t("create")}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                >
                  {buttonName}
                </Button>
              </Tooltip>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">{t("create")}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To create the new one row, please enter your from name, to
                    name, Ext.number, phone and CSQ.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={t("screenpop_from")}
                    type="text"
                    value={fromName}
                    onChange={(e) => handleOnChange(setFromName, e.target.value)}
                    fullWidth
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={t("screenpop_to")}
                    type="text"
                    value={toName}
                    onChange={(e) => handleOnChange(setToName, e.target.value)}
                    fullWidth
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={t("screenpop_ext")}
                    type="number"
                    value={ext}
                    onChange={(e) => handleOnChange(setExt, e.target.value)}
                    fullWidth
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={t("screenpop_phone")}
                    type="number"
                    value={phone}
                    onChange={(e) => handleOnChange(setPhone, e.target.value)}
                    fullWidth
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="name"
                    label={t("screenpop_csq")}
                    type="text"
                    value={csq}
                    onChange={(e) => handleOnChange(setCsq, e.target.value)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                  {t("cancel")}
                  </Button>
                  <Button onClick={() => handleEnterAndClose()} color="primary">
                  {t("enter")}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  onClickButton: PropTypes.func,
  // uploadBtnName: PropTypes.string,
  // onClickUpload: PropTypes.func,
  // onClickDown: PropTypes.func,
};

PageHeader.defaultProps = {
  buttonName: undefined,
  onClickButton: undefined,
  // uploadBtnName: undefined,
  // onClickUpload: undefined,
  // onClickUpload: undefined
};

export default PageHeader;
