/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory } from 'react-router-dom';
import RouteLayout from './components/RouteLayout'
import SecureLayout from './layouts/SecureLayout'
import BlankLayout from './layouts/BlankLayout'
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import LoginPage from './pages/LoginPage';
import ForbiddenPage from './pages/ForbiddenPage';
import IncidentReportPage from './modules/TM/src/pages/IncidentReportPage';
import REPORT01_IVRMenuSummaryReportMonthlyByDate from './modules/REPORT/src/pages/STD/REPORT01_IVRMenuSummaryReportMonthlyByDate';
import REPORT02_CSQSummaryReport from './modules/REPORT/src/pages/STD/REPORT02_CSQSummaryReport';
import REPORT03_IVRMenuSummaryReportByTimeInterval from './modules/REPORT/src/pages/STD/REPORT03_IVRMenuSummaryReportByTimeInterval';
import REPORT04_CSQSummaryReportByTimeInterval from './modules/REPORT/src/pages/STD/REPORT04_CSQSummaryReportByTimeInterval';
import REPORT05_IVRMenuSummaryReportByMonthlyCompare from './modules/REPORT/src/pages/STD/REPORT05_IVRMenuSummaryReportByMonthlyCompare';
import REPORT06_AgentLoginOutDurationAndStageSummary from './modules/REPORT/src/pages/STD/REPORT06_AgentLoginOutDurationAndStageSummary';
import CallbackDetailsReport from './modules/REPORT/src/pages/STD/CallbackDetailsReport';
import IvrMenuSummaryReport from './modules/REPORT/src/pages/STD/IvrMenuSummaryReport';
import SurveyCsqSummaryReport from './modules/REPORT/src/pages/STD/SurveyCsqSummaryReport';
import SurveyAgentSummaryReport from './modules/REPORT/src/pages/STD/SurveyAgentSummaryReport';
import OutgoingCallDetailsReport from './modules/REPORT/src/pages/STD/OutgoingCallDetailsReport';
import TicketSummaryByCategoryReport from './modules/REPORT/src/pages/STD/TicketSummaryByCategoryReport';
import TicketSummaryStatsReport from './modules/REPORT/src/pages/STD/TicketSummaryStatsReport';
import TicketProblemSummaryReport from './modules/REPORT/src/pages/STD/AverageServiceTimeSummaryReport';
import KbSummaryReport from './modules/REPORT/src/pages/STD/KbSummaryReport';
import KbDetailReport from './modules/REPORT/src/pages/STD/KbDetailReport';
import KbMostActiveReport from './modules/REPORT/src/pages/STD/KbMostActiveReport';
import KbKeywordSummaryReport from './modules/REPORT/src/pages/STD/KbKeywordSummaryReport';
import KbKeywordReport from './modules/REPORT/src/pages/STD/KbKeywordReport';
import KbDashboardReport from './modules/REPORT/src/pages/STD/KbDashboardReport';
import OverdueSLADetailReport from './modules/REPORT/src/pages/STD/OverdueSLADetailReport';
import Contacts from './modules/CONTACT/src/Pages/Contacts'
import RamaContactPoint from './modules/CONTACT/src/Pages/RAMAContactPointPage'
import RamaContactCaller from './modules/CONTACT/src/Pages/RAMACallerPage'
import RamaContactPatient from './modules/CONTACT/src/Pages/RAMAContactPatientPage'
import Create from './modules/CONTACT/src/Pages/Create'
import Edit from './modules/CONTACT/src/Pages/Edit'
import KB from './modules/KB/src/Pages/KB'
import Editor from './modules/KB/src/Pages/MyEditor'
import CreateKB from './modules/KB/src/Pages/CreateKB'
import ViewKB from './modules/KB/src/Pages/ViewKB'
import Approve from './modules/KB/src/Pages/Approving'
import Version from './modules/KB/src/Pages/Version'
import SeeAlso from './modules/KB/src/Pages/SeeAlso'
import Config from './modules/CONFIG/src/Pages/Main'
import ConfigUsers_Create from './modules/CONFIG/src/Pages/Users/Create'
import ConfigUsers_Edit from './modules/CONFIG/src/Pages/Users/Edit'
import ConfigTemplate_Create from './modules/CONFIG/src/Pages/Template/Create'
import ConfigTemplate_Edit from './modules/CONFIG/src/Pages/Template/Edit'
import ConfigTeam_Create from './modules/CONFIG/src/Pages/Teams/Create'
import ConfigTeam_Edit from './modules/CONFIG/src/Pages/Teams/Edit'
import ConfigRole_Create from './modules/CONFIG/src/Pages/Role/Create'
import ConfigRole_Edit from './modules/CONFIG/src/Pages/Role/Edit'
import ConfigPriority_Create from './modules/CONFIG/src/Pages/Priority/Create';
import ConfigPriority_Edit from './modules/CONFIG/src/Pages/Priority/Edit';
import ConfigCategory_Create from './modules/CONFIG/src/Pages/Category/Create';
import ConfigCategory_Edit from './modules/CONFIG/src/Pages/Category/Edit';
import ConfigStatus_Create from './modules/CONFIG/src/Pages/Status/Create';
import ConfigStatus_Edit from './modules/CONFIG/src/Pages/Status/Edit';
import ConfigChannel_Create from './modules/CONFIG/src/Pages/Channel/Create';
import ConfigChannel_Edit from './modules/CONFIG/src/Pages/Channel/Edit';
import ConfigAnnounce_Create from './modules/CONFIG/src/Pages/Announcement/Create'
import ConfigAnnounce_Edit from './modules/CONFIG/src/Pages/Announcement/Edit'
import ConfigGeneral_Edit from './modules/CONFIG/src/Pages/General/Edit'
import ConfigAnnounceGroup_Create from './modules/CONFIG/src/Pages/Announcement Group/Create'
import ConfigAnnounceGroup_Edit from './modules/CONFIG/src/Pages/Announcement Group/Edit'
import EmailPage from './modules/EMAIL/index';
import Substance from './modules/Substance/index';
import NewSubstance from './modules/Substance/Pages/NewSubstance';
import EditSubstance from './modules/Substance/Pages/EditSubstance';
import TextToSpeech from './modules/TEXT_TO_SPEECH/index';
import Ingredient from './modules/INGREDIENT/index';
import NewIngredient from './modules/INGREDIENT/Pages/NewIngredient';
import EditIngredient from './modules/INGREDIENT/Pages/EditIngredient';
import LoadingScreen from './modules/TM/src/components/LoadingScreen';
import { logger, helper } from './modules/TM/src/common';
import { DashboardAPI } from './apis';
import { AppContext } from './contexts/AppStore'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './modules/TM/src/styles/utilities.css';
import './modules/TM/src/styles/overrides.css';
import './i18next';
import selectedModules from './modules/SELECTED_MODULES';
import RAMA_REPORT1 from './modules/REPORT/src/pages/RAMA/REPORT1'
import RAMA_REPORT2 from './modules/REPORT/src/pages/RAMA/REPORT2'
import RAMA_REPORT3 from './modules/REPORT/src/pages/RAMA/REPORT3'
import RAMA_REPORT4 from './modules/REPORT/src/pages/RAMA/REPORT4'
import RAMA_REPORT5 from './modules/REPORT/src/pages/RAMA/REPORT5'
import RAMA_REPORT6 from './modules/REPORT/src/pages/RAMA/REPORT6'
import RAMA_REPORT7 from './modules/REPORT/src/pages/RAMA/REPORT7'
import RAMA_REPORT8 from './modules/REPORT/src/pages/RAMA/REPORT8'
import RAMA_REPORT9 from './modules/REPORT/src/pages/RAMA/REPORT9'
import RAMA_REPORT10 from './modules/REPORT/src/pages/RAMA/REPORT10'
import RAMA_REPORT11 from './modules/REPORT/src/pages/RAMA/REPORT11'
import RAMA_REPORT12 from './modules/REPORT/src/pages/RAMA/REPORT12'
import RAMA_REPORT13 from './modules/REPORT/src/pages/RAMA/REPORT13'
import RAMA_REPORT14 from './modules/REPORT/src/pages/RAMA/REPORT14'
import RAMA_REPORT15 from './modules/REPORT/src/pages/RAMA/REPORT15'
import RAMA_REPORT16 from './modules/REPORT/src/pages/RAMA/REPORT16'
import RAMA_REPORT17 from './modules/REPORT/src/pages/RAMA/REPORT17'
import RAMA_REPORT18 from './modules/REPORT/src/pages/RAMA/REPORT18'
import RAMA_REPORT19 from './modules/REPORT/src/pages/RAMA/REPORT19'
import RAMA_REPORT20 from './modules/REPORT/src/pages/RAMA/REPORT20'
import RAMA_REPORT21 from './modules/REPORT/src/pages/RAMA/REPORT21'
import RAMA_REPORT22 from './modules/REPORT/src/pages/RAMA/REPORT22'
import RAMA_REPORT23 from './modules/REPORT/src/pages/RAMA/REPORT23'

import DashboardPage from './modules/Dashboard'
import Troubleshoot from './modules/Troubleshoot/src/Pages/Main'
import useLoadResources from './hooks/use-load-resources';


console.table(`REACT_APP_BUILD_TARGET = ${process.env.REACT_APP_BUILD_TARGET}`);

// Adds new spliting component here
const CreateTicketPage = React.lazy(() => import(`./modules/TM/src/pages/${process.env.REACT_APP_BUILD_TARGET}/CreateTicketPage`));
const UpdateTicketPage = React.lazy(() => import(`./modules/TM/src/pages/${process.env.REACT_APP_BUILD_TARGET}/UpdateTicketPage`));
const AllTicketPage = React.lazy(() => import(`./modules/TM/src/pages/${process.env.REACT_APP_BUILD_TARGET}/AllTicketPage`));
const MyTicketPage = React.lazy(() => import(`./modules/TM/src/pages/${process.env.REACT_APP_BUILD_TARGET}/MyTicketPage`));
const TeamTicketPage = React.lazy(() => import(`./modules/TM/src/pages/${process.env.REACT_APP_BUILD_TARGET}/TeamTicketPage`));
const StudyTicketPage = React.lazy(() => import(`./modules/TM/src/pages/${process.env.REACT_APP_BUILD_TARGET}/StudyTicketPage`));
const ChatPage = React.lazy(() => import(`./modules/CHAT/${process.env.REACT_APP_CHAT}/pages/index`));
let ChatQueuePage;
if (process.env.REACT_APP_CHAT === 'EXTERNAL') {
  // Currently ONLY used for PRINC Demo (shows Forviz Chat Queue page in addition to the normal Chat page)
  ChatQueuePage = React.lazy(() => import(`./modules/CHAT/${process.env.REACT_APP_CHAT}/pages/chatQueue`));
}
const PoolTicketPage = React.lazy(() => import(`./modules/TM/src/pages/RAMA/PoolTicketPage`));

// The component that present this file
const App = ({ locale }) => {
  const history = useHistory()
  const { i18n } = useTranslation();
  const { isLoading } = useLoadResources();
  const { loginUser, setLoginUser } = useContext(AppContext);

  // Side effect of props.locale changes
  useEffect(() => {
    if (locale) i18n.changeLanguage(locale);
  }, [i18n, locale]);

  // Side effect of componentDidMount.
  useEffect(() => {
    DashboardAPI.getSession()
      .then((response) => {
        const sessionUser = response.data;
        setLoginUser(sessionUser);
      })
      .catch(() => {
        logger.warn('Your session has expired!');
        history.push('/login' + window.location.search);
      });
  }, []);

  return (
    <>
      {/* Render Loading */}
      {isLoading && <LoadingScreen />}

      {/* Render Login */}
      {(!isLoading && !loginUser) && (
        <Switch>
          <RouteLayout path="/login" component={LoginPage} layout={BlankLayout} />
        </Switch>
      )}

      {/* Render App */}
      {(!isLoading && loginUser) && (
        <SnackbarProvider maxSnack={3} anchorOrigin={helper.getSnackbarOptions()}>

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Suspense fallback>
              <Switch>
                <RouteLayout path="/login" component={LoginPage} layout={BlankLayout} />

                <RouteLayout exact path="/dashboard" component={DashboardPage} layout={SecureLayout} />

                <RouteLayout path="/tickets/all" component={AllTicketPage} layout={SecureLayout} />
                <RouteLayout path="/tickets/me" component={MyTicketPage} layout={SecureLayout} />
                <RouteLayout path="/tickets/team" component={TeamTicketPage} layout={SecureLayout} />
                <RouteLayout path="/tickets/study" component={StudyTicketPage} layout={SecureLayout} />
                <RouteLayout path="/tickets/create" component={CreateTicketPage} layout={SecureLayout} showBackButton />
                <RouteLayout path="/tickets/update/:paramId" component={UpdateTicketPage} layout={SecureLayout} showBackButton />
                <RouteLayout path="/tickets/incident/:paramId" component={IncidentReportPage} layout={BlankLayout} />
                {process.env.REACT_APP_BUILD_TARGET === "RAMA" && <RouteLayout path="/tickets/pool" component={PoolTicketPage} layout={SecureLayout} />}


                <RouteLayout exact path="/contacts" component={Contacts} layout={SecureLayout} />
                {process.env.REACT_APP_BUILD_TARGET === "RAMA" &&  <RouteLayout exact path="/contacts/contactpoint" component={RamaContactPoint} layout={SecureLayout} />}
                {process.env.REACT_APP_BUILD_TARGET === "RAMA" &&  <RouteLayout exact path="/contacts/caller" component={RamaContactCaller} layout={SecureLayout} />}
                {process.env.REACT_APP_BUILD_TARGET === "RAMA" &&  <RouteLayout exact path="/contacts/patient" component={RamaContactPatient} layout={SecureLayout} />}
                <RouteLayout exact path="/contacts/create" component={Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/contacts/edit" component={Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/kb" component={KB} layout={SecureLayout} />
                <RouteLayout exact path="/kb/editor" component={Editor} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/kb/create" component={CreateKB} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/kb/view" component={ViewKB} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/kb/approve" component={Approve} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/kb/newVersion" component={Version} layout={SecureLayout} />
                <RouteLayout exact path="/kb/see_also" component={SeeAlso} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config" component={Config} layout={SecureLayout} />
                <RouteLayout exact path="/config/users/create" component={ConfigUsers_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/users/edit" component={ConfigUsers_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/category/create" component={ConfigCategory_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/category/edit" component={ConfigCategory_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/priority/create" component={ConfigPriority_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/priority/edit" component={ConfigPriority_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/status/create" component={ConfigStatus_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/status/edit" component={ConfigStatus_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/channel/create" component={ConfigChannel_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/channel/edit" component={ConfigChannel_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/template/create" component={ConfigTemplate_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/template/edit" component={ConfigTemplate_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/teams/create" component={ConfigTeam_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/teams/edit" component={ConfigTeam_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/role/create" component={ConfigRole_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/role/edit" component={ConfigRole_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/announce/create" component={ConfigAnnounce_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/announce/edit" component={ConfigAnnounce_Edit} layout={SecureLayout} showBackButton />

                <RouteLayout exact path="/config/announceGroup/create" component={ConfigAnnounceGroup_Create} layout={SecureLayout} showBackButton />
                <RouteLayout exact path="/config/announceGroup/edit" component={ConfigAnnounceGroup_Edit} layout={SecureLayout} showBackButton />


                <RouteLayout exact path="/config/general/edit" component={ConfigGeneral_Edit} layout={SecureLayout} showBackButton />


                <RouteLayout path="/chat" component={ChatPage} layout={SecureLayout} />
                {process.env.REACT_APP_CHAT === 'EXTERNAL' &&
                  <RouteLayout path="/chat_queue" component={ChatQueuePage} layout={SecureLayout} />
                }

                <RouteLayout path="/text_to_speech" component={TextToSpeech} layout={SecureLayout} />

                <RouteLayout path="/substance" component={Substance} layout={SecureLayout} />
                <RouteLayout path="/product/create" component={NewSubstance} layout={SecureLayout} showBackButton />
                <RouteLayout path="/product/edit" component={EditSubstance} layout={SecureLayout} showBackButton />

                <RouteLayout path="/ingredients" component={Ingredient} layout={SecureLayout} />
                <RouteLayout path="/ingredient/create" component={NewIngredient} layout={SecureLayout} showBackButton />
                <RouteLayout path="/ingredient/edit" component={EditIngredient} layout={SecureLayout} showBackButton />




                {/* EXAT REPORT */}
                <RouteLayout path="/ivr-menu-summary-report-monthly-by-date" component={REPORT01_IVRMenuSummaryReportMonthlyByDate} layout={SecureLayout} />
                <RouteLayout path="/csq_summary_report" component={REPORT02_CSQSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/ivr-menu-summary-report-by-time-interval" component={REPORT03_IVRMenuSummaryReportByTimeInterval} layout={SecureLayout} />
                <RouteLayout path="/csq_summary_report_by_time_interval" component={REPORT04_CSQSummaryReportByTimeInterval} layout={SecureLayout} />
                <RouteLayout path="/ivr_menu_summary_report_by_monthly_compare" component={REPORT05_IVRMenuSummaryReportByMonthlyCompare} layout={SecureLayout} />
                <RouteLayout path="/agent_login-out_duration_and_stage_summary" component={REPORT06_AgentLoginOutDurationAndStageSummary} layout={SecureLayout} />
                <RouteLayout path="/ticket-summary-by-category-report" component={TicketSummaryByCategoryReport} layout={SecureLayout} />
                <RouteLayout path="/ticket-summary-stats-report" component={TicketSummaryStatsReport} layout={SecureLayout} />
                <RouteLayout path="/ticket-problem-summary-report" component={TicketProblemSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/outgoing-call-details-report" component={OutgoingCallDetailsReport} layout={SecureLayout} />
                <RouteLayout path="/callback-details-report" component={CallbackDetailsReport} layout={SecureLayout} />
                <RouteLayout path="/ivr-menu-summary-report" component={IvrMenuSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/survey-csq-summary-report" component={SurveyCsqSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/survey-agent-summary-report" component={SurveyAgentSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/kb-summary-report" component={KbSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/kb-detail-report" component={KbDetailReport} layout={SecureLayout} />
                <RouteLayout path="/kb-most-active-report" component={KbMostActiveReport} layout={SecureLayout} />
                <RouteLayout path="/kb-keyword-summary-report" component={KbKeywordSummaryReport} layout={SecureLayout} />
                <RouteLayout path="/kb-keyword-report" component={KbKeywordReport} layout={SecureLayout} />
                <RouteLayout path="/kb-dashboard-report" component={KbDashboardReport} layout={SecureLayout} />
                <RouteLayout path="/overdue-sla-detail-report" component={OverdueSLADetailReport} layout={SecureLayout} />

                {/* RAMA REPORT */}
                <RouteLayout path="/type_of_inquiry" component={RAMA_REPORT1} layout={SecureLayout} />
                <RouteLayout path="/profession_of_interlocutors" component={RAMA_REPORT2} layout={SecureLayout} />
                <RouteLayout path="/regions_and_locations" component={RAMA_REPORT3} layout={SecureLayout} />
                <RouteLayout path="/type_of_information" component={RAMA_REPORT4} layout={SecureLayout} />
                <RouteLayout path="/list_of_substances" component={RAMA_REPORT5} layout={SecureLayout} />
                <RouteLayout path="/common_types_of_pesticides" component={RAMA_REPORT6} layout={SecureLayout} />
                <RouteLayout path="/no_of_substances" component={RAMA_REPORT7} layout={SecureLayout} />
                <RouteLayout path="/routes_of_exposure" component={RAMA_REPORT8} layout={SecureLayout} />
                <RouteLayout path="/circumstances_of_human_poison" component={RAMA_REPORT9} layout={SecureLayout} />
                <RouteLayout path="/no_of_death_cases" component={RAMA_REPORT10} layout={SecureLayout} />
                <RouteLayout path="/distribution_of_age_range" component={RAMA_REPORT11} layout={SecureLayout} />
                <RouteLayout path="/circumstances_in_human" component={RAMA_REPORT12} layout={SecureLayout} />
                <RouteLayout path="/number_of_death_cases" component={RAMA_REPORT13} layout={SecureLayout} />
                <RouteLayout path="/final_medical_outcome" component={RAMA_REPORT14} layout={SecureLayout} />
                <RouteLayout path="/the_initial_severity_of_patients" component={RAMA_REPORT15} layout={SecureLayout} />
                <RouteLayout path="/final_severity_of_patient" component={RAMA_REPORT16} layout={SecureLayout} />
                <RouteLayout path="/number_of_death_cases_in_each_category" component={RAMA_REPORT17} layout={SecureLayout} />
                <RouteLayout path="/final_medical_outcome_of_patient" component={RAMA_REPORT18} layout={SecureLayout} />
                <RouteLayout path="/summary_of_death" component={RAMA_REPORT19} layout={SecureLayout} />
                <RouteLayout path="/summary_tickets_group_patient_privilage" component={RAMA_REPORT20} layout={SecureLayout} />
                <RouteLayout path="/summary_tickets_group_working_hours" component={RAMA_REPORT21} layout={SecureLayout} />
                <RouteLayout path="/export_tickets_data" component={RAMA_REPORT22} layout={SecureLayout} />
                <RouteLayout path="/export_patients_data" component={RAMA_REPORT23} layout={SecureLayout} />

                <RouteLayout exact path="/troubleshoot" component={Troubleshoot} layout={SecureLayout} />



                {selectedModules.email &&
                  <RouteLayout path="/email" component={EmailPage} layout={SecureLayout} />
                }

                <RouteLayout path="/error/403" component={ForbiddenPage} layout={SecureLayout} />

                <RouteLayout exat path="/" component={MyTicketPage} layout={SecureLayout} />
              </Switch>
            </Suspense>
          </MuiPickersUtilsProvider>

        </SnackbarProvider>
      )}
    </>
  );
};

export default App;
