import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {Grid, Card, Box, Button, TextField, Typography, Divider, Select, MenuItem, FormControl, Checkbox} from '@material-ui/core';
import {useForm, Controller} from "react-hook-form";
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';



const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    card: {
        margin: "1%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "50%",
        '& > *': {
          margin: theme.spacing(1),
        }
    },
    middle: {
        margin: "1.5%",
        width: "15%",
        maxWidth: "15%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "46%",
        marginBottom: "1%"
    },
    errorMsg : {
        color: "#f44336"
    }
}))



const Edit = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const [teamList, set_teamList] = React.useState([]);
    const [roleList, set_roleList] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    const [personInfo, set_personInfo] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState();
    // const [selectedDate, setSelectedDate] = React.useState(new Date(`${personInfo.dob}`));
    const { enqueueSnackbar } =useSnackbar();
    const userId = props.location.state.userId;
    const { t } = useTranslation();

    const prefixName = [
        {_id: 1, prefix: "Mr."}, 
        {_id: 2, prefix: "Miss"}, 
        {_id: 3, prefix: "Mrs."}
      ]
  
    const [prefixList, set_prefixList] = React.useState(prefixName);
    
    const shifts = [
        {
          name: "Morning shift (1AM - 9AM)",
          value: "Morning shift (1AM - 9AM)"
        },
        {
          name: "Afternoon shift (9AM - 5PM)",
          value: "Afternoon shift (9AM - 5PM)"
        },
        {
          name: "Night shift (5PM - 1AM)",
          value: "Night shift (5PM - 1AM)"
        }
    ]

    yup.addMethod(yup.string, 'validatePhone', function () {
        return this.test('test-phone', 'Invalid phone number format.', (value) => {
            if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
          return true;
        })
    })

    const yupSchema = yup.object().shape({
        // phone: yup.array().of(yup.string().validatePhone()),
        name: yup.string().required(),
        roleId: yup.string().required(),
        team: yup.string().required(),
        username: yup.string().required(),
        password: yup.string().required()
      });

    const { register, handleSubmit, errors, control } = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(yupSchema),
    });
  

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    /*fetching list of prefixes for dropdown*/
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
            .then(res => res.json())
            .then((data) => {
              set_prefixList(data.data)
            })
      } , [])
    
    /*fetching list of teams for dropdown*/
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams` )
            .then(res => res.json())
            .then((data) => {
              set_teamList(data.data)
            })
      } , [])

    
    /*fetching list of roles for dropdown*/
    React.useEffect(() => { 
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles`)
        .then(res => res.json())
        .then((data) => {
            set_roleList(data.data)
        })
    } , [])
    
    /*fetching a person info*/
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${userId}` )
            .then(res => res.json())
            .then((data) => {
                // console.log("Person info")
                // console.log(data)
                set_personInfo(data)
                setSelectedDate(new Date(`${data.dob}`))
                setLoading(false)
            })
    } , [])

    if (isLoading=== true){
        return (<></>)
    }
        
    const onSubmit = (data,e) => {
        // console.log("edited", data);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${userId}`,{
            method: 'PUT', 
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        })
        .then((response) => {
            if(response.status >=200 && response.status <=299) {
                history.push('/config');
                return enqueueSnackbar(t('User has been updated.'), { variant: 'success' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('User has fail to update.'), { variant: 'error' });
        }) 
        // e.target.reset();  
    }

    return (

        <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("Manage Users")}
                subtitle= {t("Configuration for users")}
            />
            <Grid item xs={12} className={classes.top}>
                <Card className={classes.card}>
                    <form onSubmit={handleSubmit(onSubmit)}  className={classes.form_}>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('prefix')}</Typography>
                            <FormControl className={classes.parallel}>
                            <Controller
                                fullWidth
                                variant="outlined"
                                as={
                                    <Select>
                                    {prefixList.map((prefixList) => (
                                        <MenuItem key={prefixList._id} value={prefixList.prefix}>{prefixList.prefix}</MenuItem>
                                    ))}
                                </Select>
                                } 
                                name="title_prefix"
                                control={control}
                                defaultValue={personInfo.title_prefix}
                            />
                            {errors.title_prefix &&  <span className={classes.errorMsg}>{t('Prefix field is required')}</span>}
                            </FormControl>
                        </Box>
                        {/* Agent name */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Agent name')} * </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth
                                    inputRef={register} 
                                    defaultValue={personInfo.name} 
                                    name="name" 
                                    variant="outlined"
                                ></TextField>
                                {errors.name &&  <span className={classes.errorMsg}>{t('Name field is required')}</span>}
                            </div>
                        </Box>

                         {/* Employee code */}
                        <Box display="flex" flexDirection="row">
                          <Typography  className={classes.middle}>{t('Employee code')}  </Typography>
                          <div className={classes.parallel}>
                            <TextField 
                              fullWidth
                              inputRef={register} 
                              name="CustomData.employeeCode"
                              variant="outlined"
                              defaultValue={personInfo.CustomData.employeeCode}
                            ></TextField>
                          </div>
                        </Box>

                        {/* Role */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Role')} * </Typography>
                            <FormControl className={classes.parallel}>
                                <Controller
                                    as={
                                        <Select>
                                        {roleList.map((roleList) => (
                                            <MenuItem value={roleList._id}>{roleList.name}</MenuItem>
                                        ))}
                                        </Select>
                                    } 
                                name="roleId"
                                control={control}
                                defaultValue={personInfo.roleId}
                                />
                                {errors.roleId &&  <span className={classes.errorMsg}>{t('Role field is required')}</span>}
                            </FormControl>
                        </Box>

                        {/* Team */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Team')} * </Typography>
                            <FormControl className={classes.parallel}>
                                <Controller
                                    as={
                                        <Select >
                                        {teamList.map((teamList) => (
                                            <MenuItem value={teamList._id}>{teamList.name}</MenuItem>
                                        ))}
                                        </Select>
                                    } 
                                    name="team"
                                    control={control}
                                    defaultValue={personInfo.team}
                                />
                                {errors.team &&  <span className={classes.errorMsg}>{t('Team field is required')}</span>}
                            </FormControl>
                        </Box>

                        {/* Agent skill */}
                        {/* <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Agent skill')} : </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth
                                    inputRef={register({ required: true})} 
                                    defaultValue={personInfo.CustomData.skill} 
                                    name="CustomData.skill" 
                                    variant="outlined"
                                ></TextField>
                                {errors.CustomData &&  <span className={classes.errorMsg}>Skill field is required</span>}
                            </div>
                        </Box> */}

                        {/* Agent shift */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Agent shift')} </Typography>
                            <FormControl className={classes.parallel}>
                              <Controller
                                as={
                                    <Select>
                                      {shifts.map((shift) => (
                                        <MenuItem value={shift.value}>{shift.name}</MenuItem>
                                      ))}
                                  </Select>
                                } 
                                name="CustomData.shift"
                                control={control}
                                defaultValue={personInfo.CustomData.shift}
                            />
                            {/* {errors.CustomData &&  <span className={classes.errorMsg}>Shift field is required</span>} */}
                          </FormControl>
                        </Box>

                        {/* Mobile Phone */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Mobile phone')} </Typography>
                            <div className={classes.parallel} >
                            <Controller
                                name="phone[0]"
                                control={control}
                                defaultValue={personInfo.phone[0]} 
                                render={({value, onChange}) => {
                                    return ( 
                                    <TextField 
                                        fullWidth 
                                        variant="outlined" 
                                        inputRef={register} 
                                        defaultValue={value}
                                        onChange={(e) => onChange(e.target.value)}
                                    />
                                )}}
                            />   
                            {errors.phone && errors.phone[0] && <span className={classes.errorMsg}>{t('Please enter a valid phone number')} </span>}
                            </div>
                        </Box>

                        {/* Work phone */}
                        <Box display="flex" flexDirection="row">
                          <Typography className={classes.middle}>{t('Work phone')}  </Typography>
                          <div className={classes.parallel} >
                            <Controller
                                    name="phone[1]"
                                    control={control}
                                    defaultValue={personInfo.phone[1]} 
                                    render={({value, onChange}) => {
                                        return ( 
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            inputRef={register} 
                                            defaultValue={value}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}}
                                />   
                            {errors.phone && errors.phone[1] && <span className={classes.errorMsg}>{t('Please enter a valid phone number')} </span>}
                          </div>
                        </Box>

                        {/* Home phone */}
                        <Box display="flex" flexDirection="row">
                          <Typography className={classes.middle}>{t('Home phone')}  </Typography>
                          <div className={classes.parallel} >
                            <Controller
                                name="phone[2]"
                                control={control}
                                defaultValue={personInfo.phone[2]} 
                                render={({value, onChange}) => {
                                    return ( 
                                    <TextField 
                                        fullWidth 
                                        variant="outlined" 
                                        inputRef={register} 
                                        defaultValue={value}
                                        onChange={(e) => onChange(e.target.value)}
                                    />
                                )}}
                            /> 
                            {errors.phone && errors.phone[2] && <span className={classes.errorMsg}>{t('Please enter a valid phone number')} </span>}
                          </div>
                        </Box>

                        {/* Address */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Address')}  </Typography>
                            <TextField 
                                fullWidth
                                className={classes.parallel} 
                                inputRef={register} 
                                defaultValue={personInfo.address} 
                                name="address[0]" 
                                variant="outlined"
                            ></TextField>
                        </Box>

                        {/* Email */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Email')}  </Typography>
                            <TextField 
                                fullWidth
                                className={classes.parallel} 
                                inputRef={register} 
                                defaultValue={personInfo.email[0]} 
                                name="email.[0]" 
                                variant="outlined"
                                type="email"
                            ></TextField>
                        </Box>

                        {/* Position */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Position')}</Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth
                                    inputRef={register({ required: false })} 
                                    defaultValue={personInfo.position} 
                                    name="position" 
                                    type="text" 
                                    variant="outlined"
                                ></TextField>
                            </div>
                        </Box>

                        {/* Date of Birth */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Date of Birth')}  </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}><KeyboardDatePicker 
                                    className={classes.parallel}
                                    margin="normal"
                                    // label="Date picker dialog"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    inputRef={register}
                                    name= "dob"
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                            /></MuiPickersUtilsProvider>
                        </Box>

                        {/* Username */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Username')} * </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth
                                    inputRef={register({ required: true})} 
                                    defaultValue={personInfo.username} 
                                    name="username" 
                                    variant="outlined"
                                ></TextField>
                                {errors.username &&  <span className={classes.errorMsg}>{t('Username field is required')}</span>}
                            </div>
                        </Box>

                        {/* Password */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Password')} * </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth
                                    inputRef={register({ required: true})} 
                                    defaultValue={personInfo.password} 
                                    name="password" 
                                    type="password" 
                                    variant="outlined"
                                ></TextField>
                                {errors.password &&  <span className={classes.errorMsg}>{t('Password field is required')}</span>}
                            </div>
                        </Box>

                        {/* isActive */}
                        <Box display="none">
                            <Checkbox
                                checked
                                inputRef={register({ required: false }) }
                                name="isActive"
                                type={'checkbox'}
                            />
                        </Box>

                        {/* Buttons */}
                        <Box className={classes.btn_}>
                            <Button type="submit" color="secondary"   variant="contained">{t('Save')}</Button>
                            <Button color="secondary" variant="contained" onClick={() => history.push('/config')}>{t('Cancel')}</Button>
                        </Box>
                    </form>
                </Card>
            </Grid>
        </Container>
           

    )
}
export default Edit;
