import { InputBase } from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/styles";

export const ContactDialogInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    // backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    width: 400
  },
}))(InputBase);

export const SelectionField = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
    width: 362
  },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
  containerContactPage: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  autoCom: {
    margin: "16px 0"
  },
  cellWidth: {
    maxWidth: "30vh",
  },
  ty: {
    margin: "3%",
  },
  body_table: {
    maxHeight: "100%",
    overflow: "auto",
    marginTop: "20px"
  },
  findingMargin: {
    paddingLeft: "14px",
    paddingRight: "14px"
  },
  paginationActions: {
    marginRight: "5%"
  },
  root: {
    padding: "0.5rem 0.75rem 1.25rem 0.25rem",
  },
  title: {
    paddingTop: 0,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.75),
    },
  },
  subtitle: {
    marginTop: theme.spacing(0.25),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.25),
    },
  },
  gridButton: {
    paddingRight: "0px !important",
    // margin: theme.spacing(1),
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "6px",
      padding: "4px 10px",
      fontSize: "0.8125rem",
    },
  },
  container: {
    marginTop: "1%"
  },
  create_card: {
    marginLeft: "5%",
    marginRight: "0",
    paddingRight: "0",
    marginTop: "2%",
    disableGutters: "true"
  },
  create_functions: {
      '& > *': {
          margin: theme.spacing(1),
        }
  },
  name_: {
    textAlign: "left"
  },
  card_:{
    marginLeft: "45%",
  },
  submit: {
    marginLeft: "60%",
    marginTop: "3%"
  },
  btn: {
    marginTop: "1%",
    textAlign: "center",
    '& > *': {
        margin: theme.spacing(1),
      },
  },
  detailCard: {
    marginTop: "1%",
    marginLeft: "38%",
    textdecoration: "none"
  },
  errorMsg : {
    color: "#f44336",
    marginTop: "1%",
    marginLeft: "1%"
  },
  parallel: {
    width: "100%",
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
  },
  middle: {
    margin: "1.5%",
    width: "25%",
    maxWidth: "25%"
  },
  form_: {
    marginLeft: "10%",
    marginTop: "3%",
    marginBottom: "3%",
    width: 700
  },
  titleForm: {
    // margin: "1%",
    paddingTop: "3%",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.75),
    },
  },
  subtitleForm: {
    marginLeft: "3%",
    marginTop: theme.spacing(0.25),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.25),
    },
  },
  textInput: {
    fontSize: 16,
    width: 400
  },
  }));