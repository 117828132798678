import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography, 
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import 'yup-phone';
import { useSnackbar } from 'notistack';
import { useStyles, SelectionField } from './styles';

const contactTypeOption = [
  'Hospital (รพ.)',
  'ประชาชนทั่วไป',
  'Caller',
]

const contactTypeOption2 = contactTypeOption.map((val, index) => (
  {key: index, value: val}
))

const contactPointQuery = {
  bool: {
    must: [
      { term: { "isActive": true } },
    ],
    should: [
      { term: { "CustomData.contactType":  "contact" } },
      { term: { "CustomData.contactType":  "domestic" } },
      { term: { "CustomData.contactType":  "account" } },
      { term: { "CustomData.contactType":  "internal" } },
      { term: { "CustomData.contactType":  "สถานีตำรวจ" } },
      { term: { "CustomData.contactType":  "สถานศึกษา" } },
      { term: { "CustomData.contactType":  "คลินิก" } },
      { term: { "CustomData.contactType":  "สถานฑูต" } },
      { term: { "CustomData.contactType":  "บริษัท" } },
      { term: { "CustomData.contactType":  "หน่วยงานราชการ" } },
      { term: { "CustomData.contactType":  "โรงงาน" } },
      { term: { "CustomData.contactType":  "โรงแรม" } },
      { term: { "CustomData.contactType":  "ที่พัก" } },
    ],
  }
}


const CallerDialog = (props) => {
  const { open, onCancel } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const [position, setPosition] = useState([]);
  const [existingName, setExistingName] = useState([]);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [selectedContactPoint, setSelectedContactPoint] = useState();
  const [contactPointOption, setContactPointOption] = useState([]);
  const [isNoContactPoint, setIsNoContactPoint] = useState(false);

  /* Get position options */
  const getPosition = () => {
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs`)
    .then((res) => res.json())
    .then((data) => {
      const resJson = data.data;
      let filteredPosition = resJson.filter((val) => val.groupName === 'Position');
      let positonArray = [];
      for(let i = 0; i < filteredPosition.length; i++) {
        positonArray.push({_id: i, name: filteredPosition[i].name});
      }
      setPosition(positonArray);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  /* Phone validation */
  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    })
  })
  const yupSchema = yup.object().shape({
    // phone: yup.array().of(yup.string().validatePhone()),
    name: yup.string().required(),
    //order: yup.number().required().positive().integer(),
  });
  const { register, handleSubmit, errors, control } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    if (open) getPosition();
    if (open) searchEsInitialContactPoint();
    if (open && props.callerData) searchEsContactPointById(props.callerData.parentId);
    if (open && props.callerData && props.submit === 'edit') searchEsExistingCallerByParentId(props.callerData.parentId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, props.callerData]);

  useEffect(() => {
    if (props.triggerToRefresh) props.triggerToRefresh()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 } , [props.contactId])

  /* Close dialog */
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      props.onChangeCallerData(props.defaultCallerData);
      setContactPointOption([]);
      setExistingName([]);
      setIsDuplicateName(false);
      setIsNoContactPoint(false);
    };
  };

  /* How to detect existing name on same Contact Point
    1. when user choose Contact Point and type Caller name, go to find parent id. --> "<parent id>"
    2. use parent id to find caller that have the same parent id. --> [{}, {}, ...]
    3. filter existing caller name from the array. --> [{}, {}, ...] or []
    4. if there is any object in the finding array, so 'the Caller name' is duplicated.
  */

  /* Elastic Search for check existing name */
  const searchEsExistingCallerByParentId = (parentId) => {
    const input_query = {
      query: {
        filtered: {
          query: {
            query_string: {
              query: `${parentId}`,
              fields: [
                "parentId"
              ]
            }
          },
          filter: {
            bool: {
              must: [
                { term: { "isActive": true } },
              ],
              should: [
                { term: { "CustomData.contactType":  "caller" } },
                { term: { "CustomData.contactType":  "ประชาชนทั่วไป" } },
                { term: { "CustomData.contactType":  "hospital" } },
              ],
            }
          },
        }
      }
    };
    getEsName(input_query, 'existingName');
  };

  /* Check duplicated name (item 1 of how to detect existing name) */
  const callerNameOnchangeHandler = async (event) => {
    const searchName = await event.target.value;
    props.onChangeCallerData({
      ...props.callerData,
      name: searchName,
    });
  };

  /* Check existing name (item 2 of how to detect existing name) */
  const checkExistingCaller = (contactPointObj) => {
    setSelectedContactPoint(contactPointObj)
    if (contactPointObj) searchEsExistingCallerByParentId(contactPointObj._id)
  }

  /* Initial contact point to be the initial optiion */
  const searchEsInitialContactPoint = () => {
    const input_query = {
      query: {
        filtered: {
          filter: contactPointQuery,
        }
      }
    }
    getEsName(input_query, 'option');
  };

  /* search contact point by name to be parentID */
  /* Using this function when open dialog for creating new caller */
  const searchEsContactPoint = (e) => {
    let text_query = {};
    if (e.target.value != null && e.target.value) {
      text_query = {
        query_string: {
          query: `*${e.target.value}*`,
          fields: [
            "name"
          ]
        }
      }
    } else {
      text_query = null
    };
    const input_query = {
      query: {
        filtered: {
          query: text_query,
          filter: contactPointQuery,
        }
      }
    };
    getEsName(input_query, 'option');
  };

  /* search contact point by _id to be parentID */
  /* Using this function when open dialog for editing */
  const searchEsContactPointById = (parentId) => {
    const input_query = {
      query: {
        filtered: {
          query: {
            query_string: {
              query: `${parentId}`,
              fields: [
                "_id"
              ]
            }
          },
          filter: contactPointQuery,
        }
      }
    };
    getEsName(input_query, 'parentId');
  };

  /* ElasticSearch Function */
  const getEsName = (input_query, field) => {
    const query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`;

    fetch(query, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input_query)
    })
      .then(res => res.json())
      .then((data) => {
        let name_list = data.hits.hits.map((v) => {
          v._source._id = v._id;
          return v._source;
        });
        if (field === 'option') setContactPointOption(name_list);
        if (field === 'parentId') setSelectedContactPoint(name_list[0]);
        if (field === 'existingName') setExistingName(name_list);
      });
  }

  /* Save function */
  const onSubmit = (data,e) => {
    const selectedDefaultFlag = props.checkedDefaultFlag ? 1 : 0;
    const arrangedData = { 
      ...data,
      isActive: true,
      CustomData: {
        ...data.CustomData,
        defaultFlag: selectedDefaultFlag,
      },
      parentId: selectedContactPoint ? selectedContactPoint._id : ''
    };
    var raw = JSON.stringify(arrangedData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    /* (item 3 of how to detect existing name) */
    if (selectedContactPoint) {
      const noSpaceText = (text) => text.replace(/\s/g, '');
      const existingCallerArrary = existingName.filter((item) =>
        noSpaceText(item.name) === noSpaceText(props.callerData.name));
      /* prevent search itself to be one of existingName if open on edit dialog */
      if (props.submit === 'edit') {
        const duplicatedName = existingCallerArrary.filter((item) => item._id !== props.callerData._id);
        if (duplicatedName.length > 0) {
          /* (item 4 of how to detect existing name) */
          setIsDuplicateName(true);
          setIsNoContactPoint(false);
          return;
        } else setIsDuplicateName(false);
      }
      else if (props.submit === 'create' && existingCallerArrary.length > 0) {
        /* (item 4 of how to detect existing name) */
        setIsDuplicateName(true);
        setIsNoContactPoint(false);
        return;
      } else setIsDuplicateName(false);
    };

    if (props.submit === 'create') {
      if (selectedContactPoint == null) {
        setIsDuplicateName(false);
        setIsNoContactPoint(true);
        return;
      } else {
        setIsDuplicateName(false);
        setIsNoContactPoint(false);
      };
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299){  
          handleCancel();
          props.triggerToRefresh();
          return enqueueSnackbar('Contact has been created.', { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to create.'), { variant: 'error' });
      })
    } else {
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${props.contactId}`,{
        method: 'PUT', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {   
          handleCancel();
          props.triggerToRefresh();
          return enqueueSnackbar(t('Contact has been updated.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to update.'), { variant: 'error' });
      }) 
    };
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Container>
        <DialogTitle className={classes.titleForm} id="form-dialog-title">
          {props.submit === 'create' ? t('create_caller_title') : t('edit_caller_title')}
        </DialogTitle>
        <form className={classes.form_} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            {/* Name */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_name')} *</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="name"
                  inputRef={register}
                  value={props.callerData ? props.callerData.name : ''}
                  onChange={(e) => callerNameOnchangeHandler(e)}
                />
                {errors.name && (<div className={classes.errorMsg}> {t('Name field is required')} </div>)}
                {isDuplicateName && (<div className={classes.errorMsg}> {t('Name could not be duplicated.')} </div>)}
              </Grid>
            </Grid>

            {/* Position */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('caller_position')}</Typography>
              <Grid>
                {open && props.callerData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {position && position.map((option) => (
                            <MenuItem key={option._id} value={option.name}>{option.name}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="position"
                      control={control}
                      defaultValue={props.submit === 'create'? 'Physicians' : props.callerData ? (
                        props.callerData.position ? props.callerData.position : 'Physicians'
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Personal Phone */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_phone')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="phone[0]"
                  inputRef={register} 
                  value={props.callerData ? props.callerData.phone[0] : ''}
                  onChange={(e) => props.onChangeCallerData({ 
                    ...props.callerData,
                    phone: [ e.target.value, props.callerData.phone[1] ],
                  })}
                />
              </Grid>
            </Grid>

            {/* Personal Mobile */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_mobile')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="phone[1]"
                  inputRef={register} 
                  value={props.callerData ? props.callerData.phone[1] : ''}
                  onChange={(e) => props.onChangeCallerData({ 
                    ...props.callerData,
                    phone: [ props.callerData.phone[0], e.target.value ],
                  })}
                />
              </Grid>
            </Grid>

            {/* Contact Type */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('contact_type')}</Typography>
              <Grid>
                {open && props.callerData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {contactTypeOption2.map((option) => (
                            <MenuItem key={option.key} value={option.value}>{option.value}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.contactType"
                      control={control}
                      defaultValue={props.submit === 'create'? "Caller" : props.callerData ? (
                        props.callerData.CustomData ? props.callerData.CustomData["contactType"] : ''
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
              
            {/* Email */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_personal_email')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="email[0]"
                  inputRef={register} 
                  value={props.callerData ? props.callerData.email[0] : ''}
                  onChange={(e) => props.onChangeCallerData({ 
                    ...props.callerData,
                    email: [ e.target.value ]
                  })}
                />
              </Grid>
            </Grid>

            {/* Phone Text */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('phone_text')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="CustomData[phoneText]"
                  inputRef={register} 
                  value={props.callerData ? props.callerData.CustomData.phoneText : ''}
                  onChange={(e) => props.onChangeCallerData({ 
                    ...props.callerData,
                    CustomData: { ...props.callerData.CustomData, phoneText: e.target.value }
                  })}
                />
              </Grid>
            </Grid>

            {/* Search Contact Point */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('contact_point_title')} *</Typography>
              <Autocomplete
                size="small"
                id="size-small-outlined"
                options={contactPointOption}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name}
                onChange={(event, value) => checkExistingCaller(value)}
                value={selectedContactPoint ? selectedContactPoint : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{width: 400}}
                    variant="outlined"
                    onChange={(e) => searchEsContactPoint(e)}
                  />
                )}
                disabled={props.submit === 'edit' ? true : false}
              />
              {isNoContactPoint && (<span className={classes.errorMsg}> {t('Contact Point should be selected.')} </span>)}
            </Grid>

            {/* Sequence */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('caller_sequence')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  style={{width: 250}}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="CustomData[order]"
                  inputRef={register}
                  type="number"
                  value={props.callerData ? props.callerData.CustomData.order : ''}
                  onChange={(e) => {if(e.target.value > 0) props.onChangeCallerData({
                    ...props.callerData,
                    CustomData: { ...props.callerData.CustomData, order: e.target.value }
                  })}}
                />
                <FormControlLabel
                  style={{marginLeft: 10}}
                  control={<Checkbox />}
                  label="Default Flag"
                  checked={props.checkedDefaultFlag}
                  onChange={props.checkedDefaultFlagHandleChange}
                />
              </Grid>
            </Grid>

            {/* Note */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('note')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={4}
                  name="CustomData[note]"
                  inputRef={register}
                  value={props.callerData ? props.callerData.CustomData.note : ''}
                  onChange={(e) => props.onChangeCallerData({ 
                    ...props.callerData,
                    CustomData: { ...props.callerData.CustomData, note: e.target.value }
                  })}
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">Save</Button>
            <Button onClick={handleCancel} color="primary">Cancel</Button>
          </DialogActions>
        </form>
        
      </Container>
    </Dialog>
  );
};

export default CallerDialog;