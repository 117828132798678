import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Grid, Typography, Box, Card, Button, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import PageHeader from './PageHeader'
import { useForm } from "react-hook-form";
import { AppContext } from '../../../contexts/AppStore'
import { CustomAPI } from '../../../modules/TM/src/apis'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1.5%"
  },
  form_team: {
    margin: "1%"
  },
  form_: {
    marginLeft: "18%",
    marginTop: "3%",
    marginBottom: "3%"
  },
  parallel: {
    width: "50%",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  middle: {
    margin: "1.5%",
    width: "20%",
    maxWidth: "20%"
  },
  btn: {
    textAlign: "center",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  errorMsg: {
    color: '#f44336',
    paddingLeft: '8px',
    marginTop: '0px',
    display: 'block',
  },
}))

const EditIngredient = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { loginUser } = useContext(AppContext);
  const editIngredientObj = props.location.state.ingredient

  const [isDuplicateIngredient, setIsDuplicateIngredient] = React.useState(false);
  const [categories, setCategories] = React.useState([])
  const [families, setFamilies] = React.useState([])
  const [chemicals, setChemicals] = React.useState([])

  const [selectedCategory, setSelectedCategory] = React.useState((editIngredientObj.group) ? editIngredientObj.group.group.category_name : "")
  const [selectedFamily, setSelectedFamily] = React.useState((editIngredientObj.group) ? editIngredientObj.group.group.family_name : "")
  const [selectedChemical, setSelectedChemical] = React.useState((editIngredientObj.group) ? editIngredientObj.group.group.chemical_name : "")

  const chooseOption = 'choose';
  const notAvailable = 'N/A';

  React.useEffect(() => {
    fetchCategories()
    fetchFamilies(selectedCategory)
    fetchChemcials(selectedFamily, selectedCategory)
  }, [selectedCategory, selectedFamily, selectedChemical])

  const fetchCategories = () => {
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=2&sort=name`)
      .then(res => res.json())
      .then((data) => {
        // console.log("CATEGORIES data=", data.data);
        setCategories(data.data);
      });
  };

  const fetchFamilies = (data) => {
    const encodedCategory = encodeURIComponent(data);
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=3&group.category_name="${encodedCategory}"&sort=name`)
      .then(res => res.json())
      .then((data) => {
        // console.log("FAMILIES data=", data.data);
        setFamilies(data.data);
      });
  };

  const fetchChemcials = (data, categoryName) => {
    const encodedCategory = encodeURIComponent(categoryName);
    const encodedFamily = encodeURIComponent(data);
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=4&group.category_name="${encodedCategory}"&group.family_name="${encodedFamily}"&sort=name`)
      .then(res => res.json())
      .then((data) => {
        // console.log("CHEMICALS data=", data.data);
        setChemicals(data.data)
      });
  };

  const resetFamilyDropDown = () => {
    setSelectedFamily(chooseOption);
    setFamilies([]);
  };

  const resetChemicalDropDown = () => {
    setSelectedChemical(chooseOption);
    setChemicals([]);
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
    resetFamilyDropDown();
    resetChemicalDropDown();
    if (event.target.value && event.target.value !== chooseOption) {
      fetchFamilies(event.target.value);
    }
  };

  const handleChangeFamily = (event) => {
    setSelectedFamily(event.target.value);
    resetChemicalDropDown();
    if (event.target.value && event.target.value !== chooseOption) {
      fetchChemcials(event.target.value, selectedCategory);
    }
  };

  const handleChangeChemical = (event) => {
    setSelectedChemical(event.target.value);
  };

  async function fetchproductGroupId(categoryName, familyName, chemicalName) {
    let hello = []
    const findCategoryName = 'group.category_name'
    const findFamilyName = 'group.family_name'
    const findChemicalName = 'group.chemical_name'

    const newCategoryName = encodeURIComponent(categoryName)
    const newFamilyName = encodeURIComponent(familyName)
    const newChemicalName = encodeURIComponent(chemicalName);

    if (categoryName && familyName && chemicalName) {
      const url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?${findCategoryName}="${newCategoryName}"&${findFamilyName}="${newFamilyName}"&${findChemicalName}="${newChemicalName}"&level=4`
      await fetch(url)
        .then(res => res.json())
        .then((data) => {
          if (data.data.length > 0) {
            return hello.push(data.data[0])
          }
        })
    }
    else if (categoryName && familyName) {
      const url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?${findCategoryName}="${newCategoryName}"&${findFamilyName}="${newFamilyName}"&level=3`
      await fetch(url)
        .then(res => res.json())
        .then((data) => {
          if (data.data.length > 0) {
            return hello.push(data.data[0])
          }
        })

    }
    else if (categoryName) {
      const url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?${findCategoryName}="${newCategoryName}"&level=2`
      await fetch(url)
        .then(res => res.json())
        .then((data) => {
          if (data.data.length > 0) {
            return hello.push(data.data[0])
          }
        })
    }
    return hello
  }

  const callAPI = (bodyData) => {
    var raw = JSON.stringify(bodyData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/ingredients/${editIngredientObj._id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          history.push('/ingredients');
          return enqueueSnackbar(t('Ingredient has been edited.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Ingredient has fail to edit.'), { variant: 'error' });
      })
  }

  const validateUniqueIngredient = async (ingredientName) => {
    let isDuplicate = false;

    if (!ingredientName) return;
    let categoryName = (selectedCategory !== chooseOption ? selectedCategory : "")
    let familyName = (selectedFamily !== chooseOption ? selectedFamily : "")
    let chemicalName = (selectedChemical !== chooseOption ? selectedChemical : "")

    try {
      const getResponse = await CustomAPI.getDuplicateIngredients(
        ingredientName, categoryName, familyName, chemicalName, editIngredientObj._id);
      const duplicateIngredients = getResponse.data.data;
      if (duplicateIngredients.length >= 1) {
        setIsDuplicateIngredient(true);
        isDuplicate = true;
      }
    } catch (error) {
      console.error('An error occurred on validateUniqueIngredient.');
      console.error(error);
    }

    return isDuplicate;
  };

  const resetValidateUniqueIngredient = () => {
    if (isDuplicateIngredient) setIsDuplicateIngredient(false);
  };

  const onSubmit = async (data, e) => {
    let categoryName = (selectedCategory !== chooseOption ? selectedCategory : "")
    let familyName = (selectedFamily !== chooseOption ? selectedFamily : "")
    let chemicalName = (selectedChemical !== chooseOption ? selectedChemical : "")
    var productGroupObj = await fetchproductGroupId(categoryName, familyName, chemicalName)

    // Reset recent ingredient already exsts
    resetValidateUniqueIngredient();

    try {
      // Validate not allow duplocate ingredient 
      const isDuplicate = await validateUniqueIngredient(data.name);
      if (isDuplicate) return;
    } catch (error) {
      console.error(error);
      console.error("An error occurred on onSubmit -> validateUniqueIngredient");
    }

    // no match group type Id
    if (productGroupObj[0] === undefined) {
      return enqueueSnackbar(t('There is no existing group type for selected category, family, chemical.'), { variant: 'error' });
    }

    // match id with user selected category, family, chemical 
    else if (productGroupObj[0]) {
      const bodyData = {
        name: data.name,
        group: productGroupObj[0],
        advice_info: data.advice_info || "",
        updated_by: loginUser._id,
        updated_by_name: loginUser.name
      }
      console.log('have group id', JSON.stringify(bodyData))
      callAPI(bodyData)
    }
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <PageHeader
        title={t("Edit Ingredient")}
        subtitle={t("Updating the existing ingredient")}
      />
      <Grid item xs={12} className={classes.top}>
        <Card>
          <form className={classes.form_} id="editForm" onSubmit={handleSubmit(onSubmit)}>

            <Box display="flex" flexDirection="row">
              <Typography className={classes.middle}>{t('Ingredient Name')} * </Typography>
              <div className={classes.parallel}>
                <TextField
                  fullWidth
                  size="small"
                  name="name"
                  defaultValue={editIngredientObj.name}
                  inputRef={register({ required: true })}
                  variant="outlined"
                />
                {errors.name && <span className={classes.errorMsg}>{t('Name field is required')}</span>}
                {isDuplicateIngredient && <span className={classes.errorMsg}>{t('This ingredient already exists.')}</span>}
              </div>
            </Box>

            <Box display="flex" flexDirection="row">
              <Typography className={classes.middle}>{t('Select Category')} * </Typography>
              <FormControl className={classes.parallel}   >
                <Select
                  id="substance-select-category-filled"
                  value={selectedCategory}
                  margin="dense"
                  name="category_name"
                  variant="outlined"
                  defaultValue={chooseOption}
                  onChange={handleChangeCategory}
                >
                  <MenuItem value={chooseOption} key={chooseOption}>
                    <em>Choose a Category</em>
                  </MenuItem>

                  {categories && categories.map((category) => (
                    <MenuItem value={category.name} key={category.name}>
                      <em>{category.name}</em>
                    </MenuItem>
                  )
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" flexDirection="row">
              <Typography className={classes.middle}>{t('Select Family')}  </Typography>
              <FormControl className={classes.parallel} >
                <Select
                  id="substance-select-family-filled"
                  value={selectedFamily}
                  margin="dense"
                  variant="outlined"
                  name="family_name"
                  defaultValue={chooseOption}
                  onChange={handleChangeFamily}
                >
                  <MenuItem value={chooseOption} key={chooseOption}>
                    <em>{selectedCategory && selectedCategory !== chooseOption ? "Choose a Family" : notAvailable}</em>
                  </MenuItem>

                  {families && families.map((family) => (
                    <MenuItem value={family.name} key={family.name}>
                      <em>{family.name}</em>
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
            </Box>

            <Box display="flex" flexDirection="row">
              <Typography className={classes.middle}>{t('Select Chemical')}  </Typography>
              <FormControl className={classes.parallel}   >
                <Select
                  id="substance-select-chemical-filled"
                  value={selectedChemical}
                  margin="dense"
                  variant="outlined"
                  defaultValue={chooseOption}
                  name="chemical_name"
                  onChange={handleChangeChemical}
                >
                  <MenuItem value={chooseOption} key={chooseOption}>
                    <em>{selectedFamily && selectedFamily !== chooseOption ? "Choose a Chemical" : notAvailable}</em>
                  </MenuItem>

                  {chemicals && chemicals.map((chemical) => (
                    <MenuItem value={chemical.name} key={chemical.name}>
                      <em>{chemical.name}</em>
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
            </Box>

            {/* Advice Info */}
            <Box display="flex" flexDirection="row">
              <Typography className={classes.middle}>{t('Advice Info')}  </Typography>
              <div className={classes.parallel}>
                <TextField
                  fullWidth
                  defaultValue={editIngredientObj.advice_info}
                  name="advice_info"
                  inputRef={register}
                  variant="outlined"
                  multiline
                  rows={5}
                />
              </div>
            </Box>
            {/* Form Buttons */}
            <Box className={classes.btn} >
              <Button variant="contained" form="editForm" type="submit" color="primary"> {t('Save')} </Button>
              <Button variant="contained" color="primary" onClick={() => history.push('/ingredients')}> {t('Cancel')} </Button>
            </Box>

          </form>
        </Card>
      </Grid>
    </Container>
  )
}

export default EditIngredient;
