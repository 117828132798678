import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select } from '@material-ui/core';
import { logger } from '../../TM/src/common';

const FETCH_FAMILY_URL = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=3&sort=name`

const SelectFamily = ({ value, onChange, cascadeCategory, ...restProps }) => {
  const [families, setFamilies] = useState([]);

  // Handle select change event.
  const handleChange = (event) => {
    onChange(event.target.value);
  }

  // Side effect of DidMount and cascadeCategory.
  useEffect(() => {
    
    // Not allow select family without cascadeCategory.
    if (!cascadeCategory) {
      setFamilies([]);
      return;
    }

    // Safety build reuqest URL with parameters.
    let requestUrl = FETCH_FAMILY_URL;
    requestUrl += cascadeCategory ? `&group.category_name="${encodeURIComponent(cascadeCategory)}"` : '';

    // Create a HTTP request to get families data.
    fetch(requestUrl)
      .then(res => res.json())
      .then((data) => setFamilies(data.data))
      .catch(error => logger.error(error)); 

  }, [cascadeCategory]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      {...restProps}
    >
      <MenuItem value="" key="family-option-default">
        <em>Choose a Family</em>
      </MenuItem>

      {families.map((family, index) => (
        <MenuItem value={family.name} key={`family-option-${index}`}>
          <em>{family.name}</em>
        </MenuItem>
      ))}
    </Select>
  );
};

SelectFamily.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  cascadeCategory: PropTypes.string,
};

SelectFamily.defaultProps = {
  value: '',
  onChange: () => {},
  cascadeCategory: '',
};

export default SelectFamily;