
import React from 'react';
import { EditorState, RichUtils } from 'draft-js';
import createUndoPlugin from 'draft-js-undo-plugin';
import 'draft-js/dist/Draft.css';
import { convertToRaw } from 'draft-js';
import { convertFromRaw } from 'draft-js';
import { Grid, Box, Button,
Divider, Table, TableCell, TableRow, TableBody, TableHead, Card, List, CardContent, IconButton, TextField} from '@material-ui/core';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import createImagePlugin from 'draft-js-image-plugin';
import {useForm, Controller} from "react-hook-form";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import FileDisplay from './FileDisplay';
import PageHeader from '../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import { useHistory } from 'react-router-dom';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Editor as NewEditor } from 'react-draft-wysiwyg';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AutocompleteCategory from './AutocompleteCategory';
import { AppContext } from '../../../../contexts/AppStore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {DropzoneDialog} from 'material-ui-dropzone'
import StdAttachmentList from '../../../TM/src/components/STD/AttachmentList';
import RamaAttachmentList from '../../../TM/src/components/RAMA/AttachmentList';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import * as FileAPI from '../../../TM/src/apis/FileAPI';
import { get, set } from 'lodash';

const undoPlugin = createUndoPlugin();
const { UndoButton, RedoButton } = undoPlugin;
const imagePlugin = createImagePlugin();
const plugins = [undoPlugin, imagePlugin];



const useStyles= makeStyles ((theme) => ({
  container: {
    marginTop: "1%"
  },
  topLeft: {
    marginLeft: "1.5%",
    marginBottom: "1%",
    marginRight: "1.5%"
  },
  tools: {
   backgroundColor: "#fafafa"
  },
  btn_spacing: {
  '& > *': {
      margin: theme.spacing(1),
    },
  },
    upload: {
    marginTop: "5%",
    marginLeft: "5%"
  },
  form_upload: {
    marginLeft: "2%",
    marginBottom: "2%"
  },
  card_upload: {
    marginLeft: "1%",
    marginRight: "3%"
  },
  list_container: {
    maxHeight: "40%",
    overflow: "auto",
    marginLeft: "1%",
    marginRight: "3%"
  },
  cards: {
    marginRight: "10%",
    marginLeft: "10%"
  },
  parallel: {
    width: "50%",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  btn_: {
    marginLeft: "40%",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  file_upload: {
    margin: "1%"
  },
  Un: {
    marginLeft: "0.5%",
  },
  Re: {
    marginLeft: "0.5%"
  },
  upload: {
    margin: theme.spacing(1)
  },
  errorMsg : {
    color: "#f44336",
    marginTop: "1%",
    marginLeft: "1%"
  },


}));

function MyEditor(props) {
  const classes=useStyles();
  const [content_id, set_content_id] =React.useState("");
  const { t } = useTranslation();
  const [editorState, setEditorState] = React.useState();
  const { register, handleSubmit, control, errors } = useForm();
  const { register: registerFile , handleSubmit: handleFileSubmit} = useForm();
  const [preview, setpreview] =React.useState(null);
  const [rows, set_rows] = React.useState([]);
  const [list_files, setlist_files]= React.useState([rows]);
  const [contentState, setContentState] = React.useState(undefined);
  const { loginUser, hasPermission } = React.useContext(AppContext);
  const [articleInfo, setArticleInfo] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const article_id = props.location.state.article_id;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const [attachments, setAttachments] = React.useState()
  const [isShowDropzone, setIsShowDropzone] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);


  const isOwner = (article_obj) => (article_obj.owner && article_obj.owner === loginUser._id);

  const ONE_MB_IN_BYTES = 1048576;
  const MAX_FILE_SIZE_10_MB = ONE_MB_IN_BYTES * 10;


  React.useEffect(() => {
    // console.log(article_id)
    fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${article_id}`)
        .then(res => res.json())
        .then((data) => {

          setArticleInfo(data.article);
          setLoading(false);
          
          set_content_id(data.article.content)
          setlist_files(data.article.files)
          rows.push(data.article.files)
          setAttachments(data.article.files)

          {/* check if the content is empty */}
          if (data.content.blocks != null) {
            const content = {};
            content.blocks = data.content.blocks
            content.entityMap = data.content.entityMap

            const state =  EditorState.createWithContent(convertFromRaw(content))
            // const state = content
            //     ? EditorState.createWithContent(convertFromRaw(content))
            //     : EditorState.createEmpty();
            setEditorState(state);
          }
          else {
            setEditorState(EditorState.createEmpty());
          }

          
        })
  } , []) 

  const onSubmit= (data, e) => {
    const all_data = {}

    all_data.article = data;
    all_data.article.content = content_id;
    all_data.article.owner = loginUser._id


    let attachmentsKB = []
    attachments.map((attachment) => {
      attachmentsKB.push({
        id : attachment.id,
        file_name : attachment.file_name
      })
    })
    
    all_data.article.files = attachmentsKB;

    //draft js convertToRaw
    // const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    all_data.content = rawContentState;

    //draft js convertToPlainText
    const plain_text_state = editorState.getCurrentContent();
    const plain_text = plain_text_state.getPlainText();
    // console.log("get current plain text", plain_text);
    all_data.article.text= plain_text;

    // console.log(all_data);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(all_data);

    fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${article_id}`,{
      method: 'PUT', 
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    })
    .then(response => {
      if(response.status >= 200 && response.status <= 299) {
        history.push('/kb');    
        return enqueueSnackbar(t('Article has been updated.'), { variant: 'success' });
      }
      else {
        enqueueSnackbar(t('Article has fail to update.'), { variant: 'error' });
      } 
    }) 
    .catch((error) => {
      console.log('1111Error:', error);
      if (error){
        enqueueSnackbar(t('Article has fail to update.'), { variant: 'error' });
        }
    }) 
  }

  
  const handleOpen = (index) => {
    setOpen(true)
  }

  function handleClose () {
    setOpen(false)
  }
  

  const handleUploadAttachment = (files) => {
    const file1 = get(files, '0', undefined);
    const formData = new FormData();
    formData.append('_file', file1);

    FileAPI.postUploadFile(formData)
      .then(({ data }) => {
        const addedAttachments = [...attachments, data];
        setAttachments(addedAttachments)
        // handleFieldChange('attachments', addedAttachments);
        setIsUploading(false);
        setIsShowDropzone(false);
      })
      .catch((err) => {
        setIsUploading(false);
        setIsShowDropzone(false);
        // logger.error('Unable to upload file to server.');
        // if (err) logger.error(err);
      });
  };

  const handleDeletedAttachment = (fileId) => {
    const removedAttachments = attachments.filter((e) => e.id !== fileId);
    setAttachments(removedAttachments)
    // handleFieldChange('attachments', removedAttachments);
  };





  if (loading === true) {
    return (<></>);
  } 


  return ( 
    
    <Container maxWidth="xl" className={classes.container}>
      <PageHeader
        title={t('Editor')}
        subtitle={t('Edit the article and input file upload')}
        buttonName= {(articleInfo.kb_status === "approve" & hasPermission('article_new_version') ) ? t("New version") : null}
        onClickButton={()=> history.push({pathname: '/KB/newVersion', state: { articleId: article_id } })}
      /> 
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cards}>
            <CardContent>
              <form id="form_id" onSubmit={handleSubmit(onSubmit)}>
                <List>
                  <TextField 
                    fullWidth 
                    variant="outlined" 
                    name="title" 
                    defaultValue={articleInfo.title} 
                    inputRef={register({required: true})}
                    label={t("Article title *")} />
                  {errors.title && (<span className={classes.errorMsg}>The title field is required</span>)}
                </List>
                <List>
                  <TextField 
                    fullWidth 
                    variant="outlined" 
                    name="caption" 
                    defaultValue={articleInfo.caption} 
                    inputRef={register}  
                    label={t("Caption_Description")} />
                </List>
                <List>
                  <Controller
                    name="category"
                    control={control}
                    defaultValue={articleInfo.category || null}
                    onChange={([, data]) => data}
                    render={({ value, onChange }) => {
                      return (
                        <AutocompleteCategory
                          fullWidth
                          size="medium"
                          defaultValue={value}
                          fieldLabel={t("Category")}
                          onSelectChange={(e, data) => onChange(data)}
                        />
                      ); 
                    }}
                  />
                </List>
                <Controller
                  name="version"
                  control={control}
                  defaultValue={1} 
                  render={(props) => (
                    <TextField 
                      className={classes.parallel} 
                      defaultValue={articleInfo.version} 
                      InputProps={{readOnly: true}} 
                      type="number"  
                      variant="outlined" 
                      name="version" 
                      label={t("Version")} 
                    />
                  )}
                />
                <TextField  
                  className={classes.parallel} 
                  variant="outlined" 
                  name="tags[0]"
                  defaultValue={articleInfo.tags}
                  inputRef={register}
                  label={t("Tags")}
                />
      
                <NewEditor 
                  editorStyle={{minHeight: "400px", maxHeight: "450px", overflow: "auto"}}
                  editorState={editorState}
                  onContentStateChange={setContentState}
                  onEditorStateChange={setEditorState}
                  toolbar={{
                    image: {
                      previewImage: true,
                      alignmentEnabled: false,
                      alt: { present: false }, 
                      uploadCallback: (file) => {
                        return new Promise((resolve, reject) => {
                          var formdata = new FormData();
                          formdata.append("_file", file, file.name);
                    
                          var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                          };
                    
                          fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/UploadFile`, requestOptions)
                            .then(response => response.json())
                            .then(fileDoc => {
                              resolve({ 
                                data: { 
                                  link: `${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile?id=` + fileDoc.id
                                }
                              })
                            })
                            .catch(error => {
                              if (error) console.error(error);
                              reject(error);
                            });
                        });
                      }, 
                    },
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                    },
                  }}
                />
              </form>
        
              <List className={classes.upload}>
                <DropzoneDialog
                  open={isShowDropzone}
                  Icon={UploadIcon}
                  onDrop={handleUploadAttachment}
                  onClose={() => setIsShowDropzone(false)}
                  acceptedFiles={[]}
                  filesLimit={100}
                  maxFileSize={10485760}
                  showPreviews={false}
                  showAlerts={false}
                  cancelButtonText=""
                  dialogTitle={t('general_upload_file')}
                  dropzoneText={t('Drag and drop a file here.')}
                  submitButtonText={isUploading ? t('general_uploading') : ''}
                  onDropRejected={() => {
                    enqueueSnackbar(t('Your file exceeds the maximum upload size (10MB).'), {
                      variant: 'error',
                    });
                  }}
                />
              </List>
              
              {/* Rama's AttachmentList */}
              {process.env.REACT_APP_BUILD_TARGET === 'RAMA' && (
                <RamaAttachmentList
                  attachments={attachments}
                  onUpload={() => setIsShowDropzone(true)}
                  onDeleted={handleDeletedAttachment}
                />
              )}

              {/* Standard's AttachmentList */}
              {process.env.REACT_APP_BUILD_TARGET !== 'RAMA' && (
                <StdAttachmentList
                  attachments={attachments}
                  onUpload={() => setIsShowDropzone(true)}
                  onDeleted={handleDeletedAttachment}
                />
              )}

              <Box display="flex" flexDirection="row" className={classes.btn_}>
                <Button variant="contained" type="submit"   form="form_id" color="primary">{t('Save')}</Button>
                <Button variant="contained" color="primary" onClick={() => history.push('/kb')}>{t('Cancel')}</Button>
              </Box>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>  
        
  )
}

export default MyEditor;
