import React, { useState } from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
// import PageHelmet from '../../TM/src/components/PageHelmet';
// import PageHeader from '../../TM/src/components/PageHeader';
import { Container, DialogActions, Grid, Button, Typography, IconButton } from '@material-ui/core';
import {  useSnackbar } from 'notistack';
// import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import EmailDialogContent from './EmailDialogContent.js'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Left from '../elements/left';
import Right from '../elements/right';
import EmailPagination from '../elements/EmailPagination';

const zIndex = 1500;
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  root: {
    zIndex,
    height: 600,
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);',
  },
      
  left: {
    height: 550,
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    '@media (min-width: 864px)': {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  Pagination: {
    height: 50,
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    '@media (min-width: 864px)': {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  right:{
    flexGrow: 1,
    width: 650,
    flexBasis: 650,
    flexShrink: 0,
  }

}))



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
  
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
      </IconButton>
    ) : null}
    </MuiDialogTitle>
  );
});



const EmailDialog = ({
  dialogOpen,
  handleDialog,
  trackingId,
}) => {
  // const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [boolReply, setBoolReply] = useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState();
  const [boolForward , setBoolForward] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [emails, setEmails] = React.useState([]);
  const [emailPerPage, setEmailPerPage] = React.useState([]);
  const [pageAllCount, setPageAllCount] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // setSelectedEmail(0);

  const onSelectEmail = (email) => {
    setSelectedEmail(email)
    setBoolReply(false)
    setBoolForward(false)
  }

  const handleReply = (bool) => {
    setBoolReply(bool)
    setBoolForward(false)
    setAnchorEl(null);
  }

  const handleForward = () => {
    setBoolForward(true);
    setBoolReply(false)
    setAnchorEl(null);
  }

  const handleDiscard = () => {
    setBoolReply(false)
    setBoolForward(false)
  }

  const handleMoreOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const changeRowsPerPageQuery = (event) => {
    setPageSize(+event.target.value);
    setPageNum(1);
  };

  const firstPageButtonClick = (event) => {
    setPageNum(1);
  };

  const backButtonClick = (event) => {
    setPageNum(pageNum-1);
  };

  const nextButtonClick = (event) => {
    setPageNum(pageNum+1);
  };

  const lastPageButtonClick = (event) => {
    setPageNum(pageAllCount);
  };

  /* Get data to list */
  const fetchEmails = React.useCallback(() => {
      
    let url = `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/Emails/GetEmailsbyTracker2?id=${trackingId}`;
    let config = {
      method: 'GET',
      timeout: 1000 * 3,
      headers: {
        "Content-Type": "application/json"
      },
    };

    axios( url, config )
      .then(response => {
        if (response.data.length > 0 && response.status === 200) {
          let data = response.data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
          setEmails(data);
          // setPageAllCount(response.data.totalPages)
          // console.log(" data ---- ", response.data.data)

          /* Set up data by rows per page for serving Pagination */
          if(response.data.length % pageSize > 0) {
            let allCount = parseInt(response.data.length / pageSize) + 1;
            setPageAllCount(allCount);
            console.log('pageNum for now ---> ', pageNum);
            let dataIndex = (pageNum-1)*pageSize;
            // return setEmailPerPage(data.slice(dataIndex, dataIndex + pageSize));
            if(pageNum === allCount) {
              return setEmailPerPage(data.slice(dataIndex));
            } else {
              return setEmailPerPage(data.slice(dataIndex, dataIndex + pageSize));
            };
          };
        } else { return setEmails(response.data); };
      })
      .catch(err => {
        enqueueSnackbar(t('Fail to load emails.'), { variant: 'error' });
        console.log(err.message);
      });

  }, [pageNum, pageSize, enqueueSnackbar, t, trackingId]);

  /* Use Effect */
  // React.useEffect( () =>  {
  //   fetchEmails()
  //   // console.log('selectedEmail ---- ', selectedEmail);
  // }, [fetchEmails, selectedEmail])

  React.useEffect( () =>  {
    if (!dialogOpen) return;
    fetchEmails()
    // console.log('selectedEmail ---- ', selectedEmail);
  }, [dialogOpen])

  return (
    <div>

      <Dialog 
        open={dialogOpen}
        onClose={() =>handleDialog(false)}
        maxWidth={'xl'}
        fullWidth
      >

        <DialogTitle
          onClose={() => handleDialog(false)}
        >
          Email
        </DialogTitle>

        <DialogContent>
          <Container maxWidth="xl" className={classes.container}>
          
          <Grid 
            className={clsx({
                [classes.root] : true,
                [classes.stretch]: selectedEmail,
            })}
          >

            {/* Email List*/}
            {/* <Left
              className={classes.left}
              emails={emails}
              onSelectEmail={onSelectEmail}
              pageAll={pageAll}
              pageNum={pageNum}
              pageSize={pageSize}
              changeRowsPerPageQuery={changeRowsPerPageQuery}
              firstPage={firstPage}
              backPage={backPage}
              nextPage={nextPage}
              lastPage={lastPage}
            /> */}
            {/* Email List*/}
            <Grid>
              <Left
                className={classes.left}
                // emails={emails}
                emails={emailPerPage}
                onSelectEmail={onSelectEmail}
              />
              <EmailPagination
                className={classes.Pagination}
                pageAll={pageAllCount}
                pageNum={pageNum}
                pageSize={pageSize}
                changeRowsPerPageQuery={changeRowsPerPageQuery}
                firstPage={firstPageButtonClick}
                backPage={backButtonClick}
                nextPage={nextButtonClick}
                lastPage={lastPageButtonClick}
              />
            </Grid>

            {/* Selected email */}
            {selectedEmail && (
              <Right
                className={classes.right}
                selectedEmail = {selectedEmail}
                boolReply={boolReply}
                handleReply={handleReply}
                boolForward={boolForward}
                handleForward={handleForward}
                handleDiscard={handleDiscard}
                anchorEl={anchorEl}
                handleMoreOpen={handleMoreOpen}
                handleMoreClose={handleMoreClose}
              />
            )}

          </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleDialog(false)}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EmailDialog;