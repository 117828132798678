import React from 'react';
import { Grid, Box,  List, TableRow, CardContent, Divider, Card, Typography, IconButton,  TableCell, TableBody, Table, RadioGroup, FormControlLabel, Radio, MenuItem, Menu, ListItemText, ListItemIcon, Button} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import TagOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { EditorState, ContentState, convertFromRaw } from 'draft-js';
// import Editor from 'draft-js-plugins-editor';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkFilledIcon from '@material-ui/icons/Bookmark';
// import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {
    Link
  } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppStore';
import { useTranslation } from 'react-i18next';



 
import axios from 'axios';
import Comments from './Comments'

import { Editor as NewEditor } from 'react-draft-wysiwyg';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "1%",
    },
    view_card: {
        marginLeft: "1%",
        marginRight: "1%"
    },
    view_list: {
        marginRight: "3%"
    },
    side_card: {
        flexGrow: 1,
        // position: 'relative'
        margin: "1%",
        // position: "fixed"
        // marginRight: "1%"
        width: "25%"
    },
    extraSpace: {
        width: "5%"
    },
    help_:{
        margin: theme.spacing(1),
        '& > *': {
               margin: theme.spacing(1),
        },
    },
    content_area: {
        width: "80%",
        textAlign: "center"
        // maxHeight: "60vh",
        // overflow: "auto",
    },
    add_comment: {
        marginLeft: "1%",
        width: "40%"
    },
    tags: {
        color: "#757575",
        marginLeft: "1%",
        // width: "20%"   
    },
    medium: {
        marginLeft: "3%",
    },
    details: {
        // width: "65%",
        color: "#1769aa"
    },
    title_: {
        width: "80%"
    },
    post_comment: {
        marginTop: "1.5%",
        marginBottom: "1.5%",
        marginLeft: "1%"
    },
    form_comment: {
        width: "100%",
        height: "100%"
    },
    comment_text: {
        margin: "1%"
    },
    seeAlsoSpace: {
        margin: "2%"
    }
}))

let bookmark_test_data = [
    {
        id: "K-001",
        type: "knowledge-base",
        title: "Article #1234-abc-001 (2020-10-10)"
    },
    {
        id: "K-002",
        type: "knowledge-base",
        title: "Article #1234-abc-002 (2020-10-10)"
    },
    {
        id: "K-003",
        type: "knowledge_base",
        title: "Article #1234-abc-003 (2020-10-10)"
    }
];

const ViewKB = (props) => {
    const classes= useStyles();
    const [listFiles, setListFiles] = React.useState([]);
    const [allArticlesByCategory, setAllArticlesByCategory] = React.useState([]);
    const [allArticles, setAllArticles] = React.useState([]);
    const [btnColor, setBtnColor] = React.useState("default")
    const [bookmarks, setBookmarks] = React.useState(bookmark_test_data);
    const [isBookmarkChecked, setIsBookmarkChecked] = React.useState(false);
    const [articleInfo, setArticleInfo] = React.useState({});
    const [value, setValue] = React.useState('yes');
    const [editorState, setEditorState] = React.useState(undefined);
    const { loginUser, hasPermission } = React.useContext(AppContext);
    const {t} = useTranslation();
    const [articleOwner, setArticleOwner] = React.useState();


    // console.log("* [ViewKB] I have Executed");

    const article_id = props.location.state.val.id;

    const handleChange_radio = (event) => {
        setValue(event.target.value);
    };

    const isOwner = (article_obj) => (article_obj.owner && article_obj.owner === loginUser._id);

    const filterArticles = (list) => {
        let filtered_list = list.filter((v) => {
            if (isOwner(v))
              return true;
            if (v.kb_status === 'approve')
              return true;
            if (hasPermission('article_approve') && v.kb_status === 'waiting approval')
              return true;
            return false;
          });
        //   console.log("[filterArticles] filtered_list", filtered_list);
          return filtered_list;
    }

    const setBookmarkCheckedState = (article_id) => {
        for(let key in Object.keys(bookmarks)) {
            // console.log(`[ViewKB] [isChecked] bookmarks[key].id = ${bookmarks[key].id} (${bookmarks[key].title})`);
            if(bookmarks[key].id === article_id) {
                // console.log(`[ViewKB] [isChecked] setting isBookmarkChecked = true`);
                setIsBookmarkChecked(true);
                return;
            }
        }
        // console.log(`[ViewKB] [isChecked] setting isBookmarkChecked = false`);
        setIsBookmarkChecked(false);
    };

    const setBookmarkColour = (is_checked) => {
        if(is_checked) {
            setBtnColor("secondary");
        } else {
            setBtnColor("primary");
        }
    };

    const fetchArticlesByCategory = (category, limit) => {
        console.log(`[ViewKB] FETCHING All Articles by Category (for See Also)`);
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/GetAllByCategory?category_id=${category}&limit=${limit}`)
            .then(res => res.json())
            .then((data) => {
                // console.log("[ViewKB] data (for setAllArticlesByCategory)", data);
                setAllArticlesByCategory(filterArticles(data));
            })
    };

    const fetchAllArticles = () => {
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article`)
        .then(res => res.json())
        .then((data) => {
            // console.log("fetching all articles", data);
            setAllArticles(data);
        })
    }

    const fetchContacts = (articleOwnerId) => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`)
            .then((response) => response.json())
            .then((data) => {

                console.log('data fetch contacts', data)
                const articleOwnerInfo = data.data.find((element) => element._id == articleOwnerId)
                articleOwnerInfo ? setArticleOwner(articleOwnerInfo.name) : setArticleOwner("")
            })
    }

    const fetchArticle = () => {
        // console.log(`[ViewKB] FETCHING Article`);
        // console.log("[ViewKB] KB >>> article_id", article_id);
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${article_id}`)
            .then(res => res.json())
            .then((data) => {
                console.log("[ViewKB] data (article)", data);
                
                setArticleInfo(data.article);
                setListFiles(data.article.files)
                data.article.owner ? fetchContacts(data.article.owner) : setArticleOwner("")

            
                const content = {};
                content.blocks = data.content.blocks
                content.entityMap = data.content.entityMap

                const state = content
                    ? EditorState.createWithContent(convertFromRaw(content))
                    : EditorState.createEmpty();
                setEditorState(state);
            })
    };

    const fetchBookmarks = () => {
        // console.log(`FETCHING Bookmarks`);

        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/person/bookmarks?id=${loginUser._id}`)
            .then(res => {
                if(res.status === 200) {
                    return res.json();
                } else {
                    console.log(`[ViewKB] HTTP Code was ${res.status} (${res.statusText})`);
                    return [];
                }
            })
            .then(data => {
                // console.log("[ViewKB] data (Bookmarks)", data);
                setBookmarks(data);
            })
            .catch(e => console.log(e) )
    }

    React.useEffect( () => {
        fetchArticle();
        fetchBookmarks();
    }, [] )

    React.useEffect( () => {
        if(articleInfo.category)
            fetchArticlesByCategory(articleInfo.category._id, 10);
    }, [articleInfo] )

    React.useEffect( () => {
        setBookmarkCheckedState(article_id);
    }, [bookmarks] )

    React.useEffect( () => {
        setBookmarkColour( isBookmarkChecked );
    }, [isBookmarkChecked] )

    React.useEffect( () => {
        if(articleInfo.category)
            fetchAllArticles();
    }, [articleInfo] )


    const Download_file = (data, data1) => {
        // console.log("[ViewKB] Download_file", data, data1);
        axios({
            url: `${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile?id=${data}`,
            method: 'POST',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
        
            fileLink.href = fileURL;
            fileLink.setAttribute('download', data1);
            document.body.appendChild(fileLink);
        
            fileLink.click();
        });
    };

    const addBookmark = ( article_id, article_title, article_type ) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/AddBookmark?id=${loginUser._id}&bookmark_id=${article_id}&bookmark_type=${article_type}&bookmark_title=${article_title}`,{
            method: 'PUT', 
            headers: myHeaders
        })
        .then(res => {
            if(res.status === 200) {
                // console.log(`[ViewKB] bookmark added`);
                // setBookmarkCheckedState(article_id);
                setIsBookmarkChecked(true);
            } else {
                console.log(`[ViewKB] Error: ${res.status}, bookmark not added`);
            }
            return res.json();
        })
        .then(body => console.log(body))
        .catch((error) => {
            console.log('[ViewKB] Error: bookmark not added --> ', error);
        }) 
    };

    const deleteBookmark = ( article_id ) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/DeleteBookmark?id=${loginUser._id}&bookmark_id=${article_id}`,{
            method: 'DELETE', 
            headers: myHeaders
        })
        .then(res => {
            // Note: No Content is returned cannot print body as JSON.
            if(res.status === 200) {
                // console.log(`[ViewKB] bookmark removed`);
                // setBookmarkCheckedState(article_id);
                setIsBookmarkChecked(false);
            } else {
                console.log(`[ViewKB] Error: ${res.status}, bookmark not removed`);
            }
        })
        .catch((error) => {
            console.log('[ViewKB] Error: bookmark not removed --> ', error);
        }) 
    };

    const addFollower = ( article_id ) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/AddFollower?articleId=${article_id}&userId=${loginUser._id}`,{
            method: 'POST', 
            headers: myHeaders
        })
        .then(res => {
            if(res.status !== 200) {
                console.log(`[ViewKB] Error: ${res.status}, follower not added`);
            }
            return res.json();
        })
        .then(body => console.log(body))
        .catch((error) => {
            console.log('[ViewKB] Error: follower not added --> ', error);
        }) 
    };

    const removeFollower = ( article_id ) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/DeleteFollower?articleId=${article_id}&userId=${loginUser._id}`,{
            method: 'DELETE', 
            headers: myHeaders
        })
        .then(res => {
            if(res.status !== 200) {
                console.log(`[ViewKB] Error: ${res.status}, follower not removed`);
            }
            return res.json();
        })
        .then(body => console.log(body))
        .catch((error) => {
            console.log('[ViewKB] Error: follower not removed --> ', error);
        }) 
    };

    const Bookmark = () => {
        const article_title = articleInfo.title;
        const article_type = "Article";

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (isBookmarkChecked) {
            deleteBookmark( article_id );
            removeFollower( article_id );
        } else {
            addBookmark( article_id, article_title, article_type );
            addFollower( article_id );
        }
    }

    const sendToApprove = () => {
        console.log("get article id", article_id);

        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        // myHeaders.append("content-type", "text/plain");
    
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/SendToApprove?articleId=${article_id}`,{
            method: 'PUT', 
            headers: myHeaders,
        })
        .then(res => {
            if(res.status !== 200) {
                console.log(`[ViewKB] Error: ${res.status}, article not sent for approval`);
                return res.json();
            }
            return res.text();
        })
        .then(body => console.log(body))
        .catch((error) => {
            console.log('[ViewKB] Error: article not sent for approval --> ', error);
        })
    }

      const upVote = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/Likes?articleId=${article_id}&userId=${loginUser._id}`,{
            method: 'PUT', 
            // headers: myHeaders
        })
        .then(response => response.json())
        .catch((error) => {
            console.log('[ViewKB] 1111Error:', error); 
        }) 
      }

      const downVote = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/Dislikes?articleId=${article_id}&userId=${loginUser._id}`,{
            method: 'PUT', 
            // headers: myHeaders
        })
        .then(response => response.json())
        .catch((error) => {
            console.error('[ViewKB] 1111Error:', error);
            console.log('[ViewKB] 1111Error:', error); 
        }) 
      }

    return (
        
        <Grid container>
            <Container maxWidth="xl" className={classes.container} spacing={1}>
                <PageHeader
                    title= {t('View Article')}
                    subtitle= {t('Reviewing the existing article')}
                    buttonName={(isOwner(articleInfo) && articleInfo.kb_status === "draft") ? t('Request Approval') : null}
                    onClickButton={ sendToApprove }
                />    
            </Container>
            <Grid item xs={12}>
                <Card className={classes.view_card}>
                    <CardContent className={classes.medium}>
                        
                        {/* Article title, tag, bookmark */}
                        <Box display="flex" flexDirection="row" >
                           <List className={classes.title_}>
                               <Typography variant="h5">{articleInfo.title}</Typography>
                            </List>
                           <List>
                               <Box display="flex" flexDirection="row" className={classes.tags}>
                                   <IconButton>
                                       <TagOutlinedIcon/>
                                    </IconButton>

                                    {articleInfo.tags}

                                    <IconButton color={btnColor} onClick={Bookmark} >
                                        { isBookmarkChecked 
                                            ? <BookmarkFilledIcon />
                                            : <BookmarkBorderOutlinedIcon/> }
                                    </IconButton>
                                </Box>
                            </List>
                        </Box>

                        {/* Article caption */}
                        <List>
                            <Typography variant="subtitle1">{articleInfo.caption}</Typography>
                        </List>

                        {/* Article owner, version, created at,
                            Article score, status, followers
                          */}
                        <div>
                            <List className={classes.details}>
                                {t("Owner")}: {articleOwner} | {t('Version')}: {articleInfo.version} | {t('Created at')}: {new Date(articleInfo.dateCreated).toLocaleString()}  | 
                                Start Publish: {new Date(articleInfo.startPublish).toLocaleDateString()} | End Publish: {new Date(articleInfo.endPublish).toLocaleString()}
                            </List>
                            <List className={classes.details}>
                                {t('Score')}: {articleInfo.score} | {t('Status')}: {articleInfo.kb_status} | {t('Followers')}: {articleInfo.followers ? articleInfo.followers.length : 0}
                            </List>
                        </div>


                        <Box display="flex" flexDirection="row">
                            {/* Article editor  */}
                            <div className={classes.content_area} >
                                <NewEditor 
                                    editorState={editorState}
                                    toolbarHidden={true}
                                />
                            </div>

                            <div className={classes.extraSpace}></div>

                            <Box className={classes.side_card} >
                                
                                {/* Article Attach files */}
                                <Box fontWeight="fontWeightBold">{t('List of files')}</Box>
                                    {listFiles && listFiles.map((row) => (
                                        <React.Fragment key={row.id}>
                                            <Box display="flex" flexDirection="row" margin="1%">
                                                <Typography>{row.file_name}</Typography>
                                                <IconButton onClick={() => Download_file(row.id, row.file_name)} color="primary">
                                                    <GetAppOutlinedIcon/>
                                                </IconButton>
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                    <Divider />

                                    {/* Article See also */}
                                    <Box fontWeight="fontWeightBold">{t('See Also')}</Box>
                                    {
                                        allArticlesByCategory && allArticlesByCategory.map((val, index) => {
                                            return(
                                                (articleInfo.category && 
                                                    val.category.name === articleInfo.category.name &&
                                                    <div key={index} className={classes.seeAlsoSpace} >
                                                        {(val.title !== articleInfo.title &&
                                                            <Link to={{
                                                                    pathname: '/kb/view',
                                                                    state: {
                                                                        article_id: val.id
                                                                    }
                                                                }}
                                                            >
                                                                {val.title}
                                                            </Link>
                                                        )}
                                                    </div>
                                                )
                                            )
                                        })
                                    }
                            </Box>
                        </Box>
                        <Divider />
                            <RadioGroup value={value}  onChange={handleChange_radio}className={classes.help_}>
                                <Box display="flex" flexDirection="row">
                                    <Box marginTop="1%" marginRight="1%">{t('Is the information helpful?')}</Box>
                                    <FormControlLabel onClick={upVote} value="yes" control={<Radio />} label={t("Yes")} />
                                    <FormControlLabel onClick={downVote} value="no" control={<Radio />} label={t("No")} />
                                </Box>
                            </RadioGroup>

                        <Comments article_id={article_id}/>
                      
                    </CardContent>
                </Card>
                
                   
               
            </Grid>

        </Grid>

    )
}
export default ViewKB;