import React, { useEffect, useState, useContext } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// import PageHeader from "../TM/src/components/PageHeader";
import moment from 'moment';
import dotProp from 'dot-prop';
import { AppContext } from '../../../contexts/AppStore';

import {
  Divider,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  bgCard: {
    padding: "3%",
  },
  title: {
    paddingTop: 0,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.75),
    },
    color: "primary",
  },
  firstTitleGrid: {
    // marginTop: "3%",
    marginBottom: "1%",
    paddingLeft: "1%"
  },
  titleGrid: {
    marginTop: "3%",
    marginBottom: "1%",
    paddingLeft: "1%"
  },
  dataGrid: {
    // padding: "1%",
    // paddingBottom: theme.spacing(2),
  },
  dataGridOther: {
    // paddingLeft: "1%",
    // paddingRight: "1%",
    // paddingBottom: theme.spacing(2),
  },
  dataCard: {
    padding: "0%",
    // height: "20%",
    // width: "30%",
  },
  root: {
    // width: '100%',
    width: 330,
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(3, 2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  rootVer: {
    width: 'fit-content',
    // border: `1px solid ${theme.palette.divider}`,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    // color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minWidth: 250,
    // width: 350,
    // [theme.breakpoints.between('sm', 'lg')]: {
    //   width: 300,
    // },
  },
  body: {
    fontSize: 14,
    minWidth: 250,
    // width: 350,
    // [theme.breakpoints.between('sm', 'lg')]: {
    //   width: 300,
    // },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // width: 350
  },
}))(TableRow);

const FollowUpTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const history = useHistory();
  const { loginUser } = useContext(AppContext);

  const myWorkInit = {
    newCases: 0,
    accepted: 0,
    done: 0,
    total: 0
  }

  const [countOfMyWork, setCountOfMyWork] = useState(myWorkInit);

  const myGroupInit = {
    overDue: 0,
    dueToday: 0,
    open: 0,
    closed: 0,
    total: 0
  }

  const [countOfMyGroup, setCountOfMyGroup] = useState(myGroupInit);

  const ticketPoolInit = {
    overDue: 0,
    dueToday: 0,
    dueTomorrow: 0,
    waiting: 0,
    total: 0
  }

  const [ticketPoolCounts, setTicketPoolCounts] = useState(ticketPoolInit);

  const presentYear = new Date().getFullYear().toString()
  const presentMonth = (new Date().getMonth()).toString()
  const presentDate = new Date().getDate().toString()

  /* Define variable for New Cases and Due Follow-up Today */
  const startMorningShift = new Date(presentYear, presentMonth, presentDate, "0", "0", "0").getTime();
  // const endMorningShift = new Date(presentYear, presentMonth, presentDate, "8", "0", "0").getTime();
  // const startDayShift = new Date(presentYear, presentMonth, presentDate, "8", "0", "0").getTime();
  // const endDayShift = new Date(presentYear, presentMonth, presentDate, "16", "0", "0").getTime();
  // const startNightShift = new Date(presentYear, presentMonth, presentDate, "16", "0", "0").getTime();
  const endNightShift = new Date(presentYear, presentMonth, presentDate, "23", "59", "59").getTime();

  /* Define variable for pulluser follow up  */
  const todayStartMoment = moment().startOf('day').toISOString();
  const todayEndMoment = moment().endOf('day').toISOString();
  const tomorrowStartMoment = moment().add(1, 'days').startOf('day').toISOString();
  const tomorrowEndMoment = moment().add(1, 'days').endOf('day').toISOString();
  const dayAfterTomorrowStartMoment = moment().add(2, 'days').startOf('day').toISOString();
  const dayAfterTomorrowEndMoment = moment().add(2, 'days').endOf('day').toISOString();

  useEffect(() => {

    /* GET data for My Work*/
    fetch(`${process.env.REACT_APP_TM_BACKEND_FQDN}/tm/api/v1/tickets/rama-my-work-today?userId=${dotProp.get(loginUser, '_id', 'expect-empty-array')}&poolUsername=pooluser`)
      .then(res => res.json())
      .then(res => {
        return {
          newCases: res.newCasesCount,
          accepted: res.acceptedCount,
          done: res.doneCount,
          total: res.myWorkCount
        }
      })
      .then(data => setCountOfMyWork({
        newCases: data.newCases,
        accepted: data.accepted,
        done: data.done,
        total: data.total
      }))
    

    /* GET data for My Group*/
    fetch(`${process.env.REACT_APP_TM_BACKEND_FQDN}/tm/api/v1/tickets/rama-my-group-today?poolUsername=${process.env.REACT_APP_POOL_USER}`)
      .then(res => res.json())
      .then(res => {
        return {
          overDue: res.overdueCount,
          dueToday: res.dueTodayCount,
          open: res.openCasesCount,
          closed: res.closedCasesCount,
          total: res.myGroupCount,
        }
      })
      .then(data => setCountOfMyGroup({
        overDue: data.overDue,
        dueToday: data.dueToday,
        open: data.open,
        closed: data.closed,
        total: data.total
      }))

    /* GET data for follow up */
    fetch(`${process.env.REACT_APP_TM_BACKEND_FQDN}/tm/api/v1/tickets/rama-followup-plan`)
      .then(res => res.json())
      .then(res => {
        return {
          overDue: res.overdueCount,
          dueToday: res.dueTodayCount,
          dueTomorrow: res.dueTomorrowCount,
          waiting: res.waitingCount,
          total: res.followupCount
        }
      })
      .then(data => setTicketPoolCounts({
        overDue: data.overDue,
        dueToday: data.dueToday,
        dueTomorrow: data.dueTomorrow,
        waiting: data.waiting,
        total: data.total
      }))

  }, [loginUser,
    startMorningShift,
    endNightShift,
    todayStartMoment,
    todayEndMoment,
    tomorrowStartMoment,
    tomorrowEndMoment,
    dayAfterTomorrowStartMoment,
    dayAfterTomorrowEndMoment]);

  return (
    <React.Fragment>
      <Paper className={classes.dataCard} elevation={3}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              {/* Row 1 */}
              <StyledTableRow key="name">
                <StyledTableCell style={{backgroundColor: "#0F64F9"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5" style={{color: "white"}}>
                        {t("dashboard_page_my_work")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="flex-end">
                    <Typography style={{color: "white"}} variant="h3" align="right">
                      {countOfMyWork.total}
                    </Typography>
                  </Grid>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#C6E6FF"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_new_cases")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfMyWork.newCases}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_accepted")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                     {countOfMyWork.accepted}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_done")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfMyWork.done}
                    </Typography>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              {/* Row 2 */}
              <StyledTableRow key="name">
                <StyledTableCell style={{backgroundColor: "#0F64F9"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5" style={{color: "white"}}>
                        {t("dashboard_page_my_group")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="flex-end">
                    <Typography style={{color: "white"}} variant="h3" align="right">
                      {countOfMyGroup.total}
                    </Typography>
                  </Grid>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#EBEEF0"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_overdue")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfMyGroup.overDue}
                    </Typography>
                  </Grid>
                  {/* <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        Due Today
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfMyGroup.dueToday}
                    </Typography>
                  </Grid> */}
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_open_cases")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfMyGroup.open}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_closed_cases")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfMyGroup.closed}
                    </Typography>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              {/* Row 3 */}
              <StyledTableRow key="name">
                <StyledTableCell style={{backgroundColor: "#0F64F9"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5" style={{color: "white"}}>
                        {t("dashboard_page_follow_up")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="flex-end">
                    <Typography style={{color: "white"}} variant="h3" align="right">
                      {ticketPoolCounts.total}
                    </Typography>
                  </Grid>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#C6E6FF"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_overdue")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {ticketPoolCounts.overDue}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_due_today")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {ticketPoolCounts.dueToday}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_due_tomorrow")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {ticketPoolCounts.dueTomorrow}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_waiting")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {ticketPoolCounts.waiting}
                    </Typography>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
};

export default FollowUpTable;