// NOTE: This is actually ElasticSearch API container.
// To save all Screen-Pop ES queries related.
import axios from 'axios';
import url from 'url';

const ELASTIC_SEATCH_CONTACT_URL = url.resolve(process.env.REACT_APP_ELASTIC_SEARCH_FQDN, '/elasticsearch/cream/person/_search');

// STD Elastic Search query for screen-pop.
export const findPersonByPhone = (phoneNumber) => {
  return axios({
    url: ELASTIC_SEATCH_CONTACT_URL,
    method: 'POST',
    data: {
      query: {
        match: {
          phone: phoneNumber,
        },
      },
    },
  });
};

// RAMA Elastic Search query for screen-pop.
export const findCallersByPhone = (phoneNumber) => {
  return axios({
    url: ELASTIC_SEATCH_CONTACT_URL,
    method: 'POST',
    data: {
      size: 50,
      sort: [
        {
          'CustomData.order': {
            order: 'asc',
          },
        },
      ],
      query: {
        bool: {
          must: [
            {
              match: {
                isActive: true,
              },
            },
            {
              match_phrase: {
                'CustomData.contactType': 'Caller',
              },
            },
            {
              query_string: {
                fields: ['phone'],
                query: `*${phoneNumber}*`,
              },
            },
          ],
        },
      },
    },
  });
};
