/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import url from 'url';

const POST_SEND_EMAIL_URL = url.resolve(process.env.REACT_APP_EMAIL_BACKEND_FQDN, '/api/Emails');
const GET_ATTACHMETNS_URL = url.resolve(process.env.REACT_APP_EMAIL_BACKEND_FQDN, '/api/Emails/GetEmailsbyTracker');

export const sendWelcomeEmail = (toEmail, trackingId, loginUserId) => {
  return axios({
    url: `${POST_SEND_EMAIL_URL}?user_id=${loginUserId}`,
    method: 'POST',
    data: {
      to: toEmail,
      from: process.env.REACT_APP_DEFAULT_EMAIL,
      subject: '',
      text_body: 'สวัสดีค่ะ นี่คือข้อความจากทางศูนย์พิษวิทยา โรงพยาบาลรามาธิบดี หากท่านต้องการสอบถามรายละเอียดหรือปรึกษาข้อมูลเพิ่มเติม สามารถส่งข้อความพร้อมแนบรูปหรือเอกสาร ผ่านอีเมลนี้ได้เลยค่ะ ขอบคุณที่ใช้บริการค่ะ',
      sent: false,
      tracking_id: trackingId,
      status: 'Welcome',
      attachments: [],
    },
  });
};

export const getAttachmentsByTrackingId = (trackingId) => {
  return axios({
    url: `${GET_ATTACHMETNS_URL}?id=${trackingId}`,
    method: 'GET',
  });
};
