import React, {useRef} from 'react';
import { Grid, Box,Button, TablePagination, TableFooter,  Card, OutlinedInput, InputAdornment, Avatar, IconButton, Typography, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import icon from './icons';
import MaterialTable from 'material-table';
// import { useTranslation } from 'react-i18next';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    top: {
        marginLeft: "1%",
        marginRight: "1%",
    },
    formControl: {
        marginLeft: "1%"
    },
    btn_search: {
        marginLeft: "1.5%",
        height: "3%",
        marginTop: "0.5%"
    },
    table: {
        minHeight: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        marginLeft: "1%",
        marginRight: "1%"
    },
    ty: {
        margin: "3%",
      }
 
}));

function ConfigTeam(props) {
    const history = useHistory();
    // const {t}= useTranslation();
    const classes= useStyles();
    const [age, setAge] = React.useState('');
    const [tbList, set_tbList] = React.useState([]);
    const tableRef = useRef(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("");

    const handleDeleteDialog = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleDelete = ( delete_id) => {
        setDeleteOpen(false)
        console.log(delete_id) 
        let query= `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams/${delete_id}`
        var raw = JSON.stringify(delete_id);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        fetch(query ,{
            method: 'Delete', 
            headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status >=200 && response.status <=299){    
             enqueueSnackbar(t('Team has been deleted.'), { variant: 'success' });
             fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`)
                .then(res => res.json())
                .then((data) => {
                    console.log("TB_list")
                    console.log(data)
                    set_tbList(data.data)
                })
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Team has fail to delete.'), { variant: 'error' });
        })
} 
  
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`)
            .then(res => res.json())
            .then((data) => {
                set_tbList(data.data)
            })
    } , [])
 
    return (
        <Grid container spacing={1} className={classes.whole}>
         <Container maxWidth="xl" className={classes.container}>
            <PageHeader
            title= {t("Config Teams")}
            subtitle= {t("Managing teams for the employee")}
            buttonName= {t("New Team")}
            onClickButton={()=> history.push('/config/teams/create')}
            />
         </Container>    
        <Grid item xs={12} className={classes.table} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body2"> {t('Team Name')}</Typography></TableCell>
                            <TableCell><Typography variant="body2">{t('Description')}</Typography></TableCell>
                            <TableCell><Typography variant="body2"> {t('Created at')}</Typography></TableCell>
                            <TableCell><Typography variant="body2"> {t('Action')} </Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tbList && tbList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val) => (
                            <React.Fragment key={val._id}>
                                <TableRow >
                                    <TableCell><Typography >{val.name}</Typography></TableCell>
                                    <TableCell><Typography >{val.desc}</Typography></TableCell>
                                    <TableCell><Typography>{new Date(val.created_at).toString()}</Typography></TableCell>
                                    <TableCell>
                                        <IconButton className={classes.link_edit} variant="contained"  onClick={()=> history.push({pathname: '/config/teams/edit',  
                                            state: {val: val} 
                                        })}>  
                                        <EditOutlinedIcon color="primary"/></IconButton> 
                                        <IconButton onClick={() => handleDeleteDialog(val._id)}><DeleteForeverOutlinedIcon color="primary"  /></IconButton>
                                    </TableCell> 
                                </TableRow>       
                            </React.Fragment>        
                        ))}           
                    </TableBody>            
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={tbList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                {/* Delete confirmation dialog */}
                <Dialog
                    open={deleteOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDeleteDialogClose}
                >
                    <DialogTitle>{t('Are you sure you want to delete the team?')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('This will permanently delete the team.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteDialogClose} color="secondary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={() => handleDelete(deleteId)} color="primary">
                            {t('Delete')}
                        </Button>
                    </DialogActions>
                </Dialog>
                </Grid>                    
        </Grid>
    )
}

export default ConfigTeam;
