import React from 'react';
import { Grid, Card, Divider, IconButton, TextField, CardContent, List, Box, Button, Typography, FormControl, FormControlLabel  } from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AutocompleteCategory from './AutocompleteCategory';
import { Controller } from 'react-hook-form';
import { forEach, isArray } from 'lodash';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Switch from '@material-ui/core/Switch';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { logger } from '../../../../TM/src/common';





const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    parallel_extra: {
        margin: 0,
        padding: 0,
        width: "65%",
    },
    errorMsg : {
        color: "#f44336",
        marginTop: "1%",
        marginLeft: "1%"
    },
    hoursMinutes: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    hello: {
        margin: 0,
        '& > *': {
            margin: theme.spacing(1),
        },
    }

}))


const Edit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { control, register, handleSubmit, errors, getValues } = useForm();
    // const [count, setCount] = useState(0)
    const [loading, setLoading] = React.useState(true);
    const [stillLoading, setStillLoading] = React.useState(true)
    const [parentCategory, setParentCategory] = React.useState(null);
    const [defaultParent, setDefaultParent] = React.useState(null);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [hours, setHours ] = React.useState()
    const [minutes, setMinutes] = React.useState() 
    const [isChecked, setIsChecked] = React.useState();
    const [hint, setHint] = React.useState()
    const [moment, setMoment] = React.useState([
        // {
        //     momentDays: "",
        //     momentHours: "",
        //     momentMinutes: ""
        // }
    ])

    const [targetDurationDays, setTargetDurationDays] = React.useState(0)
    const [targetDurationMinutes, setTargetDurationMinutes] = React.useState(0)
    const [targetDurationHours, setTargetDurationHours] = React.useState(0);


    

    
    const category_id =props.location.state.val._id

    const THEME = createTheme({
        typography: {
            subtitle1: {
                "fontWeight": 700,
            },
        },
    });
    
    const handleChange = (event) => {
        setIsChecked(event.target.checked);

        if (event.target.checked == true)
        {
            setHint( t("notify to manager") )
        }
        else if (event.target.checked == false) {
            setHint( t("not notify to manager") )
        }
    };

    const handle = (type, e) => {
        console.log(e.target.value)
        if (type == 'days') {
            if (e.target.value) {
                const value = e.target.value;
                const setValue = (value >= 0 && value != -0) ? value : targetDurationDays;
                setTargetDurationDays(setValue);
            }
            else {
                setTargetDurationDays("")
            }
        }
        else if (type == 'hours') {
            if (e.target.value) {
                const value = e.target.value;
                const setValue = (value >= 0 && value < 24 && value != -0) ? value : targetDurationHours;
                setTargetDurationHours(setValue);
            }
            else {
                setTargetDurationHours("")
            }
        }
        else if (type == 'minutes') {
            if (e.target.value) {
                const value = e.target.value;
                const setValue = (value >= 0 && value < 60 && value != -0) ? value : targetDurationMinutes;
                setTargetDurationMinutes(setValue);
            }
            else {
                setTargetDurationMinutes("")
            }
        }

    }

    
    const changeMomentDays = (index, e) => {
        let arr = [...moment]

        if (e.target.value) {
            const value = e.target.value;
            const setValue = value >= 0 ? value : 0;

            arr[index].momentDays = setValue
            setMoment(arr)
        }
        else {
            arr[index].momentDays = ""
            setMoment(arr)
        }
    }

    const changeMomentHours = (index, e) => {

        let arr = [...moment]

        if (e.target.value) {
            const value = e.target.value;
            const setValue = (value >= 0 && value < 24) ? value : 0;
        
            arr[index].momentHours = setValue
            setMoment(arr)
        }
        else {
            arr[index].momentHours = ""
            setMoment(arr)
        }
    }

    const changeMomentMinutes = (index, e) => {
        let arr = [...moment]

          
        if (e.target.value) {
            const value = e.target.value;
            const setValue = (value >= 0 && value < 60) ? value : 0;
           
            arr[index].momentMinutes = setValue
            setMoment(arr)
        }
        else {
            arr[index].momentMinutes = ""
            setMoment(arr)
        }
    }

    function handleNewRow() {
        setMoment([...moment,  
             {
                 momentDays: 0,
                 momentHours: 0,
                 momentMinutes: 0
             }])
    }

    function handleDeleteRow(index) {
        const values = [...moment];
        values.splice(index, 1);
        setMoment(values);
    }

    React.useEffect(() => {
        const parentId = props.location.state.val.parentId;

        // Avoid fetch for root leve.
        if (!parentId) {
            setLoading(false);
            return;
        }

        // Fetch to find the parent of category
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories/${props.location.state.val.parentId}`)
            .then(res => res.json())
            .then(body => {
                setDefaultParent(body)
            })
            .catch(err => {
                console.error(err);
                console.error('Cannot fetch the parent of category.');
            })
            .finally(() => {
                setLoading(false);
            })

    }, [props.location.state.val.parentId])


    /* exiting on/off to show Hint  */
    const existingHint = (val) => {
        if (val == true){
            setHint( t("notify to manager") )
        }
        else{
            setHint( t("not notify to manager") )
        }
    }


    /* fetching SLA  */
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories/${category_id}`)
            .then(res => res.json())
            .then(data => {

                // TargetDuration --> Milliseconds into days, hours and minutes
                console.log(data.sla.targetDuration)
                const exampleTargetDuration = data.sla.targetDuration
                const ONE_MIN_IN_MS = 1000 * 1;
                const ONE_HOUR_IN_MS = ONE_MIN_IN_MS * 60 * 60 * 1;
                const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24 * 1;

                const days_ = parseInt(exampleTargetDuration / ONE_DAY_IN_MS)
                const MILI_LEFT_AFTER_DAYS = (exampleTargetDuration - (days_ * ONE_DAY_IN_MS))

                const hours_ = parseInt(MILI_LEFT_AFTER_DAYS / ONE_HOUR_IN_MS)
                const MILI_LEFT_AFTER_HOURS = (MILI_LEFT_AFTER_DAYS - (hours_ * ONE_HOUR_IN_MS))

                const minutes_ = MILI_LEFT_AFTER_HOURS / (60*1000)

                setTargetDurationDays(days_)
                setTargetDurationHours(hours_)
                setTargetDurationMinutes(minutes_)

                // NotifyMoment --> Milliseconds into days, hours and minutes
                if (data.sla.notifyMoments.length > 0) {
                    for (let item of data.sla.notifyMoments) {
                        console.log(item)
                        const momentDays = parseInt(item / ONE_DAY_IN_MS)
                        const MILI_LEFT_AFTER_DAYS = (item - (momentDays * ONE_DAY_IN_MS))
        
                        const momentHours = parseInt(MILI_LEFT_AFTER_DAYS / ONE_HOUR_IN_MS)
                        const MILI_LEFT_AFTER_HOURS = (MILI_LEFT_AFTER_DAYS - (momentHours * ONE_HOUR_IN_MS))
        
                        const momentMinutes = MILI_LEFT_AFTER_HOURS / (60*1000)
                        moment.push({
                            momentDays: `${momentDays}`,
                            momentHours: `${momentHours}`,
                            momentMinutes: `${momentMinutes}`
                        })
                    }
                }

                setIsChecked(data.sla.doEscalation)
                existingHint(data.sla.doEscalation)
                setStillLoading(false)
            })
    }, [])



    const cleanInValidDataInArray = () => {
    
        let cleanedMomentArray = [];
        let totalMilliSeconds = 0

        // Convert notifyMoment Array into milliseconds
        if (moment.length > 0) {
            for (let item of moment) {
                const days = item.momentDays != "" ?  60 * 60 * 24 * parseInt(item.momentDays) : 0
                const hours = item.momentHours != "" ? 60 * 60 * parseInt(item.momentHours) : 0
                const minutes = item.momentMinutes != "" ? 60 * parseInt(item.momentMinutes) : 0
                totalMilliSeconds = (days + hours + minutes)* 1000
                cleanedMomentArray.push(totalMilliSeconds)
            }
        }
    
        return cleanedMomentArray;
    }
    

    const getMilliSeconds = (data) => {
        let totalMilliSeconds_TargetDuration = 0;

        // TARGET DURATION milliseconds
        const days = data.days != "" ?  60 * 60 * 24 * parseInt(data.days) : 0
        const hours = data.hours != "" ? 60 * 60 * parseInt(data.hours) : 0
        const minutes = data.minutes != "" ? 60 * parseInt(data.minutes) : 0

        return totalMilliSeconds_TargetDuration = (days + hours + minutes)* 1000

    }



    const onSubmit = (data, e) => {

        console.log(data)

        // total miliseconds targetDuration
        const totalMilliSeconds_TargetDuration = getMilliSeconds(data)
        data.sla.targetDuration = totalMilliSeconds_TargetDuration

        // total miliseconds notifyMoment
        data.sla.notifyMoments = cleanInValidDataInArray();

        
        // convert parent category object to string 
        data.parentId = data.parent && data.parent._id ? data.parent._id : null;
        data.parent = data.parent ? data.parent.path : '//' 

        // compare notifyMoment with targetDuration
        let NMgreaterThanTD = false
        if ( data.sla.notifyMoments.length > 0) {
            for (let item of data.sla.notifyMoments) {
                if (item >= data.sla.targetDuration) {
                    NMgreaterThanTD = true
                }
            }
        }

        console.log('submit', data)
        
        if (NMgreaterThanTD == false) {

            var raw = JSON.stringify(data);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories/${props.location.state.val._id}`, {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
            })
            .then(response => {
                // let data = response.text()
                if (response.status >= 200 && response.status <=299)  {history.push('/config');    
                return enqueueSnackbar(t('Category has been updated.'), { variant: 'success' });
                } 

            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar(t('Category has fail to update.'), { variant: 'error' })
            })
        }
        else if (NMgreaterThanTD == true) {
            enqueueSnackbar(t('Plese input notify moment less than target duration'), { variant: 'error' })
        }
    }

    if (loading === true) {
        return (<></>)
    }
    if (stillLoading === true) {
        return (<></>)
    }

    if (loading === false && stillLoading === false) {
        return (
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title=  {t("Edit category")}
                    subtitle={t("Edit category")}
                />

                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>
                                <Box display="flex" flexDirection="row">
                                    <Typography className={classes.middle}> {t('Parent Name')}  </Typography>
                                    <FormControl className={classes.parallel}>
                                        <Controller
                                            name="parent"
                                            control={control}
                                            defaultValue={defaultParent}
                                            onChange={([, data]) => data}
                                            render={({ value, onChange }) => {
                                                return (
                                                    <AutocompleteCategory
                                                        size="medium"
                                                        defaultValue={value}
                                                        onSelectChange={(e, data) => {
                                                            setParentCategory(data);
                                                            return onChange(data)
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Typography className={classes.middle}> {t('Name')} * </Typography>
                                    <TextField 
                                        className={classes.parallel} 
                                        inputRef={register({ required: true })} 
                                        name="name" 
                                        defaultValue={props.location.state.val.name} 
                                        variant="outlined"
                                        error={errors.name}
                                        helperText={errors.name && t("Name field is required")}
                                        
                                    />
                                    
                                </Box>

                                <Box display="flex" flexDirection="row">
                                    <Typography className={classes.middle}> {t('CSQ Name')}  </Typography>
                                    <TextField  
                                        className={classes.parallel} 
                                        inputRef={register} 
                                        name="csq" 
                                        defaultValue={props.location.state.val.csq} 
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Box>

                                <List/>
                                    <Divider/>
                                <List/>

                                <MuiThemeProvider theme={THEME}>
                                    <Typography variant="subtitle1">
                                        {t('Service Level Agreement')}
                                    </Typography>
                                </MuiThemeProvider>

                                <Box display="flex" flexDirection="row">
                                    <Typography className={classes.middle}> {t('Target Duration')}  </Typography>
                                    <Box display="flex" flexDirection="row" className={classes.hoursMinutes} >
                                    
                                        <List fullWidth>
                                            <TextField
                                                label= {t("Days")} 
                                                fullWidth
                                                // type="number" 
                                                inputRef={register} 
                                                name="days" 
                                                variant="outlined"
                                                defaultValue={targetDurationDays}
                                                value={targetDurationDays}
                                                onChange={(e) => handle('days',e)}
                                            />
                                        </List>
                                        
                                        <List fullWidth>
                                            <TextField
                                                label= {t("Hours")} 
                                                fullWidth
                                                // type="number" 
                                                defaultValue={targetDurationHours}
                                                value={targetDurationHours}
                                                onChange={(e) => handle('hours', e)}
                                                name="hours"
                                                variant="outlined"
                                                inputRef={register}
                                            /> 
                                        </List>
                                        <List fullWidth>
                                            <TextField
                                                label= {t("Minutes")} 
                                                fullWidth
                                                // type="number" 
                                                name="minutes"
                                                variant="outlined"
                                                inputRef={register}
                                                defaultValue={targetDurationMinutes}
                                                value={targetDurationMinutes}
                                                onChange={(e) => handle('minutes', e)}
                                            />         
                                        </List>
                                    </Box>

                                </Box>

                                <Box display="flex" flexDirection="row">
                                    <Typography className={classes.middle}> {t('Notify Moment')}  </Typography>
                                
                                    
                                    <List className={classes.parallel_extra}>
                                        {moment.map((val, index) => {
                                            return (
                                                <Box display="flex" flexDirection="row" className={classes.hello}>
                                                
                                                    <TextField
                                                        label= {t("Days")} 
                                                        fullWidth
                                                        // type="number" 
                                                        name="momentDays"
                                                        variant="outlined"
                                                        onChange={(e) => changeMomentDays(index, e)}
                                                        value={val.momentDays}
                                                        defaultValue={val.momentDays}
                                                        inputRef={register}
                                                    /> 
                                                   
                                                    <TextField
                                                        label= {t("Hours")} 
                                                        fullWidth
                                                        // type="number" 
                                                        name={'momentHours'}
                                                        variant="outlined"
                                                        onChange={(e) => changeMomentHours(index, e)}
                                                        value={val.momentHours}
                                                        defaultValue={val.momentHours}
                                                        inputRef={register}
                                                    /> 
                                                       

                                                    {/* <Controller 
                                                        name="momentMinutes"
                                                        control={control}
                                                        value={val.momentMinutes}
                                                        defaultValue={val.momentDays}
                                                        onChange={(e) => changeMomentMinutes(index, e)}
                                                        render={({ value, onChange }) => {
                                                            return ( */}
                                                                <TextField
                                                                    label= {t("Minutes")} 
                                                                    fullWidth
                                                                    // type="number" 
                                                                    name='momentMinutes'
                                                                    variant="outlined"
                                                                    onChange={(e) => changeMomentMinutes(index, e)}
                                                                    value={val.momentMinutes}
                                                                    inputRef={register}
                                                                    defaultValue={val.momentMinutes}

                                                                /> 
                                                                {/* )
                                                        }}
                                                        rules={{
                                                            validate: () => {
                                                                return getValues('momentMinutes') >= 0 && getValues('momentMinutes') <= 59
                                                            }
                                                        }}
                                                    /> */}


                                                    {
                                                        moment.length !== 1 &&                                             
                                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                                <DeleteForeverOutlinedIcon/>
                                                            </IconButton>
                                                    }
                                                    {
                                                        moment.length - 1 == index &&
                                                            <IconButton 
                                                                onClick={handleNewRow}
                                                            >
                                                                <AddBoxOutlinedIcon/>
                                                            </IconButton>
                                                    }
                                                </Box>
                                            )
                                        })}
                                    </List>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Typography className={classes.middle}> {t('Escalation')}  </Typography>
                                    <FormControlLabel
                                        inputRef={register}
                                        control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleChange}
                                            name="sla.doEscalation"
                                            color="primary"
                                        />
                                        }
                                        label={hint}
                                    />
                                </Box>
                                <Box className={classes.btn_}>
                                    <Button type="submit" color="primary" variant="contained"> {t('Save')}</Button>
                                    <Button color="primary" variant="contained" onClick={() => history.push('/config')}> {t('Cancel')}</Button>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        )
    }
}
export default Edit;