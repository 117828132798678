import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
// import clsx from 'clsx'
// import PageHelmet from '../../TM/src/components/PageHelmet';
// import PageHeader from '../../TM/src/components/PageHeader';
import { Box, Input, Divider, Button , Typography} from '@material-ui/core';
import {  useSnackbar } from 'notistack';
// import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import Index from './EmailDialogContent.js'
import {DropzoneDialog} from 'material-ui-dropzone'
import { useForm } from "react-hook-form";
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import { convertToRaw , EditorState, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';









// const zIndex = 1500;
const useStyles = makeStyles((theme) => ({
    btnAttach: {
        marginLeft: '5px'
    },
    btnSend: {
        marginRight: "3%"
    },
    box1: {
        width: '40%'
    },
    box2: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '70%',
    },
    attachments: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '50%',
    },
    name: {
        width: '50px',
        maxWidth: '50px',
        padding: '0px'
    },

}))


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});
  
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});




const InstantReplyDialog = ({
    instantReplyOpen,
    handleInstantReplyDialog,
    ticketId,
    replyTo,
    selectedEmail,
}) => {
    // const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } =useSnackbar();
    const { register, handleSubmit, errors, control } = useForm();

    // const [replyTo, setReplyTo] = React.useState()
    const [open, setOpen] = React.useState(false)
    const [editorState, setEditorState] = React.useState();
    const [contentState, setContentState] = React.useState(undefined);
    const [attachments, setAttachments] = React.useState([])
    const [prepareAttachment, setPrepareAttachment] = React.useState([])

    // const [replyToEmail, setReplyToEmail] = React.useState()


    
    const handleAttachOpen = (index) => {
        setOpen(true)
    }
    
    function handleAttachClose () {
        setOpen(false)
    }


    const onSubmitFile = async (data) => {

        console.log(data)
        const formData= new FormData()
        formData.append("_file", data[0])

      
       await fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/UploadFile`,{
                method: 'POST', 
                body: formData
            })
            .then(response => { 
                if (response.status ===200){
                    enqueueSnackbar(t('File successfully uploaded.'), { variant: 'success' });    
                    // fetchUploadedFileID(data[0].name)  
                }
                return response.json()
            })
            .then(json_data => { 
                
                prepareAttachment.push({
                    id: json_data.id, 
                    file_name: json_data.file_name
                })
                setAttachments(prepareAttachment)
                setOpen(false)

            })
            .catch((error) => {
                console.error('Error:', error)
                enqueueSnackbar(t('File fail to upload.'), { variant: 'error' });        
            })

    }
    

    const onSubmit = (data, e) => {

        const attachmentsIDs = []
        attachments.map((val) => {
            attachmentsIDs.push({
                attachment_id : val.id
            })
        })

        const draftJsData = {}

        if (editorState != undefined) {
      
            // const plain_text_state = editorState.getCurrentContent();
            // const plain_text = plain_text_state.getPlainText();
            // draftJsData.plain_text = plain_text
            draftJsData.toHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }

        const sendEmailData = {
            to: replyTo,
            from: process.env.REACT_APP_DEFAULT_EMAIL,
            cc: data.cc,
            subject: '',
            text_body: draftJsData.toHTML || "",
            sent: false,
            // references: [selectedEmail.message_id],
            trackingId: ticketId,
            attachments:  attachmentsIDs || [],
            status: "Reply"
        }

        console.log(JSON.stringify(sendEmailData))

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_EMAIL_BACKEND_FQDN}/api/Emails`, {
            method: 'POST', 
            headers: myHeaders,
            body: JSON.stringify(sendEmailData)
        })
        .then((response) => {
            if (response.status >= 200 && response.status <=299) {
                e.preventDefault();
                e.target.reset();
                const emptyEditorState = EditorState.push(editorState, ContentState.createFromText(''));
                setEditorState(emptyEditorState)
                handleInstantReplyDialog(false)                
                return enqueueSnackbar('Email send successfully', {variant: 'success'})
            }
        })
        .catch(function (error) {
            console.log(error)
            enqueueSnackbar('Email fail to send', {variant: 'error'})
        });


    }




    return (
        <div>

            <Dialog 
                open={instantReplyOpen}
                onClose={() =>handleInstantReplyDialog(false)}
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle
                    onClose={() => handleInstantReplyDialog(false)}
                >
                    Email
                </DialogTitle>
                <DialogContent>
                    <form id="form_id" onSubmit={handleSubmit(onSubmit)} className={classes.form} autoComplete="off">   
                        <Box display="flex" flexDirection="row" >
                            <Input 
                                className={classes.name}
                                defaultValue='To:'
                                readOnly
                                disableUnderline
                            />
                            <Input 
                                fullWidth
                                disableUnderline 
                                name='to'
                                inputRef={register({required: true})}
                                className={classes.input}
                                // key={`replyTo:${replyToEmail && replyToEmail.slice(0 , replyToEmail.length - 1 )}`}
                                defaultValue={replyTo}
                            />
                        </Box>
                        <Divider/>
                        {errors.to && (<span className={classes.errorMsg}>{t('To field is required')}</span>)}

                        <Box display="flex" flexDirection="row">
                            <Input 
                                className={classes.name}
                                defaultValue='Cc:'
                                readOnly
                                disableUnderline
                            />
                            <Input
                                name='cc'
                                fullWidth 
                                disableUnderline 
                                inputRef={register}
                                className={classes.input}
                            />
                        </Box>
                        <Divider/>

                        <DraftEditor 
                            editorStyle={{minHeight: "240px", maxHeight: "340px", overflow: "auto", width: '100%'}}
                            editorState={editorState}
                            onContentStateChange={setContentState}
                            onEditorStateChange={setEditorState}
                            toolbarStyle={{border: 'none', marginTop: '5px', bottom: '0'}}
                            toolbar={{
                                options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                            }}
                        />

                    </form>
                    
                    {attachments.map((attachment) => (
                        <div className={classes.attachments}>
                            <Typography>{attachment.file_name.substring(0, 10)} </Typography>
                            <IconButton><DeleteOutlineOutlinedIcon/></IconButton>
                        </div>
                
                    ))}

                </DialogContent>

                <DialogActions>
                        
                        {/* Attachment btn */}
                        <Box display="flex" flexDirection="row" className={classes.box1}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                className={classes.btnAttach}
                                onClick={() => handleAttachOpen()}
                            >
                                Attach
                            </Button>
                            <DropzoneDialog
                                open={open}
                                onSave={(files) => onSubmitFile(files)}
                                showPreviews={true}
                                maxFileSize={10000000}
                                onClose={handleAttachClose}
                                filesLimit='1'
                            />
                        </Box>

                        {/* Send & discard btn */}
                        <Box className={classes.box2}>
                            <Button  
                                color="primary"
                                variant="contained" 
                                className={classes.btnDiscard}
                                onClick={() =>handleInstantReplyDialog(false)}
                            >
                                Discard
                            </Button>
                            <Button  
                                type='submit' 
                                form='form_id' 
                                variant="contained" 
                                color="primary" 
                                className={classes.btnSend}
                            >
                                Send
                            </Button>
                        </Box>

                </DialogActions>

            </Dialog>

            


        </div>
        

    )
}

export default InstantReplyDialog;