import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography, 
  Select,
  MenuItem,
  Grid,
  TextField,
  Tooltip
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';
import 'yup-phone';
import { ContactDialogInput, useStyles } from './styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from "@material-ui/icons/AddOutlined";
import RemoveIcon from "@material-ui/icons/Remove";

const ContactDialog = ({
  isCreateDialog,
  utilizeTo,
  open,
  handleDateChange,
  handleClose,
  dialogTitle,
  dialogSubtitle,
  onSubmit,
  defaultPrefix,
  defaultPid,
  isDuplicatePid,
  isPidNotThirteenDigits,
  defaultFirstName,
  defaultLastName,
  defaultPrevName,
  defaultDob,
  defaultMobile,
  defaultPhone,
  defaultPersonalAddress,
  defaultWorkAddress,
  defaultPersonalEmail,
  defaultWorkEmail,
  defaultContactType,
  defaultType,
  defaultProvince,
  defaultRegion,
  defaultPhoneText,
  defaultVipType,
  defaultGender,
  defaultDepartment,
  defaultNote,
  defaultParentId,
  parentIdValue,
  parentIdOnChange,
  saveButton,
  closeButton,
  parentIdOptions,
  elasticSearchInputData,
  getSelectedOptions,
  parentIdCondition,

  prefixOnChange,
  pidOnChange,
  duplicatedPidChecking,
  firstNameOnChange,
  lastNameOnChange,
  mobileOnChange,
  phoneOnChange,
  personalAddressOnChange,
  workAddressOnChange,
  personalEmailOnChange,
  workEmailOnChange,
  contactTypeOnChange,
  typeOnChange,
  provinceOnChange,
  regionOnChange,
  phoneTextOnChange,
  vipTypeOnChange,
  genderOnChange,
  departmentOnChange,
  noteOnChange,

  addPrevNameSubmit,
  removePrevNameSubmit,
  isDefaultFlag,
  isDefaultFlagHandleChange,

  contactTypeList,
  genderList,
  addrProvince,
  addrRegion
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [prefixList, set_prefixList] = useState([ {_id: 1, name: ""}, ]);
  const typeInit = [ {_id: 1, name: "Human"}, {_id: 2, name: "Animal"}, ];
  const [contactAddrProvince, setContactAddrProvince] = useState([ {_id: 1, name: ""}, ]);
  const [contactAddrRegion, setContactAddrRegion] = useState([ {_id: 1, name: ""}, ]);
  const contactVipType = [ {_id: 1, name: "Yes"}, {_id: 2, name: "No"} ];
  const [contactDetail, setContactDetail] = useState({contactType: [{_id: 1, name: ""}], gender: [ {_id: 1, name: ""}, ]});
  const [existingPid, setExistingPid] = useState()

  // Get data for Prefix List
  const getPrefix = () => {
    return fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
    .then((res) => res.json())
    .then((data) => {
      let prefixList = []
      for(let i = 0; i < data.data.length; i++) {
        prefixList.push({_id: i, name: data.data[i].prefix})
      }
      set_prefixList(prefixList)
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  function searchExistingPid(searchPid) {
    const input_query = {
      query: {
        filtered: {
          query: {
            query_string: {
              query: `*${searchPid}*`,
              fields: [
                // "name",
                // "email",
                // "phone",
                "CustomData.pid"
              ]
            }
          },
          filter: {
            bool: {
              must: []
            }
          }
        }
      }
    }
    esGetSearchPid(input_query);
  }

  const esGetSearchPid = (input_query) => {
    const query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`;

    fetch(query, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input_query)
    })
      .then(res => res.json())
      .then((data) => {
        let pid_list = data.hits.hits.map((v) => {
          v._source._id = v._id;
          return v._source;
        });
        setExistingPid(pid_list);
        duplicatedPidChecking(pid_list)
      });
  }

  const checkDuplicatedPid = async(event, value) => {
    const searchPid = await event.target.value;
    searchExistingPid(searchPid)
  }

  useEffect(() => {
    if(open) {
      getPrefix();
      
      setContactDetail({contactType: contactTypeList, gender: genderList})
      setContactAddrProvince(addrProvince)
      setContactAddrRegion(addrRegion)

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [open, contactTypeList, genderList])

  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    })
  })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Container>
        <DialogTitle className={classes.titleForm} id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContentText className={classes.subtitleForm}>
          {dialogSubtitle}
        </DialogContentText>
        <DialogContent className={classes.form_}>
          {/* Prefix */}
          {utilizeTo === "contactPage" && (
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('prefix')} *</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="title_prefix" value={defaultPrefix}/>} onChange={prefixOnChange}>
                  {prefixList.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
          )}

          {/* PID */}
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Typography  className={classes.middle}>{t('person_pid')}</Typography>
            <Grid>
              <ContactDialogInput
                name="pid"
                value={defaultPid}
                onBlur={isCreateDialog? (e) => checkDuplicatedPid(e) : null}
                onChange={pidOnChange}
              />
            </Grid>
            {isDuplicatePid && (<span className={classes.errorMsg}> {t('PID could not be duplicated.')} </span>)}
            {isPidNotThirteenDigits && (<span className={classes.errorMsg}> {t('PID must be 13 digits.')} </span>)}
          </Grid>
          
          {/* First Name */}
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Typography  className={classes.middle}>{t('person_firstName')} *</Typography>
            <Grid>
              <ContactDialogInput name="firstname" value={defaultFirstName} onChange={firstNameOnChange} />
            </Grid>
          </Grid>

          {/* Last Name */}
          <Grid container direction="row" alignItems="center">
            <Typography  className={classes.middle}>{t('person_lastName')}</Typography>
            <Grid>
            <ContactDialogInput name="lastname" value={defaultLastName} onChange={lastNameOnChange} />
            </Grid>
          </Grid>

          {/* Previous Name */}
          {defaultFirstName && (
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_name')}</Typography>
              <Grid>
                <Box component="span" >
                  {defaultPrevName && (
                    <Typography 
                      style={{ height: 150, width: 330, border: '1px solid #ced4da', marginTop: '3px', padding: '10px 26px 10px 12px', fontSize: 16, borderRadius: 4, }}
                    >
                      {/* {data.firstName + " " + data.lastName} */}
                      {defaultPrevName && defaultPrevName.slice(0,5).map((data, index) => (
                        <Typography>{data.firstName + " " + data.lastName}</Typography>
                      ))}
                    </Typography>
                  )}
                  
                  {!defaultPrevName && (
                  <Typography 
                    style={{ height: 150, width: 330, border: '1px solid #ced4da', marginTop: '3px',  fontSize: 16, borderRadius: 4, }}
                  >
                  </Typography>
                  )}
                </Box>
              </Grid>
              <Grid >
              <Container >
              <Grid>
                <Tooltip title="add previuos name">
                  <IconButton
                    onClick={addPrevNameSubmit}
                  >
                    <AddIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid>
                <Tooltip title="delete previous name">
                  <IconButton
                    onClick={removePrevNameSubmit}
                  >
                    <RemoveIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>

              </Container>
              </Grid>
            </Grid>
          )}

          {/* DOB */}
          {utilizeTo ==="contactPage" && (
            <Grid 
              container 
              direction="row"
              // justifyContent="space-between"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('Date of Birth')}</Typography>
              <Grid>
                <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    format="MM/dd/yyyy"
                    value={defaultDob}
                    // inputRef={register}
                    name= "dob"
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{width: 430}}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          )}

          {/* Phone */}
          {(utilizeTo ==="contactPage" || utilizeTo ==="caller") && (
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_phone')}</Typography>
              <Grid>
                <ContactDialogInput name="phone[0]" value={defaultMobile} onChange={mobileOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Mobile */}
          {(utilizeTo ==="contactPage" || utilizeTo ==="contactPoint") && (
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_mobile')}</Typography>
              <Grid>
                <ContactDialogInput name="phone[1]" value={defaultPhone} onChange={phoneOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Personal Address */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('person_address')}</Typography>
              <Grid>
                <ContactDialogInput name="address[0]" value={defaultPersonalAddress} onChange={personalAddressOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Work Address */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('person_work_address')}</Typography>
              <Grid>
                <ContactDialogInput name="address[1]" value={defaultWorkAddress} onChange={workAddressOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Personal Email */}
          {(utilizeTo ==="contactPage" || utilizeTo ==="caller") && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('person_personal_email')}</Typography>
              <Grid>
                <ContactDialogInput name="email[0]" value={defaultPersonalEmail} onChange={personalEmailOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Work Email */}
          {(utilizeTo ==="contactPage" || utilizeTo ==="contactPoint") && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('person_work_email')}</Typography>
              <Grid>
                <ContactDialogInput name="email[1]" value={defaultWorkEmail} onChange={workEmailOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Contact Type */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('contact_type')}</Typography>
              <Grid>
                <Select style={{width: 250}} input={<ContactDialogInput name="CustomData[contactType]" value={defaultContactType} />} onChange={contactTypeOnChange}>
                  {contactDetail.contactType.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
                <FormControlLabel
                  style={{marginLeft: 10}}
                  control={<Checkbox />}
                  label="Default Flag"
                  checked={isDefaultFlag}
                  onChange={isDefaultFlagHandleChange}
                />
              </Grid>
            </Grid>
          )}
          {utilizeTo === "contactPoint" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('contact_type')}</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="CustomData[contactType]" value={"ACCOUNT"} />} onChange={contactTypeOnChange}>
                  {contactDetail.contactType.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
          )}

          {/* Type */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('type')}</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="CustomData[type]" value={defaultType} />} onChange={typeOnChange}>
                  {typeInit.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
          )}

          {/* Province */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('province')}</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="CustomData[addrProvince]" value={defaultProvince} />} onChange={provinceOnChange}>
                  {contactAddrProvince.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
          )}

          {/* Region */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('region')}</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="CustomData[addrRegion]" value={defaultRegion} />} onChange={regionOnChange}>
                  {contactAddrRegion.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
          )}

          {/* Phone Text */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('phone_text')}</Typography>
              <Grid>
                <ContactDialogInput name="CustomData[phoneText]" value={defaultPhoneText} onChange={phoneTextOnChange} />
              </Grid>
            </Grid>
          )}

          {/* VIP Type */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('vip_type')}</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="CustomData[vipType]" value={defaultVipType} />} onChange={vipTypeOnChange}>
                  {contactVipType.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
            )}

          {/* Gender */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('gender')}</Typography>
              <Grid>
                <Select input={<ContactDialogInput name="gender" value={defaultGender} />} onChange={genderOnChange}>
                  {contactDetail.gender.map((val) => (
                  <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
                ))}
                </Select>
              </Grid>
            </Grid>
          )}

          {/* Department */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('department')}</Typography>
              <Grid>
                <ContactDialogInput name="CustomData[department]" value={defaultDepartment} onChange={departmentOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Note */}
          {utilizeTo === "contactPage" && (
            <Grid container direction="row" alignItems="center">
              <Typography  className={classes.middle}>{t('note')}</Typography>
              <Grid>
                <ContactDialogInput name="CustomData[note]" value={defaultNote} onChange={noteOnChange} />
              </Grid>
            </Grid>
          )}

          {/* Text field for Parent ID */}
          {(utilizeTo ==="contactPage" || utilizeTo ==="caller") && (
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t("Parent ID")}</Typography>
              <Autocomplete
                size="small"
                id="size-small-outlined"
                options={parentIdOptions}
                getOptionLabel={(option) => option.name}
                // getOptionSelected={(option, value) => option.name === value.name}
                onChange={(event, value) => getSelectedOptions(value)}
                defaultValue={defaultParentId}
                value={parentIdValue? parentIdValue: null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{width: 440}}
                    variant="outlined"
                    error={parentIdCondition}
                    onChange={(e) => elasticSearchInputData(e)}
                  />
                )}
              />
              {parentIdCondition && (<span className={classes.errorMsg}> {t('Parent ID could not be same as Name')} </span>)}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            {saveButton}
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
          >
            {closeButton}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ContactDialog;