import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers';
import { DashboardAPI } from '../../apis';
import FieldError from '../../modules/TM/src/components/FieldError';
import { AppContext } from '../../contexts/AppStore';
import * as yup from 'yup';
import queryString from 'query-string';
import base64 from 'base-64';

// The bypass user object for auto sign-in.
let bypassUser = {
  username: process.env.REACT_APP_USERNAME || '',
  password: process.env.REACT_APP_PASSWORD || '',
  extension: process.env.REACT_APP_EXTENSION || '',
}

// Parse URL query string into an object.
let urlParamObject = queryString.parse(window.location.search);

// Detect is auto sign-in via Cisco Finesse?
const isAutoSignInByFinesse = urlParamObject.token && urlParamObject.extension;

// Decoding the simple base64 authentication.
if (isAutoSignInByFinesse) {
  const decodedToken = base64.decode(urlParamObject.token);
  const [username, password] = decodedToken.split(':');
  bypassUser.username = username;
  bypassUser.password = password;
  bypassUser.extension = urlParamObject.extension;
}

// Spliting login images based on build target.
const imageLogo = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_BUILD_TARGET}/image-logo.png`
const imageWallpaper = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_BUILD_TARGET}/image-wallpaper.jpg`

// Material-UI style of the component
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${imageWallpaper})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  unauthorized: {
    color: '#ff4e4e',
    textAlign: 'center',
    paddingLeft: '4px',
    fontWeight: '500',
  },
}));

const LoginPage = ({ location }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { setLoginUser } = useContext(AppContext)
  const [errorReason, setErrorReason] = useState('');
  const loginButtonRef = useRef(null);

  // Perform click login button if auto sign-in.
  useEffect(() => {
    if (isAutoSignInByFinesse) {
      loginButtonRef.current.click();
      bypassUser.username = '';
      bypassUser.password = '';
      bypassUser.extension = '';
    }
  }, []);

  const yupSchema = yup.object().shape({
    username: yup.string().min(4, t('Please enter longer than 4 characters.')).required(t('Please enter longer than 4 characters.')),
    password: yup.string().min(4, t('Please enter longer than 4 characters.')).required(t('Please enter longer than 4 characters.')),
    extension: yup.number().typeError(t('Please enter number between 3 and 4 digit.'))
      .min(100, t('Please enter number between 3 and 5 digit.'))
      .max(99999, t('Please enter number between 3 and 5 digit.'))
  })

  const { handleSubmit, errors, control } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(yupSchema)
  });

  const onSubmitForm = ({ username, password, extension }) => {
    
    // Reset response error message.
    setErrorReason(undefined);

    /// The post authentication request.
    DashboardAPI.postAuthLocal(username, password, extension)
      .then((response) => {
        const personUser = response.data;
        setLoginUser(personUser);
        history.push('/');
      })
      .catch((error) => {
        if (error.response && error.response.status < 500) {
          setErrorReason(error.response.data.message);
        } else {
          setErrorReason(error.message);
        }
      });
  }

  return (
    <Grid container component="main" className={classes.root}>

      {/* Left */}
      <Grid item xs={false} sm={false} md={9} className={classes.image} />

      {/* Right */}
      <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>

          {/* Logo */}
          <img src={imageLogo} height="100" alt="" />

          {/* Form */}
          <form className={classes.form} onSubmit={handleSubmit(onSubmitForm)}>

            {/* Error Reason */}
            {errorReason && (
              <span className={classes.unauthorized}>{errorReason}</span>
            )}

            {/* TextField Username */}
            <Controller
              name="username"
              control={control}
              defaultValue={bypassUser.username}
              render={({ value, onChange }) => {
                return (
                  <TextField
                    label={t('general_username')}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )
              }}
            />
            {errors.username && <FieldError message={errors.username.message}></FieldError>}
            
            {/* TextField Password */}
            <Controller
              name="password"
              control={control}
              defaultValue={bypassUser.password}
              render={({ value, onChange }) => {
                return (
                  <TextField
                    label={t('general_password')}
                    fullWidth
                    type="password"
                    margin="normal"
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )
              }}
            />
            {errors.password && <FieldError message={errors.password.message}></FieldError>}
            
            {/* TextField Extension */}
            <Controller
              name="extension"
              control={control}
              defaultValue={bypassUser.extension}
              render={({ value, onChange }) => {
                return (
                  <TextField
                    label={t('general_extension')}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )
              }}
            />
            {errors.extension && <FieldError message={errors.extension.message}></FieldError>}
            
            {/* SignIn Button */}
            <Button
              ref={loginButtonRef}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('general_sign_in')}
            </Button>

          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginPage;