import React, { useEffect, useState, useContext } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { useHistory } from 'react-router-dom';
// import { AppContext } from '../../contexts/AppStore';
import { useTranslation } from "react-i18next";
// import PageHeader from "../TM/src/components/PageHeader";
// import moment from 'moment';
import dotProp from 'dot-prop';
import { AppContext } from '../../../contexts/AppStore';

import {
  Divider,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  bgCard: {
    padding: "3%",
  },
  dataCard: {
    padding: "0%",
  },
  tableStyles: {
    // padding: "1%",
    // height: 700,
    width: "100%",
  },
  root: {
    width: 350,
    backgroundColor: theme.palette.background.paper,
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(3, 2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  rootVer: {
    width: 'fit-content',
    // border: `1px solid ${theme.palette.divider}`,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    // color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minWidth: 250,
    // width: 350,
  },
  body: {
    fontSize: 14,
    minWidth: 250,
    // width: 350,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // width: 350
  },
}))(TableRow);


const NewCasesTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const history = useHistory();
  const { loginUser } = useContext(AppContext);

  const newCaseInit = {
    allShift: 0,
    morningShift: 0,
    dayShift: 0,
    nightShift: 0,
  }

  const [countOfNewCase, setCountOfNewCase] = useState(newCaseInit);

  const dueFollowUpTodayInit = {
    followUpPatients: 0,
    followUpLab: 0,
    pending: 0,
    done: 0,
    total: 0
  }

  const [countOfDueFollowUpToday, setCountOfDueFollowUpToday] = useState(dueFollowUpTodayInit);

  useEffect(() => {
    /* New cases */
    fetch(`${process.env.REACT_APP_TM_BACKEND_FQDN}/tm/api/v1/tickets/rama-shift-summary-today`)
      .then(res => res.json())
      .then(res => {
        return {
          allShift: res['allShiftCount'],
          morningShift: res['1stShiftCount'],
          dayShift: res['2ndShiftCount'],
          nightShift: res['3rdShiftCount'],
        };
      })
      .then(data => {
        setCountOfNewCase({
          allShift: data.allShift,
          morningShift: data.morningShift,
          dayShift: data.dayShift,
          nightShift: data.nightShift,
        });
      });

    /* Follow up patients and lab */
    fetch(`${process.env.REACT_APP_TM_BACKEND_FQDN}/tm/api/v1/tickets/rama-followup-summary-today?poolUsername=${process.env.REACT_APP_POOL_USER}`)
      .then(res => res.json())
      .then(res => {
        return {
          followUpPatients: res.followupPatientCount,
          followUpLab: res.followupLabCount,
          pending: res.pendingCount,
          done: res.doneCount,
          total: res.followupCount,
        };
      })
      .then(data => {
        setCountOfDueFollowUpToday({
          followUpPatients: data.followUpPatients,
          followUpLab: data.followUpLab,
          pending: data.pending,
          done: data.done,
          total: data.total,
        });
      });

  }, [loginUser]);


  return (
    <React.Fragment>
      <Paper className={classes.dataCard} elevation={3}>
        <TableContainer component={Paper} className={classes.tableStyles}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{backgroundColor: "#0F64F9"}}>
                  <div className={classes.section1}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography gutterBottom variant="h5" style={{color: "white"}}>
                          {t("dashboard_page_new_cases_shift")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid justifyContent="flex-end">
                      <Typography style={{color: "white"}} variant="h3" align="right">
                        {countOfNewCase.morningShift + countOfNewCase.dayShift + countOfNewCase.nightShift}
                      </Typography>
                    </Grid>
                  </div>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#0F64F9"}}>
                  <div className={classes.section1}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography gutterBottom variant="h5" style={{color: "white"}}>
                          {t("dashboard_page_due_follow_up_today")}
                        </Typography>
                      </Grid>  
                    </Grid>
                    <Grid justifyContent="flex-end">
                      <Typography style={{color: "white"}} variant="h3" align="right">
                        {countOfDueFollowUpToday.total}
                      </Typography>
                    </Grid>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Row 1 */}
              <StyledTableRow key="name">
                <StyledTableCell style={{backgroundColor: "#C6E6FF"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_night_shift")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="flex-end">
                  <Typography color="textSecondary" variant="h5" align="right">
                    {countOfNewCase.morningShift}
                  </Typography>
                  </Grid>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#C6E6FF"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_follow_up_patients")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfDueFollowUpToday.followUpPatients}
                    </Typography>
                  </Grid>
                  {/* <br /> */}
                  {/* <br /> */}
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_follow_up_lab")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfDueFollowUpToday.followUpLab}
                    </Typography>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              {/* Row 2 */}
              <StyledTableRow key="name">
                <StyledTableCell style={{backgroundColor: "#EBEEF0"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_morning_shift")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="flex-end">
                  <Typography color="textSecondary" variant="h5" align="right">
                    {countOfNewCase.dayShift}
                  </Typography>
                  </Grid>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#EBEEF0"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_pending")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfDueFollowUpToday.pending}
                    </Typography>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              {/* Row 3 */}
              <StyledTableRow key="name">
                <StyledTableCell style={{backgroundColor: "#C6E6FF"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_afternoon_shift")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="flex-end">
                  <Typography color="textSecondary" variant="h5" align="right">
                    {countOfNewCase.nightShift}
                  </Typography>
                  </Grid>
                </StyledTableCell>
                <Divider orientation="vertical" flexItem />
                <StyledTableCell style={{backgroundColor: "#C6E6FF"}}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography gutterBottom variant="h5">
                        {t("dashboard_page_done")}
                      </Typography>
                    </Grid>
                    <Typography color="textSecondary" variant="h5" align="right">
                      {countOfDueFollowUpToday.done}
                    </Typography>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
};

export default NewCasesTable;