/* eslint-disable camelcase */
import axios from 'axios';
import url from 'url';
import qs from 'qs';
import { logger } from '../common';

const GetAllDeputyGovernorUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetAllDeputyGovernor.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetAllExpertUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetAllExpert.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetAllDepartmentUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetAllDepartment.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetAllDivisionUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetAllDivision.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetAllSectionUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetAllSection.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetSomeDepartmentUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetDepartment.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetSomeDivisionUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetDivision.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetSomeSectionUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetSection.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetSyntaxGennoUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetSyntaxGenno.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetBookTypeUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetBookType.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetSecureUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetSecure.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetExpressUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetExpress.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetCommandendingUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetCommandending.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetCommanderUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetCommander.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetOrgsendlistUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetOrgsendlist.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetBookForReferUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetBookForRefer.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const CreateBookUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/CreateBook.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP&orgSN=14327&depcod=A10906');
const GenInternalBookNoUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GenInternalBookNo.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP&orgSN=14327&depcod=A10906');
const SendBookUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/SendBook.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP&orgSN=14327&depcod=A10906');
const GetBooklistNameByOrgsnUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetBooklistNameByOrgsn.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');
const GetMapDepcodSerialNoUrl = url.resolve(process.env.REACT_APP_EDP_API_FQDN, '/exat/EDP_Services_Helper/GetMapDepcodSerialNo.do?u_id=EDP&u_pass=s69111120119118181169&u_ip=192.168.102.112&appId=EDP');

export const GetMapDepcodSerialNo = () => {
  return axios({
    url: GetMapDepcodSerialNoUrl,
    method: 'GET',
  });
};

export const GetAllDeputyGovernor = () => {
  return axios({
    url: GetAllDeputyGovernorUrl,
    method: 'GET',
  });
};

export const GetAllExpert = () => {
  return axios({
    url: GetAllExpertUrl,
    method: 'GET',
  });
};

export const GetAllDepartment = () => {
  return axios({
    url: GetAllDepartmentUrl,
    method: 'GET',
  });
};

export const GetAllDivision = () => {
  return axios({
    url: GetAllDivisionUrl,
    method: 'GET',
  });
};

export const GetAllSection = () => {
  return axios({
    url: GetAllSectionUrl,
    method: 'GET',
  });
};

export const GetSomeDepartment = (org_code = '') => {
  return axios({
    url: `${GetSomeDepartmentUrl}&org_code=${org_code}`,
    method: 'GET',
  });
};

export const GetSomeDivision = (org_code = '') => {
  return axios({
    url: `${GetSomeDivisionUrl}&org_code=${org_code}`,
    method: 'GET',
  });
};

export const GetSomeSection = (org_code_dep = '', org_code_div = '') => {
  return axios({
    url: `${GetSomeSectionUrl}&org_code_dep=${org_code_dep}&org_code_div=${org_code_div}`,
    method: 'GET',
  });
};

export const GetSyntaxGenno = (orgsn = '14327') => {
  return axios({
    url: `${GetSyntaxGennoUrl}&orgsn=${orgsn}`,
    method: 'GET',
  });
};

export const GetBookType = () => {
  return axios({
    url: GetBookTypeUrl,
    method: 'GET',
  });
};

export const GetSecure = () => {
  return axios({
    url: GetSecureUrl,
    method: 'GET',
  });
};

export const GetExpress = () => {
  return axios({
    url: GetExpressUrl,
    method: 'GET',
  });
};

export const GetCommandending = () => {
  return axios({
    url: GetCommandendingUrl,
    method: 'GET',
  });
};

export const GetCommander = (flag, orgsn = '14327') => {
  return axios({
    url: `${GetCommanderUrl}&flag=${flag}&serial_no=${orgsn}`,
    method: 'GET',
  });
};

export const GetOrgsendlist = (orgsn = '14327') => {
  return axios({
    url: `${GetOrgsendlistUrl}&orgsn=${orgsn}`,
    method: 'GET',
  });
};

export const GetBookForRefer = (bookidno, date = '') => {
  return axios({
    url: `${GetBookForReferUrl}&bookidno=${bookidno}&date=${date}`,
    method: 'GET',
  });
};

// NOTE: qs.stringify encode URL by default
export const CreateBook = (payloadObj) => {
  // Convert js object to query string
  const payloadQS = qs.stringify(payloadObj);
  // EXAT require twice encode request body
  const requestBody = encodeURI(payloadQS);
  // Return configured axios promise
  return axios({
    url: CreateBookUrl,
    method: 'POST',
    data: requestBody,
  });
};

// NOTE: qs.stringify encode URL by default
export const GenInternalBookNo = (payloadObj) => {
  // Convert js object to query string
  const payloadQS = qs.stringify(payloadObj);
  // EXAT require twice encode request body
  const requestBody = encodeURI(payloadQS);
  // Return configured axios promise
  return axios({
    url: GenInternalBookNoUrl,
    method: 'POST',
    data: requestBody,
  });
};

// NOTE: qs.stringify encode URL by default
export const SendBook = (payloadObj) => {
  // Convert js object to query string
  const payloadQS = qs.stringify(payloadObj);
  // EXAT require twice encode request body
  const requestBody = encodeURI(payloadQS);
  // Return configured axios promise
  return axios({
    url: SendBookUrl,
    method: 'POST',
    data: requestBody,
  });
};

export const GetBooklistNameByOrgsn = (orgsn = '14327') => {
  return axios({
    url: `${GetBooklistNameByOrgsnUrl}&orgsn=${orgsn}`,
    method: 'GET',
  });
};
