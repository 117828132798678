import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as FileAPI from '../apis/FileAPI';
import { logger } from '../common';

const TextViewer = ({ fileId }) => {
  const [textContent, setTextContent] = useState(undefined);

  useEffect(() => {
    if (fileId !== undefined) {
      FileAPI.postDownloadFile(fileId)
        .then((res) => {
          const blob = new Blob([res.data]);
          const reader = new FileReader();
          reader.addEventListener('loadend', () => {
            setTextContent(reader.result);
          });
          reader.readAsText(blob);
        })
        .catch((err) => {
          logger.error('Unable to download txt file.');
          logger.error(err);
        });
    }
  }, [fileId]);

  return (
    <pre>{textContent}</pre>
  );
};

TextViewer.propTypes = {
  fileId: PropTypes.string,
};

TextViewer.defaultProps = {
  fileId: undefined,
};

export default TextViewer;
