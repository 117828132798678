import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {Grid, Card, Box,  Button,  TextField, Typography, FormControl, MenuItem, Select} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from "react-hook-form";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { KeyboardDateTimePicker  } from "@material-ui/pickers";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "18%",
        maxWidth: "20%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    errorMsg : {
      color: "#f44336",
      marginTop: "1%",
      marginLeft: "1%"
    }
}))

const keyboardButtonProps = { style: { marginRight: -14 } };


const AnnounceCreate = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const { register, handleSubmit, control, errors, getValues } = useForm();
    const [teamList, set_teamList] = React.useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const announce_id = props.location.state.announce_id
    
    const [announceInfo, set_announceInfo] = React.useState([])
    const [isLoading, setLoading] = React.useState(true);
    const [startDate, setstartDate] = React.useState();
    const [expiryDate, setExpiryDate] = React.useState();
    const [radioValue, setRadioValue] = React.useState("");
    const [groupList, setGroupList] = React.useState([]);

    const handleRadioChange = (event) => {
      setRadioValue(event.target.value);
    };

    
    const handleStartDate = (e) => {
      setstartDate(e.target.value);
    };

    const handleExpiryDate = (e) => {
      setExpiryDate(e.target.value);
    };

    React.useEffect(() => { 
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams` )
          .then(res => res.json())
          .then((data) => {
            set_teamList(data.data)
          })
    } , [])

    React.useEffect(() => { 
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups`)
          .then(res => res.json())
          .then((data) => {
              setGroupList(data.data)
          })
    } , [])


    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements/${announce_id}`)
            .then(res => res.json())
            .then((data) => {
              set_announceInfo(data)
              setstartDate(data.effectiveOn)
              setExpiryDate(data.expiresOn)
              setRadioValue(data.audienceType)

              /* set default expiry date */
              // var expiresOn = new Date(data.expiresOn)
              // console.log("expire on", expiresOn.toUTCString())
              // var now_expire = expiresOn.toISOString().replace('Z', '');
              // console.log("now expire", now_expire)
              // setExpiryDate(now_expire)

              /* set default effective date */
              // var effectiveOn = new Date(data.effectiveOn)
              // var now_effective = effectiveOn.toISOString().replace('Z', '');
              // setstartDate(now_effective)

              setLoading(false)  
            })
    } , [])

    if (isLoading=== true){
      return (<></>)
    }

    const onSubmit = (data,e) => { 
      
      var bodyData = data
      bodyData.audienceType = radioValue

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements/${announce_id}`,{
          method: 'PUT', 
          headers: myHeaders,
          body: JSON.stringify(bodyData),
          redirect: 'follow'
      })
      .then((response) => {
          if(response.status == 200) {history.push('/config');    
              return enqueueSnackbar(t('Announcement has been updated.'), { variant: 'success' });
          } 
      })
      .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar(t('Announcement has fail to update.'), { variant: 'error' })
      }) 
    }

    return (
            <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("Manage Announcement")}
                subtitle={t("Configuration for announcement")}
            />  
            <Grid item xs={12} className={classes.top}>
                <Card className={classes.card}>
                    <form onSubmit={handleSubmit(onSubmit)}  className={classes.form_} autoComplete="off">
                        <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}>{t('Announcement name *')} : </Typography>
                            <div className={classes.parallel}>
                              <TextField 
                                fullWidth
                                inputRef={register({ required: true})} 
                                defaultValue={announceInfo.name} 
                                name="name" 
                                variant="outlined"
                              ></TextField>
                              {errors.name && (<span className={classes.errorMsg}> {t('Name field is required')}</span>)}
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Description *')} : </Typography>
                            <div className={classes.parallel}>
                              <TextField 
                                fullWidth 
                                inputRef={register({required: true})} 
                                defaultValue={announceInfo.message} 
                                name="message" 
                                variant="outlined"
                                multiline
                                rows={4}
                              >
                              </TextField>
                              {errors.message && (<span className={classes.errorMsg} > {t('Description is required')}</span>)}
                            </div>
                        </Box>

                        <Box display="flex" flexDirection="row">
                          <Typography className={classes.middle}>{t('Announcement for *')} : </Typography>
                          <RadioGroup
                              row 
                              value={radioValue} 
                              onChange={handleRadioChange} 
                          >
                              <FormControlLabel 
                                  label="Group" 
                                  value="group" 
                                  control={
                                      <Radio />
                                  } 
                              />
                              <FormControlLabel 
                                  label="Team"
                                  value="team" 
                                  control={
                                      <Radio />
                                  }
                              />
                          </RadioGroup>
                        </Box>

                        {radioValue == 'group' && (
                          <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Group *')} : </Typography>
                            <FormControl className={classes.parallel}>
                              <Controller
                                name="audienceId"
                                control={control}
                                defaultValue={announceInfo.audienceId}
                                as={
                                    <Select>
                                      {groupList.map((groupList) => (
                                      <MenuItem value={groupList._id}>{groupList.name}</MenuItem>
                                      ))}
                                  </Select>
                                } 
                            />
                            </FormControl>
                          </Box>
                        )}
                        
                        {radioValue == 'team' && (
                          <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Team *')} : </Typography>
                            <FormControl className={classes.parallel}>
                              <Controller
                                name="audienceId"
                                control={control}
                                defaultValue={announceInfo.audienceId}
                                as={
                                    <Select>
                                      <MenuItem value={0}>Everyone</MenuItem>
                                      {teamList.map((teamList) => (
                                      <MenuItem value={teamList._id}>{teamList.name}</MenuItem>
                                      ))}
                                  </Select>
                                } 
                            
                            />
                            </FormControl>
                        </Box>
                        )}


                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Starting date')} : </Typography>
                              <FormControl className={classes.parallel}>
                                <Controller
                                  className={classes.textField}
                                  name="effectiveOn"
                                  control={control}
                                  rules={{
                                    validate: () =>{
                                        return getValues("expiresOn") > getValues("effectiveOn") && 
                                        getValues("expiresOn") !== getValues("effectiveOn") &&
                                        getValues("effectiveOn") !== new Date ()
                                    }
                                  }}
                                  defaultValue={startDate}
                                  onChange={([, data]) => data}
                                  as={
                                  <KeyboardDateTimePicker
                                      ampm={false}
                                      minutesStep={process.env.REACT_APP_BUILD_TARGET === 'RAMA' ? 5 : 1}
                                      margin="dense"
                                      variant="inline"
                                      inputVariant="outlined"
                                      format="DD/MM/YYYY HH:mm"
                                      KeyboardButtonProps={keyboardButtonProps}
                                      InputAdornmentProps={{ position: 'end' }}
                                  />
                                  }
                              />
                              {errors.effectiveOn && (
                                <span className={classes.errorMsg}> {t('Please enter valid date and time'
                              )}</span>)} 
                            </FormControl> 
                        </Box>    


                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Expiration date')} : </Typography>
                            <FormControl className={classes.parallel}>
                              <Controller
                                className={classes.textField}
                                name="expiresOn"
                                control={control}
                                defaultValue={expiryDate}
                                rules={{
                                  validate: () =>{
                                      return getValues("expiresOn") > getValues("effectiveOn") && 
                                      getValues("expiresOn") !== getValues("effectiveOn") && 
                                      getValues("expiresOn") !== new Date ()
                                  }
                                }}
                                onChange={([, data]) => data}
                                as={
                                <KeyboardDateTimePicker
                                    ampm={false}
                                    minutesStep={process.env.REACT_APP_BUILD_TARGET === 'RAMA' ? 5 : 1}
                                    margin="dense"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MM/YYYY HH:mm"
                                    KeyboardButtonProps={keyboardButtonProps}
                                    InputAdornmentProps={{ position: 'end' }}
                                />
                                }
                              />
                              {errors.expiresOn && (
                                    <span className={classes.errorMsg}>{t('Please enter valid date and time')}</span>
                              )}
                            </FormControl>
                        </Box>    


                        <Box className={classes.btn_}>
                            <Button type="submit"  color="secondary"  variant="contained">{t('Save')}</Button>
                            <Button color="secondary" variant="contained" onClick={() => history.push('/config')}>{t('Cancel')}</Button>
                        </Box>
                    </form>
                </Card>
            </Grid>
            </Container>
   
    )
}
export default AnnounceCreate;
