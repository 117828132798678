import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader';
import Container from '@material-ui/core/Container';
import { Grid, Card, Box, Button, TextField, Typography, FormControl, FormGroup, FormControlLabel, Checkbox, InputBase, List, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, appendErrors } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { KeyboardDateTimePicker, validate } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';



const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "50%",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    // agentList: {
    //     marginLeft:'300px',
    //     height: '300px',
    //     maxHeight: '300px',
    //     overflow: 'auto'
    // },
    middle: {
        margin: "1.5%",
        width: "13%",
        maxWidth: "13%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    errorMsg: {
        color: "#f44336",
        marginTop: "1%",
        marginLeft: "1%"
    },
    sideList: {
        display: 'flex',
        flexDirection: 'column'
    }
}))

const keyboardButtonProps = { style: { marginRight: -14 } };

const AnnounceGroupCreate = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { register, handleSubmit, control, errors, getValues } = useForm();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [agents, setAgents] = React.useState([])
    const [checked, setChecked] = React.useState();
    const [listSelectedAgents, setListSelectedAgents] = React.useState([])
    const [checkedArray, setCheckedArray] = React.useState([])




    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
            .then(res => res.json())
            .then((data) => {
                setAgents(data)
            })
    }, [])



    const handleCheckboxChange = (isChecked, agentObj) => {

        //handling the selected agent list on the side
        if (isChecked == true) {
            setListSelectedAgents((prevArray) => [...prevArray, agentObj])

        }
        else {
            let result = listSelectedAgents.filter((agent) => agent._id !== agentObj._id)
            setListSelectedAgents(result)
        }


    };

    const handleListItemClick = (event, selectedAgentObj) => {
        let result = listSelectedAgents.filter((agent) => agent._id != selectedAgentObj._id)
        setListSelectedAgents(result)
    }

    const onSubmit = (data, e) => {

        const newListSelectedAgents = listSelectedAgents.map(selectedAgent => {
            return  selectedAgent._id
        });


        const bodyData = {
            name: data.name,
            desc: data.desc,
            agentIDs: newListSelectedAgents
        }


        let url = `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups`;
        let config = {
            method: 'POST',
            timeout: 1000 * 3,
            headers: {
                "Content-Type": "application/json"
            },
            data: bodyData
        };

        axios( url, config )
            .then(response => {
                if (response.status >= 200 && response.status  <= 299) {
                    history.push('/config')
                    enqueueSnackbar(t('Successfully created announcement group'), { variant: 'success' });
                }
            })
            .catch(err => {
                enqueueSnackbar(t('Fail to load emails.'), { variant: 'error' });
                console.log(err.message)
            });      


    }


    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader
                title={t("Create new announcement group")}
                subtitle={t("Configuration for groups in announcement")}
            />
            <Grid item xs={12} className={classes.top}>
                <Card className={classes.card}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Group Name *')} : </Typography>
                            <TextField
                                className={classes.parallel}
                                inputRef={register({ required: true })}
                                name="name"
                                variant="outlined"
                            >
                            </TextField>
                            {errors.message && (<span className={classes.errorMsg}>{t('Group Name field is required')}</span>)}
                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Announce Group Description')} : </Typography>
                            <TextField
                                className={classes.parallel}
                                inputRef={register}
                                name="desc"
                                variant="outlined"
                                multiline
                                rows={3}
                            >
                            </TextField>
                        </Box>


                        <Box display="flex" flexDirection="row" className={classes.agentList}>
                            <Typography className={classes.middle}>{t('Choose agents')} : </Typography>
                            <List className={classes.parallel}>
                                {agents.map((agent, index) => {
                                    const foundIndex = listSelectedAgents.findIndex((sa) => sa._id === agent._id);
                                    const alreadyCheck = foundIndex !== -1;
        
                                    return (
                                    <Box display="flex" flexDirection="row" >
                                        <FormControl className={classes.formControl}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={alreadyCheck}
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                handleCheckboxChange(isChecked, agent);
                                                            }}
                                                            name={`agents[${index}]._id`}
                                                        />
                                                    }
                                                    name={`agents[${index}]._id`}
                                                    inputRef={register}
                                                />

                                            </FormGroup>
                                        </FormControl>
                                        <InputBase
                                            value={agent.name}
                                            className={classes.inputBase}
                                            inputProps={{
                                                'aria-label': 'naked',
                                                readOnly: true,
                                            }}
                                            inputRef={register}
                                            name={`agents[${index}].name`}
                                        />
                                        <InputBase
                                            type="hidden"
                                            value={agent.name}
                                            inputProps={{
                                                'aria-label': 'naked',
                                                readOnly: true,
                                            }}
                                            inputRef={register}
                                            name={`agents[${index}].name`}
                                        />


                                    </Box>
                                )})}
                            </List>
                            <CardContent className={classes.sideList}>
                                <Typography> {t('Selected Agents List')}</Typography>
                                {listSelectedAgents.map((selectedAgent) => (
                                    <Chip
                                        size="small"
                                        style={{marginTop: '5px', marginBottom:'5px'}}
                                        color="primary"
                                        variant="outlined"
                                        label={selectedAgent.name}
                                        onDelete={(e) => handleListItemClick(e, selectedAgent)}
                                    />
                                ))}
                            </CardContent>
                        </Box>

                        <Box className={classes.btn_}>
                            <Button type="submit" color="secondary" variant="contained">{t('Announce Group Create')}</Button>
                            <Button color="secondary" variant="contained" onClick={() => history.push('/config')}>{t('Cancel')}</Button>
                        </Box>
                    </form>
                </Card>
            </Grid>
        </Container>

    )
}
export default AnnounceGroupCreate;
