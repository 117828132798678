import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select } from '@material-ui/core';
import { logger } from '../../TM/src/common';

const FETCH_CATEGORY_URL = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=2&sort=name`

const SelectCategory = ({ value, onChange, ...restProps }) => {
  const [categories, setCategories] = useState([]);

  // Handle select change event.
  const handleChange = (event) => {
    onChange(event.target.value);
  }

  // Side effect of componentDidMount.
  useEffect(() => {
    fetch(FETCH_CATEGORY_URL)
      .then(res => res.json())
      .then((data) => setCategories(data.data))
      .catch(error => logger.error(error)); 
  }, []);

  return (
    <Select
      value={value}
      onChange={handleChange}
      {...restProps}
    >
      <MenuItem value="" key="category-option-default">
        <em>Choose a Category</em>
      </MenuItem>

      {categories.map((category, index) => (
        <MenuItem value={category.name} key={`category-option-${index}`}>
          <em>{category.name}</em>
        </MenuItem>
      ))}
    </Select>
  );
};

SelectCategory.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SelectCategory.defaultProps = {
  value: '',
  onChange: () => {},
};

export default SelectCategory;