/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from 'dot-prop';
import { makeStyles } from '@material-ui/styles';
import { ContactAPI } from '../../../apis';
import { logger, utils } from '../../../common';

const { hitsToDocs } = utils;

const useStyles = makeStyles(() => ({
  loading: {
    marginRight: '-28px',
  },
  inputAdornment: {
    marginLeft: '4px',
    marginRight: '4px',
  },
  defaultAvatar: {
    color: '#bdbdbd',
    marginTop: '3.5px',
    width: 22,
    height: 22,
  },
  customAvatar: {
    width: 22,
    height: 22,
    marginTop: '4px',
    fontSize: '0.8rem',
    paddingTop: '2px',
  },
}));

const DropdownContactPoint = ({
  label,
  margin,
  value,
  onChange,
  required,
  variant,
  cascadeProvince,
  ...restProps
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  // Side effect of inputValue changes
  useEffect(() => {

    // Skip this if component is unmount
    if (!isMounted.current) {
      isMounted.current = true;
    }

    // Skip this if dropdown is closed
    if (!expand) return;

    // Display component loader
    setLoading(true);

    // Search contact by keyword
    ContactAPI.searchContactPoints(inputValue, cascadeProvince)
      .then(({ data }) => {
        if (isMounted.current) {
          const contactPoints = hitsToDocs(data.hits.hits);
          setOptions(contactPoints);
        }
      })
      .catch((err) => {
        logger.error('DropdownContactPoint.jsx: Unable to search contacts from server.');
        if (err) logger.error(err);
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    // Flag the component unmount
    return () => isMounted.current = false;

  }, [inputValue, expand, cascadeProvince]);

  // Side effect of expand state changes
  useEffect(() => {
    if (expand === false) setOptions([]);
  }, [expand]);

  // How to display each option (include selected)?
  const getOptionLabel = (option) => {
    return get(option, 'name') || '';
  };

  // How to identify selected option?
  const getOptionSelected = (option, selected) => {
    return option._id === selected._id;
  };

  // The right loading component.
  const rightAdornment = (InputProps) => (
    <>
      {loading && (
        <CircularProgress
          size={20}
          color="inherit"
          className={classes.loading}
        />
      )}
      {InputProps.endAdornment}
    </>
  );

  return (
    <Autocomplete
      {...restProps}
      filterOptions={(originalOpts) => originalOpts} // Disable search in-memory options
      loading={loading}
      options={options}
      open={expand}
      onOpen={() => setExpand(true)}
      onClose={() => setExpand(false)}
      onChange={onChange}
      value={value}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      className={classes.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          margin={margin}
          label={label}
          variant={variant}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: rightAdornment(params.InputProps),
          }}
        />
      )}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

DropdownContactPoint.propTypes = {
  margin: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  variant: PropTypes.string,
  cascadeProvince: PropTypes.string,
};

DropdownContactPoint.defaultProps = {
  margin: 'none',
  label: '',
  value: null,
  required: false,
  variant: 'outlined',
  cascadeProvince: undefined,
};

export default DropdownContactPoint;
