import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import { Typography, Table, TableHead, TableBody, TableCell, Select, MenuItem, TableRow, IconButton, Box, TableFooter, TablePagination, List, Button, Grid, FormControl, InputLabel } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import CreateDialogBox from './CreateDialogBox';
import EditDialogBox from './EditDialogBox'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    }

}))

const ConfigTM = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [groupNames, setGroupNames] = React.useState([])
    const [groupName, setGroupName] = React.useState("")
    const [customConfigs, setCustomConfigs] = React.useState([]);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [deleteDiallogOpen, setDeleteDialogOpen] = React.useState(false);
    const [editObj, setEditObj] = React.useState()
    const [deleteId, setDeleteId] = React.useState()
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);




    const fetchCustomConfigs = () => {
      // fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FDQN}/custom-rama/api/v1/customTMConfigs`)
      fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs`)
      .then(res => res.json())
      .then((data) => {
          setGroupName("")
          setCustomConfigs(data.data)
          const testSet = new Set()
          for (let i= 0; i< data.data.length; i ++) {
            testSet.add(data.data[i].groupName)
          }
          let arr = Array.from(testSet);
          setGroupNames(arr)
      })
      .catch(err => {
        console.log(err.message)
      })

    }

    React.useEffect(() => {
      fetchCustomConfigs()
    } , [])



    const handleCreateDialogOpen = () => {
      setCreateDialogOpen(true)
    }

    const handleCreateDialogClose = ()=> {
      setCreateDialogOpen(false)
    }

    const handleEditDialogOpen = (editObj) => {
      setEditObj(editObj)
      setEditDialogOpen(true)
    }

    const handleEditDialogClose = () => {
      setEditDialogOpen(false)
    }

    const handleDeleteDialogOpen = (deleteObj) => {
      setDeleteId(deleteObj._id)
      setDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
      setDeleteDialogOpen(false)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
 

    const handleGroupName = (e) => {
        console.log(e.target.value)
        setGroupName(e.target.value)
        if (e.target.value !== '- Choose group name -') {
          fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs/?groupName=${e.target.value}`)
            .then(res => res.json())
            .then((data) => {
                setCustomConfigs(data.data)
            })
            .catch(err => {
              enqueueSnackbar(t('Fail to load custom configs'), { variant: 'error' });
              console.log(err.message)
            })
        }
        else {
          fetchCustomConfigs()
        }
    }

    const handleDelete = () => {

      fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FDQN}/custom-rama/api/v1/customTMConfigs/${deleteId}`,{
        method: 'DELETE', 
      })
      .then(response => {
        if (response.status >=200 && response.status <= 299){
            handleDeleteDialogClose()
            const e = {
              target: {
                  value: groupName
              }
            }
            fetchCustomConfigs()
            return enqueueSnackbar(t('Custom config has been deleted .'), { variant: 'success' });
        }
      })
      .catch((error) => {
       console.error('Error:', error);
       enqueueSnackbar(t('Custom config has fail to delete.'), { variant: 'error' })
      }) 
    }



    return (
   
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader 
                    title= {t("Custom Configuration ")}
                    subtitle= {t("Custom Configuration")}
                    buttonName=  {t("New custom config")}
                    onClickButton={handleCreateDialogOpen}
                />
                <CreateDialogBox
                  handleCreateDialogClose={handleCreateDialogClose}
                  createDialogOpen={createDialogOpen}
                  fetchCustomConfigs={fetchCustomConfigs}
                  groupNames={groupNames}
                />

                {editDialogOpen == true && editObj && (
                  <EditDialogBox
                    handleEditDialogClose={handleEditDialogClose}
                    editDialogOpen={editDialogOpen}
                    editObj={editObj}
                    // handleGroupName={handleGroupName}
                    groupName={groupName}
                    fetchGroupNames={fetchCustomConfigs}
                    groupNames={groupNames}

                  />
                )}


                <Box display="flex" flexDirection="row">
                  <Grid item xs={3}>
                      <Select
                        margin="dense"
                        variant="outlined"
                        defaultValue={"- Choose group name -"}
                        fullWidth
                        value={groupName? groupName : "- Choose group name -"}
                        onChange={(e) => handleGroupName(e)}
                      >
                        <MenuItem value="- Choose group name -">- Choose group name -</MenuItem>
                        {groupNames.map((groupName) => (
                            <MenuItem  value={groupName}>{groupName}</MenuItem>
                        ))}
                      </Select>
                  </Grid>

                </Box>

                <Grid item xs={12} className={classes.table}>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell><Typography variant="body2"> {t('Name')}</Typography></TableCell>
                              <TableCell><Typography variant="body2"> {t('GroupName')}</Typography></TableCell>
                              <TableCell><Typography variant="body2"> {t('Actions')}</Typography></TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {customConfigs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customConfig) => (
                          <React.Fragment key={customConfig._id}>
                              <TableRow>
                                  <TableCell>
                                      <Typography>{customConfig.name}</Typography>
                                  </TableCell>         
                                  <TableCell>
                                      <Typography>{customConfig.groupName}</Typography>
                                  </TableCell>              
                                  <TableCell>
                                      <IconButton color="secondary" onClick={() => handleEditDialogOpen(customConfig)}>  
                                          <EditOutlinedIcon color="primary"/>
                                      </IconButton> 
                                      <IconButton color="secondary" onClick={()  => handleDeleteDialogOpen(customConfig)}>
                                          <DeleteForeverOutlinedIcon color="primary"  />
                                      </IconButton>
                                  </TableCell> 
                              </TableRow>
                          </React.Fragment>
                          ))}
                      </TableBody>

                      <TableFooter>
                        <TableRow>    
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={customConfigs.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            /> 
                        </TableRow>
                      </TableFooter>
                  </Table>
                </Grid>   


                {/* Delete confirmation dialog */}
                <Dialog
                  open={deleteDiallogOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleDeleteDialogClose}
                >
                  <DialogTitle>{t('Are you sure you want to delete custom config?')}</DialogTitle>
                  <DialogContent>
                      <DialogContentText>
                          {t('This will permanently delete the selected custom config.')}
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleDeleteDialogClose} color="secondary">
                          {t('TM Config Cancel')}
                      </Button>
                      <Button onClick={handleDelete} color="primary">
                          {t('TM Config Delete')}
                      </Button>
                  </DialogActions>
                </Dialog>          
                
            </Container>
    )
}
export default ConfigTM;
