import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import * as yup from 'yup';
import 'yup-phone';
import { useStyles } from '../components/styles';
import { Title, Subtitle } from '../components/Title';
import CreateButton from '../components/CreateButton';
import PatientDialog from "../components/PatientDialog";

export const splitSpace = (data) => {
  let string = data.split(" ");
  let stringArray = [];
  for(let i = 0; i < string.length; i++){
    if(string[i] !== "") {
      stringArray.push(string[i]);
    }
  };
  return stringArray;
};

export const surName = (data) => {
  let arr = splitSpace(data).reverse().slice(0, -1).reverse();
  return arr.join(" ");
}

const PatientHeader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  /* Set up a default of Contact object */
  const defaultPatientData = {
    prefix: '',
    name: '',
    firstName: '',
    lastName: '',
    phone: [],
    email: [],
    CustomData: {
      contactType: 'Patient (คนไข้ )',
      pid: '',
      type: '',
    },
    gender: '',
  }
  const [patitentData, setPatientData] = useState(defaultPatientData);

  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    });
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <div className={classes.root}>
      <Grid alignItems="flex-start" container justifyContent="space-between" spacing={3}>
        <Title title={props.title}/>
        <Grid item className={classes.gridButton}>
          <CreateButton buttonName={props.buttonName} handleClickOpen={handleClickOpen}/>
        </Grid>
      </Grid>
      <Subtitle subtitle={props.subtitle}/>

      {props.buttonName && props.contactPage === 'patient' && (
        <PatientDialog
          contactPage={'patient'}
          submit={'create'}
          open={open}
          onCancel={handleClose}
          patientData={patitentData}
          onChangePatientData={setPatientData}
          triggerToRefresh={props.triggerToRefresh}
          defaultPatientData={defaultPatientData}
          // prefixList={prefixList}
          // prefixOnchange={setPrefixList}

        />
      )}
    </div>
  )
}

PatientHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  onClickButton: PropTypes.func,
};

PatientHeader.defaultProps = {
  buttonName: undefined,
  onClickButton: undefined,
};

export default PatientHeader;