import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Chip, Input, Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import ScrollBar from 'react-perfect-scrollbar'
import {DropzoneDialog} from 'material-ui-dropzone'
// import { FilterCenterFocusSharp } from '@material-ui/icons';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw , EditorState, ContentState, convertFromHTML} from 'draft-js';
// import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ForwardIcon from '@material-ui/icons/Forward';
import Forward from './forward';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& > *': {
        margin: theme.spacing(1),
    },
  },
  card: {
    border: "none", 
    boxShadow: "none", 
  },
  cardheader1: {
    paddingBottom: 0,
    position: 'relative'
  },
  cardheader2: {
    paddingBottom: '10px', 
    paddingTop: '10px',
    position: 'relative'

  },
  subheaderList: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  // cardContent: {
  //     paddingBottom: '5px',
  //     overflowY: "scroll",
  // }
  card2: {
    margin: '30px',
  },
  name: {
    width: '50px',
    maxWidth: '50px',
    padding: '0px'
  },
  errorMsg : {
    color: "#f44336",
    marginLeft: '1%'
  },
  btnAttach: {
    marginLeft: '5px'
  },
  btnSend: {
    marginRight: "3%"
  },
  box1: {
    width: '40%'
  },
  box2: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '70%',
  },
  attachments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '50%',
  },
  download: {
    marginTop: 10,
    marginLeft: 10,
    // marginRight: 50,
    paddingRight: 50,
  },
  downloadComponent: {
    marginTop: 5,
    marginLeft: 5,
  },

}))

const Right = ({
  className,
  selectedEmail,
  handleReply,
  boolReply,
  handleForward,
  boolForward,
  handleDiscard,
  handleMoreOpen,
  handleMoreClose,
  anchorEl
}) => {
  // const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const { enqueueSnackbar } =useSnackbar();
  const axios = require('axios')
  const [replyTo, setReplyTo] = React.useState()
  const [prepareAttachment, setPrepareAttachment] = React.useState([])
  const [attachments, setAttachments] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [scrollEl] = useState();
  const [editorState, setEditorState] = React.useState();
  const [displayEditorState, setDisplayEditorState] = React.useState()

  const handleAttachOpen = (index) => {
    setOpen(true)
  }
  
  function handleAttachClose () {
    setOpen(false)
  }

    // for reply, scrollbar will be at the end
  useEffect(() => {
    if (scrollEl && (boolReply || boolForward)) {
    const { scrollHeight, clientHeight } = scrollEl
      scrollEl.scrollTop = scrollHeight - clientHeight;
    }
    console.log('attachments ---> ', attachments);
  }, [scrollEl,selectedEmail, boolReply, boolForward, attachments]);


  useEffect(() => {
    let url = `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/Emails/${selectedEmail.message_id}`;
    let config = {
      method: 'GET',
      timeout: 1000 * 3,
      headers: {
        "Content-Type": "application/json"
      },
    };

    axios( url, config )
      .then(response => {
        // if (response.attachments) {
        //   // setMessageText(response.data.text_body)
        //   // console.log(" messageText ---- ", response.data.text_body)
        //   // setReceivedFile(response.attachments);
        // }
        return response.data.text_body;
      })
      .then((data) => {
        /* display selected email text_body(html) in draft editor */
        const blocksFromHTML = convertFromHTML(data);

        const htmlState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ); 
        
        // const state =  EditorState.createWithContent(customContentStateConverter(htmlState))
        const state =  EditorState.createWithContent(htmlState)
        setDisplayEditorState(state)

      })
      .catch(err => {
        // enqueueSnackbar(t('Fail to load emails.'), { variant: 'error' });
        console.log(err.message)
      });
  }, [axios, selectedEmail])

  
  // extract email account only from  'From' property 
  useEffect(() => {
    let extractReplyToEmail = [];

    for (let i= 0; i < selectedEmail.from.length; i++ ) {
      if ( selectedEmail.from[i] === '<') {
          
        while (selectedEmail.from[i] !== '>') {
          extractReplyToEmail.push(selectedEmail.from[i+1])
          setReplyTo(extractReplyToEmail.join(''))
          i++;
        }

      }
    } 
      // setReplyTo(selectedEmail.from)

  },[selectedEmail])


  // const customContentStateConverter = (contentState) => {
      
  //     // changes block type of images to 'atomic'
  //     const newBlockMap = contentState.getBlockMap().map((block) => {
  //         const entityKey = block.getEntityAt(0);
  //         if (entityKey !== null) {
  //             const entityBlock = contentState.getEntity(entityKey);
  //             const entityType = entityBlock.getType();
  //             switch (entityType) {
  //                 case 'IMAGE': {
  //                     const newBlock = block.merge({
  //                         type: 'atomic',
  //                         text: 'img',
  //                     });
  //                     return newBlock;
  //                 }
  //                 default:
  //                     return block;
  //             }
  //         }
  //         return block;
  //     });
  //     const newContentState = contentState.set('blockMap', newBlockMap);
  //     return newContentState;
  // }


  // display selected email text_body(html) in draft editor
  // useEffect(() => {        

  //     console.log('HTML', messageText)
  //     const blocksFromHTML = convertFromHTML(messageText);

  //     const htmlState = ContentState.createFromBlockArray(
  //         blocksFromHTML.contentBlocks,
  //         blocksFromHTML.entityMap,
  //     ); 
      
  //     const state =  EditorState.createWithContent(customContentStateConverter(htmlState))
  //     setDisplayEditorState(state)

  // }, [messageText])




  const onSubmitFile = (data) => {
      const formData= new FormData()
      formData.append("_file", data[0])

      fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/UploadFile`,{
        method: 'POST', 
        body: formData
      })
        .then(response => {
          if (response.status ===200){
            enqueueSnackbar(t('File successfully uploaded.'), { variant: 'success' });    
            // fetchUploadedFileID(data[0].name)  
          }
          return response.json()
        })
        .then(json_data => { 
          
          prepareAttachment.push({
            id: json_data.id, 
            file_name: json_data.file_name
          })
          setAttachments(prepareAttachment)
          setOpen(false)

        })
        .catch((error) => {
          console.error('Error:', error)
          enqueueSnackbar(t('File fail to upload.'), { variant: 'error' });        
        })

  }

  const deleteFile = (data) => {
    let idData = data;
    fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile/DeletebyId?id=${idData}`,{
      method: 'DELETE', 
      redirect: 'follow',
    })
      .then(response => {
        if (response.status ===200){
          enqueueSnackbar(t('File successfully deleted.'), { variant: 'success' });
        }
        return response.text();
      })
      .then((result) => { 
        // console.log(result);
        let removedAttach = attachments.filter(item => item.id !== idData);
        setPrepareAttachment(removedAttach);
        setAttachments(removedAttach);
        // console.log('removedAttach --- ', removedAttach);
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('File fail to upload.'), { variant: 'error' });        
      });
  };

  /* The function for save attached file when get response body from server */
  // function download(blob, filename) {
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.style.display = 'none';
  //   a.href = url;
  //   // the filename you want
  //   a.download = filename;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  // }

  /* The function for GET downloading file */
  const downloadFile = (data, dataName) => {
    let idData = data;
    return fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile/DownloadByFileId/${idData}`,{
    // return fetch(`http://192.168.102.112/api/DownloadFile/DownloadByFileId/${idData}`,{
      method: 'GET',
    })
      .then(response => {
        // if (response.status ===200){
        //   enqueueSnackbar(t('File successfully deleted.'), { variant: 'success' });
        // }
        return response.blob()
        
      })
      .then(blob => {
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement('a');
        fileLink.display = 'none';
        fileLink.href = fileURL;
        fileLink.setAttribute('download', dataName);
        document.body.appendChild(fileLink);
    
        fileLink.click();
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
      })
      // .then(result => console.log(result))
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('File fail to upload.'), { variant: 'error' });        
      });
  };

  /* The function for submit data */
  const onSubmit = (data, e) => {

    const attachmentsIDs = []
    attachments.map((val) => {
      return attachmentsIDs.push({
        attachment_id : val.id
      })
    })

    const draftJsData = {}

    if (editorState !== undefined) {
  
      // const plain_text_state = editorState.getCurrentContent();
      // const plain_text = plain_text_state.getPlainText();
      // draftJsData.plain_text = plain_text
      draftJsData.toHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }

    const sendEmailData = {
      to: data.to,
      from: process.env.REACT_APP_DEFAULT_EMAIL,
      cc: data.cc,
      subject: '',
      text_body: draftJsData.toHTML || "",
      sent: false,
      references: [selectedEmail.message_id],
      trackingId: selectedEmail.tracking_id,
      attachments:  attachmentsIDs || [],
      status: "Reply"
    }

    console.log(JSON.stringify(sendEmailData))

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(`${process.env.REACT_APP_EMAIL_BACKEND_FQDN}/api/Emails`, {
      method: 'POST', 
      headers: myHeaders,
      body: JSON.stringify(sendEmailData)
    })
    .then((response) => {
      if (response.status >= 200 && response.status <=299) {
        e.preventDefault();
        e.target.reset();
        const emptyEditorState = EditorState.push(editorState, ContentState.createFromText(''));
        setEditorState(emptyEditorState)
        handleReply(false)
        return enqueueSnackbar('Email send successfully', {variant: 'success'})
      }
    })
    .catch(function (error) {
      console.log(error)
      enqueueSnackbar('Email fail to send', {variant: 'error'})
    });


  }

  return (
    <div className={clsx(classes.root, className)}>

      {/* View Selected Email */}
      <ScrollBar>
        <Card className={classes.card} >
          <CardHeader 
            title={selectedEmail.subject || "<No Subject>"}
            className={classes.cardheader1}
          />
          <CardHeader
            className={classes.cardheader2}
            subheader={
              <ul className={classes.subheaderList}>
                <li>{`from: ${selectedEmail.from}`}</li>
                <li>{new Date(selectedEmail.date).toLocaleString()}</li>
              </ul>
            }
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {selectedEmail.from.charAt(1)}
              </Avatar>
            }
            action={
              <React.Fragment>
                <Tooltip title="Reply">
                  <IconButton  aria-label="reply">
                    <ReplyOutlinedIcon
                      onClick={() => handleReply(true)}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Options">
                  <IconButton onClick={handleMoreOpen}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  getContentAnchorEl={null}
                  onClose={handleMoreClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={handleForward}>
                    <ListItemIcon>
                      <ForwardIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("Forward")} />
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("Delete")} />
                  </MenuItem>
                </Menu>
              </React.Fragment>
            }
          />

          <CardContent className={classes.cardContent}>
            {/* Attachments */}
            <Grid container direction="row" spacing={1} className={classes.download}>
              {selectedEmail && selectedEmail.attachments.map((receive) => (
                <Grid className={classes.downloadComponent}>
                  <Chip
                    // clickable
                    variant="outlined"
                    label={receive.filename.substring(0, 10)}
                    onClick={() => downloadFile(receive.attachment_id, receive.filename)}
                    onDelete={() => {return}}
                    deleteIcon={<ArrowDownwardIcon />}
                  />
                </Grid>
                
              // </Grid>
              ))}
            </Grid>
            <DraftEditor 
              editorStyle={{width: '100%'}}
              editorState={displayEditorState}
              // onContentStateChange={setDisplayContentState}
              onEditorStateChange={setDisplayEditorState}
              toolbarHidden={true}
              readOnly={true}
            />
            
          </CardContent>
          
        </Card>
        
        {/* if boolForward == true -> will show the  forward form */}
        {boolForward && (
          <Forward
            selectedEmail={selectedEmail}
            handleDiscard={handleDiscard}
            handleReply={handleReply}
          />
        )}

        {/* if boolReply == true -> will show the  reply form */}
        {boolReply && (
          <Card className={classes.card2} variant='outlined' >
            <CardContent className={classes.cardContent}>
              <form id="form_id" onSubmit={handleSubmit(onSubmit)} className={classes.form} autoComplete="off">
                  
                <Box display="flex" flexDirection="row" >
                  <Input 
                    className={classes.name}
                    defaultValue='To:'
                    readOnly
                    disableUnderline
                  />
                  <Input 
                    fullWidth
                    disableUnderline 
                    name='to'
                    inputRef={register({required: true})}
                    className={classes.input}
                    // key={`replyTo:${replyTo.slice(0, replyTo.length - 1)}`}
                    defaultValue={replyTo.slice(0, replyTo.length - 1)}
                  />
                </Box>
                <Divider/>
                {errors.to && (<span className={classes.errorMsg}>{t('To field is required')}</span>)}

                <Box display="flex" flexDirection="row">
                  <Input 
                    className={classes.name}
                    defaultValue='Cc:'
                    readOnly
                    disableUnderline
                  />
                  <Input
                    name='cc'
                    fullWidth 
                    disableUnderline 
                    inputRef={register}
                    className={classes.input}
                  />
                </Box>
                <Divider/>

                <DraftEditor
                  editorStyle={{minHeight: "240px", maxHeight: "340px", overflow: "auto", width: '100%'}}
                  editorState={editorState}
                  // onContentStateChange={setContentState}
                  onEditorStateChange={setEditorState}
                  toolbarStyle={{border: 'none', marginTop: '5px', bottom: '0'}}
                  toolbar={{
                    options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                />

              </form>
              
              {attachments.map((attachment) => (
                <div className={classes.attachments}>
                  <Typography>{attachment.file_name.substring(0, 10)} </Typography>
                  <IconButton
                    onClick={() => deleteFile(attachment.id)}
                  >
                    <DeleteOutlineOutlinedIcon/>
                  </IconButton>
                </div>
          
              ))}


              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="row" className={classes.box1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.btnAttach}
                    onClick={() => handleAttachOpen()}
                  >
                    Attach
                  </Button>
                  <DropzoneDialog
                    open={open}
                    onSave={(files) => onSubmitFile(files)}
                    showPreviews={true}
                    maxFileSize={10000000}
                    onClose={handleAttachClose}
                    filesLimit='1'
                  />
                </Box>

                <Box className={classes.box2}>
                  <Button
                    color="secondary"
                    variant="contained" 
                    className={classes.btnDiscard}
                    onClick={handleDiscard}
                  >
                    Discard
                  </Button>
                  <Button
                    type='submit'
                    form='form_id'
                    variant="contained"
                    color="secondary"
                    className={classes.btnSend}
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </ScrollBar>
    </div>
  );
};

export default Right;