import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import 'yup-phone';
import { useStyles } from '../components/styles';
import { Title, Subtitle } from '../components/Title';
import ContactDialog from '../components/ContactDialog';
import CreateButton from '../components/CreateButton';
import ContactPointDialog from '../components/ContactPointDialog';
// import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";

export const splitSpace = (data) => {
  let string = data.split(" ");
  let stringArray = [];
  for(let i = 0; i < string.length; i++){
    if(string[i] !== "") {
      stringArray.push(string[i]);
    }
  };
  return stringArray;
};

export const surName = (data) => {
  let arr = splitSpace(data).reverse().slice(0, -1).reverse();
  return arr.join(" ");
}

export const ContactHeader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  // const history = useHistory();
  const { enqueueSnackbar } =useSnackbar();
  const [prefix, setPrefix] = useState("");
  const [pid, setPid] = useState('');
  const [isDuplicatePid, setIsDuplicatePid] = useState(false);
  const [isPidNotThirteenDigits, setIsPidNotThirteenDigits] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [personalAddress, setPersonalAddress] = useState("");
  const [workAddress, setWorkAddress] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const initialContactType = props.contactPage === 'caller' ? 'Caller' : (
    props.contactPage === 'patient' ? 'Patient (คนไข้ )' : (
      props.contactPage === 'contactPoint' ? 'ACCOUNT' : (
        'ไม่ระบุ'
      )
    )
  )
  const [contactType, setContactType] = useState(initialContactType);
  const [type, setType] = useState("Human");
  const [province, setProvince] = useState("ไม่ระบุ");
  const [region, setRegion] = useState("Unknown");
  const [phoneText, setPhoneText] = useState("");
  const [vipType, setVipType] = useState("Yes");
  const [gender, setGender] = useState("Unknown");
  const [department, setDepartment] = useState("");
  const [note, setNote] = useState("");
  const [isDefaultFlag, setIsDefaultFlag] = useState(false);
  const [allContacts, set_allContacts] = useState(props.allContacts);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [parentIdCondition, setParentIdCondition] = useState(false);
  const pageNum = 1;
  const pageSize = 10;

  const isDefaultFlagHandleChange = (event) => {
    setIsDefaultFlag(event.target.checked);
  };

  /* Set up a default of Contact object */
  const defaultContactData = {
    name: '',
    firstName: '',
    lastName: '',
    phone: [],
    CustomData: {
      contactType: '',
      vipType: 'No'
    },
    province: 'ไม่ระบุ',
    region: 'Unknown',
  }
  const [contactData, set_contactData] = useState(defaultContactData);
  const [address, setAddress] = useState({province: [], region: []})

  /* elasticSearch */
  function elasticSearchInputData(event, value) {

    const input_query = {
      query: {
        query_string: {
          fields: [
            "name",
          ],
          query: `*${event.target.value}*`,
        }
      },
    }
    if (event.target.value != null && event.target.value) {
      let query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`
      fetch(query, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(input_query)
      })
        .then(res => res.json())
        .then((data) => {
          let converted_list = data.hits.hits.map((v) => {
            v._source._id = v._id;
            return v._source;
          });
          set_allContacts(converted_list);
        })
    }
    else if (!event.target.value || event.target.value==="") {
      return fetchContacts()
    }
  }

  const fetchContacts = () => {
    fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person?page=${pageNum}&pageSize=${pageSize}`)
      .then((response) => response.json())
      .then((data) => set_allContacts(data.data))
  };

  const selectedOption = (data) => {
    setSelectedParentId(data);
  };

  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    });
  });

  const createContact = (data) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
      method: 'POST', 
      headers: myHeaders,
      body: JSON.stringify(data),
    })
    .then(response => {
      if (response.status >= 200 && response.status <= 299){
        setOpen(false);
        setParentIdCondition(false);
        setPid('');
        setIsPidNotThirteenDigits(false);
        return enqueueSnackbar('Contact has been created.', { variant: 'success' });
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      enqueueSnackbar(t('Contact has fail to create.'), { variant: 'error' });
    })
  }

  const onSubmit = () => {
    const rawData = {
      prefix: prefix,
      name: firstName + " " + lastName,
      firstName: firstName,
      lastName: lastName,
      dob: selectedDate,
      phone: [phone, mobile],
      address: [personalAddress, workAddress],
      email: [personalEmail, workEmail],
      CustomData: {
        contactType: contactType,
        type: type,
        addrProvince: province,
        addrRegion: region,
        phoneText: phoneText,
        vipType: vipType,
        department: department,
        note: note,
        pid: pid,
        defaultFlag: isDefaultFlag ? 1 : 0,
      },
      gender: gender,
      parentId: ""
    };

    if(pid && pid.length !== 13) {
      // alert('PID must be 13 digits')
      setIsPidNotThirteenDigits(true)
      return
    } else if (isDuplicatePid) {
      return
    } else {
      if (selectedParentId) {
        if (selectedParentId.name !== firstName) {
          rawData.parentId = selectedParentId._id
          createContact(rawData);
          console.log('rawData === ', rawData);
        } else {
          setParentIdCondition(true);
        }
      } else {
        createContact(rawData);
        console.log('rawData without parentId === ', rawData);
      };
    }
  }

  const duplicatedPidChecking = (arr) => {
    setIsDuplicatePid(arr.length > 0);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPid('')
    setIsDuplicatePid(false);
    setIsPidNotThirteenDigits(false);
  };

  var filterUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  /* Function for get Province and Region */
  const getProvinceAndRegion = () => {
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/custom-rama/api/v1/provinces`)
    .then((res) => res.json())
    .then((data) => {
      const resJson = data.data;
      let filteredRegion = resJson.map(res => res.regionName);
      filteredRegion = filteredRegion.filter(filterUnique);
      let regionJsonObject = [];
      for(let i = 0; i < filteredRegion.length; i++) {
        regionJsonObject.push({_id: i, name: filteredRegion[i]});
      }
      setAddress({ province: resJson, region: regionJsonObject });
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  React.useEffect(() => {
    if (open) getProvinceAndRegion();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <div className={classes.root}>
      <Grid alignItems="flex-start" container justifyContent="space-between" spacing={3}>
       <Title title={props.title}/>
        <Grid item className={classes.gridButton}>
          <CreateButton buttonName={props.buttonName} handleClickOpen={handleClickOpen}/>
        </Grid>
      </Grid>
      <Subtitle subtitle={props.subtitle}/>

      {props.buttonName && props.contactPage === 'contact' && (
        <ContactDialog
          isCreateDialog={true}
          utilizeTo="contactPage"
          open={open}
          handleDateChange={handleDateChange}
          handleClose={handleClose}
          dialogTitle={t("create_contact_page_title")}
          dialogSubtitle={t('create_contact_page_subtitle')}
          onSubmit={onSubmit}
          defaultPrefix={prefix}
          defaultPid={pid}
          isDuplicatePid={isDuplicatePid}
          isPidNotThirteenDigits={isPidNotThirteenDigits}
          defaultFirstName={firstName}
          defaultLastName={lastName}
          // prevNameFinal={"-"}
          defaultDob={selectedDate}
          defaultMobile={mobile}
          defaultPhone={phone}
          defaultPersonalAddress={personalAddress}
          defaultWorkAddress={workAddress}
          defaultPersonalEmail={personalEmail}
          defaultWorkEmail={workEmail}
          defaultContactType={contactType}
          defaultType={type}
          defaultProvince={province}
          defaultRegion={region}
          defaultPhoneText={phoneText}
          defaultVipType={vipType}
          defaultGender={gender}
          defaultDepartment={department}
          defaultNote={note}
          defaultParentId={''}
          parentIdValue={selectedParentId}
          saveButton={t("btn_create")}
          closeButton={t("btn_cancel")}
          parentIdOptions={allContacts}
          elasticSearchInputData={elasticSearchInputData}
          getSelectedOptions={selectedOption}
          parentIdCondition={parentIdCondition}
          prefixOnChange={(e) => setPrefix(e.target.value)}
          pidOnChange={(e) => setPid(e.target.value)}
          duplicatedPidChecking={duplicatedPidChecking}
          firstNameOnChange={(e) => setFirstName(e.target.value)}
          lastNameOnChange={(e) => setLastName(e.target.value)}
          mobileOnChange={(e) => setMobile(e.target.value)}
          phoneOnChange={(e) => setPhone(e.target.value)}
          personalAddressOnChange={(e) => setPersonalAddress(e.target.value)}
          workAddressOnChange={(e) => setWorkAddress(e.target.value)}
          personalEmailOnChange={(e) => setPersonalEmail(e.target.value)}
          workEmailOnChange={(e) => setWorkEmail(e.target.value)}
          contactTypeOnChange={(e) => setContactType(e.target.value)}
          typeOnChange={(e) => setType(e.target.value)}
          provinceOnChange={(e) => setProvince(e.target.value)}
          regionOnChange={(e) => setRegion(e.target.value)}
          phoneTextOnChange={(e) => setPhoneText(e.target.value)}
          vipTypeOnChange={(e) => setVipType(e.target.value)}
          genderOnChange={(e) => setGender(e.target.value)}
          departmentOnChange={(e) => setDepartment(e.target.value)}
          noteOnChange={(e) => setNote(e.target.value)}
          isDefaultFlag={isDefaultFlag}
          isDefaultFlagHandleChange={isDefaultFlagHandleChange}
          
          contactTypeList={props.contactTypeList}
          genderList={props.genderList}
          addrProvince={props.addrProvince}
          addrRegion={props.addrRegion}
        />
      )}
      {props.buttonName && props.contactPage === 'contactPoint' && (
        <ContactPointDialog
          contactPage={'contactPoint'}
          submit={'create'}
          open={open}
          onCancel={handleClose}
          contactData={contactData}
          onChangeContactData={set_contactData}
          address={address}
          onchangeAddress={setAddress}
          triggerToRefresh={props.triggerToRefresh}
          defaultContactData={defaultContactData}
        />
      )}
      
    </div>
  )
}

ContactHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  onClickButton: PropTypes.func,
  // uploadBtnName: PropTypes.string,
  // onClickUpload: PropTypes.func,
  // onClickDown: PropTypes.func,
};

ContactHeader.defaultProps = {
  buttonName: undefined,
  onClickButton: undefined,
  // uploadBtnName: undefined,
  // onClickUpload: undefined,
  // onClickUpload: undefined
};

// export default PageHeader;