import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FieldError from '../../../TM/src/components/FieldError';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { yearArray, monthAll } from './dateTimeForReports';

const yearAll = yearArray(2007);

const keyboardButtonProps = { style: { marginRight: -14 } };

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: '0.50rem !important',
    marginBottom: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  fieldLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '24px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
  fieldSelectionLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '16px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
}));

function DatePeriodSelector(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [yearOfMonthSelection] = useState(`${new Date().getFullYear()}`);
  const [monthOfMonthSelection] = useState(0);
  const [yearOfYearSelection] = useState(`${new Date().getFullYear()}`);

  return (
    <div>
      <Grid container spacing={2} className={classes.formRow}>
        {/* Selection type in left side */}
        <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('Select type')}</Grid>
        <Grid item xs={12} md={2} className={classes.fieldControl}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="date-selection" name="date-selection" value={props.timeSelection} onChange={props.timeSelectionhandleChange}>
              <FormControlLabel value="date" control={<Radio />} label="Date" style={{ marginTop: '12px'}} />
              <FormControlLabel value="week" control={<Radio />} label="Week" style={{ marginTop: '12px'}} />
              <FormControlLabel value="month" control={<Radio />} label="Month" style={{ marginTop: '12px'}} />
              <FormControlLabel value="year" control={<Radio />} label="Year" style={{ marginTop: '12px'}} />
              <FormControlLabel value="period" control={<Radio />} label="Period" style={{ marginTop: '12px'}} />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Selection Components in right side */}
        <Grid item xs={12} md={9} className={classes.fieldControl}>

          {/* Date DatePicker */}
          <Grid container spacing={2} style={{visibility: props.timeSelection === 'date'? 'visible':'hidden'}}>
            <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('Date')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
              <Controller
                name="selectedDate"
                control={props.control}
                defaultValue={new Date()}
                onChange={([, data]) => data}
                as={
                  <KeyboardDatePicker
                    fullWidth
                    margin="dense"
                    variant="inline"
                    inputVariant="outlined"
                    error={false}
                    helperText={null}
                    format="DD/MM/YYYY"
                    KeyboardButtonProps={keyboardButtonProps}
                    InputAdornmentProps={{ position: 'end' }}
                    disabled={props.timeSelection === 'date'? false : true}
                  />
                }
              />
              {props.timeSelection === 'date' && props.selectedDateErr && (
                <FieldError message={t('This field must be valid date format.')} />
              )}
            </Grid>
          </Grid>

          {/* Week DatePicker */}
          <Grid container spacing={2} style={{visibility: props.timeSelection === 'week'? 'visible':'hidden'}}>
            <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('Week')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
              <Controller
                name="selectedWeek"
                control={props.control}
                defaultValue={null}
                onChange={([, data]) => data}
                as={
                  <KeyboardDatePicker
                    fullWidth
                    margin="dense"
                    variant="inline"
                    inputVariant="outlined"
                    error={false}
                    helperText={null}
                    format="DD/MM/YYYY"
                    KeyboardButtonProps={keyboardButtonProps}
                    InputAdornmentProps={{ position: 'end' }}
                    disabled={props.timeSelection === 'week'? false : true}
                  />
                }
              />
              {props.timeSelection === 'week' && props.selectedWeekErr && (
                <FieldError message={t('This field must be valid date format.')} />
              )}
            </Grid>
          </Grid>

          {/* Year and Month Selection */}
          <Grid container spacing={2} style={{visibility: props.timeSelection === 'month'? 'visible':'hidden'}}>
            <Grid item xs={12} md={1} className={classes.fieldSelectionLabel}>{t('Year')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
            <Controller
              name="yearOfMonthSelection"
              rules={{ required: true }}
              control={props.control}
              defaultValue={yearOfMonthSelection}
              render={({ onChange, value, name }) => (
                <Select
                  className={classes.select}
                  variant="outlined"
                  value={value ? value : 'None'}
                  name={name}
                  margin="dense"
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  disabled={props.timeSelection === 'month'? false : true}
                >
                  {yearAll.map((year) =>
                    <MenuItem key={year.id} value={year.year}>{year.year}</MenuItem>
                  )}
                </Select>
              )}
            />
            </Grid>

            <Grid item xs={12} md={2} className={classes.fieldSelectionLabel}>{t('Month')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
            <Controller
              name="monthOfMonthSelection"
              rules={{ required: true }}
              control={props.control}
              defaultValue={monthOfMonthSelection}
              render={({ onChange, value, name }) => (
                <Select
                  className={classes.select}
                  variant="outlined"
                  value={value ? value : 0}
                  name={name}
                  margin="dense"
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  disabled={props.timeSelection === 'month'? false : true}
                >
                  {monthAll.map((month) => (
                    <MenuItem key={month.id} value={month.id}>
                      {month.month}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            </Grid>
          </Grid>

          {/* Year Selection */}
          <Grid container spacing={2} style={{visibility: props.timeSelection === 'year'? 'visible':'hidden'}}>
            <Grid item xs={12} md={1} className={classes.fieldSelectionLabel}>{t('Year')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
            <Controller
              name="yearOfYearSelection"
              rules={{ required: true }}
              control={props.control}
              defaultValue={yearOfYearSelection}
              render={({ onChange, value, name }) => (
                <Select
                  className={classes.select}
                  variant="outlined"
                  value={value ? value : 'None'}
                  name={name}
                  margin="dense"
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  disabled={props.timeSelection === 'year'? false : true}
                >
                  {yearAll.map((year) =>
                    <MenuItem key={year.id} value={year.year}>{year.year}</MenuItem>
                  )}
                </Select>
              )}
            />
            </Grid>
          </Grid>

          {/* From and To DateTimePicker */}
          <Grid container spacing={2} style={{visibility: props.timeSelection === 'period'? 'visible':'hidden'}}>
            <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_from')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
              <Controller
                name="fromDate"
                control={props.control}
                defaultValue={null}
                onChange={([, data]) => data}
                as={
                  <KeyboardDatePicker
                    fullWidth
                    margin="dense"
                    variant="inline"
                    inputVariant="outlined"
                    error={false}
                    helperText={null}
                    format="DD/MM/YYYY"
                    KeyboardButtonProps={keyboardButtonProps}
                    InputAdornmentProps={{ position: 'end' }}
                    disabled={props.timeSelection === 'period'? false : true}
                  />
                }
              />
              {props.timeSelection === 'period' && props.fromDateErr && (
                <FieldError message={t('This field must be valid date format.')} />
              )}
            </Grid>

            <Grid item xs={12} md={2} className={classes.fieldLabel}>{t('general_to')}</Grid>
            <Grid item xs={12} md={3} className={classes.fieldControl}>
              <Controller
                name="toDate"
                control={props.control}
                defaultValue={null}
                onChange={([, data]) => data}
                as={
                  <KeyboardDatePicker
                    fullWidth
                    margin="dense"
                    variant="inline"
                    inputVariant="outlined"
                    error={false}
                    helperText={null}
                    format="DD/MM/YYYY"
                    KeyboardButtonProps={keyboardButtonProps}
                    InputAdornmentProps={{ position: 'end' }}
                    disabled={props.timeSelection === 'period'? false : true}
                  />
                }
              />
              {props.timeSelection === 'period' && props.toDateErr && (
                <FieldError message={t('This field must be valid date format.')} />
              )}
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </div>
  )
}

export default DatePeriodSelector



            