import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    top: '45%',
    left: '47.5%',
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();
  return (
    <CircularProgress className={classes.progress} color="secondary" />
  );
};

export default LoadingScreen;
