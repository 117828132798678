import React from 'react';
import {  Grid, Button, TextField,  Card, CardContent, CardHeader, Divider, Box, List, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import {appendErrors, useForm, Controller} from "react-hook-form";
import { makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import 'yup-phone';




const useStyles = makeStyles((theme)=> ({
    container: {
        marginTop: "1%"
    },
    create_card: {
        marginLeft: "5%",
        marginRight: "0",
        paddingRight: "0",
        marginTop: "2%",
        disableGutters: "true"
    },
    create_functions: {
        '& > *': {
            margin: theme.spacing(1),
          }
    },
    name_: {
      textAlign: "left"
    },
    card_:{
      marginLeft: "45%",
    },
    submit: {
      marginLeft: "60%",
      marginTop: "3%"
    },
    btn: {
      marginTop: "1%",
      textAlign: "center",
      '& > *': {
          margin: theme.spacing(1),
        },
    },
    detailCard: {
        marginTop: "1%",
        marginLeft: "38%",
        textdecoration: "none"
    },
    errorMsg : {
      color: "#f44336",
      marginTop: "1%",
      marginLeft: "1%"
    },
    parallel: {
      width: "50%",
      '& > *': {
        margin: theme.spacing(1),
      },
  },
  middle: {
      margin: "1.5%",
      width: "15%",
      maxWidth: "15%"
  },
  form_: {
    marginLeft: "18%",
    marginTop: "3%",
    marginBottom: "3%"
},
}))


function Create (props) {
    const classes= useStyles();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const {t} = useTranslation();
    const { enqueueSnackbar } =useSnackbar();
    const [selectedDate, setSelectedDate] = React.useState();

    const prefixName = [
      {_id: 1, prefix: "Mr."}, 
      {_id: 2, prefix: "Miss"}, 
      {_id: 3, prefix: "Mrs."}
    ]

    const [prefixList, set_prefixList] = React.useState(prefixName);

    /*fetching list of prefixes for dropdown*/
    React.useEffect(() => {
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
        .then(res => res.json())
        .then((data) => {
          set_prefixList(data.data)
        })
    } , [])
  
    yup.addMethod(yup.string, 'validatePhone', function () {
      return this.test('test-phone', 'Invalid phone number format.', (value) => {
        if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
        return true;
      })
    })

    const yupSchema = yup.object().shape({
      phone: yup.array().of(yup.string().validatePhone()),
      // phone: yup.array().of(yup.string().max(10)),
      name: yup.string().required(),
    });
    const { register, handleSubmit, errors, control } = useForm({
      reValidateMode: 'onChange',
      resolver: yupResolver(yupSchema),
    });


    const handleDateChange = (date) => {
      setSelectedDate(date);
    };



    const onSubmit = (data,e) => { 
      console.log(data);
      var raw = JSON.stringify(data);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299){history.push('/contacts');    
        return enqueueSnackbar('Contact has been created.', { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to create.'), { variant: 'error' });
      }) 
    }

    return (
  
    // <Grid container  className={classes.root} spacing={1}>
        <Container maxWidth="xl" className={classes.container}>
          <PageHeader
            title={t('create_contact_page_title')}
            subtitle={t('create_contact_page_subtitle')}
          />
        
        <Grid item xs={12} className={classes.top}>
          <Card className={classes.card}>
              <CardHeader className={classes.header} title={t('create_contact_form')}>
              </CardHeader>
              <Divider/>
              <CardContent>
                  <form id="create_form"  onSubmit={handleSubmit(onSubmit)} className={classes.form_}>
                    {/* Prefixes */}
                    <Box display="flex" flexDirection="row">
                      <Typography className={classes.middle}> {t('prefix')}</Typography>
                      <FormControl className={classes.parallel}>
                        <Controller
                          fullWidth
                          variant="outlined"
                          as={
                            <Select>
                              {prefixList.map((prefixList) => (
                                <MenuItem key={prefixList._id} value={prefixList.prefix}>{prefixList.prefix}</MenuItem>
                              ))}
                            </Select>
                          } 
                          name="title_prefix"
                          control={control}
                          defaultValue=""
                        />
                        {/* {errors.title_prefix &&  <span className={classes.errorMsg}>{t('Prefix field is required')}</span>} */}
                      </FormControl>
                    </Box>
                    {/* Name */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('person_name')} * </Typography>
                      <div className={classes.parallel}>
                      <TextField 
                        fullWidth 
                        variant="outlined" 
                        inputRef={register} 
                        name="name"   
                      />
                      {errors.name && (<span className={classes.errorMsg}> {t('Name field is required')} </span>)}
                      </div>
                    </Box>

                    {/* Date of Birth */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('Date of Birth')} </Typography>
                      <div className={classes.parallel}>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            inputRef={register}
                            name= "dob"
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                      </MuiPickersUtilsProvider>
                      </div>
                    </Box>

                    {/* Mobile Phone */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('Mobile phone')}  </Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth 
                          variant="outlined" 
                          inputRef={register} 
                          name="phone[0]">
                        </TextField>            
                        {errors.phone && errors.phone[0] && <span className={classes.errorMsg}> Please enter a valid phone number  </span>}
                      </div>
                    </Box>
                    
                    {/* Work Phone */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('Work phone')}   </Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth 
                          variant="outlined" 
                          inputRef={register} 
                          name="phone[1]"  
                        />
                        {errors.phone && errors.phone[1] && <span className={classes.errorMsg}> Please enter a valid phone number </span>}
                      </div>
                    </Box>

                    {/* Home Phone */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('general_home_phone')}  </Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth 
                          variant="outlined" 
                          inputRef={register} 
                          name="phone[2]"  
                        />
                        {errors.phone && errors.phone[2] && <span className={classes.errorMsg}> Please enter a valid phone number </span>}
                      </div>
                    </Box>

                    {/* Address */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('person_address')}   </Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth 
                          variant="outlined" 
                          inputRef={register} 
                          name="address[0]"   
                        />
                      </div>
                    </Box>


                    {/* Work Address */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('person_work_address')}  </Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth  
                          variant="outlined"
                          inputRef={register} 
                          name="address[1]" 
                        />
                      </div>
                    </Box>

                    {/* Personal Email */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('person_personal_email')}   </Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth 
                          variant="outlined" 
                          inputRef={register} 
                          name="email[0]"
                          type="email"  
                        />
                      </div>
                    </Box>

                    {/* Work Email */}
                    <Box display="flex" flexDirection="row">
                      <Typography  className={classes.middle}>{t('person_work_email')}   </Typography>
                      <div className={classes.parallel}>
                      <TextField 
                        fullWidth 
                        variant="outlined" 
                        inputRef={register} 
                        name="email[1]"
                        type="email"
                      />
                      </div>
                    </Box>
                   
         

  
                    <Box className={classes.btn} >
                        <Button 
                          variant="contained" 
                          type="submit"   
                          color="primary"
                        > 
                          {t('btn_create')} 
                        </Button>
                        <Button 
                          variant="contained" 
                          color="primary"  
                          onClick={() => history.push('/contacts')}
                        > 
                        {t('btn_cancel')}
                        </Button>
                    </Box> 
                  </form> 
              </CardContent>
          </Card>
        </Grid>  
       </Container>
 

    )
}
export default Create;
