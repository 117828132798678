import React from "react";
import {
  IconButton,
  Tooltip,
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/AddOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useTranslation } from "react-i18next";
// import { useStyles } from './styles'
// import EditDialog from "../Pages/EditDialog";


const EditButton = ({handleClickOpen}) => {
//   const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip title={t("edit")}>
        <IconButton
          variant="contained"
          onClick={handleClickOpen}
        >
          <EditOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
      {/* <EditDialog /> */}
    </React.Fragment>
    
  )
}

export default EditButton;