/* eslint-disable radix */
import moment from 'moment';
import 'moment/locale/th';

import { isArray, mergeWith } from 'lodash';

/**
 * This is important and used many times in TM modules
 * TM used lodash merge method many places but we found
 * array property should be merge like normal for our forms
 * (Array should be replace even empty array).
 *
 * And this is just make the code more reusable actaully.
 *
 * NOTE: Checkout the link below the code is copied from.
 * LINK: https://lodash.com/docs/4.17.15#mergeWith
 */
const merge = (object, sources) => {
  // eslint-disable-next-line consistent-return
  return mergeWith(object, sources, (objValue, srcValue) => {
    // if (isArray(objValue) || isObject(objValue)) {
    if (isArray(objValue)) {
      return srcValue;
    }
  });
};

/**
 * A Set a cookie function to the browser?
 *
 * @param {*} cookieName - The cookie key name.
 * @param {*} cookieValue - The cookie key value.
 * @param {*} days - Expiration in days.
 */
const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

/**
 * Get the existing cookie by key name.
 *
 * @param {*} cookieName - The cookie key name.
 */
const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Remove the existing cookie by key name.
 *
 * @param {*} cookieName - The cookie key name.
 */
const eraseCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

/**
 * Get default priority doc from memory (first).
 */
const getDefaultPriority = () => {
  if (!window.ticketPriorities) return null;
  if (!isArray(window.ticketPriorities)) return null;
  if (!window.ticketPriorities[0]) return null;
  return window.ticketPriorities[0];
};

/**
 * Get default priority doc from memory (first).
 */
const getDefaultStatus = () => {
  if (!window.ticketStatuses) return null;
  if (!isArray(window.ticketStatuses)) return null;
  if (!window.ticketStatuses[0]) return null;
  return window.ticketStatuses[0];
};

/**
 * Get first reason Open status doc from memory (RAMA).
 */
const getReasonOpenStatus = () => {
  let foundReasonOpenStatus = null;
  if (!window.ticketStatuses) return null;
  if (!isArray(window.ticketStatuses)) return null;
  for (let i = window.ticketStatuses.length - 1; i >= 0; i--) {
    if (window.ticketStatuses[i].reason === 'Open') {
      foundReasonOpenStatus = window.ticketStatuses[i];
    }
  }
  return foundReasonOpenStatus || null;
};

/**
 * Get first reason Close status doc from memory (RAMA).
 */
const getReasonCloseStatus = () => {
  let foundReasonCloseStatus = null;
  if (!window.ticketStatuses) return null;
  if (!isArray(window.ticketStatuses)) return null;
  for (let i = window.ticketStatuses.length - 1; i >= 0; i--) {
    if (window.ticketStatuses[i].reason === 'Close') {
      foundReasonCloseStatus = window.ticketStatuses[i];
    }
  }
  return foundReasonCloseStatus || null;
};

/**
 * Get first reason Complete status doc from memory (RAMA).
 */
const getReasonCompleteStatus = () => {
  let foundReasonCompleteStatus = null;
  if (!window.ticketStatuses) return null;
  if (!isArray(window.ticketStatuses)) return null;
  for (let i = window.ticketStatuses.length - 1; i >= 0; i--) {
    if (window.ticketStatuses[i].reason === 'Complete') {
      foundReasonCompleteStatus = window.ticketStatuses[i];
    }
  }
  return foundReasonCompleteStatus || null;
};

/**
 * Get first state complete status doc from memory (window variable).
 */
const getFirstStateCompleteStatus = () => {
  let foundFirstCompleteStatus = null;
  if (!window.ticketStatuses) return null;
  if (!isArray(window.ticketStatuses)) return null;
  for (let i = window.ticketStatuses.length - 1; i >= 0; i--) {
    if (window.ticketStatuses[i].state === 'complete') {
      foundFirstCompleteStatus = window.ticketStatuses[i];
    }
  }
  return foundFirstCompleteStatus || null;
};

/**
 * Get last state status doc from memory (window variable).
 */
const getLastStateCloseStatus = () => {
  let foundLastCloseStatus = null;
  if (!window.ticketStatuses) return null;
  if (!isArray(window.ticketStatuses)) return null;
  for (let i = window.ticketStatuses.length - 1; i >= 0; i--) {
    if (window.ticketStatuses[i].state === 'close') {
      foundLastCloseStatus = window.ticketStatuses[i];
    }
  }
  return foundLastCloseStatus || null;
};

/**
 * Convert program datetime to user date.
 *
 * @param {string} date - ISODate in string
 * @return Formatted date in string
 */
const printDate = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY');
};

/**
 * Convert program datetime to user time (HH:mm).
 *
 * @param {string} datetime - ISODate in string
 * @return Formatted time in string
 */
const printTime = (datetime) => {
  if (!datetime) return '';
  return moment(datetime).format('HH:mm');
};

/**
 * Convert program datetime to user datetime (without millisecond).
 *
 * @param {string} datetime - ISODate in string
 * @return Formatted datetime in string
 */
const printDateTime = (datetime) => {
  if (!datetime) return '';
  return moment(datetime).format('DD/MM/YYYY HH:mm');
};

/**
 * Convert program datetime to user datetime like a sentence (without millisecond).
 *
 * @param {string} datetime - ISODate in string
 * @return Formatted datetime in string
 */
const printUserDateTime = (datetime) => {
  if (!datetime) return '';
  return moment(datetime).format('D MMM YYYY HH:mm');
};

/**
 * Convert datetime to relative time using moment.
 *
 * @param {string} datetime - ISODate in string
 * @return Relative time in string
 */
const printRelativeTime = (datetime) => {
  if (!datetime) return '';
  const currentLocale = getCookie('locale');
  if (currentLocale) moment.locale(currentLocale);
  return moment(datetime).fromNow();
};

/**
 * Get global snackbar options object.
 */
const getSnackbarOptions = () => {
  return {
    vertical: 'bottom',
    horizontal: 'left',
  };
};

/**
 * get keyboard button props.
 */
const getKeyboardButtonProps = () => {
  return {
    style: {
      marginRight: -16,
      color: 'rgb(162 162 162)',
    },
  };
};

/**
 * Delete permissions property from user object.
 */
const getPlainUser = (user) => {
  delete user.role.permissions;
  delete user.teamObj.categoryIDs;
  return user;
};

/**
 * Detect draftjs empty data (plain text)
 */
const isDraftJsEmpty = (rawContent) => {
  return !rawContent || rawContent === {};
};

/**
 * Calculate person's age by their birthday.
 *
 * @param {Date} birthdate
 * @returns
 */
const calculateAge = (birthdate) => {
  const now = moment();
  const birthMoment = moment(birthdate);
  const yearDiff = moment.duration(now - birthMoment).as('years');
  return Math.floor(yearDiff);
};

/**
 * Convert js date object to that date 2564 in string
 */
const toThaiDate = (date) => {
  const eng = moment(date);
  const day = eng.format('DD');
  const month = eng.format('MM');
  const year = parseInt(eng.format('YYYY')) + 543;
  return `${day}/${month}/${year}`;
};

/**
 * Parse thai date back to js date object
 */
const parseThaiDate = (thaiDate) => {
  if (!thaiDate) return '';
  if (thaiDate.length !== 10) return '';
  const day = thaiDate.substr(0, 2);
  const month = thaiDate.substr(3, 2);
  const year = parseInt(thaiDate.substr(thaiDate.length - 4)) - 543;
  return moment(`${year}-${month}-${day}-`, 'YYYY-MM-DD');
};

/**
 * Verify if the value is a number?
 *
 * @param {number} num
 * @returns
 */
const isNumeric = (num) => {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(num);
};

/**
 * Find pool user object from window.users.
 *
 * @returns The person object
 */
const getPoolUser = () => {
  return window.users.find((e) => {
    return e.username === process.env.REACT_APP_POOL_USER;
  });
};

/**
 * Sort array of caller using CustomData.order(seq).
 */
const sortCallersByOrder = (persons) => {
  return persons.sort((a, b) => {
    const aSeq = a.CustomData.order || 999;
    const bSeq = b.CustomData.order || 999;
    return (aSeq > bSeq ? 1 : -1);
  });
};

export {
  merge,
  printDate,
  printTime,
  printDateTime,
  printUserDateTime,
  printRelativeTime,
  setCookie,
  getCookie,
  eraseCookie,
  getDefaultPriority,
  getDefaultStatus,
  getReasonOpenStatus,
  getReasonCloseStatus,
  getReasonCompleteStatus,
  getFirstStateCompleteStatus,
  getLastStateCloseStatus,
  getSnackbarOptions,
  getKeyboardButtonProps,
  getPlainUser,
  isDraftJsEmpty,
  calculateAge,
  toThaiDate,
  parseThaiDate,
  isNumeric,
  getPoolUser,
  sortCallersByOrder,
};
