/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Divider,
  Card,
  Typography,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SearchResult from './Pages/SearchResult';
import PageHelmet from '../TM/src/components/PageHelmet';
import PageHeader from '../TM/src/components/PageHeader';
import { logger } from '../TM/src/common';
import { hitsToDocs } from '../TM/src/common/utils';
import TextFieldDebounce from '../../components/TextFieldDebounce';
import * as IngredientAPI from './apis/IngredientAPI';
import SelectCategory from '../Substance/components/SelectCategory';
import SelectFamily from '../Substance/components/SelectFamily';
import SelectChemical from '../Substance/components/SelectChemical';

// Component's style object.
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  mainCard: {
    padding: '32px',
  },
  searchToolbar: {
    marginBottom: '8px',
  },
  dividerLine: {
    margin: '12px 0px',
  },
  textField: {
    marginTop: '4px',
    marginBottom: '8px',
  },
  select: {
    marginTop: '4px',
    marginBottom: '8px',
  },
}));

// Force TextField input style.
const lineHeightInputProps = {
  style: {
    lineHeight: '2em',
  },
};

// The ingredients page component.
const IngredientsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [ingredients, setIngredients] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const [searchForm, setSearchForm] = useState({
    name: '',
    category_name: '',
    family_name: '',
    chemical_name: '',
  });

  // Pagination related variables.
  const rowsPerPage = 100;
  const rowsOffset = currentPage * rowsPerPage;

  // Handle search form state change shortcut.
  const handleFieldChange = (fieldPath, newValue) => {
    setSearchForm((prevSearchForm) => {
      return {
        ...prevSearchForm,
        [fieldPath]: newValue,
      };
    });
  };

  // Side effect of DidMount, searchForm, currentPage.
  useEffect(() => {
    logger.info('useEffect.searchForm:', searchForm);

    // Create a post request to elastic search.
    IngredientAPI.searchIngredients(
      searchForm.name,
      searchForm.category_name,
      searchForm.family_name,
      searchForm.chemical_name,
      rowsPerPage,
      rowsOffset,
    )
      .then((res) => {
        setTotalHits(res.data.hits.total);
        setIngredients(hitsToDocs(res.data.hits.hits));
      })
      .catch((error) => {
        logger.error(error);
        logger.error('Unable to fetch ingredients from Elastic Search.');
        enqueueSnackbar('Unable to fetch ingredients from Elastic Search.', {
          variant: 'error',
        });
      });

  }, [searchForm, currentPage, refreshData]);

  // The ingredients page rendering.
  return (
    <PageHelmet title={t('Ingredients')}>
      <Container maxWidth="xl" className={classes.container}>

        {/* Page Header */}
        <PageHeader
          title={t('Ingredients')}
          subtitle={t('Searching for ingredients')}
          buttonName={t('New ingredient')}
          onClickButton={() => history.push('/ingredient/create')}
        />

        {/* Main Card */}
        <Card className={classes.mainCard}>

          {/* Search Toolbar */}
          <Grid container spacing={2} xs={10} className={classes.searchToolbar}>

            {/* TextField Ingredient Name */}
            <Grid item xs={3}>
              <TextFieldDebounce
                fullWidth
                variant="outlined"
                className={classes.textField}
                label={t('Ingredient Name')}
                margin="normal"
                inputProps={lineHeightInputProps}
                onStopTyping={(value) => handleFieldChange('name', value)}
              />
            </Grid>

            {/* Select Category */}
            <Grid item xs={3}>
              <SelectCategory
                fullWidth
                variant="outlined"
                className={classes.select}
                id="ingredient-select-category-filed"
                displayEmpty
                value={searchForm.category_name}
                onChange={(value) => {
                  handleFieldChange('category_name', value);
                  handleFieldChange('family_name', '');
                  handleFieldChange('chemical_name', '');
                }}
              />
            </Grid>

            {/* Select Family */}
            <Grid item xs={3}>
              <SelectFamily
                fullWidth
                variant="outlined"
                className={classes.select}
                id="ingredient-select-family-filled"
                displayEmpty
                value={searchForm.family_name}
                onChange={(value) => {
                  handleFieldChange('family_name', value);
                  handleFieldChange('chemical_name', '');
                }}
                cascadeCategory={searchForm.category_name}
              />
            </Grid>

            {/* Select Family */}
            <Grid item xs={3}>
              <SelectChemical
                fullWidth
                variant="outlined"
                className={classes.select}
                id="ingredient-select-chemical-filled"
                displayEmpty
                value={searchForm.chemical_name}
                onChange={(value) => handleFieldChange('chemical_name', value)}
                cascadeCategory={searchForm.category_name}
                cascadeFamily={searchForm.family_name}
              />
            </Grid>

          </Grid>
          {/* /.Search Toolbar */}

          {/* Divider Line */}
          <Grid item xs={12} className={classes.dividerLine}>
            <Divider />
          </Grid>

          {/* Result Section */}
          <Grid item xs={12}>
            <Typography variant="h6">{t('Search results')}</Typography>
          </Grid>

          {/* Search Result */}
          <Grid item xs={12}>
            <SearchResult
              ingredients={ingredients}
              totalHits={totalHits}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              onItemDeleted={(error, deletedId) => {
                // Before getting new data we should wait sometime.
                // To let MongoDB Connector and ElasticSerch do thier jobs.
                setTimeout(() => setRefreshData(!refreshData), 1000);
              }}
            />
          </Grid>

        </Card>
        {/* Main Card */}

      </Container>
    </PageHelmet>
  );
};

export default IngredientsPage;
