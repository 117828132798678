import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "./PageHeader";
import Container from "@material-ui/core/Container";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Table,
  Button,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
  Divider,
  TextField,
  Slide,
  Tooltip,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DropzoneDialog } from "material-ui-dropzone";
import { useForm, Controller } from "react-hook-form";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AutocompleteSearch from "../../../components/AutocompleteSearch";
import moment from "moment";
import Delete from "./screenpopDelete";
import Edit from "./screenpopEdit";
import Calling from "./screenpopCalling";
import { toFinite } from "lodash-es";
import MomentUtils from "@date-io/moment";

const colors = require("colors");
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Fix material ui date picker icon too much space.
const keyboardButtonProps = { style: { marginRight: -14 } };

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1.5%",
  },
  table: {
    minWidth: 250,
  },
  top: {
    minHeight: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    marginLeft: "1%",
    marginRight: "1%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Users = (props) => {
  const classes = useStyles();
  // const tableRef = useRef(null);
  const history = useHistory();

  const [tbList, set_tbList] = useState([]);
  const tableRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [empty, setEmpty] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [toFromName, setToFromName] = useState([]);
  const [toToName, setToToName] = useState();
  const [toExt, setToExt] = useState();
  const [toPhone, setToPhone] = useState();

  const [selectedFromName, setSelectedFromName] = useState();
  const [selectedToName, setSelectedToName] = useState();
  const [selectedExt, setSelectedExt] = useState();
  const [selectedPhone, setSelectedPhone] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateArray, setFromDateArray] = useState(null);
  
  const [inputValueFromDate, setInputValueFromDate] = useState(moment().format("DD/MM/YYYY k:mm"));
  const [inputValueToDate, setInputValueToDate] = useState(moment().format("DD/MM/YYYY k:mm"));

  const [trigger, setTrigger] = useState(false);

  const url = process.env.REACT_APP_TROUBLESHOOT_BACKEND_FDQN || `http://127.0.0.1:3000`;

  // React hook form schema.
  const yupSchema = yup.object().shape({
    fromDate: yup.date().required(),
    toDate: yup.date().required(),
  });

  // React hook form initialize.
  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(yupSchema),
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* fetch data to any dropdowns */
  useEffect(() => {
    /* fetch data to the from dropdown */
    fetch(`${url}/tsm/api/v1/screenpop/`)
      .then((res) => res.json())
      .then((data) => data.data.map((res) => res.from))
      .then((data) => {
        setToFromName(data);
      })
      .catch((error) => {
        console.log(error);
      });

    /* fetch data to the to dropdown */
    fetch(`${url}/tsm/api/v1/screenpop/`)
      .then((res) => res.json())
      .then((data) => data.data.map((res) => res.to))
      .then((data) => {
        setToToName(data);
      })
      .catch((error) => {
        console.log(error);
      });

    /* fetch data to the ext dropdown */
    fetch(`${url}/tsm/api/v1/screenpop/`)
      .then((res) => res.json())
      .then((data) => data.data.map((res) => res.ext))
      .then((data) => {
        setToExt(data);
      })
      .catch((error) => {
        console.log(error);
      });

    /* fetch data to the phone dropdown */
    fetch(`${url}/tsm/api/v1/screenpop/`)
      .then((res) => res.json())
      .then((data) => data.data.map((res) => res.phone))
      .then((data) => {
        setToPhone(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [url]);

  /* function for return the set state */
  const handleOnChange = async (func, setTo) => {
    await func(setTo);
    await setTrigger(!trigger)
  };

  const dateFormatter = str => {
    return str;
  };

  const onFromDateChange = (date, value) => {
    setFromDate(date);
    setInputValueFromDate(value);
  };

  const onToDateChange = (date, value) => {
    setToDate(date);
    setInputValueToDate(value);
  };

  /* fetching all data to screenpop page */

  /**
   * Example
    * var accounts =[
      {id: "1", name: "item 1", amount: "15", date:"2015-07-24T12:58:17.430Z"},
      {id: "2", name: "item 2", amount: "200", date:"2015-08-04T18:54:16.948Z"},
      {id: "3", name: "item 3", amount: "300", date:"2015-07-04T18:54:16.948Z"},
      {id: "4", name: "item 4", amount: "300", date:"2015-08-10T18:54:16.948Z"}
    ];

    let preDate = new Date("2015-08-04").getTime();
    let postDate = new Date("2015-08-12").getTime();
    let filteredAccounts = accounts.filter(function(account){
      return new Date(account.date).getTime() >= preDate && new Date(account.date).getTime() <= postDate
    });

    console.log(filteredAccounts);
   */

  const filteredDataFromDateRange = (dataArray, dateStart, dateTo) => {
    let preDate = new Date(dateStart).getTime();
    // console.log("preDate : ", new Date(dateStart))
    let postDate = new Date(dateTo).getTime();
    // console.log("postDate : ", new Date(dateTo))
    let filterData;
    if(preDate && postDate) {
      filterData = dataArray.filter(function(data){
        return new Date(data.dateTimeStart).getTime() >= preDate && new Date(data.dateTimeStart).getTime() <= postDate
      });
    } else if(preDate && !postDate) {
      filterData = dataArray.filter(function(data){
        return new Date(data.dateTimeStart).getTime() >= preDate
      });
    } else if(!preDate && postDate) {
      filterData = dataArray.filter(function(data){
        return new Date(data.dateTimeStart).getTime() <= postDate
      });
    } else {
      filterData = dataArray;
    }
    // console.log("filterData : ", filterData);
    return filterData;
  }

  useEffect(() => {
    fetch(`${url}/tsm/api/v1/screenpop/`)
      .then((res) => res.json())
      .then((data) => {
        return data.data;
      })
      .then(res => selectedFromName? res.filter((data) => data.from==selectedFromName) : res)
      .then(res => selectedToName? res.filter((data) => data.to==selectedToName) : res)
      .then(res => selectedExt? res.filter((data) => data.ext==selectedExt) : res)
      .then(res => selectedPhone? res.filter((data) => data.phone==selectedPhone) : res)
      .then(res => fromDate? filteredDataFromDateRange(res, fromDate, toDate) : res)
      .then((data) => {
        set_tbList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [url, selectedFromName, selectedToName, selectedExt, selectedPhone, fromDate, toDate]);

  return (
    // <Grid container spacing={1} className={classes.whole}>
    <Container maxWidth="xl" className={classes.container}>
      <PageHeader
        title={t("screenpop")}
        subtitle={t("screenpop_of_troubleshooting")}
        buttonName={t("create")}
        url={url}
        toSetTrigger={() => setTrigger(!trigger)}
        // onClickButton={()=> history.push('/config/users/create')}
        // onClickUpload={() => handleOpen()}
        // onClickDown={() => handleDownLoad()}
      />
      {/* <DropzoneDialog
                open={open}
                onSave={(files) => handleSave(files)}
                acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}                showPreviews={true}
                maxFileSize={5000000}
                onClose={handleClose}
            /> */}

      {/* Search Box Row 1 */}
      <Grid container className="fixed" spacing={2} xs={14}>
        {/* <div>{`value: ${toDate !== null ? `'${new Date(toDate)}'` : "null"}`}</div> */}
        {/* {console.log("Date : ", new Date(fromDate))} */}

        {/* Search from name */}
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            freeSolo
            value={selectedFromName}
            onChange={(e, newVal) =>
              handleOnChange(setSelectedFromName, newVal)
            }
            options={toFromName? toFromName : []}
            renderOption={(from) => from}
            getOptionLabel={(from) => from}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("screenpop_search_from")}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {/* Search to name */}
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            freeSolo
            value={selectedToName}
            onChange={(e, newVal) => handleOnChange(setSelectedToName, newVal)}
            options={toToName? toToName : []}
            renderOption={(to) => to}
            getOptionLabel={(to) => to}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("screenpop_search_to")}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {/* Search ext */}
        <Grid item xs={4}>
          <Autocomplete
            freeSolo
            size="small"
            value={selectedExt}
            onChange={(e, newVal) => handleOnChange(setSelectedExt, newVal)}
            options={toExt? toExt : []}
            renderOption={(ext) => ext}
            getOptionLabel={(ext) => ext}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("screenpop_search_ext")}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      {/* End Row 1 */}

      {/* Search Box Row 2 */}
      <Grid container className="mb-4" spacing={2} xs={14}>
        {/* Search phone */}
        <Grid item xs={4} className="mt-2">
          <Autocomplete
            freeSolo
            size="small"
            value={selectedPhone}
            onChange={(e, newVal) => handleOnChange(setSelectedPhone, newVal)}
            options={toPhone? toPhone : []}
            renderOption={(phone) => phone}
            getOptionLabel={(phone) => phone}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("screenpop_search_phone")}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {/* From Date */}
        <Grid item xs={4}>
          <Controller
            name="fromDate"
            control={control}
            defaultValue={fromDate}
            onChange={([, data]) => data}
            render={() => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker
                label={t("screenpop_search_from_date")}
                fullWidth
                ampm={false}
                margin="dense"
                variant="inline"
                inputVariant="outlined"
                error={false}
                helperText={null}
                format="DD/MM/YYYY k:mm"
                KeyboardButtonProps={keyboardButtonProps}
                InputAdornmentProps={{ position: "end" }}
                inputValue={fromDate? inputValueFromDate : null}
                rifmFormatter={dateFormatter}
                value={fromDate? new Date(fromDate) : null}
                onChange={onFromDateChange}
              />
              </MuiPickersUtilsProvider>
            )}
          />
        </Grid>

        {/* To Date */}
        <Grid item xs={4}>
          <Controller
            name="toDate"
            control={control}
            defaultValue={toDate}
            onChange={([, data]) => data}
            render={() => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker
                label={t("screenpop_search_to_date")}
                fullWidth
                ampm={false}
                margin="dense"
                variant="inline"
                inputVariant="outlined"
                error={false}
                helperText={null}
                format="DD/MM/YYYY k:mm"
                KeyboardButtonProps={keyboardButtonProps}
                InputAdornmentProps={{ position: "end" }}
                inputValue={toDate? inputValueToDate : null}
                rifmFormatter={dateFormatter}
                value={toDate? new Date(toDate) : null}
                onChange={onToDateChange}
              />
              </MuiPickersUtilsProvider>
            )}
          />
        </Grid>
      </Grid>
      {/* End Row 2 */}

      <Divider />

      {/* Data Table */}
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.top}>
          <div style={{ width: "auto", overflowX: "scroll" }}>
            <Table className={classes.table}>
              {/* Header */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>{t("screenpop_from")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("screenpop_to")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("screenpop_ext")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("screenpop_phone")} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("screenpop_csq")} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("screenpop_datetime")} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("Actions")} </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Body */}
              <TableBody>
                {tbList &&
                  tbList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((val) => (
                      <TableRow key={val._id}>
                        <TableCell>
                          <Typography>{val.from}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{val.to}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{val.ext}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{val.phone}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{val.csq}</Typography>
                        </TableCell>
                        <TableCell format="DD/MM/YYYY HH:mm">
                          <Typography>
                            {moment(new Date(val.dateTimeStart)).format(
                              "DD/MM/YYYY k:mm"
                            )}
                          </Typography>
                        </TableCell>

                        {/* Call, Edit, Delete */}
                        <TableCell>
                          <Calling
                            call_ext={val.ext}
                            call_phone={val.phone}
                            call_csq={val.csq}
                            call_dateTimeStart={val.dateTimeStart}
                            toSetTrigger={() => setTrigger(!trigger)}
                          />

                          <Edit
                            url={url}
                            edit_id={val._id}
                            edit_from={val.from}
                            edit_to={val.to}
                            edit_ext={val.ext}
                            edit_phone={val.phone}
                            edit_csq={val.csq}
                            toSetTrigger={() => setTrigger(!trigger)}
                          />

                          {/* <Delete url={url} delete_id={val._id} toSetTrigger={() => setTrigger(!trigger)}/> */}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
              {(tbList) && (
                <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={tbList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
              )}
            </Table>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Users;
