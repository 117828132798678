/* eslint-disable no-dupe-keys */
import axios from 'axios';
import url from 'url';

const ELASTIC_SEATCH_PERSON_URL = url.resolve(process.env.REACT_APP_ELASTIC_SEARCH_FQDN, '/elasticsearch/cream/person/_search');
const GET_SOME_CONTACT_URL = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person');
const GET_ONE_CONTACT_URL = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person');
const GET_ONE_BY_USERNAME_URL = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person/FindOneByUsername');
const POST_CREATE_CONTACT_URL = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person');
const PUT_UPDATE_CONTACT_URL = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person');
const GET_ALL_AGENTS = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person/GetAllAgents');
const GET_ALL_ACTIVE_AGENTS = url.resolve(process.env.REACT_APP_CONTACT_BACKEND_FQDN, '/api/contacts/Person/GetAllActiveAgents');

export const searchContacts = (keyword = '') => {
  return axios({
    url: ELASTIC_SEATCH_PERSON_URL,
    method: 'POST',
    data: {
      query: {
        filtered: {
          ...(keyword && {
            query: {
              query_string: {
                query: `*${keyword}*`,
                fields: [
                  'name',
                  'phone',
                  'email',
                ],
              },
            },
          }),
        },
      },
    },
  });
};

export const getContacts = (qs = '') => {
  return axios({
    url: `${GET_SOME_CONTACT_URL}?${qs}`,
    method: 'GET',
  });
};

export const getContact = (contactId) => {
  return axios({
    url: `${GET_ONE_CONTACT_URL}/${contactId}`,
    method: 'GET',
  });
};

export const getContactByUsername = (username) => {
  return axios({
    url: `${GET_ONE_BY_USERNAME_URL}?username=${username}`,
    method: 'GET',
  });
};

export const createContact = (payload) => {
  return axios({
    url: POST_CREATE_CONTACT_URL,
    method: 'POST',
    data: payload,
  });
};

const resolvePersonCustomData = (contactObj) => {
  // Convert array of serial number into a string
  if (contactObj.CustomData && contactObj.CustomData.serialNos) {
    if (Array.isArray(contactObj.CustomData.serialNos)) {
      const commaSeparated = contactObj.CustomData.serialNos.join();
      contactObj.CustomData.serialNos = commaSeparated;
    }
  }
  return contactObj;
};

export const updateContact = (contactId, payload) => {
  payload = resolvePersonCustomData(payload);
  return axios({
    url: `${PUT_UPDATE_CONTACT_URL}/${contactId}`,
    method: 'PUT',
    data: payload,
  });
};

export const getAllAgents = () => {
  return axios({
    url: GET_ALL_AGENTS,
    method: 'GET',
    timeout: 3000,
  });
};

export const getAllActiveAgents = () => {
  return axios({
    url: GET_ALL_ACTIVE_AGENTS,
    method: 'GET',
    timeout: 3000,
  });
};

export const searchPatientsByName = (keyword = '') => {
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('-', ' ');
  keyword = keyword.replace('-', ' ');
  return axios({
    url: ELASTIC_SEATCH_PERSON_URL,
    method: 'POST',
    data: {
      query: {
        filtered: {
          query: {
            query_string: {
              fields: ['name'],
              query: `*${keyword}*`,
            },
          },
          filter: {
            term: {
              'CustomData.contactType': 'patient',
            },
          },
        },
      },
    },
  });
};

// NOTE: Avoid sending ** with empty keyword to ElasticSearch
export const searchPatientsByPidFirstNameLastName = (pid = '', firstName = '', lastName = '') => {
  pid = pid.trim();
  firstName = firstName.replace('.', ' ');
  firstName = firstName.replace('.', ' ');
  firstName = firstName.replace('-', ' ');
  firstName = firstName.replace('-', ' ');
  lastName = lastName.replace('.', ' ');
  lastName = lastName.replace('.', ' ');
  lastName = lastName.replace('-', ' ');
  lastName = lastName.replace('-', ' ');

  const reqBody = {
    size: 150,
    query: {
      bool: {
        must: [
          {
            match: {
              isActive:
              true,
            },
          },
          {
            terms: {
              'CustomData.contactType': ['patient'],
            },
          },
        ],
      },
    },
  };

  if (pid) {
    reqBody.query.bool.must.push({
      query_string: { fields: ['CustomData.pid'], query: `*${pid}*` },
    });
  }
  if (firstName) {
    reqBody.query.bool.must.push({
      query_string: { fields: ['firstName'], query: `*${firstName}*` },
    });
  }
  if (lastName) {
    reqBody.query.bool.must.push({
      query_string: { fields: ['lastName'], query: `*${lastName}*` },
    });
  }

  return axios({
    url: ELASTIC_SEATCH_PERSON_URL,
    method: 'POST',
    data: reqBody,
  });
};

export const searchCallers = (keyword = '', parentId) => {
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('-', ' ');
  keyword = keyword.replace('-', ' ');

  const reqBody = {
    size: 30,
    sort: [
      {
        'CustomData.order': {
          order: 'asc',
        },
      },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              isActive: true,
            },
          },
        ],
      },
    },
  };

  if (keyword) {
    reqBody.query.bool.must.push({
      query_string: {
        fields: ['name'],
        query: `*${keyword}*`,
      },
    });
  }

  if (parentId) {
    reqBody.query.bool.must.push({
      term: {
        parentId,
      },
    });
  }

  return axios({
    url: ELASTIC_SEATCH_PERSON_URL,
    method: 'POST',
    data: reqBody,
  });
};

export const searchContactPoints = (keyword = '', province) => {
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('.', ' ');
  keyword = keyword.replace('-', ' ');
  keyword = keyword.replace('-', ' ');

  const reqBody = {
    size: 150,
    query: {
      bool: {
        must: [
          {
            match: {
              isActive: true,
            },
          },
          {
            terms: {
              'CustomData.contactType': [
                'contact',
                'domestic',
                'account',
                'internal',
                'สถานีตำรวจ',
                'สถานศึกษา',
                'คลินิก',
                'สถานฑูต',
                'บริษัท',
                'หน่วยงานราชการ',
                'โรงงาน',
                'โรงแรม',
                'ที่พัก',
              ],
            },
          },
          {
            query_string: {
              fields: ['name'],
              query: `*${keyword}*`,
            },
          },
        ],
      },
    },
  };

  if (province) {
    reqBody.query.bool.must.push({
      match_phrase: {
        'CustomData.addrProvince': province,
      },
    });
  }

  return axios({
    url: ELASTIC_SEATCH_PERSON_URL,
    method: 'POST',
    data: reqBody,
  });
};

export const findCallersByParentId = (parentId) => {
  return axios({
    url: ELASTIC_SEATCH_PERSON_URL,
    method: 'POST',
    data: {
      size: 500,
      sort: [
        {
          'CustomData.order': {
            order: 'asc',
          },
        },
      ],
      query: {
        bool: {
          must: [
            {
              match: {
                isActive: true,
              },
            },
            {
              term: {
                parentId,
              },
            },
          ],
        },
      },
    },
  });
};

export const getContactsByPID = (pid) => {
  return axios({
    url: `${ELASTIC_SEATCH_PERSON_URL}?size=5`,
    method: 'POST',
    data: {
      query: {
        match: {
          pid,
        },
      },
    },
  });
};

export const getContactsByNameAndParentId = (name, parentId) => {
  return axios({
    url: `${ELASTIC_SEATCH_PERSON_URL}?size=200`,
    method: 'POST',
    data: {
      query: {
        bool: {
          must: {
            term: {
              name,
            },
          },
          must: {
            match: {
              parentId,
            },
          },
        },
      },
    },
  });
};

export const getUnidentityPatient = () => {
  return axios({
    url: `${ELASTIC_SEATCH_PERSON_URL}?size=1`,
    method: 'POST',
    data: {
      query: {
        match: {
          'CustomData.code': 99999999,
        },
      },
    },
  });
};
