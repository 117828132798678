import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import * as yup from 'yup';
import 'yup-phone';
import { useStyles } from '../components/styles';
import { Title, Subtitle } from '../components/Title';
import CreateButton from '../components/CreateButton';
import CallerDialog from "../components/CallerDialog";

export const splitSpace = (data) => {
  let string = data.split(" ");
  let stringArray = [];
  for(let i = 0; i < string.length; i++){
    if(string[i] !== "") {
      stringArray.push(string[i]);
    }
  };
  return stringArray;
};

export const surName = (data) => {
  let arr = splitSpace(data).reverse().slice(0, -1).reverse();
  return arr.join(" ");
}

const CallerHeader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  /* Set up a default of Contact object */
  const defaultCallerData = {
    name: '',
    phone: [],
    email: [],
    CustomData: {
      contactType: '',
      phoneText: '',
      note: '',
      order: '',
      defaultFlag: 0,
    },
    parentId: '',
    position: 'Physicians',
  }
  const [callerData, setCallerData] = useState(defaultCallerData);

  /* Default Flag on Caller dialog */
  const [checkedDefaultFlag, setCheckedDefaultFlag] = useState(false);

  /* Default Flag onchange on Caller dialog */
  const checkedDefaultFlagHandleChange = (event) => {
    setCheckedDefaultFlag(event.target.checked);
  };

  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    });
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <div className={classes.root}>
      <Grid alignItems="flex-start" container justifyContent="space-between" spacing={3}>
        <Title title={props.title}/>
        <Grid item className={classes.gridButton}>
          <CreateButton buttonName={props.buttonName} handleClickOpen={handleClickOpen}/>
        </Grid>
      </Grid>
      <Subtitle subtitle={props.subtitle}/>

      {props.buttonName && props.contactPage === 'caller' && (
        <CallerDialog
          contactPage={'caller'}
          submit={'create'}
          open={open}
          onCancel={handleClose}
          callerData={callerData}
          onChangeCallerData={setCallerData}
          triggerToRefresh={props.triggerToRefresh}
          defaultCallerData={defaultCallerData}
          checkedDefaultFlag={checkedDefaultFlag}
          checkedDefaultFlagHandleChange={checkedDefaultFlagHandleChange}
        />
      )}
      
    </div>
  )
}

CallerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  onClickButton: PropTypes.func,
};

CallerHeader.defaultProps = {
  buttonName: undefined,
  onClickButton: undefined,
};

export default CallerHeader;