import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {Grid, Card, Box,  Button,  TextField, Typography, FormControl, MenuItem, Select, List} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useForm, Controller, appendErrors} from "react-hook-form";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { KeyboardDateTimePicker, validate } from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "18%",
        maxWidth: "20%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    errorMsg : {
        color: "#f44336",
        marginTop: "1%",
        marginLeft: "1%"
    }
}))

const keyboardButtonProps = { style: { marginRight: -14 } };

const AnnounceCreate = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const { register, handleSubmit, control, errors, getValues} = useForm();
    const [startDate, setStartDate] = React.useState();
    const [expiryDate, setExpiryDate] = React.useState();
    const [teamList, set_teamList] = React.useState([]);
    const [roleList, set_roleList] = React.useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const [radioValue, setRadioValue] = React.useState("");
    const [groupList, setGroupList] = React.useState([]);

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };

    const handleExpiryDate = (e) => {
      setExpiryDate(e.target.value);
    };

    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`)
            .then(res => res.json())
            .then((data) => {
              set_teamList(data.data)
            })
    } , [])

    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups`)
            .then(res => res.json())
            .then((data) => {
                setGroupList(data.data)
            })
    } , [])

    const onSubmit = (data,e) => { 

        console.log(data)
        var bodyData = data
        bodyData.audienceType = radioValue

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements`,{
          method: 'POST', 
          headers: myHeaders,
          body: JSON.stringify(bodyData),
        })
        .then(response => {
          if  (response.status >= 200 && response.status <=299 ){history.push('/config')
            return enqueueSnackbar(t('Announcement has been created.'), { variant: 'success' });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar(t('Announcement has fail to create.'), { variant: 'error' })
        })          

    }




    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("Manage Announcement")}
                subtitle={t("Configuration for announcement")}
            />  
            <Grid item xs={12} className={classes.top}>
                <Card className={classes.card}>
                    <form onSubmit={handleSubmit(onSubmit)}  className={classes.form_} autoComplete="off">
                        <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}>{t('Announcement name *')} : </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth 
                                    inputRef={register({ required: true})} 
                                    name="name" 
                                    variant="outlined"
                                    >
                                </TextField>
                                {errors.name && (
                                    <span className={classes.errorMsg}> {t('Name field is required')}</span>
                                )}
                            </div>
                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Description *')} : </Typography>
                            <div className={classes.parallel} >
                                <TextField 
                                    fullWidth
                                    inputRef={register({required: true})} 
                                    name="message" 
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                >
                                </TextField>
                                {errors.message && (
                                    <span className={classes.errorMsg}>{t('Description field is required')}</span>
                                )}
                            </div>
                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Announcement for *')} : </Typography>
                            <RadioGroup
                                row
                                value={radioValue} 
                                onChange={handleRadioChange} 
                            >
                                <FormControlLabel 
                                    label="Group" 
                                    value="group" 
                                    control={
                                        <Radio />
                                    } 
                                />
                                <FormControlLabel 
                                    label="Team"
                                    value="team" 
                                    control={
                                        <Radio />
                                    }
                                />
                            </RadioGroup>
                       </Box>
                       
                       {radioValue == 'team' && (
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}>{t('Team *')} : </Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                        name="audienceId"
                                        control={control}
                                        defaultValue=""
                                        as={
                                            <Select>
                                                <MenuItem value={0}>Everyone</MenuItem>
                                                {teamList.map((teamList) => (
                                                    <MenuItem value={teamList._id}>{teamList.name}</MenuItem>
                                                ))}
                                            </Select>
                                        }
                                    />                          
                                </FormControl>
                            </Box>
                       )}

                        {radioValue == 'group' && (
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}>{t('Group *')} : </Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                        name="audienceId"
                                        control={control}
                                        defaultValue=""
                                        as={
                                            <Select>
                                                {groupList.map((groupList) => (
                                                    <MenuItem value={groupList._id}>{groupList.name}</MenuItem>
                                                ))}
                                            </Select>
                                        }
                                    />                          
                                </FormControl>
                            </Box>
                        )}

                       <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Starting date')} : </Typography>
                            <FormControl className={classes.parallel}>
                                <Controller
                                    defaultValue={null}
                                    className={classes.textField}
                                    name="effectiveOn"
                                    control={control}
                                    rules={{
                                        validate: () => {
                                            return getValues("expiresOn") > getValues("effectiveOn") && 
                                            getValues("expiresOn") !== getValues("effectiveOn") && 
                                            getValues("effectiveOn") !== new Date ()
                                        }
                                    }}
                                    onChange={([, data]) => data}
                                    as={
                                    <KeyboardDateTimePicker
                                        ampm={false}
                                        minutesStep={process.env.REACT_APP_BUILD_TARGET === 'RAMA' ? 5 : 1}
                                        margin="dense"
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="DD/MM/YYYY HH:mm"
                                        KeyboardButtonProps={keyboardButtonProps}
                                        InputAdornmentProps={{ position: 'end' }}
                                    />
                                    }
                                />
                                {errors.effectiveOn && (
                                    <span className={classes.errorMsg}>{t('Please enter valid date and time')}</span>
                                )}  
                            </FormControl>                       
                        </Box> 


                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>{t('Expiration date')} : </Typography>
                            <FormControl className={classes.parallel}>
                                <Controller
                                    defaultValue={null}
                                    className={classes.textField}
                                    name="expiresOn"
                                    control={control}
                                    rules={{
                                        validate: () =>{
                                            return getValues("expiresOn") > getValues("effectiveOn") && 
                                            getValues("expiresOn") !== getValues("effectiveOn") && 
                                            getValues("expiresOn") !== new Date ()
                                        }
                                    }}
                                    onChange={([, data]) => data}
                                    as={
                                    <KeyboardDateTimePicker
                                        ampm={false}
                                        minutesStep={process.env.REACT_APP_BUILD_TARGET === 'RAMA' ? 5 : 1}
                                        margin="dense"
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="DD/MM/YYYY HH:mm"
                                        KeyboardButtonProps={keyboardButtonProps}
                                        InputAdornmentProps={{ position: 'end' }}
                                    />
                                    }
                                />
                                {errors.expiresOn && (
                                    <span className={classes.errorMsg}>{t('Please enter valid date and time')}</span>
                                )}  
                            </FormControl>
                        </Box>  


                        <Box className={classes.btn_}>
                            <Button type="submit"  color="secondary"  variant="contained">{t('Create')}</Button>
                            <Button color="secondary" variant="contained" onClick={() => history.push('/config')}>{t('Cancel')}</Button>
                        </Box>
                    </form>
                </Card>
            </Grid>
            </Container>
           
    )
}
export default AnnounceCreate;
