import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: '0.50rem !important',
    marginBottom: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  fieldLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '24px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
  fieldSelectionLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '16px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
}));

function SubstanceCategories(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [substance] = useState('Medical Drugs');
  const [substanceArray, setSubstanceArray] = useState([{_id: 1, name: 1}]);

  const fetchSubstanceCategories = () => {
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=2`)
      .then(res => res.json())
      .then((data) => {
        setSubstanceArray(data.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    fetchSubstanceCategories()
  }, [])

  return (
    <div>
      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={12} md={1} className={classes.fieldSelectionLabel}>{t('Substance Categories')}</Grid>
        <Grid item xs={12} md={5} lg={4} className={classes.fieldControl}>
          <Controller
            name="substanceCatetory"
            rules={{ required: true }}
            control={props.control}
            defaultValue={substance}
            render={({ onChange, value, name }) => (
              <Select
                className={classes.select}
                variant="outlined"
                value={value ? value : 'None'}
                name={name}
                margin="dense"
                fullWidth
                onChange={(e) => {
                  onChange(e.target.value)
                }}
              >
                {substanceArray && substanceArray.map((item) =>
                  <MenuItem key={item._id} value={item.name}>{item.name}</MenuItem>
                )}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default SubstanceCategories



            