import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import { Typography, Table, TableHead, TableBody, TableCell, TableRow, IconButton, Box, List, Button, Grid } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },

}))

const Announce = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [announceGroups, setAnnounceGroups] = React.useState([]);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [announceGroupId, setAnnounceGroupId] = React.useState();
    const [agents, setAgents] = React.useState([]);


    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
            .then(res => res.json())
            .then((data) => {
                setAgents(data)
            })
    }, [])


    React.useEffect(() => {

        let url = `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups`;
        let config = {
            method: 'GET',
            timeout: 1000 * 3,
            headers: {
                "Content-Type": "application/json"
            },
        };

        axios( url, config )
            .then(response => {
                if (response.status == 200) {
                    setAnnounceGroups(response.data.data)
                }
            })
            .catch(err => {
                enqueueSnackbar(t('Fail to load emails.'), { variant: 'error' });
                console.log(err.message)
            });

    }, [])

    const handleDeleteDialog = (deleteId) => {
        setDeleteOpen(true)
        setAnnounceGroupId(deleteId)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }

    const handleDelete = () => {
        setDeleteOpen(false)

        
        let url = `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups/${announceGroupId}`;
        let config = {
            method: 'DELETE',
            timeout: 1000 * 3,
            headers: {
                "Content-Type": "application/json"
            },
        };

        axios( url, config )
            .then(response => {
                if (response.status >= 200 && response.status  <= 299) {
                    history.push('/config')
                    enqueueSnackbar(t('Successfully deleted announcement group'), { variant: 'success' });
                    
                    let url = `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups`;
                    let config = {
                        method: 'GET',
                        timeout: 1000 * 3,
                        headers: {
                            "Content-Type": "application/json"
                        },
                    };
            
                    axios( url, config )
                        .then(response => {
                            if (response.status == 200) {
                                setAnnounceGroups(response.data.data)
                            }
                        })

                }
            })
            .catch(err => {
                enqueueSnackbar(t('Fail to delete announcement group.'), { variant: 'error' });
                console.log(err.message)
            });    

    }



    return (
   
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader 
                    title= {t("Config Announcement Group ")}
                    subtitle= {t("Configuration for groups in announcement")}
                    buttonName=  {t("New Announcement Group")}
                    onClickButton={()=> history.push('/config/announceGroup/create')}
                />

                <Grid item xs={12} className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="body2"> {t('Announce Group Name')}</Typography></TableCell>
                                <TableCell><Typography variant="body2"> {t('Announce Group Description')}</Typography></TableCell>
                                <TableCell><Typography variant="body2"> {t('Total agents')}</Typography></TableCell>
                                <TableCell><Typography variant="body2"> {t('Actions')}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {announceGroups.map((announceGroup) => (
                            <React.Fragment key={announceGroup._id}>
                                <TableRow>
                                    <TableCell>
                                        <Typography>{announceGroup.name}</Typography>
                                    </TableCell>         
                                    <TableCell>
                                        <Typography>{announceGroup.desc}</Typography>
                                    </TableCell>  
                                    <TableCell>
                                        <Typography>{announceGroup.agentIDs.length}</Typography>
                                    </TableCell>              
                                    <TableCell>
                                        <IconButton onClick={() => history.push({pathname: '/config/announceGroup/edit', state: {announceGroupId: announceGroup._id, agents: agents}})}>  
                                            <EditOutlinedIcon color="primary"/>
                                        </IconButton> 
                                        <IconButton onClick={() => handleDeleteDialog(announceGroup._id)}>
                                            <DeleteForeverOutlinedIcon color="primary"  />
                                        </IconButton>
                                    </TableCell> 
                                </TableRow>
                            </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>


                    {/* Delete confirmation dialog */}
                    <Dialog
                        open={deleteOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                    >
                        <DialogTitle>{t('Are you sure you want to delete the Announcement Group?')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('This will permanently delete the Announcement Group.')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} color="secondary">
                                {t('Announce Group Cancel')}
                            </Button>
                            <Button onClick={handleDelete} color="primary">
                                {t('Announce Group Delete')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>             
                
            </Container>
    )
}
export default Announce;
