import axios from 'axios';
import url from 'url';

const GET_ALL_UNIQUE_CSQ_NAMES = url.resolve(process.env.REACT_APP_RM_BACKEND_FQDN, '/rm/api/v1/uniques/csq-names');
const GET_ALL_UNIQUE_AGENT_NAMES = url.resolve(process.env.REACT_APP_RM_BACKEND_FQDN, '/rm/api/v1/uniques/agent-names');
const GET_ALL_UNIQUE_TEAM_NAMES = url.resolve(process.env.REACT_APP_RM_BACKEND_FQDN, '/rm/api/v1/uniques/team-names');

export const getUniqueArrayOfCsqNameFromIVR = () => {
  return axios({
    url: GET_ALL_UNIQUE_CSQ_NAMES,
    method: 'GET',
  });
};

export const getUniqueArrayOfAgentNameFromIVR = () => {
  return axios({
    url: GET_ALL_UNIQUE_AGENT_NAMES,
    method: 'GET',
  });
};

export const getUniqueArrayOfTeamNameFromIVR = () => {
  return axios({
    url: GET_ALL_UNIQUE_TEAM_NAMES,
    method: 'GET',
  });
};

export const getCallbackDetailsReportTemplate = (template) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/static/jsreports/callback-detail-report/${template}/content.handlebars`,
    method: 'GET',
  });
};

export const getCallbackDetailsReportData = (fromDate, toDate, csqName) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/api/v1/reports/callback-detail-data`,
    method: 'GET',
    params: {
      ...(fromDate && { 'timestamp>': fromDate.toISOString() }),
      ...(toDate && { 'timestamp<': toDate.toISOString() }),
      ...(csqName && { csq_name: csqName }),
    },
  });
};

export const getIvrMenuSummaryReportTemplate = (template) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/static/jsreports/ivr-menu-summary-report/${template}/content.handlebars`,
    method: 'GET',
  });
};

export const getIvrMenuSummaryReportData = (fromDate, toDate, csqName) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/api/v1/reports/ivr-menu-summary-data`,
    method: 'GET',
    params: {
      ...(fromDate && { 'timestamp>': fromDate.toISOString() }),
      ...(toDate && { 'timestamp<': toDate.toISOString() }),
      ...(csqName && { csq_name: csqName }),
    },
  });
};

export const getSurveyCsqSummaryReportTemplate = (template) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/static/jsreports/survey-csq-summary-report/${template}/content.handlebars`,
    method: 'GET',
  });
};

export const getSurveyCsqSummaryReportData = (fromDate, toDate, csqName, agentName, teamName) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/api/v1/reports/survey-csq-summary-data`,
    method: 'GET',
    params: {
      ...(fromDate && { 'timestamp>': fromDate.toISOString() }),
      ...(toDate && { 'timestamp<': toDate.toISOString() }),
      ...(csqName && { csq_name: csqName }),
      ...(agentName && { agent_name: agentName }),
      ...(teamName && { team_name: teamName }),
    },
  });
};

export const getSurveyAgentSummaryReportTemplate = (template) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/static/jsreports/survey-agent-summary-report/${template}/content.handlebars`,
    method: 'GET',
  });
};

export const getSurveyAgentSummaryReportData = (fromDate, toDate, csqName, agentName, teamName) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/api/v1/reports/survey-agent-summary-data`,
    method: 'GET',
    params: {
      ...(fromDate && { 'timestamp>': fromDate.toISOString() }),
      ...(toDate && { 'timestamp<': toDate.toISOString() }),
      ...(csqName && { csq_name: csqName }),
      ...(agentName && { agent_name: agentName }),
      ...(teamName && { team_name: teamName }),
    },
  });
};

export const getTicketIncidentReportTemplate = (template) => {
  return axios({
    url: `${process.env.REACT_APP_RM_BACKEND_FQDN}/rm/static/jsreports/ticket-incident-report/${template}/content.handlebars`,
    method: 'GET',
  });
};