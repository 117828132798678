import React from "react";
import Block from "./Block";
import Header from "./Header";
import {  Box, Divider, Button, Grid } from '@material-ui/core';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';

export const BLOCK_TYPES = [
	{ id: "1", label: " “ ” ", style: "blockquote" },
	{ id: "2",label: <FormatListBulletedOutlinedIcon/>, style: "unordered-list-item" },
	{ id: "3",label: <FormatListNumberedOutlinedIcon/>, style: "ordered-list-item" },
	{id: "4",label: "{ }", style: "code-block" }
];

export const HEADER_TYPES = [
	{ label: "(None)", style: "unstyled" },
	{ label: "H1", style: "header-one" },
	{ label: "H2", style: "header-two" },
	{ label: "H3", style: "header-three" },
	{ label: "H4", style: "header-four" },
	{ label: "H5", style: "header-five" },
	{ label: "H6", style: "header-six" }
];

export function getBlockStyle(block) {
	switch (block.getType()) {
		case "blockquote":
			return "RichEditor-blockquote";
		default:
			return null;
	}
}

class BlockStyleToolbar extends React.Component {
	render() { 
		const { editorState } = this.props;
		const selection = editorState.getSelection();
		const blockType = editorState
			.getCurrentContent()
			.getBlockForKey(selection.getStartKey())
			.getType();

		return (
			<Box className="RichEditor-controls" display="flex" flexDirection="row">
				{BLOCK_TYPES.map(type => {
					return (
						<React.Fragment key={type.id}>
                            <Grid container >
                            {/* <List> */}
                                <Button >
                                    <Block
                                        blockOptions= {BLOCK_TYPES}
                                        active={type.style === blockType}
                                        label={type.label}
                                        onToggle={this.props.onToggle}
                                        style={type.style}
                                        key={type.label}
                                        type={type}
                                    /> 
                                </Button>
                                {/* </List> */}
                                <Divider orientation="vertical" flexItem/>
                            </Grid>
							</React.Fragment>
						
					);
				})}
                <Header
					headerOptions={HEADER_TYPES}
					active={this.blockType}
					onToggle={this.props.onToggle}
				/>
			</Box>
		);
	}
}

export default BlockStyleToolbar;
