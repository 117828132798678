import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import FieldError from '../../../../TM/src/components/FieldError';
import FieldHelper from '../../../../TM/src/components/FieldHelper';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHelmet from '../../components/PageHelmet';
import LoadingScreen from '../../components/LoadingScreen';
import * as yup from 'yup';
import moment from 'moment';
import queryString from 'query-string';
import { get } from 'dot-prop';
import { logger } from '../../common';
import { ReportAPI, JsReportAPI } from '../../apis';
import AutocompleteIvrTeam from '../../components/AutocompleteIvrTeam';



// Set the report url for this component here.
const THE_JASPER_REPORT_URL = process.env.REACT_APP_JASPER_REPORT_FQDN + '/jasperserver/rest_v2/reports/cream/Ticket_Summary_Stats_Reports';

// Intial material-ui overriding styles.
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  reportHead: {
    fontSize: '1.15rem',
    paddingBottom: '1.15rem',
  },
  formRow: {
    marginTop: '0.50rem !important',
    marginBottom: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  fieldLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '24px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
  footerDivider: {
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.25rem',
    },
  },
  previewIframe: {
    height: 640,
    width: '100%',
  },
  select: {
    marginTop: 5,
    marginBottom: 5,
  }
}));

// Fix material ui date picker icon too much space.
const keyboardButtonProps = { style: { marginRight: -14 } };

const TicketSummaryStatsReport = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [preparing, setPreparing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [agents, setAgents] = React.useState([]);
  const [roleName, setRoleName] = React.useState();
  const [teamName, setTeamName] = React.useState();
  const [teamNameAll, setTeamNameAll] = React.useState([]);
  const [enableAgentFilter, setEnableAgentFilter] = React.useState(true);
  const [selectedTeam, setSelectedTeam] = React.useState('all');


  // fetching the agents 
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
      .then((response) => response.json())
      .then((data) => {
        console.log(" all contacts data", data)
        setAgents(data)
      })
  }, [])


    // fetching the TeamAll
    React.useEffect(() => {
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/admin/api/v1/teams`)
        .then((response) => response.json())
        .then((body) => {
          // console.log(" all Team", body);
          console.log(" all Team data", body.data);
          setTeamNameAll(body.data)
        })
    }, [])

  // handle the selected agent 
  const handleAgent = (e) => {
    console.log("get from handle", e.target.value)
    //getRoleName(e.target.value)
    //getTeamName(e.target.value)
  }

  const handleTeamName = (e) => {
    console.log("get from handle", e.target.value)
    //getRoleName(e.target.value)
    getTeamNameAll(e.target.value)
  }

  // get role name since agent obj come with roleId only
  const getRoleName = (agentObj) => {

    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data)
        data.data.map((val) => {
          if (val._id == agentObj.roleId) {
            setRoleName(val.name)
          }
        })
      })
      .catch((error) => console.log(error))
  }


  // get team name since agent obj come with teamId only
  const getTeamName = (agentObj) => {

    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`)
      .then((response) => response.json())
      .then((data) => {
        data.data.map((val) => {
          if (val._id == agentObj.team) {
            // console.log(val.name)
            setTeamName(val.name)
          }

        })
      })
      .catch((error) => console.log(error))
  }

 // get team name since agent obj come with All
 const getTeamNameAll = (agentObj) => {

  fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`)
      .then((response) => response.json())
      .then((data) => {
        data.data.map((val) => {
          if (val._id == agentObj.team) {
            // console.log(val.name)
            setTeamName(val.name)
          }

        })
      })
      .catch((error) => console.log(error))
}


  // React hook form schema.
  const yupSchema = yup.object().shape({
    fromDate: yup.date().required(),
    toDate: yup.date().required(),
  });

  // React hook form initialize.
  const { handleSubmit, control, errors } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(yupSchema),
  });

  // Concat & return report sub title.
  const getReportSubtitle = (fromDate, toDate, isFile) => {
    if (!fromDate || !toDate) return '';
    const text = isFile ? 'D MMM YYYY' : 'D MMM YYYY HH:mm';
    return `${moment(fromDate).format(text)} - ${moment(toDate).format(text)}`;
  };

  // Concat & return report file name.
  const getReportFileName = (fromDate, toDate, fileExt) => {
    console.log(fromDate, toDate, fileExt);
    const title = t('ticket_summary_stats_report_name');
    const subtitle = getReportSubtitle(fromDate, toDate, true);
    return `${title} ${subtitle}.${fileExt}`;
  };

  // Handle click preview button event.
  const handleClickPreviewButton = () => {
    const callback = (data) => {
      data.template = 'preview';
      data.fileExt = 'html';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickExcelButton = () => {
    const callback = (data) => {
      data.template = 'excel';
      data.fileExt = 'xlsx';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click pdf button event.
  const handleClickPdfButton = () => {
    const callback = (data) => {
      data.template = 'pdf';
      data.fileExt = 'pdf';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickWordButton = () => {
    const callback = (data) => {
      data.template = 'word';
      data.fileExt = 'docx';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle form submit event.
  const onSubmitForm = async (formData) => {
    let stringTemplate = '';
    let jsonData = '';
    setPreviewUrl(null);
    setLoading(true);

    // Destructure parameters for report from the form.
    const { fromDate, toDate, group_by, agent_filter, team_filter } = formData;

    // Create a filename & subtitle of report.
    const filename = getReportFileName(fromDate, toDate, formData.fileExt);
    const subtitle = getReportSubtitle(fromDate, toDate, false);

    // Concat output format to jasper report formaturl.
    let reportUrl = `${THE_JASPER_REPORT_URL}.${formData.fileExt}?`;

    // Adds the url parameters here (query string).
    reportUrl += queryString.stringify({
      ...(fromDate && { stDate: fromDate.toISOString() }),
      ...(toDate && { enDate: toDate.toISOString() }),
      ...(group_by && { GroupBy: group_by }),
      ...(agent_filter && { Agent: agent_filter }),
      ...(team_filter && { AgentTeam: team_filter }),
      //...(roleName && { AgentType: roleName }),
    });

    // Preview report or download report file?
    if (formData.template === 'preview') {
      console.log(reportUrl)
      // Set the preview report url state.
      setLoading(false);
      setPreviewUrl(reportUrl);     

    } else {

      // Download report file via JasperReport Server.
      setLoading(false);
      const link = document.createElement('a');
      link.href = reportUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <PageHelmet title={t('ticket_summary_stats_report_name')}>
      <form>
        <Container maxWidth="xl" className={classes.container}>

          {/* Top Paper */}
          <Paper className={classes.paper}>

            {/* Report Name */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.reportHead}>
                  {t('ticket_summary_stats_report_name')}
                </Typography>
              </Grid>
            </Grid>

            {/* Divider */}
            <div className="mb-4"><Divider /></div>

            {/* 1st Row */}
            <Grid container spacing={2} className={classes.formRow}>

              {/* From DateTimePicker */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_from')} *</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="fromDate"
                  control={control}
                  defaultValue={null}
                  // defaultValue="2020-01-01"
                  onChange={([, data]) => data}
                  as={
                    <KeyboardDateTimePicker
                      fullWidth
                      ampm={false}
                      margin="dense"
                      variant="inline"
                      inputVariant="outlined"
                      error={false}
                      helperText={null}
                      format="DD/MM/YYYY HH:mm"
                      KeyboardButtonProps={keyboardButtonProps}
                      InputAdornmentProps={{ position: 'end' }}
                    />
                  }
                />
                {errors.fromDate && <FieldError message={t('This field must be valid date format.')} />}
              </Grid>

              {/* To DateTimePicker */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_to')} *</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="toDate"
                  control={control}
                  defaultValue={null}
                  // defaultValue="2020-12-31"
                  onChange={([, data]) => data}
                  as={
                    <KeyboardDateTimePicker
                      fullWidth
                      ampm={false}
                      margin="dense"
                      variant="inline"
                      inputVariant="outlined"
                      error={false}
                      helperText={null}
                      format="DD/MM/YYYY HH:mm"
                      KeyboardButtonProps={keyboardButtonProps}
                      InputAdornmentProps={{ position: 'end' }}
                    />
                  }
                />
                {errors.toDate && <FieldError message={t('This field must be valid date format.')} />}
              </Grid>

            </Grid>
            {/* /.1st Row */}

            {/* 2nd Row */}
            <Grid container spacing={2} className={classes.formRow}>

              {/* Drop down Group-By */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_group_by')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="group_by"
                  rules={{ required: true }}
                  control={control}
                  defaultValue="Agents"
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={value}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e)
                        // handleAgent(e)
                        if (e.target.value.toLowerCase() === 'agents') {
                          setEnableAgentFilter(true)
                        } else {
                          setEnableAgentFilter(false)
                        }
                      }}
                    >
                      <MenuItem selected={true} key={"Agents"} value="Agents">{t('Agents')}</MenuItem>
                      <MenuItem selected={false} key={"Teams"} value="Teams">{t('Teams')}</MenuItem>
                    </Select>
                  )
                  }
                />
              </Grid>

              {/* Drop down Filter Teams or Agents */}
              {/* <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_team')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="team_filter"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={"All"}
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={value ? value : 'All'}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e)
                        // handleTeamName(e)
                        setSelectedTeam(e.target.value)
                      }}
                    >
                      <MenuItem selected={true} key={"All"} value={"All"}>{t('All')}</MenuItem>
                      {teamNameAll.map((team) =>
                        <MenuItem key={team._id} value={team._id}>{team.name}</MenuItem>
                      )
                      }
                    </Select>
                    )
                  }
                />
              </Grid> */}

              {/* Drop down sub-filter */}
              {/* <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_agent')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="agent_filter"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={"none"}                  
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={enableAgentFilter && value === 'none' ? 'all-agents' : value}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e)
                        // handleTeamName(e)
                      }}
                    >
                      {enableAgentFilter && <MenuItem selected={true} key={"all-agents"} value={"all-agents"}>{t('All')}</MenuItem>}
                      {enableAgentFilter && agents.map((agent) => {
                        if (selectedTeam.toLowerCase() == 'all' || agent.team === selectedTeam) {
                          return <MenuItem key={agent._id} value={agent._id}>{agent.name}</MenuItem>
                        }
                      }
                      )}
                      {!enableAgentFilter && <MenuItem selected={true} key={"none"} value={"none"}>{t('N/A')}</MenuItem>}
                    </Select>
                    )
                  }
                />
              </Grid> */}

            </Grid>
            {/* /.2nd Row */}

            {/* 3rd Row */}
            <Grid container spacing={2} className={classes.formRow}>
              {/* Drop down Filter Teams or Agents */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_team')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="team_filter"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={"All"}
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={value ? value : 'All'}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e)
                        // handleTeamName(e)
                        setSelectedTeam(e.target.value)
                      }}
                    >
                      <MenuItem selected={true} key={"All"} value={"All"}>{t('All')}</MenuItem>
                      {teamNameAll.map((team) =>
                        <MenuItem key={team._id} value={team._id}>{team.name}</MenuItem>
                      )
                      }
                    </Select>
                    )
                  }
                />
              </Grid>

              {/* Drop down sub-filter */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_agent')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="agent_filter"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={"none"}                  
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={enableAgentFilter && value === 'none' ? 'all-agents' : value}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e)
                        // handleTeamName(e)
                      }}
                    >
                      {enableAgentFilter && <MenuItem selected={true} key={"all-agents"} value={"all-agents"}>{t('All')}</MenuItem>}
                      {enableAgentFilter && agents.map((agent) => {
                        if (selectedTeam.toLowerCase() == 'all' || agent.team === selectedTeam) {
                          return <MenuItem key={agent._id} value={agent._id}>{agent.name}</MenuItem>
                        }
                      }
                      )}
                      {!enableAgentFilter && <MenuItem selected={true} key={"none"} value={"none"}>{t('N/A')}</MenuItem>}
                    </Select>
                    )
                  }
                />
              </Grid>
            </Grid>
            {/* /.3rd Row */}

            {/* Divider */}
            <div className={classes.footerDivider}><Divider /></div>

            {/* Export Buttons */}
            <Grid container spacing={2} >
              <Grid item xs={12} className="text-right">
                <Button variant="outlined" className="ml-2" onClick={handleClickPreviewButton} disabled={loading}>{t('general_preview')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickExcelButton} disabled={loading}>{t('general_excel')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickPdfButton} disabled={loading}>{t('general_pdf')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickWordButton} disabled={loading}>{t('general_word')}</Button>
                {loading && (
                  <Button variant="outlined" className="ml-2 text-center">
                    <CircularProgress size={22} />
                  </Button>
                )}
              </Grid>
            </Grid>

          </Paper>

          {/* Bottom Paper */}
          {!loading && previewUrl && (
            <Paper className="mt-4">
              {/* Iframe Previewer */}
              <iframe frameBorder="0" src={previewUrl} className={classes.previewIframe} />
            </Paper>
          )}

        </Container>
      </form>
    </PageHelmet>
  );
};

export default TicketSummaryStatsReport;
