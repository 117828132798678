import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Grid,  Typography, IconButton, Box, Table, Button, List, TableBody, Card, TableHead, TableRow, TableCell, TablePagination, TableFooter } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import PageHeader from './PageHeader'; 
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';



const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    card: {
        margin: "1%"
    },
    first: {
        margin: "1.5%",
        width: "60%",
        maxWidth: "60%"
    },
    second: {
        width: "30%",
        '& > *': {
          margin: theme.spacing(2),
        },
    },
    btnEdit: {
        position: "absolute",
        top: 0,
        right: 0
    }
}))

const Users = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const [config, setConfig] = React.useState({});
    const [ttsPath, setTtsPath] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const [poolUser, setPoolUser] = React.useState()

    const fetchContacts = () => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
        .then(res => res.json())
        .then((data) => {

            //check if pool user already exist
            const result = data.filter((val) => val.username == process.env.REACT_APP_POOL_USER)
            
            if (result.length > 0) {
                setPoolUser(result[0].name)
            }
        })
        .catch((error) => {console.log(error)})
    }

    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/configs` )
            .then(res => res.json())
            .then((data) => {
                console.log(data.data)
                var lastConfig = data.data[data.data.length - 1]
                setConfig(lastConfig)
                setTtsPath(lastConfig.tts_dynamic_paths)
                setLoading(true)
            })
    
        fetchContacts()
    } , [])

    if (isLoading == false) {
        return <></>
    }


    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("Config general")}
                subtitle= {t("General configuration for administrator")}
                buttonName={t("general_edit")}
                onClickButton={() => history.push("/config/general/edit")}
            />
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_number_of_additional_years")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.complaint_no_additional_year}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_number_of_prefix")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.complaint_no_prefix}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_number_of_sequence_separators")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.complaint_no_sequence_separator}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_director_name")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.complaint_director_name}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_director_position")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.complaint_director_position}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_code_for_additional_year")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.ticket_code_additional_year}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_code_prefix")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.ticket_code_prefix}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_code_sequence_separator")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.ticket_code_sequence_separator}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_auto_refresh_index")}: </Typography>
                            <div className={classes.second}>
                                <Typography >{config.ticket_auto_refresh_index}</Typography>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("text_to_speech_paths")}: </Typography>
                            <Box className={classes.second}>
                                {ttsPath.map((val)=> (
                                    <Typography >{val.name}</Typography>
                                ))}
                            </Box>
                        </Box>

                        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && (
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t('Pool user')}: </Typography>
                            <Box className={classes.second}>
                                    <Typography >{poolUser}</Typography>
                            </Box>
                        </Box>
                        )}
                        
                    </Card>
                </Grid>

      
        </Container>
   

    )
}
export default Users;
