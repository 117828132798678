import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import FieldError from '../../../../TM/src/components/FieldError';
import FieldHelper from '../../../../TM/src/components/FieldHelper';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHelmet from '../../components/PageHelmet';
import LoadingScreen from '../../components/LoadingScreen';
import AutocompleteIvrCsq from '../../components/AutocompleteIvrCsq';
import AutocompleteIvrAgent from '../../components/AutocompleteIvrAgent';
import AutocompleteIvrTeam from '../../components/AutocompleteIvrTeam';
import * as yup from 'yup';
import moment from 'moment';
import { get } from 'dot-prop';
import { logger } from '../../common';
import { ReportAPI, JsReportAPI } from '../../apis';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  reportHead: {
    fontSize: '1.15rem',
    paddingBottom: '1.15rem',
  },
  formRow: {
    marginTop: '0.50rem !important',
    marginBottom: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  fieldLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '24px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
  footerDivider: {
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.25rem',
    },
  },
  previewIframe: {
    height: 640,
    width: '100%',
  },
}));

// Fix material ui date picker icon too much space.
const keyboardButtonProps = { style: { marginRight: -14 } };

const SurveyAgentSummaryReport = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [preparing, setPreparing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [htmlDOM, setHtmlDOM] = useState('');

  // React hook form schema.
  const yupSchema = yup.object().shape({
    fromDate: yup.date().required(),
    toDate: yup.date().required(),
  });

  // React hook form initialize.
  const { handleSubmit, control, errors } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(yupSchema),
  });

  // Concat & return report sub title.
  const getReportSubtitle = (fromDate, toDate, isFile) => {
    if (!fromDate || !toDate) return '';
    const text = isFile ? 'D MMM YYYY' : 'D MMM YYYY HH:mm';
    return `${moment(fromDate).format(text)} - ${moment(toDate).format(text)}`;
  };

  // Concat & return report file name.
  const getReportFileName = (fromDate, toDate, fileExt) => {
    console.log(fromDate, toDate, fileExt);
    const title = t('survey_agent_summary_report_name');
    const subtitle = getReportSubtitle(fromDate, toDate, true);
    return `${title} ${subtitle}.${fileExt}`;
  };

  // Handle click preview button event.
  const handleClickPreviewButton = () => {
    const callback = (data) => {
      data.template = 'preview';
      data.recipe = 'html';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickExcelButton = () => {
    const callback = (data) => {
      data.template = 'excel';
      data.recipe = 'html-to-xlsx';
      data.fileExt = 'xlsx';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click pdf button event.
  const handleClickPdfButton = () => {
    const callback = (data) => {
      data.template = 'pdf';
      data.recipe = 'chrome-pdf';
      data.fileExt = 'pdf';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickWordButton = () => {
    const callback = (data) => {
      data.template = 'word';
      data.recipe = 'html-embedded-in-docx';
      data.fileExt = 'docx';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle form submit event.
  const onSubmitForm = async (formData) => {
    let stringTemplate = '';
    let jsonData = '';
    setLoading(true);

    // Destructure parameters for report from the form.
    const { fromDate, toDate, csqName, agentName, teamName } = formData;

    // Fetch report template and report data.
    await Promise.all([
      ReportAPI.getSurveyAgentSummaryReportTemplate(formData.template),
      ReportAPI.getSurveyAgentSummaryReportData(fromDate, toDate, csqName, agentName, teamName),
    ])
    .then((reses) => {
      stringTemplate = get(reses, '0.data', '');
      jsonData = get(reses, '1.data', null);
    })
    .catch((err) => {
      // Print error logs.
      logger.error('Unable to get report template from server.');
      logger.error(err);
      // Handle submit error.
      setLoading(false);
      enqueueSnackbar(t('Sorry, something went wrong there.'), {
        variant: 'error',
      });
    })

    // Cannot pull template or data from server?
    if (!stringTemplate || !jsonData) return;

    // Create a filename & subtitle of report.
    const filename = getReportFileName(fromDate, toDate, formData.fileExt);
    const subtitle = getReportSubtitle(fromDate, toDate, false);

    // The JsReport API request body.
    const reqBody = {
      template: {
        content: stringTemplate,
        recipe: formData.recipe,
        engine: 'handlebars',
      },
      data: { 
        questions: jsonData.questions,
        total: jsonData.total,
        rows: jsonData.rows,
        subtitle: subtitle,
      },
    }

    // Preview report or download report file?
    if (formData.template === 'preview') {

      // Fetch preview report html DOM from JsReport API.
      JsReportAPI.previewReport(reqBody)
        .then((res) => {
          // Handle fetch success
          setLoading(false);
          setHtmlDOM(res.data);
        })
        .catch((err) => {
          // Print error logs.
          logger.error('Unable to get preview report from server.');
          logger.error(err);
          // Handle submit error.
          setLoading(false);
          enqueueSnackbar(t('Sorry, something went wrong there.'), {
            variant: 'error',
          });
        });

    } else {
      
      // Download report file from JsReport API.
      JsReportAPI.downloadReport(reqBody)
        .then((res) => {
          // Handle download success
          setLoading(false);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          // Print error logs.
          logger.error('Unable to download report from server.');
          logger.error(err);
          // Handle submit error.
          setLoading(false);
          enqueueSnackbar(t('Sorry, something went wrong there.'), {
            variant: 'error',
          });
        });
    }
  };

  return (
    <PageHelmet title={t('survey_agent_summary_report_name')}>
      <form>
        <Container maxWidth="xl" className={classes.container}>

          {/* Top Paper */}
          <Paper className={classes.paper}>

            {/* Report Name */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.reportHead}>
                  {t('survey_agent_summary_report_name')}
                </Typography>
              </Grid>
            </Grid>

            {/* Divider */}
            <div className="mb-4"><Divider /></div>

            {/* 1st Row */}
            <Grid container spacing={2} className={classes.formRow}>

              {/* From DateTimePicker */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_from')} *</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="fromDate"
                  control={control}
                  defaultValue={null}
                  // defaultValue="2020-01-01"
                  onChange={([, data]) => data}
                  as={
                    <KeyboardDateTimePicker
                      fullWidth
                      ampm={false}
                      margin="dense"
                      variant="inline"
                      inputVariant="outlined"
                      error={false}
                      helperText={null}
                      format="DD/MM/YYYY HH:mm"
                      KeyboardButtonProps={keyboardButtonProps}
                      InputAdornmentProps={{ position: 'end' }}
                    />
                  }
                />
                {errors.fromDate && <FieldError message={t('This field must be valid date format.')} />}
              </Grid>

              {/* To DateTimePicker */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_to')} *</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="toDate"
                  control={control}
                  defaultValue={null}
                  // defaultValue="2020-12-31"
                  onChange={([, data]) => data}
                  as={
                    <KeyboardDateTimePicker
                      fullWidth
                      ampm={false}
                      margin="dense"
                      variant="inline"
                      inputVariant="outlined"
                      error={false}
                      helperText={null}
                      format="DD/MM/YYYY HH:mm"
                      KeyboardButtonProps={keyboardButtonProps}
                      InputAdornmentProps={{ position: 'end' }}
                    />
                  }
                />
                {errors.toDate && <FieldError message={t('This field must be valid date format.')} />}
              </Grid>

            </Grid>
            {/* /.1st Row */}

            {/* 2nd Row */}
            <Grid container spacing={2} className={classes.formRow}>

              {/* Menu Select */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_menu')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="csqName"
                  control={control}
                  defaultValue={null}
                  onChange={([, data]) => data}
                  render={({ value, onChange }) => {
                    return (
                      <AutocompleteIvrCsq
                        fullWidth
                        size="small"
                        margin="dense"
                        defaultValue={value}
                        onSelectChange={(e, data) => {
                          return onChange(data);
                        }}
                      />
                    );
                  }}
                />
                <FieldHelper message="Contact Service Queue (CSQ)" />
              </Grid>

              {/* Agent Select */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_agent')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="agentName"
                  control={control}
                  defaultValue={null}
                  onChange={([, data]) => data}
                  render={({ value, onChange }) => {
                    return (
                      <AutocompleteIvrAgent
                        fullWidth
                        size="small"
                        margin="dense"
                        defaultValue={value}
                        onSelectChange={(e, data) => {
                          return onChange(data);
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Team Select */}
              <Grid item xs={12} md={1} className={classes.fieldLabel}>{t('general_team')}</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="teamName"
                  control={control}
                  defaultValue={null}
                  onChange={([, data]) => data}
                  render={({ value, onChange }) => {
                    return (
                      <AutocompleteIvrTeam
                        fullWidth
                        size="small"
                        margin="dense"
                        defaultValue={value}
                        onSelectChange={(e, data) => {
                          return onChange(data);
                        }}
                      />
                    );
                  }}
                />
              </Grid>

            </Grid>
            {/* /.2nd Row */}

            {/* Divider */}
            <div className={classes.footerDivider}><Divider /></div>

            {/* Export Buttons */}
            <Grid container spacing={2} >
              <Grid item xs={12} className="text-right">
                <Button variant="outlined" className="ml-2" onClick={handleClickPreviewButton} disabled={loading}>{t('general_preview')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickExcelButton} disabled={loading}>{t('general_excel')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickPdfButton} disabled={loading}>{t('general_pdf')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickWordButton} disabled={loading}>{t('general_word')}</Button>
                {loading && (
                  <Button variant="outlined" className="ml-2 text-center">
                    <CircularProgress size={22} />
                  </Button>
                )}
              </Grid>
            </Grid>

          </Paper>

          {/* Bottom Paper */}
          {!loading && htmlDOM && (
            <Paper className="mt-4">
              {/* Iframe Previewer */}
              <iframe frameBorder="0" srcDoc={htmlDOM} className={classes.previewIframe} />
            </Paper>
          )}

        </Container>
      </form>
    </PageHelmet>
  );
};

export default SurveyAgentSummaryReport;
