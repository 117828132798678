import React from 'react';
import path from 'path';
import filesize from 'filesize';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FileViewer from 'react-file-viewer';
import { logger, helper } from '../common';
import { FileAPI } from '../apis';
import TextViewer from './TextViewer';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  dialogTitle: {
  },
  dialogSubtitle: {
    color: '#8e8e8e',
    display: 'block',
    fontWeight: '400',
    fontSize: '0.9rem',
  },
  dialogContent: {
    height: window.innerHeight / 1.3,
  },
}));

const AttachmentViewer = ({ onCancel, attachment }) => {
  const classes = useStyles();

  const fileUrl = FileAPI.getFileURL(attachment.id);
  const fileExt = path.extname(attachment.file_name).toLowerCase();
  const fileType = fileExt ? fileExt.substr(1) : undefined;

  // Concatinate subtitle avoid breaking older version
  let subtitle = '';
  if (attachment.length && attachment.uploadDate) {
    const fileSize = filesize(attachment.length);
    const fileDate = helper.printUserDateTime(attachment.uploadDate);
    subtitle = `${fileSize}\u00A0 - \u00A0${fileDate}`;
  }

  // Concatenate attachment name and description
  let attachmentName = attachment.file_name;
  if (attachment.desc) attachmentName += ` (${attachment.desc})`;

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  // NOTE: The library doesn't support txt file
  return (
    <Dialog
      fullWidth
      className="test-1"
      maxWidth="lg"
      open={!!attachment}
      onClose={handleCancel}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.dialogTitle}>
        {attachmentName}
        <small className={classes.dialogSubtitle}>
          {subtitle}
        </small>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>

        {(fileType === 'txt' || fileType === 'log') && (
          <TextViewer fileId={attachment.id} />
        )}

        {fileType !== 'txt' && (
          <FileViewer
            className="m-auto"
            fileType={fileType}
            filePath={fileUrl}
            onError={(err) => logger.error(err)}
          />
        )}

      </DialogContent>
    </Dialog>
  );
};

AttachmentViewer.propTypes = {
  onCancel: PropTypes.func,
  attachment: PropTypes.object,
};

AttachmentViewer.defaultProps = {
  onCancel: undefined,
  attachment: undefined,
};

export default AttachmentViewer;
