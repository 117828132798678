import React, { useState, useEffect, useContext, useRef } from 'react';
import { 
  Container,
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import PatientHeader from './PatientHeader';
import EditDialog from './EditDialog';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import { AppContext } from '../../../../contexts/AppStore';
import TablePaginationActions from '../components/TablePaginationActions';
import { useStyles } from '../components/styles';
import DeleteDialog from './DeleteDialog';

function RamaContactPatient(props) {
  const { t } = useTranslation();
  const { hasPermission } = useContext(AppContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allContacts, set_allContacts] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [pagerToggleReFresh, setPagerToggleReFresh] = useState(true);
  const [parentIdContacts] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [isSearchES, setIsSearchES] = useState(false);
  const [searchESText, setSearchESText] = useState();

  /* State of Search Box inputRef */
  const [typing, setTyping] = useState(false);
  const keyUpTimeOut = 1000;
  const keywordRef = useRef(null);

  /* State of Pagination when using any Search Box */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [RowsPerPageTrigger, setRowsPerPageTrigger] = useState(false);
  const [countESTotal, setCountESTotal] = useState(0);

  /* 
  Functions for CONTACT Dialog
  */
  const refreshContactTable = () => {
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  const handleDeleteDialog = (deleteId) => {
    setDeleteId(deleteId);
    setDeleteOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteOpen(false);
  };
  /* Ending Line of Functions */

  /* 
  Functions for CONTACT LIST table pagination
  When HAVE any word in SEARCH BOX
  */
  const lastPage = Math.ceil(countESTotal / rowsPerPage) - 1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let pageNumber = newPage
    if (searchESText) {
      elasticSearchInputData(searchESText, pageNumber, rowsPerPage);
    } else {
      fetchContacts(pageNumber, rowsPerPage)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPageTrigger(!RowsPerPageTrigger);
    setPage(0);
  };

  const handleFirstPage = (event) => {
    handleChangePage(event, 0);
  };

  const handleBackPage = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextPage = (event) => {
    handleChangePage(event, page + 1);
  };

  const handleLastPage = (event) => {
    handleChangePage(event, lastPage);
  };
  /* Ending Line of Functions */

  /* Function of ES for Search box */
  function elasticSearchInputData(searchText, pageNumber, rowsPerPage) {

    // let keyword = searchContact;
    let keyword = searchText ? searchText : ' ';
    keyword = keyword.replace('.', ' ');
    keyword = keyword.replace('.', ' ');
    keyword = keyword.replace('-', ' ');
    keyword = keyword.replace('-', ' ');

    let from = 0;
    let size = rowsPerPage;

    //NOTE: Material UI pagination start from 0
    from = rowsPerPage * pageNumber;

    const input_query = {
      from: from,
      size: size,
      query: {
        filtered: {
          query: {
            query_string: {
              query: `*${keyword}*`,
              fields: [
                "name",
                "firstName",
                "lastName",
                "CustomData.pid"
              ]
            }
          },
          filter: {
            bool: {
              must: {
                term: {
                  "isActive": true
                }
              },
              should: {
                term: {
                  "CustomData.contactType": ["patient"]
                }
              }           
            }        
          }
        }
      }
    }

    esGetSearchData(input_query);
  }

  const esGetSearchData = (input_query) => {
    const query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`;

    fetch(query, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input_query)
    })
      .then(res => res.json())
      .then((data) => {
        setCountESTotal(data.hits.total)
        let converted_list = data.hits.hits.map((v) => {
          v._source._id = v._id;
          if (v._source.isActive === true) {
            return v._source;
          } else {
            return null
          }
        });
        let result = converted_list.filter((data) => data !== null)
        set_allContacts(result);
      });
  }

  /* Function for Checking word in Search Box */
  const handleEsTextFieldChange = (event) => {
    if (event.value.length > 0) {
      setSearchESText(event.value);
      elasticSearchInputData(event.value, page, rowsPerPage);
      setIsSearchES(event.value.length > 0);
    } else {
      setIsSearchES(false);
      refreshContacts();
    }
  }

  useEffect(() => {
    if (isSearchES && page > lastPage-1) {
      setPage(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isSearchES, lastPage])

  /* Checking word in Search Box */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (handleEsTextFieldChange) {
        handleEsTextFieldChange(keywordRef.current);
      }
    }, keyUpTimeOut);
    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typing, RowsPerPageTrigger, pagerToggleReFresh]);

  /* Fetch contact point list when no search text field */
  const fetchContacts = (pageNumber, rowsPerPage) => {
    let from = 0;
    let size = rowsPerPage;

    //NOTE: Material UI pagination start from 0
    from = rowsPerPage * pageNumber;

    const input_query = {
      from: from,
      size: size,
      "query": {
        "bool": {
          "must": [
            {
              "match": {
                "isActive": true
              }
            },
            {
              "terms": {
                "CustomData.contactType": ["patient"]
              }
            }
          ]
        }
      },
    }

    esGetSearchData(input_query);
  }

  /* REFRESH Contact list */
  const refreshContacts = () => {
    fetchContacts(page,rowsPerPage);
  };

  const handleOnCloseDialog = () => {
    refreshContacts();
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  /* Refresh table when no text in Search Box */
  useEffect(() => {
    if (isSearchES === false) {
      refreshContacts();
      const refreshContactsTimer = setInterval(() => {
        refreshContacts();
      }, 3000);
      return () => {
        clearInterval(refreshContactsTimer);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagerToggleReFresh, isSearchES]);

  /* Delect Contact in list */
  const handleDelete = (delete_id) => {
    setDeleteOpen(false)
    let query = `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${delete_id}`
    var raw = JSON.stringify(delete_id);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch(query, {
      method: 'Delete',
      headers: myHeaders,
      body: raw,
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          setPagerToggleReFresh(!pagerToggleReFresh);
          enqueueSnackbar(t('Contact has been deleted.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to delete.'), { variant: 'error' });

      })
  }

  return (
    <Container maxWidth="xl" className={classes.containerContactPage}>
      <PatientHeader
        contactPage="patient"
        title={t('patient_title')}
        subtitle={t('patient_subtitle')}
        buttonName={hasPermission('contact_create') ? t('new_patient') : null}
        triggerToRefresh={refreshContactTable}
        refreshTable={() => fetchContacts(page,rowsPerPage)}
      />

      <Card className={classes.findingMargin}>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              onChange={(e) => e.target.value ? setIsSearchES(true) : setIsSearchES(false)}
              inputRef={keywordRef}
              onKeyUp={(e) => setTyping(!typing)}
              label={t("Search patient")}
              className={classes.autoCom}
              margin="normal"
              variant="outlined"
              InputProps={{
                type: t('contacts_page_search'), startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid item xs={12}>
        <Card className={classes.body_table}>
          <Table>

            {/* Table Head */}
            <TableHead>
              <TableRow>
                <TableCell style={{width: '35%'}}><Typography variant="body2">{t('contact_person_name')}</Typography></TableCell>
                <TableCell style={{width: '20%'}}><Typography variant="body2">{t('person_pid')}</Typography></TableCell>
                <TableCell style={{width: '15%'}}><Typography variant="body2">{t('gender')}</Typography></TableCell>
                <TableCell style={{width: '15%'}}><Typography variant="body2">{t('patient_subtype')}</Typography></TableCell>
                <TableCell style={{width: '15%'}}><Typography variant="body2">{t('Action')} </Typography></TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {allContacts.map((val) => (
                <React.Fragment key={val._id}>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" flexDirection="row">
                        <Avatar alt={val.name} src="/broken-image.jpg" />
                        <Typography className={classes.ty}>
                          {val.firstName ? (
                              val.lastName ? String(val.firstName + " " + val.lastName).substring(0, 40) : val.firstName
                            ) : String(val.name).substring(0, 40)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.CW_email}>
                      <Typography variant="subtitle2">
                        {(val.CustomData) ? (val.CustomData.pid ? String(val.CustomData.pid).substring(0, 10) : "") : ""}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellWidth}>
                      <Typography variant="subtitle2">
                        {(val.gender) ? String(val.gender) : ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {(val.CustomData) ? (val.CustomData.subtype ? String(val.CustomData.subtype).substring(0, 10) : "") : ""}
                    </TableCell> 

                    <TableCell>

                      {/* Edit Button */}
                      <EditDialog
                        contactPage={'patient'}
                        contactId={val._id}
                        triggerToRefresh={refreshContactTable}
                        parentIdOptions={parentIdContacts}
                        openEditDialog={openEditDialog}
                        setOpenEditDialog={setOpenEditDialog}
                        allContacts={allContacts}
                        onCloseDialog={handleOnCloseDialog}
                        refreshTable={() => fetchContacts(page,rowsPerPage)}
                      />

                      {/* Delete Button */}
                      <IconButton
                        disabled={!hasPermission('contact_delete')}
                        onClick={() => handleDeleteDialog(val._id)}
                      >
                        <DeleteForeverOutlinedIcon />
                      </IconButton>

                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
              
            </TableBody>
          </Table>

          {/* Table Pagination */}
          <Card>
            <Grid justify="flex-end" className={classes.paginationActions}>
              <TablePaginationActions
                count={countESTotal}
                pageAll={lastPage}
                pageNum={page}
                pageAllLabel={lastPage + 1}
                pageNumLabel={lastPage + 1 <= 0 ? 0 : page + 1}
                firstPage={0}
                pageSize={rowsPerPage}
                handleFirstPageButtonClick={handleFirstPage}
                handleBackButtonClick={handleBackPage}
                handleNextButtonClick={handleNextPage}
                handleLastPageButtonClick={handleLastPage}
                handleChangeRowsPerPageQuery={handleChangeRowsPerPage}
              />
            </Grid>
          </Card>

          {/* Delete confirmation dialog */}
          <DeleteDialog
            title={t("patient_delete_dialog_title")}
            contentText={t("patient_delete_dialog_content")}
            deleteOpen={deleteOpen}
            handleDeleteDialogClose={handleDeleteDialogClose}
            handleDeleteItem={() => handleDelete(deleteId)}
          />

        </Card>
      </Grid>

    </Container>
  )
}

export default RamaContactPatient;
