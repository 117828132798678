import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {Grid, Card, Box, Button, TextField, IconButton, Typography} from '@material-ui/core';
import {
    useForm,
    // Controller
} from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    card: {
        margin: "1%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"

    },
    first: {
        margin: "1.5%",
        width: "30%",
        maxWidth: "30%"
    },
    second: {
        width: "30%",
        '& > *': {
          margin: theme.spacing(2),
        },
    },
    second_2: {
        width: "39%",
        '& > *': {
          margin: theme.spacing(2),
        },
    },
    btn_ : {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "30%",
        marginBottom: "1%"
    },
    pathFirst: {
        margin: "1.5%",
        width: "40%",
        maxWidth: "40%"
    },
    ttsList: {
        width: "100%",
        '& > *': {
            marginLeft: theme.spacing(1),
        },
    },
    name: {
        width: "25%",
    },
    value: {
        width: "25%",
    },
    filename: {
        width: "25%",
    },
    errorMsg: {
        color: "#f44336"
    }
}))



const Edit = (props) => {
    const classes=useStyles();
    const {
        register,
        handleSubmit,
        // control,
        errors,
        // setValue
    } =useForm();
    const history = useHistory();
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [config, setConfig] = useState({});
    const [loading, isLoading] = useState(true);
    const [configId, setConfigId] = useState();
    const [ttsPath, setTtsPath] = useState([]);
    const [boolExistUser, setBoolExistUser] = useState(false)
    const [poolUser, setPoolUser] = React.useState();

    const checkContacts = () => {

        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
        .then(res => res.json())
        .then((data) => {

            //check if pool user already exist
            const result = data.filter((val) => val.username === process.env.REACT_APP_POOL_USER)
            
            if (result.length > 0) {
                setBoolExistUser(true) 
                setPoolUser(result[0].name)
            }
        })
        .catch((error) => {console.log(error)})

    }

    
    /*fetching general config*/
    useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/configs`)
            .then(res => res.json())
            .then((data) => {
                var lastConfig = data.data[data.data.length - 1]
                setConfig(lastConfig)
                setConfigId(lastConfig._id)

                if (lastConfig.tts_dynamic_paths.length !== 0) {
                    setTtsPath(lastConfig.tts_dynamic_paths)
                } else {
                    setTtsPath([{
                        name: "",
                        value: "",
                        fileName:""
                    }]);
                }
                isLoading(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar(t('Cannot load config.'), { variant: 'error' });
            })
        
        // checkContacts()
        
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
            .then(res => res.json())
            .then((data) => {
    
                //check if pool user already exist
                const result = data.filter((val) => val.username === process.env.REACT_APP_POOL_USER)
                // return result;
                
                if (result.length > 0) {
                    setBoolExistUser(true);
                    setPoolUser(result[0].name);
                }
            })
            .catch((error) => {console.log(error)})

    } , [enqueueSnackbar, t, poolUser])

    const changeTTSvalue = (index, e) => {
        let arr = [...ttsPath]
        arr[index].value = e.target.value
        setTtsPath(arr)
    }

    const changeTTSname = (index, e) => {
        let arr = [...ttsPath]
        arr[index].name = e.target.value
        setTtsPath(arr)
    }

    const changeTTSfileName = (index, e) => {
        let arr = [...ttsPath]
        arr[index].fileName = e.target.value
        setTtsPath(arr)
    }

    function handleAdd () {
        setTtsPath([...ttsPath, {name: '', value: '', fileName: '' }]);
    }

    function handleDelete(index) {
        let result = [...ttsPath]
        result.splice(index,1);
        setTtsPath(result);
    }

    const handleAddPoolUser = () => {

        const data = {
            username: process.env.REACT_APP_POOL_USER,
            name: 'Pool User',

        }
        var raw = JSON.stringify(data);
        console.log("data", raw)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
          method: 'POST', 
          headers: myHeaders,
          body: raw,
        })
        .then(response => {
          if (response.status >=200 && response.status <=299){history.push('/config')
              checkContacts()
            return enqueueSnackbar(t('Pool user has been created.'), { variant: 'success' });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar(t('Pool user has fail to create.'), { variant: 'fail' })
        }) 

    }
        
    const onSubmit = (data,e) => {
        // console.log("final data", JSON.stringify(data))
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/configs/${configId}`,{
            method: 'PUT', 
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        })
        .then((response) => {
            if(response.status >=200 && response.status <=299) {history.push('/config');    
               return enqueueSnackbar(t('Config has been updated.'), { variant: 'success' });
            } else {
                return enqueueSnackbar(t('Config has fail to update.'), { variant: 'error' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            return enqueueSnackbar(t('Config has fail to update.'), { variant: 'error' });
        }) 
    }

    if (loading=== true){
        return (<></>)
    }

    return (

        <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("Config general")}
                subtitle= {t("General configuration for users")}
            />
            <Grid item xs={12} spacing={1} className={classes.top}>
                <Card className={classes.card}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>

                        {/* Complaints No. additional years */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_number_of_additional_years")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.complaint_no_additional_year}
                                    name="complaint_no_additional_year"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Complaints No. prefix */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_number_of_prefix")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.complaint_no_prefix}
                                    name="complaint_no_prefix"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Complaints No. sequence separators */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_number_of_sequence_separators")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.complaint_no_sequence_separator}
                                    name="complaint_no_sequence_separator"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Complaints Director Name */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_director_name")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.complaint_director_name}
                                    name="complaint_director_name"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Complaints Director Position */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("complaints_director_position")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.complaint_director_position}
                                    name="complaint_director_position"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Ticket additional year */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_code_for_additional_year")} : </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.ticket_code_additional_year}
                                    name="ticket_code_additional_year"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Ticket prefix */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_code_prefix")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.ticket_code_prefix}
                                    name="ticket_code_prefix"
                                    inputRef={register}
                                />
                            </div>
                        </Box>


                        {/* Ticket sequence separator */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_code_sequence_separator")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.ticket_code_sequence_separator}
                                    name="ticket_code_sequence_separator"
                                    inputRef={register}
                                />
                            </div>
                        </Box>

                        {/* Ticket auto refresh index */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.first}>{t("ticket_auto_refresh_index")}: </Typography>
                            <div className={classes.second}>
                                <TextField 
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={config.ticket_auto_refresh_index}
                                    name="ticket_auto_refresh_index"
                                    inputRef={register}
                                />
                            </div>
                        </Box>


                        {/* Pool user */}
                        {process.env.REACT_APP_BUILD_TARGET ==='RAMA' && (
                            <Box display='flex' flexDirection='row'>
                                <Typography className={classes.first}>{t("Pool user")}: </Typography>
                                <Box display='flex' flexDirection='row' className={classes.second_2}>

                                        {boolExistUser && (
                                            <TextField
                                                disabled
                                                fullWidth
                                                variant="outlined"
                                                value={poolUser}
                                            />
                                        )}
                                        <Button 
                                            variant='contained' 
                                            color='secondary'
                                            disabled={boolExistUser === true ? true : false}
                                            onClick={() => handleAddPoolUser()}
                                        >
                                            Add
                                        </Button>

                                </Box>
                            </Box>
                        )}

                        {/* TTS paths */}
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.pathFirst}>{t("text_to_speech_paths")}: * </Typography>
                            <Grid container spacing={1}>
                                {ttsPath.map((val, index) => {
                                return (
                                    <Grid item xs={12} md={8} lg={8} className={classes.ttsList}>
                                    <TextField 
                                        fullWidth
                                        className={classes.name}
                                        defaultValue={val.name}
                                        variant="outlined"
                                        label="Display path"
                                        name={`tts_dynamic_paths[${index}].name`}
                                        inputRef={register({required: true})}
                                        onChange={(e) => changeTTSname(index, e)}
                                        value={val.name}
                                    />

                                    <TextField
                                        fullWidth
                                        className={classes.value}
                                        defaultValue={val.value}
                                        variant="outlined"
                                        label="Value"
                                        name={`tts_dynamic_paths[${index}].value`}
                                        inputRef={register}
                                        onChange={(e) => changeTTSvalue(index, e)}
                                        value={val.value}

                                    />
                              
                                    <TextField
                                        fullWidth
                                        className={classes.filename}
                                        defaultValue={val.fileName}
                                        variant="outlined"
                                        label="File Name"
                                        name={`tts_dynamic_paths[${index}].fileName`}
                                        inputRef={register}
                                        onChange={(e) => changeTTSfileName(index, e)}
                                        value={val.fileName}

                                    />
                                    {ttsPath.length !== 1 &&                                             
                                        <IconButton  onClick={() =>handleDelete(index)}>
                                            <DeleteForeverOutlinedIcon/>
                                        </IconButton>
                                    }
                                    {ttsPath.length -1 === index &&
                                        <IconButton >
                                            <AddBoxOutlinedIcon onClick={handleAdd}/>
                                        </IconButton>
                                    }
                                </Grid>
                                )
                            })}
                            {errors.tts_dynamic_paths && errors.tts_dynamic_paths[0] && <span className={classes.errorMsg}>{t('At least one text to speech path is required')} </span>}
                            </Grid>

                        </Box>



                        {/* Save and Cancel btn */}
                        <Box display="flex" flexDirection="row" className={classes.btn_}>
                            <Button 
                                type="submit" 
                                color="secondary"
                                variant="contained"
                            >{t('Save')}
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => history.push('/config')}
                            >{t('Cancel')}
                            </Button>
                        </Box>
                    </form>
  
                </Card>
            </Grid>
        </Container>
           

    )
}
export default Edit;
