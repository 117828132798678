/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { yearArray } from '../../components/dateTimeForReports';
// import { KeyboardDateTimePicker } from "@material-ui/pickers";
// import FieldError from "../../../../TM/src/components/FieldError";
import { 
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  // FormLabel,
} from '@material-ui/core';
import PageHelmet from '../../components/PageHelmet';
import * as yup from 'yup';
import moment from 'moment';
import queryString from 'query-string';

// Set the report url for this component here.
const THE_JASPER_REPORT_URL =
  process.env.REACT_APP_JASPER_REPORT_FQDN +
  '/jasperserver/rest_v2/reports/cream/1_IVR_Menu_Summary_Report';

// Intial material-ui overriding styles.
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  reportHead: {
    fontSize: '1.15rem',
    paddingBottom: '1.15rem',
  },
  formRow: {
    marginTop: '0.50rem !important',
    marginBottom: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  fieldLabel: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingTop: '16px !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
      paddingTop: '4px !important',
      paddingBottom: '0px !important',
    },
  },
  footerDivider: {
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.25rem',
    },
  },
  previewIframe: {
    height: 640,
    width: '100%',
  },
  childCheckbox: {
    [theme.breakpoints.up('lg')]: {
      flexDirection:'row',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection:'column',
    },
  },
}));

// const keyboardButtonProps = { style: { marginRight: -14 } };

// const yearAll = [ { id: 0, year: 2021 } ];
const yearAll = yearArray(2020);

const monthAll = [
  { id: 0, month: 'January' },
  { id: 1, month: 'February' },
  { id: 2, month: 'March' },
  { id: 3, month: 'April' },
  { id: 4, month: 'May' },
  { id: 5, month: 'June' },
  { id: 6, month: 'July' },
  { id: 7, month: 'August' },
  { id: 8, month: 'September' },
  { id: 9, month: 'October' },
  { id: 10, month: 'November' },
  { id: 11, month: 'December' },
];

const getMonthId = (monthName) => {
  const foundMonth = monthAll.find(item => item.month === monthName)
  return foundMonth.id
}

const REPORT01_IVRMenuSummaryReportMonthlyByDate = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [radioValue, setRadioValue] = useState([1, 0, 0])
  const [checkedAll, setCheckedAll] = useState(true);
  const [checkedFilter, setCheckedFilter] = React.useState([true, true, true, true]);
  const [checkValue, setCheckValue] = useState([0, 0, 0, 0])

  /* Selection handle of all information or table only or chart only */
  const radioHandler = (event) => {
    const radioValue = event.target.value
    if(radioValue === 'all') {
      // console.log('select value is all');
      setRadioValue([1, 0, 0])
    } else if(radioValue === 'table') {
      // console.log('select value is table');
      setRadioValue([0, 1, 0])
    } else {
      // console.log('select value is chart');
      setRadioValue([0, 0, 1])
    }
  }

  /* Handle of all filter */
  const allHandler = (event) => {
    setCheckedAll(event.target.checked);
    setCheckedFilter([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);
    if(checkedFilter===[true, true, true, true]) {
      setCheckedAll(event.target.checked);
    }
  };

  /* Handle of filter group */
  const parentFilterHandler = (event) => {
    setCheckedFilter([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);
  };

  /* Handle of filter weekday */
  const weekdayHandler = (event) => {
    setCheckedFilter([event.target.checked, checkedFilter[1], checkedFilter[2], checkedFilter[3]]);
  };

  /* Handle of filter Saturday */
  const saturdayhandler = (event) => {
    setCheckedFilter([checkedFilter[0], event.target.checked, checkedFilter[2], checkedFilter[3]]);
  };

  /* Handle of filter Sunday */
  const sundayHandler = (event) => {
    setCheckedFilter([checkedFilter[0], checkedFilter[1], event.target.checked, checkedFilter[3]]);
  };

  /* Handle of filter holiday */
  const holidayHandler = (event) => {
    setCheckedFilter([checkedFilter[0], checkedFilter[1], checkedFilter[2], event.target.checked]);
  };

  const filterCheckbox = (
    <Box sx={{ display: 'flex', ml: 3 }} className={classes.childCheckbox}>
      <FormControlLabel
        style={{width: 120}}
        label={t('general_weekday')}
        control={<Checkbox checked={checkedFilter[0]} onChange={weekdayHandler} />}
        disabled={checkedAll}
      />
      <FormControlLabel
        style={{width: 120}}
        label={t('general_saturday')}
        control={<Checkbox checked={checkedFilter[1]} onChange={saturdayhandler} />}
        disabled={checkedAll}
      />
      <FormControlLabel
        style={{width: 120}}
        label={t('general_sunday')}
        control={<Checkbox checked={checkedFilter[2]} onChange={sundayHandler} />}
        disabled={checkedAll}
      />
      <FormControlLabel
        style={{width: 200}}
        label={t('general_special_holiday')}
        control={<Checkbox checked={checkedFilter[3]} onChange={holidayHandler} />}
        disabled={checkedAll}
      />
    </Box>
  );

  React.useEffect(() => {
    if(!checkedFilter[0] || !checkedFilter[1] || !checkedFilter[2] || !checkedFilter[3]) {
      setCheckedAll(false)
    }
    setCheckValue([
      checkedAll? 11 : checkedFilter[0]? 11 : 0,
      checkedAll? 7 : checkedFilter[1]? 7 : 0,
      checkedAll? 1 : checkedFilter[2]? 1 : 0,
      checkedAll? 'Holiday' : checkedFilter[3]? 'Holiday' : 0,
    ])
  }, [checkedAll, checkedFilter])

  // React hook form schema.
  const yupSchema = yup.object().shape({
  });

  // React hook form initialize.
  const { handleSubmit, control } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(yupSchema),
  });

  // Concat & return report sub title.
  const getReportSubtitle = (fromDate, toDate, isFile) => {
    if (!fromDate || !toDate) return "";
    const text = isFile ? "D MMM YYYY" : "D MMM YYYY HH:mm";
    return `${moment(fromDate).format(text)} - ${moment(toDate).format(text)}`;
  };

  // Concat & return report file name.
  const getReportFileName = (fromDate, toDate, fileExt) => {
    // console.log(fromDate, toDate, fileExt);
    const title = t("ivr_menu_summary_report_monthly_by_date");
    const subtitle = getReportSubtitle(fromDate, toDate, true);
    return `${title} ${subtitle}.${fileExt}`;
  };

  // Handle click preview button event.
  const handleClickPreviewButton = () => {
    const callback = (data) => {
      data.template = "preview";
      data.fileExt = "html";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickExcelButton = () => {
    const callback = (data) => {
      data.template = "excel";
      data.fileExt = "xlsx";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click pdf button event.
  const handleClickPdfButton = () => {
    const callback = (data) => {
      data.template = "pdf";
      data.fileExt = "pdf";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickWordButton = () => {
    const callback = (data) => {
      data.template = "word";
      data.fileExt = "docx";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle form submit event.
  const onSubmitForm = async (formData) => {
    setPreviewUrl(null);
    setLoading(true);

    // Destructure parameters for report from the form.
    const { fromDate, toDate } = formData;

    // Create a filename & subtitle of report.
    const filename = getReportFileName(fromDate, toDate, formData.fileExt);

    // Concat output format to jasper report formaturl.
    let reportUrl = `${THE_JASPER_REPORT_URL}.${formData.fileExt}?`;

    // Shorter form data variables
    const { year_filter, month_filter } = formData

    // Adds the url parameters here (query string).
    // https://momentjs.com/docs/#/get-set/set/
    reportUrl += queryString.stringify({
      stDate: moment()
        .set('year', year_filter)
        .set('month', getMonthId(month_filter))
        .startOf('month')
        .toISOString(),
      enDate: moment()
        .set('year', year_filter)
        .set('month', getMonthId(month_filter))
        .endOf('month')
        .toISOString(),
      work: checkValue[0],
      sat: checkValue[1],
      sun: checkValue[2],
      holiday: checkValue[3],
      all: radioValue[0],
      table: radioValue[1],
      chart: radioValue[2],
    });

    // Preview report or download report file?
    if (formData.template === "preview") {
      // Set the preview report url state.
      setLoading(false);
      setPreviewUrl(reportUrl);
    } else {
      // Download report file via JasperReport Server.
      setLoading(false);
      const link = document.createElement("a");
      link.href = reportUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <PageHelmet title={t("ivr_menu_summary_report_monthly_by_date")}>
      <form>
        <Container maxWidth="xl" className={classes.container}>
          {/* Top Paper */}
          <Paper className={classes.paper}>
            {/* Report Name */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.reportHead}>
                  {t("ivr_menu_summary_report_monthly_by_date")}
                </Typography>
              </Grid>
            </Grid>

            <div className="mb-4"><Divider /></div>

            <Grid container direction="row">
              {/* 1st Row */}
              <Grid xs={12}>
                <Grid container spacing={2} className={classes.formRow}>
                  <Grid item xs={12} md={2} className={classes.fieldLabel}>{t('Select Data to display')}</Grid>
                  <Grid item xs={10} md={6} className={classes.fieldControl}>
                    <FormControl component="fieldset">
                      <RadioGroup row name="row-radio-buttons-group" onChange={radioHandler} defaultValue="all">
                        <FormControlLabel value="all" control={<Radio />} label="Table and chart"/>
                        <FormControlLabel value="table" control={<Radio />} label="Table only"/>
                        <FormControlLabel value="chart" control={<Radio />} label="Chart only"/>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid xs={6}></Grid> */}
              {/* /.1st Row */}

              {/* 2nd Row left side */}
              <Grid xs={6}>
                {/* Year Selection */}
                <Grid container spacing={2} className={classes.formRow}>
                  <Grid item xs={12} md={4} className={classes.fieldLabel}>{t('general_choose_year')} *</Grid>
                  <Grid item xs={10} md={6} className={classes.fieldControl}>
                    <Controller
                      name="year_filter"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={'2021'}
                      render={({ onChange, value, name }) => (
                        <Select
                          className={classes.select}
                          variant="outlined"
                          value={value ? value : 'None'}
                          name={name}
                          margin="dense"
                          fullWidth
                          onChange={(e) => {
                            onChange(e.target.value)
                          }}
                        >
                          {yearAll.map((year) =>
                            <MenuItem key={year.id} value={year.year}>{year.year}</MenuItem>
                          )
                          }
                        </Select>
                        )
                      }
                    />
                  </Grid>
                </Grid>

                {/* Month Selection */}
                <Grid container spacing={2} className={classes.formRow}>
                  <Grid item xs={12} md={4} className={classes.fieldLabel}>
                    {t("general_choose_month")} *
                  </Grid>
                  <Grid item xs={10} md={6} className={classes.fieldControl}>
                    <Controller
                      name="month_filter"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={"January"}
                      render={({ onChange, value, name }) => (
                        <Select
                          className={classes.select}
                          variant="outlined"
                          value={value ? value : "January"}
                          name={name}
                          margin="dense"
                          fullWidth
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        >
                          {monthAll.map((month) => (
                            <MenuItem key={month.id} value={month.month}>
                              {month.month}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* /.2nd Row left side */}

              {/* 2nd Row right side */}
              <Grid xs={6}>
                <Grid container spacing={2} className={classes.formRow}>
                  <Grid item xs={12} md={4} xl={3} className={classes.fieldLabel}>{t('general_choosing_by')}</Grid>
                  <Grid item xs={12} md={3} className={classes.fieldControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // defaultChecked
                            checked={checkedAll && checkedFilter[0] && checkedFilter[1] && checkedFilter[2] && checkedFilter[3]}
                            onChange={allHandler}
                          />
                        }
                        label={t('general_all')}
                      />
                      <FormControlLabel
                        label={t('general_filter_by_date_type')}
                        control={
                          <Checkbox
                            checked={checkedFilter[0] && checkedFilter[1] && checkedFilter[2] && checkedFilter[3]}
                            // indeterminate={checkedFilter[0] !== checkedFilter[1]}
                            onChange={parentFilterHandler}
                            disabled={checkedAll}
                          />
                        }
                      />
                      {filterCheckbox}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              {/* /.2nd Row right side */}

            </Grid>
            
            {/* Divider */}
            <div className="mb-4">
              <Divider />
            </div>

            {/* Export Buttons */}
            <Grid container spacing={2}>
              <Grid item xs={12} className="text-right">
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickPreviewButton}
                  disabled={loading}
                >
                  {t("general_preview")}
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickExcelButton}
                  disabled={loading}
                >
                  {t("general_excel")}
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickPdfButton}
                  disabled={loading}
                >
                  {t("general_pdf")}
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickWordButton}
                  disabled={loading}
                >
                  {t("general_word")}
                </Button>
                {loading && (
                  <Button variant="outlined" className="ml-2 text-center">
                    <CircularProgress size={22} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>

          {/* Bottom Paper */}
          {!loading && previewUrl && (
            <Paper className="mt-4">
              {/* Iframe Previewer */}
              <iframe
                frameBorder="0"
                src={previewUrl}
                className={classes.previewIframe}
              />
            </Paper>
          )}
        </Container>
      </form>
    </PageHelmet>
  );
};

export default REPORT01_IVRMenuSummaryReportMonthlyByDate;
