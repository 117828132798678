import React, { useState } from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import AttachIcon from '@material-ui/icons/AttachmentOutlined';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BlackTooltip from '../BlackTooltip';
import * as FileAPI from '../../apis/FileAPI';
import { logger, helper } from '../../common';
import AttachmentViewer from '../AttachmentViewer';

// To access helper functions shorter.
const { printUserDateTime } = helper;

// Component override styles.
const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: 6,
  },
  scrollable: {
    maxHeight: 185,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 2,
  },
  listItem: {
    paddingLeft: '11px',
    paddingRight: '70px',
  },
  listItemIcon: {
    minWidth: 26,
  },
  listItemAction: {
    right: '6px',
  },
  iconButton: {
    marginRight: '-6px',
    marginLeft: '-6px',
  },
  btnUpload: {
    marginLeft: 8,
  },
}));

// ListItemText secondary syle
const secondaryTypographyPropsStyle = {
  style: {
    fontSize: '0.775rem',
  },
};

/**
 * Taking attachments from props render as a list view.
 * NOTE: This component used for RAMA only (Not standard enough).
 */
const AttachmentList = ({ attachments, onUpload, onDeleted, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [previewAttachment, setPreviewAttachment] = useState(undefined);

  // Handle click download button event.
  const handleClickDownload = (fileId, fileName) => {
    FileAPI.postDownloadFile(fileId)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        logger.error('Unable to download file from server.');
        if (err) logger.error(err);
      });
  };

  // Handle click delete button event.
  // NOTE: Exec callback with no handle results.
  const handleClickDelete = (fileId) => {
    // Temporary disable this due to email-attachents
    // alway added to the ticket when refresh.
    // FileAPI.deleteFileById(fileId)
    //   .catch((err) => {
    //     logger.error('Unable to delete file from server.');
    //     if (err) logger.error(err);
    //   })
    //   .finally(() => {
    //     if (onDeleted) {
    //       onDeleted(fileId);
    //     }
    //   });
    onDeleted(fileId);
  };

  // Handle click upload button event.
  const handleClickUpload = () => {
    onUpload();
  };

  // Handle click attachment item event.
  const handleClickItem = (attachment) => {
    setPreviewAttachment(attachment);
  };

  // Component's main rendering.
  return (
    <div className={classes.container}>

      {/* Attachment List */}
      {attachments && attachments.length > 0 && (
        <PerfectScrollbar className={classes.scrollable}>
          <List dense className={classes.list}>

            {/* Attachment Items */}
            {attachments.map((attachment, index) => {

              // Concatenate subtitle avoid breaking older version
              let attachmentSecondaryText = '';
              if (attachment.length && attachment.uploadDate) {
                const fileSize = filesize(attachment.length);
                const fileDate = printUserDateTime(attachment.uploadDate);
                attachmentSecondaryText = `${fileSize}\u00A0 - \u00A0${fileDate}`;
              }

              // Concatenate attachment name and description
              let attachmentName = attachment.file_name;
              if (attachment.desc) attachmentName += ` (${attachment.desc})`;

              return (
                <ListItem
                  button
                  key={`attachment-item-${index}`}
                  className={classes.listItem}
                  onClick={() => handleClickItem(attachment)}
                >

                  {/* Attachment Icon */}
                  <ListItemIcon className={classes.listItemIcon}>
                    <FileIcon style={{ width: 18 }} />
                  </ListItemIcon>

                  {/* Attachment Name */}
                  <ListItemText
                    primary={attachmentName}
                    secondary={attachmentSecondaryText}
                    secondaryTypographyProps={secondaryTypographyPropsStyle}
                  />

                  {/* Attachment Actions */}
                  <ListItemSecondaryAction className={classes.listItemAction}>

                    {/* Download Button */}
                    <BlackTooltip title={t('general_download')}>
                      <IconButton
                        edge="end"
                        className={classes.iconButton}
                        onClick={() => handleClickDownload(attachment.id, attachment.file_name)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </BlackTooltip>

                    {/* Delete Button */}
                    <BlackTooltip title={t('general_delete_file')}>
                      <IconButton
                        edge="end"
                        data-file-id={attachment.id}
                        className={classes.iconButton}
                        onClick={() => handleClickDelete(attachment.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </BlackTooltip>

                  </ListItemSecondaryAction>
                  {/* /.Attachment Actions */}

                </ListItem>
              );
            })}
          </List>
        </PerfectScrollbar>
      )}

      {/* Upload Button */}
      <Button
        size="small"
        startIcon={<AttachIcon />}
        onClick={handleClickUpload}
        className={classes.btnUpload}
        disabled={disabled}
      >
        {t('general_upload_file')}
      </Button>

      {/* Attachment Viewer */}
      {previewAttachment && (
        <AttachmentViewer
          attachment={previewAttachment}
          onCancel={() => setPreviewAttachment(undefined)}
        />
      )}

    </div>
  );
};

// Component's props types.
AttachmentList.propTypes = {
  attachments: PropTypes.array,
  onUpload: PropTypes.func,
  onDeleted: PropTypes.func,
  disabled: PropTypes.bool,
};

// Component's default props.
AttachmentList.defaultProps = {
  attachments: [],
  onUpload: () => { },
  onDeleted: () => { },
  disabled: false,
};

// Serve as default exports.
export default AttachmentList;
