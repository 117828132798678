import React from 'react';
import {Grid, Card, TextField, List, Box, Button,Typography, FormControl, FormGroup, FormControlLabel, Checkbox, InputBase} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm} from "react-hook-form";
import { useSnackbar } from 'notistack';
import permissions from './Role.json';
import { useTranslation } from 'react-i18next';



const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    card: {
        margin: "1%"
    },
    parallel: {
        width: "70%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "30%",
        maxWidth: "30%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%",
        marginRight: "18%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    form_team: {
        margin: "1%"

    },
    inputBase:{
        width: "70%",
    }


}))


const Create = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [checked, setChecked] = React.useState();


    const handleChange = (event) => {
        permissions.permissions.forEach(val => {
            if (val.name == event.target.name){
            setChecked({ ...checked, [val.name]: event.target.checked });
            }
        });
    };


    const onSubmit= (data,e) => {
      console.log(data);
      
      var body = {
        name: data.name || '',
        desc: data.desc || '',
        permissions: [],
      }

      for (let i = 0; i < data.permissions.length; i++) {
        const element = data.permissions[i];
        if (element.name !== false) {
            body.permissions.push({
                name: element.name,
                desc: element.desc
            })
        }
      }
      console.log("body", body)

      var raw = JSON.stringify(body);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles/${props.location.state.val._id}`,{
        method: 'PUT', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if(response.status >= 200 && response.status <= 299) {
            history.push('/config');    
             enqueueSnackbar('Role has been updated.', { variant: 'success' });
        } 
      })
      .catch((error) => {
        console.error('Error:', error);
      enqueueSnackbar(t('Role has fail to update.'), { variant: 'error' })
      })

    }
 

    return (
        <Grid container spacing={1} >
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title= {t("Edit role")}
                    subtitle= {t("Editing the existing role")}
                />
            </Container>
            <Grid item xs={12}>
                <Card className={classes.form_team} >
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}> {t('Type of role')} * </Typography>
                                <TextField 
                                    fullWidth 
                                    className={classes.parallel} 
                                    inputRef={register({ required: true})} 
                                    name="name" 
                                    defaultValue={props.location.state.val.name} 
                                    variant="outlined"
                                    error={errors.name}
                                    helperText={errors.name && t("Name field is required")}
                                />
                            </Box>
            
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}> {t('Permissions')}  </Typography>
                                {/* <List className={classes.parallel}>
                                {
                                    permissions.permissions.map((permissionItem, index)  => {
                                        const foundIndex  = props.location.state.val.permissions.findIndex((item) => item.name === permissionItem. name);
                                        const isFound = foundIndex > -1;
                                            return (
                                                <Box display="flex" flexDirection="row" className={classes.boxes}>
                                                    <input name={`permissions[${index}].name`} 
                                                        defaultChecked={isFound}            
                                                        value={permissionItem.name}
                                                        type='checkbox'  
                                                        ref={register}
                                                    />
                                                    <div className={classes.spaces}>
                                                        <Typography>{permissionItem. desc}</Typography>
                                                    </div>
                                                    <input name={`permissions[${index}].desc`} type='hidden' value={permissionItem. desc} ref={register}/>
                                                </Box>
                                            );
                                        })
                                }
                                </List> */}
                                <List className={classes.parallel}>
                                    {permissions.permissions.map((permissionItem, index) => {
                                        const foundIndex  = props.location.state.val.permissions.findIndex((item) => item.name === permissionItem. name);
                                        const isFound = foundIndex > -1;
                                        return (
                                                <Box display="flex" flexDirection="row">
                                                    <FormControl className={classes.formControl}>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                value={permissionItem.name}
                                                                control={
                                                                    <Checkbox 
                                                                        defaultChecked={isFound}
                                                                        checked={checked} 
                                                                        onChange={handleChange}
                                                                        name={`permissions[${index}].name`}
                                                                    />
                                                                }
                                                                name={`permissions[${index}].name`}
                                                                inputRef={register}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                    <InputBase
                                                        className={classes.inputBase}
                                                        value={t(permissionItem.desc)}
                                                        inputProps={{ 
                                                            // 'aria-label': 'naked',         
                                                            readOnly: true,
                                                        }}
                                                        name={`permissions[${index}].desc`}
                                                    />
                                                    <InputBase
                                                        type="hidden"
                                                        value={permissionItem.desc}
                                                        inputProps={{ 
                                                            // 'aria-label': 'naked',         
                                                            readOnly: true,
                                                        }}
                                                        inputRef={register}
                                                        name={`permissions[${index}].desc`}
                                                    />
                                                </Box>
                                        );
                                    })}
                                </List>
                            </Box>
                            <Box className={classes.btn_}>
                                <Button type="submit"  color="primary"  variant="contained"> {t('Save')}</Button>
                                <Button color="primary" variant="contained" onClick={() => history.goBack()}> {t('Cancel')}</Button>
                            </Box>
                        </form>
                    
      
                </Card>
            </Grid>
        </Grid>
    )
}
export default Create;