import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography, 
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import 'yup-phone';
import { useSnackbar } from 'notistack';
import { useStyles, SelectionField } from './styles';

const contactTypeOption = [
  'สถานศึกษา',
  'คลินิก',
  'สถานฑูต',
  'RAMA Internal',
  'บริษัท',
  'หน่วยงานราชการ',
  'โรงงาน',
  'โรงแรม',
  'สถานีตำรวจ',
  'Domestic มูลนิธิ',
  'ที่พัก',
  'ACCOUNT',
  'CONTACT',
]

const contactTypeOption2 = contactTypeOption.map((val, index) => (
  {key: index, value: val}
))


const ContactPointDialog = (props) => {
  const { open, onCancel } = props;
  const { enqueueSnackbar } =useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const [existingName, setExistingName] = useState([])
  const [isDuplicateName, setIsDuplicateName] = useState(false)
  // const [selectedCaller, setSelectedCaller] = useState()
  // const [callerOption, setCallerOption] = useState([])

  /* Phone validation */
  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    })
  })
  const yupSchema = yup.object().shape({
    // phone: yup.array().of(yup.string().validatePhone()),
    name: yup.string().required(),
  });
  const { register, handleSubmit, errors, control } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    if (props.triggerToRefresh) props.triggerToRefresh()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 } , [props.contactId])

  /* Close dialog */
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      props.onChangeContactData(props.defaultContactData);
      setExistingName([]);
      setIsDuplicateName(false);
    }
  };

  /* search name */
  function searchEsName(searchName) {
    const input_query = {
      query: {
        filtered: {
          query: {
            query_string: {
              query: `${searchName}`,
              fields: [ "name" ],
              default_operator: "AND",
            }
          },
          filter: {
            bool: {
              must: [
                { term: { "isActive": true } }
              ]
            }
          }
        }
      }
    }
    getEsName(input_query, 'name');
  }

  /* search caller */
  // function searchEsCaller(e) {
  //   const input_query = {
  //     query: {
  //       filtered: {
  //         query: {
  //           query_string: {
  //             query: `*${e.target.value}*`,
  //             fields: [
  //               "name"
  //             ]
  //           }
  //         },
  //       }
  //     }
  //   }
  //   getEsName(input_query, 'selectedCaller');
  // }

  /* ElasticSearch Function */
  const getEsName = (input_query, field) => {
    const query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`;

    fetch(query, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input_query)
    })
      .then(res => res.json())
      .then((data) => {
        let name_list = data.hits.hits.map((v) => {
          v._source._id = v._id;
          return v._source;
        });
        console.log('name_list ', name_list)
        if (field === 'name') setExistingName(name_list);
        // if (field === 'selectedCaller') setCallerOption(name_list);
      });
  }

  /* Check duplicated name */
  const checkDuplicatedName = async (event) => {
    const searchName = await event.target.value;
    searchEsName(searchName)
    props.onChangeContactData({
      ...props.contactData,
      name: searchName,
    })
  }

  /* Save function */
  const onSubmit = (data,e) => {
    console.log('data ', data);
    const arrangedData = {...data, isActive: true}
    var raw = JSON.stringify(arrangedData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (props.submit === 'create') {
      if (existingName.length > 0) {
        setIsDuplicateName(true);
        return;
      };
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299){  
          handleCancel();
          props.triggerToRefresh()
          return enqueueSnackbar('Contact has been created.', { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to create.'), { variant: 'error' });
      }) 
    } else {
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${props.contactId}`,{
        method: 'PUT', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {   
          handleCancel();
          props.triggerToRefresh()
          return enqueueSnackbar(t('Contact has been updated.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to update.'), { variant: 'error' });
      }) 
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Container>
        <DialogTitle className={classes.titleForm} id="form-dialog-title">
          {props.contactId? 'Edit Contact Point' : 'Create Contact Point'}
        </DialogTitle>
        <form className={classes.form_} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            {/* Name */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_name')} *</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="name"
                  inputRef={register}
                  value={props.contactData ? props.contactData.name : ''}
                  onChange={(e) => checkDuplicatedName(e)}
                />
                {errors.name && (<div className={classes.errorMsg}> {t('Name field is required')} </div>)}
                {isDuplicateName && (<div className={classes.errorMsg}> {t('Name could not be duplicated')} </div>)}
              </Grid>
            </Grid>

            {/* Position */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('contact_type')}</Typography>
              <Grid>
                {open && props.contactData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {contactTypeOption2.map((option) => (
                            <MenuItem key={option.key} value={option.value}>{option.value}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.contactType"
                      control={control}
                      defaultValue={props.submit === 'create'? "ACCOUNT" : props.contactData ? (
                        props.contactData.CustomData ? props.contactData.CustomData["contactType"] : ''
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
              
            {/* Province */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('province')}</Typography>
              <Grid>
                {open && props.contactData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {props.address.province && props.address.province.map((option) => (
                            <MenuItem key={option._id} value={option.name}>{option.name}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.addrProvince"
                      control={control}
                      defaultValue={props.submit === 'create'? "ไม่ระบุ" : props.contactData ? (
                        props.contactData.CustomData ? props.contactData.CustomData["addrProvince"] : ''
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Region */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('region')}</Typography>
              <Grid>
                {open && props.contactData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {props.address.region && props.address.region.map((option) => (
                            <MenuItem key={option._id} value={option.name}>{option.name}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.addrRegion"
                      control={control}
                      defaultValue={props.submit === 'create'? "Unknown" : props.contactData ? (
                        props.contactData.CustomData ? props.contactData.CustomData["addrRegion"] : ''
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* VIP */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('vip')}</Typography>
              <Grid>
                {open && props.contactData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          <MenuItem key={'no'} value={'No'}>{'No'}</MenuItem>
                          <MenuItem key={'yes'} value={'Yes'}>{'Yes'}</MenuItem>
                        </Select>
                      }
                      name="CustomData[vipType]"
                      control={control}
                      defaultValue={props.submit === 'create'? 'No' : props.contactData ? (
                        props.contactData.CustomData ? props.contactData.CustomData['vipType'] : ''
                      ) : 'No'}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Search caller */} 
            {/* <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t("caller")}</Typography>
              <Autocomplete
                size="small"
                id="size-small-outlined"
                options={callerOption}
                getOptionLabel={(option) => option.name}
                // getOptionSelected={(option, value) => option.name === value.name}
                onChange={(event, value) => setSelectedCaller(value)}
                defaultValue=""
                value={selectedCaller? selectedCaller: null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{width: 400}}
                    variant="outlined"
                    // error={parentIdCondition}
                    onChange={(e) => searchEsCaller(e)}
                  />
                )}
              />
            </Grid> */}

          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">Save</Button>
            <Button onClick={handleCancel} color="primary">Cancel</Button>
          </DialogActions>
        </form>
        
      </Container>
    </Dialog>
  )
}

export default ContactPointDialog;