import React from 'react';
import ReactDOM from 'react-dom';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppProvider } from './contexts/AppStore'
import { helper } from './modules/TM/src/common';
import * as serviceWorker from './serviceWorker';
import App from './App';

// https://github.com/simbathesailor/use-what-changed
setUseWhatChange(process.env.NODE_ENV === 'development');

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Sarabun',
      'Roboto',
      'sans-serif',
    ],
  },
  palette: {
    primary: {
      light: '#5ee7ef',
      main: '#2d3b42',
      dark: '#05151b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7bcab7',
      main: '#02b4bd',
      dark: '#00848d',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$active': {
          color: '#44545c',
        },
      },
      active: {},
      completed: {},
    },
    MuiFormLabel: {
      asterisk: {
        color: '#ff2d2d',
      }
    }
  },
});

ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App locale={helper.getCookie('locale') || 'en'} />
      </ThemeProvider>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
