import React, {useRef} from 'react';
import { Grid, IconButton, Typography, Table, TableHead, Button, TableBody, TableRow, TableCell} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    top: {
        marginLeft: "1%",
        marginRight: "1%",
    },
    formControl: {
        marginLeft: "1%"
    },
    btn_search: {
        marginLeft: "1.5%",
        height: "3%",
        marginTop: "0.5%"
    },
    table: {
        minHeight: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        marginLeft: "1%",
        marginRight: "1%"
    },
    ty: {
        margin: "3%",
      }
 
}));

function ConfigRole(props) {
    const history = useHistory();
    // const {t}= useTranslation();
    const classes= useStyles();
    const [age, setAge] = React.useState('');
    const [tbList, set_tbList] = React.useState([]);
    const [per, set_per] = React.useState([]);
    const tableRef = useRef(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("");

    const handleDeleteDialog = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }


    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles` )
            .then(res => res.json())
            .then((data) => {
              set_tbList(data.data)
              set_per(data.data.permissions)
            })
    } , [])

    const Delete_role = (data) => {
        setDeleteOpen(false)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles/${data}`,{
          method: 'DELETE', 
        //   headers: myHeaders,
        })
        .then(response => {
            if( response.status >= 200 && response.status <=299){        
                enqueueSnackbar(t('Role has been deleted.'), { variant: 'success' });
                fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles`)
                    .then(res => res.json())
                    .then((data) => {
                        set_tbList(data.data)
                    })
            }
        })
        .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar(t('Role has fail to delete.'), { variant: 'error' });
        }) 
    }

 
 
    return (
        <Grid container spacing={1} className={classes.whole}>
         <Container maxWidth="xl" className={classes.container}>
            <PageHeader
            title= {t("Config Roles")}
            subtitle= {t("Managing roles and permission for the employee")}
            buttonName= {t("New Role")}
            onClickButton={()=> history.push('/config/role/create')}
            />
         </Container>    
        <Grid item xs={12} className={classes.table}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="body2"> {t('Type of Role')}</Typography></TableCell>
                        <TableCell><Typography variant="body2"> {t('Description')}</Typography></TableCell>
                        <TableCell><Typography variant="body2"> {t('Actions')}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tbList && tbList.map((val) => (
                    <React.Fragment key={val._id}>
                    <TableRow>
                        <TableCell><Typography>{val.name}</Typography></TableCell>
                        <TableCell>
                            {/* {console.log(tbList.permissions)}
                            {val.permissions && val.permissions.map((val1) => (
                                <React.Fragment key={val1._id}>    
                                    <Typography>{val1.desc}</Typography>
                                </React.Fragment>
                            ))} */}
                        </TableCell>
                        <TableCell><IconButton onClick={()=> history.push({pathname: '/config/role/edit', state: {val: val}})} ><EditOutlinedIcon/></IconButton>
                            <IconButton onClick={() => handleDeleteDialog(val._id)}><DeleteForeverOutlinedIcon/></IconButton>
                        </TableCell>
                    </TableRow>
                    </React.Fragment>
                    ))}
                </TableBody>
            </Table>

            {/* Delete confirmation dialog */}
            <Dialog
                open={deleteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteDialogClose}
            >
                <DialogTitle>{t('Are you sure you want to delete the role?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('This will permanently delete the role.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="secondary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={() => Delete_role(deleteId)} color="primary">
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
                
        </Grid>                    
        </Grid>
    )
}

export default ConfigRole;
