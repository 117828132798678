import axios from 'axios';
import url from 'url';

const GET_SOME_SURVEY_URL = url.resolve(process.env.REACT_APP_SM_BACKEND_FQDN, '/sm/api/v1/surveys');

// eslint-disable-next-line import/prefer-default-export
export const getSurveys = (queryString = '') => {
  return axios({
    url: `${GET_SOME_SURVEY_URL}?${queryString}`,
    method: 'GET',
  });
};
