/* eslint-disable no-console */

const info = (...args) => {
  console.log(...args);
};
const debug = (...args) => {
  console.debug(...args);
};
const warn = (...args) => {
  console.warn(...args);
};
const error = (...args) => {
  console.error(...args);
};

export {
  info,
  debug,
  warn,
  error,
};
