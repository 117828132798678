import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  AppBar, Container, Box} from '@material-ui/core';
import {   Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import ConfigUser from './Users/ConfigUsers'
import ConfigTemplate from './Template/ConfigTemplate'
import ConfigTeams from './Teams/ConfigTeam'
import ConfigRole from './Role/ConfigRole'
import ConfigPriority from './Priority/ConfigPriority';
import ConfigCategory from './Category/ConfigCategory';
import ConfigStatus from './Status/ConfigStatus';
import ConfigChannel from './Channel/ConfigChannel';
import ConfigAnnouncement from './Announcement/ConfigAnnounce';
import ConfigGeneral from './General/ConfigGeneral';
import ConfigAnnounceGroup from './Announcement Group/ConfigAnnounceGroup';
import ConfigTM from './Rama TM Config/ConfigTM';
import ConfigPrefix from './Prefix/ConfigPrefix'
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../TM/src/components/PageHeader';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppStore';
import selectedModules from '../../../SELECTED_MODULES/index';



    function TabPanel(props) {
        const { children, value, index, ...other } = props;
  
        return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                {children}
            </Box>
            )}
        </div>
        );
    }
  
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
  
    function a11yProps(index) {
        return {
            index: index,
            'data-index': index,
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }
  
    const useStyles= makeStyles ((theme) => ({
        whole: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            // margin: "1%"
        },
        container: {
            paddingTop: theme.spacing(2.5),
            paddingBottom: theme.spacing(3),
        },
    }))

const Main = (props) => {
    const classes=useStyles();
    const { t } = useTranslation();
    const [value, setValue] = React.useState(parseInt(localStorage.getItem("LS")) || 0);
    const history = useHistory();
    const {hasPermission } = useContext(AppContext);
    const [test, setTest] = React.useState(true)



    /* store the tabs index in local storage */
    React.useEffect(() => {
        localStorage.setItem("LS", value);    

        if (hasPermission('announce_group_config_tab') == true) {
            setTest(true)
        }
        else {
            setTest(false)
        }
        
      }, [value]);
     

    const handleChange = (e, newValue) => {   
        setValue(newValue);
    };

    

    return (
        <Container maxWidth="xl" className={classes.container}>
            <PageHeader
                title={t("configuration")}
                subtitle={t("setting_menu_for_the_supervisors")}
            />
            <div className={classes.whole}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        variant="scrollable" 
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label= {t("config_users")} {...a11yProps(0)} value={0} />
                        {process.env.REACT_APP_BUILD_TARGET !== 'RAMA' && (
                            <Tab label= {t("config_template")}  {...a11yProps(1)} value={1} />
                        )}
                        <Tab label= {t("config_teams")} {...a11yProps(2)} value={2} />
                        <Tab label= {t("config_role")} {...a11yProps(3)} value={3} />
                        <Tab label= {t("config_category")} {...a11yProps(4)} value={4} />
                        <Tab label= {t("config_priority")} {...a11yProps(5)} value={5} />
                        <Tab label= {t("config_status")} {...a11yProps(6)} value={6} />
                        <Tab label= {t("config_channel")} {...a11yProps(7)}  value={7} />
                        <Tab label= {t("config_announcement")} {...a11yProps(8)} value={8} />
                        {process.env.REACT_APP_BUILD_TARGET !== 'RAMA' && (
                            <Tab label={t("config_general")} {...a11yProps(9)} value={9} />
                        )}
                        <Tab label={t("config_prefix")} {...a11yProps(10)} value={10} />
                        {hasPermission('announce_group_config_tab') && (
                            <Tab label={t("config_announce_group")} {...a11yProps(11)} value={11} />
                        )} 
                        {selectedModules.tmConfig && (
                            <Tab label={t("config_custom")} {...a11yProps(12)} value={12} />    
                        )}
              


                    </Tabs>
                </AppBar>                

                <TabPanel value={value} index={0}  >
                    <ConfigUser/>
                </TabPanel>
                {process.env.REACT_APP_BUILD_TARGET !== 'RAMA' && (
                    <TabPanel value={value}  index={1} >
                        <ConfigTemplate/>
                    </TabPanel>
                )}
                <TabPanel value={value} index={2} >
                    <ConfigTeams/>
                </TabPanel>
                <TabPanel value={value} index={3} >
                    <ConfigRole/>
                </TabPanel>
                <TabPanel value={value} index={4} >
                    <ConfigCategory/>
                </TabPanel>
                <TabPanel value={value} index={5} >
                    <ConfigPriority/>
                </TabPanel>
                <TabPanel value={value} index={6} >
                    <ConfigStatus/>
                </TabPanel>
                <TabPanel value={value} index={7} >
                    <ConfigChannel/>
                </TabPanel>
                <TabPanel value={value} index={8} >
                    <ConfigAnnouncement/>
                </TabPanel>
                {process.env.REACT_APP_BUILD_TARGET !== 'RAMA' && (
                    <TabPanel value={value} index={9} >
                        <ConfigGeneral/>
                    </TabPanel>
                )}
                <TabPanel value={value} index={10} >
                    <ConfigPrefix/>
                </TabPanel>
                {test && (
                    <TabPanel value={value} index={11} >
                       <ConfigAnnounceGroup/>
                   </TabPanel>
                )}
                {test && (
                    <TabPanel value={value} index={12} >
                        <ConfigTM/>
                    </TabPanel>
                )}
                {test == false && (
                    <TabPanel value={value} index={13} >
                        <ConfigTM/>
                    </TabPanel>
                )}
               
            </div>
        </Container>

    )
}
export default Main;
