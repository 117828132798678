/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHelmet from '../../components/PageHelmet';
import * as yup from 'yup';
import moment from 'moment';
import queryString from 'query-string';
import DatePeriodSelector from '../../components/DatePeriodSelector';

// Set the report url for this component here.
const THE_JASPER_REPORT_URL = process.env.REACT_APP_JASPER_REPORT_WITHOUT_PORT_FQDN + 
  '/jasperserver/rest_v2/reports/cream/Export_tickets_data_to_excel_follow_up_data_included';

// Intial material-ui overriding styles.
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  reportHead: {
    fontSize: '1.15rem',
    paddingBottom: '1.15rem',
  },
  footerDivider: {
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.25rem',
    },
  },
  previewIframe: {
    height: 640,
    width: '100%',
  },
}));


const RAMA_REPORT22 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [timeSelection, setTimeSelection] = useState('date');

  const timeSelectionhandleChange = (event) => {
    setTimeSelection(event.target.value);
  };

  // React hook form schema.
  const yupSchema = yup.object().shape({
    ...(timeSelection==='date' && {selectedDate: yup.date().required()}),
    ...(timeSelection==='week' && {selectedWeek: yup.date().required()}),
    ...(timeSelection==='period' && {fromDate: yup.date().required()}),
    ...(timeSelection==='period' && {toDate: yup.date().required()}),
  });

  // React hook form initialize.
  const { handleSubmit, control, errors } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(yupSchema),
  });

  // Concat & return report sub title.
  const getReportSubtitle = (fromDate, toDate, isFile) => {
    if (!fromDate || !toDate) return '';
    const text = isFile ? 'D MMM YYYY' : 'D MMM YYYY HH:mm';
    return `${moment(fromDate).format(text)} - ${moment(toDate).format(text)}`;
  };

  // Concat & return report file name.
  const getReportFileName = (fromDate, toDate, fileExt) => {
    // console.log(fromDate, toDate, fileExt);
    const title = t('kb_most_active_report_name');
    const subtitle = getReportSubtitle(fromDate, toDate, true);
    return `${title} ${subtitle}.${fileExt}`;
  };

  // Handle click preview button event.
  const handleClickPreviewButton = () => {
    const callback = (data) => {
      data.template = 'preview';
      data.fileExt = 'html';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickExcelButton = () => {
    const callback = (data) => {
      data.template = 'excel';
      data.fileExt = 'xlsx';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click pdf button event.
  const handleClickPdfButton = () => {
    const callback = (data) => {
      data.template = 'pdf';
      data.fileExt = 'pdf';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickWordButton = () => {
    const callback = (data) => {
      data.template = 'word';
      data.fileExt = 'docx';
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle form submit event.
  const onSubmitForm = async (formData) => {
    setPreviewUrl(null);
    setLoading(true);

    // Destructure parameters for report from the form.
    const { 
      selectedDate,
      selectedWeek,
      fromDate,
      toDate,
      yearOfMonthSelection,
      monthOfMonthSelection,
      yearOfYearSelection,
    } = formData;

    // Create a filename & subtitle of report.
    const filename = getReportFileName(fromDate, toDate, formData.fileExt);

    // Concat output format to jasper report formaturl.
    let reportUrl = `${THE_JASPER_REPORT_URL}.${formData.fileExt}?`;

    // Adds the url parameters here (query string).
    reportUrl += queryString.stringify({
      ...(timeSelection==='period' && fromDate && { stDate: moment(fromDate).startOf('date').toISOString() }),
      ...(timeSelection==='period' && toDate && { enDate: moment(toDate).endOf('date').toISOString() }),
      ...(timeSelection==='date' && selectedDate && { stDate: moment(selectedDate).startOf('date').toISOString() }),
      ...(timeSelection==='date' && selectedDate && { enDate: moment(selectedDate).endOf('date').toISOString() }),
      ...(timeSelection==='week' && selectedWeek && { stDate: moment(selectedWeek).startOf('date').toISOString() }),
      ...(timeSelection==='week' && selectedWeek && { enDate: moment(selectedWeek).add(6, 'days').endOf('date').toISOString() }),
      ...(timeSelection==='month' && { stDate: moment().set('year', yearOfMonthSelection).set('month', monthOfMonthSelection).startOf('month').toISOString() }),
      ...(timeSelection==='month' && { enDate: moment().set('year', yearOfMonthSelection).set('month', monthOfMonthSelection).endOf('month').toISOString() }),
      ...(timeSelection==='year' && { stDate: moment().set('year', yearOfYearSelection).startOf('year').toISOString() }),
      ...(timeSelection==='year' && { enDate: moment().set('year', yearOfYearSelection).endOf('year').toISOString() }),
    });

    // Preview report or download report file?
    if (formData.template === 'preview') {
      
      // Set the preview report url state.
      setLoading(false);
      setPreviewUrl(reportUrl);

    } else {
      
      // Download report file via JasperReport Server.
      setLoading(false);
      const link = document.createElement('a');
      link.href = reportUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <PageHelmet title="Export tickets data to excel-follow up data included">
      <form>
        <Container maxWidth="xl" className={classes.container}>

          {/* Top Paper */}
          <Paper className={classes.paper}>

            {/* Report Name */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.reportHead}>
                  22. Export tickets data to excel-follow up data included
                </Typography>
              </Grid>
            </Grid>

            {/* Divider */}
            <div className="mb-4"><Divider /></div>

            {/* 1st Row */}
            <DatePeriodSelector
              control={control}
              timeSelection={timeSelection}
              timeSelectionhandleChange={timeSelectionhandleChange}
              selectedDateErr={errors.selectedDate}
              selectedWeekErr={errors.selectedWeek}
              fromDateErr={errors.fromDate}
              toDateErr={errors.toDate}
            />
            {/* /.1st Row */}

            {/* Divider */}
            <div className={classes.footerDivider}><Divider /></div>

            {/* Export Buttons */}
            <Grid container spacing={2} >
              <Grid item xs={12} className="text-right">
                <Button variant="outlined" className="ml-2" onClick={handleClickPreviewButton} disabled={loading}>{t('general_preview')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickExcelButton} disabled={loading}>{t('general_excel')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickPdfButton} disabled={loading}>{t('general_pdf')}</Button>
                <Button variant="outlined" className="ml-2" onClick={handleClickWordButton} disabled={loading}>{t('general_word')}</Button>
                {loading && (
                  <Button variant="outlined" className="ml-2 text-center">
                    <CircularProgress size={22} />
                  </Button>
                )}
              </Grid>
            </Grid>

          </Paper>
          
          {/* Bottom Paper */}
          {!loading && previewUrl && (
            <Paper className="mt-4">
              {/* Iframe Previewer */}
              <iframe frameBorder="0" src={previewUrl} className={classes.previewIframe} />
            </Paper>
          )}

        </Container>
      </form>
    </PageHelmet>
  );
};

export default RAMA_REPORT22;
