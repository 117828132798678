import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../contexts/AppStore';
import { makeStyles } from '@material-ui/styles';
import { VscMegaphone } from 'react-icons/vsc';
import { logger } from '../modules/TM/src/common';
import { get } from 'dot-prop';
import Carousel from 'react-material-ui-carousel';
import Alert from '@material-ui/lab/Alert';
import ws from '../modules/WebSock';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
  root: {
    left: '50%',
    transform: 'translate(-50%)',
    position: 'fixed',
    zIndex: 1299,
    maxWidth: 900,
    top: 7,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      transform: 'none',
      maxWidth: '100%',
      width: '100%',
      top: 'auto',
      bottom: 0,
    },
  },
  alert: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
    },
  },
  toolTip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(50),

  }
}));

// TODO: Try use schedule by setTimeout instead.
const AnnounceCarousel = () => {
  const classes = useStyles();
  const { loginUser } = useContext(AppContext);
  const [announcements, setAnnouncements] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [announceGroupsbyAgentId, setAnnounceGroupsbyAgentId] = React.useState([]);

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  // TEMP: Handle announcement expire date every 5 seconds.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRefresh(!refresh);
    }, 1000 * 5);
    return () => clearTimeout(timeoutId);
  }, [refresh]);

  // Filter effective, expiry and team then reset the state.
  const filterAnnouncements = (announcements) => {

    const teamId = loginUser.team;
    // console.log('login user team ', loginUser.team)
    let matchAudience;
    return announcements.filter(item => {
      // console.log(item.name)
      const isEffective = new Date(item.effectiveOn) <= new Date();
      const notExpire = new Date(item.expiresOn) - new Date() >= 0;
      // console.log('isEffective', isEffective)
      // console.log('notExpire',   )

      if (item.audienceType === 'team') {
        // console.log('announcement team', item.audienceId)
        matchAudience = item.audienceId === '0' || item.audienceId === teamId;
      } 
      else if (item.audienceType === 'group') {
        const foundIndex = announceGroupsbyAgentId.findIndex((group) => group._id === item.audienceId);
        matchAudience = foundIndex !== -1;

      }
      else {
        console.log('Audience type not implemented')
      }
      return isEffective && notExpire && matchAudience;

    });
  };

  const getGroup = () => {
    axios.get(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcement-groups/agentId/${loginUser._id}`)
      .then((res) => {
        const arrayOfAnnounceGroups = get(res, 'data', []);
        setAnnounceGroupsbyAgentId(arrayOfAnnounceGroups)
      })
      .catch((err) => {
        logger.error('Unable to get announcement group from server.');
        logger.error(err);
      });

  }

  // This will get execute on initial render.
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements`)
      .then((res) => {
        const arrayOfAnnounce = get(res, 'data.data', []);
        setAnnouncements(arrayOfAnnounce);
        getGroup();
      })
      .catch((err) => {
        logger.error('Unable to get announcements from server.');
        logger.error(err);
      });
  }, []);

  // The callback when configure announcements.
  const onAnnouncementEvent = (what, event) => {
    // console.log(`onAnnouncementEvent: ${JSON.stringify(event, null, 2)}`);
    setAnnouncements(event.data.announcements);
  };

  // Register the callback to "announcement" event.
  ws.Attach_callback('announcement', onAnnouncementEvent);

  // TEMP: Verify announcement expiry every re-render.
  const aliveAnnounces = filterAnnouncements(announcements);
  
  return (
    <Carousel
      maxWidth="auto"
      animation="slide"
      interval={5000}
      indicators={false}
      className={classes.root}
      navButtonsAlwaysInvisible
    >
      {aliveAnnounces.map((announce) => (
        <div key={announce._id}>
          <Alert
            key={announce._id}
            className={classes.alert}
            icon={<VscMegaphone />}
            severity="warning"
            action={
              <Button size='small' onClick={handleDialogOpen}>
                See More
                  </Button>
            }
          >
            <div style={{ marginTop: '0.5px', textAlign: 'center' }}>
              {announce.message.substring(0, 65) + '...'}
            </div>
          </Alert>

          <Dialog onClose={handleDialogClose} open={open}>
            <MuiDialogTitle onClose={handleDialogClose}>
              {announce.name}
            </MuiDialogTitle>
            <MuiDialogContent dividers>
              <Typography gutterBottom>
                Announce Date: {new Date(announce.effectiveOn).toLocaleString()}
              </Typography>
              <Typography gutterBottom>
                {announce.message}

              </Typography>
            </MuiDialogContent>
            <MuiDialogActions>
              <Button autoFocus variant='contained' onClick={handleDialogClose} color="primary">
                Dismiss
                </Button>
            </MuiDialogActions>
          </Dialog>

        </div>
      ))}
    </Carousel>
  )
}

export default AnnounceCarousel;
