import React from 'react';
import { Grid, Box,  List, Button, TableRow,  CardContent, Divider, Card, Typography, IconButton,  TableCell} from '@material-ui/core';
import {makeStyles, createTheme, ThemeProvider} from '@material-ui/core/styles';
import PageHeader from '../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { convertFromRaw } from 'draft-js';
import { EditorState } from 'draft-js';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {  useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppStore';
import { Editor as NewEditor } from 'react-draft-wysiwyg';





import { green, red } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "1%",
    },
    view_card: {
        marginLeft: "1%",
        marginRight: "1%"
    },
    view_list: {
        marginRight: "3%"
    },
    side_card: {
        flexGrow: 1,
        // position: 'relative'
        margin: "1%",
        // position: "fixed"
        // marginRight: "1%"
        width: "25%"
    },
    help_:{
        margin: theme.spacing(1),
        '& > *': {
               margin: theme.spacing(1),
        },
    },
    content_area: {
        width: "80%",
        textAlign: "center"
        // maxHeight: "60vh",
        // overflow: "auto",
    },
    add_comment: {
        marginLeft: "1%",
        width: "40%"
    },
    tags: {
        color: "#757575",
        marginLeft: "1%",
        // width: "20%"   
    },
    medium: {
        marginLeft: "3%",
    },
    details: {
        // width: "65%",
        color: "#757575",
    },
    title_: {
        width: "85%"
    },
    post_comment: {
        marginTop: "1.5%",
        marginBottom: "1.5%",
        marginLeft: "1%"
    },
    form_comment: {
        width: "100%",
        height: "100%"
    },
    comment_text: {
        margin: "1%"
    },
    all: {
        height: "100%"
    },
    checking: {
        marginLeft: "35%",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    seeAlsoSpace: {
        margin: "2%"
    }

}))

const theme = createTheme({
    palette: {
      primary: green,
      secondary: red
    },
  });

const Approving = (props) => {
    const classes= useStyles();
    const [listFiles, setListFiles] = React.useState([]);
    const { t } = useTranslation();
    const content={};
    const [editorState, setEditorState] = React.useState(undefined);
    const [allArticles, setAllArticles] = React.useState([]);
    const [allArticlesByCategory, setAllArticlesByCategory] = React.useState([]);
    const [articleInfo, setArticleInfo] = React.useState({});
    const { enqueueSnackbar } =useSnackbar();
    const history = useHistory();
    const { hasPermission, loginUser } = React.useContext(AppContext);
    const [articleOwner, setArticleOwner] = React.useState();


    const article_id = props.location.state.article_id;
    const username = loginUser._id

    
    const isOwner = (article_obj) => (article_obj.owner && article_obj.owner === loginUser._id);

    const filterArticles = (list) => {
        let filtered_list = list.filter((v) => {
            if (isOwner(v))
              return true;
            if (v.kb_status === 'approve')
              return true;
            if (hasPermission('article_approve') && v.kb_status === 'waiting approval')
              return true;
            return false;
          });
          return filtered_list;
    }
    
    const fetchArticlesByCategory = (category, limit) => {
        // console.log(`[ViewKB] FETCHING All Articles by Category (for See Also)`);
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/GetAllByCategory?category_id=${category}&limit=${limit}`)
            .then(res => res.json())
            .then((data) => {
                // console.log("[ViewKB] data (for setAllArticlesByCategory)", data);
                setAllArticlesByCategory(filterArticles(data));
            })
    };

    const fetchAllArticles = () => {
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article`)
        .then(res => res.json())
        .then((data) => {
            // console.log("fetching all articles", data);
            setAllArticles(data);
        })
    }

    const fetchContacts = (articleOwnerId) => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`)
            .then((response) => response.json())
            .then((data) => {

                const articleOwnerInfo = data.data.find((element) => element._id == articleOwnerId)
                articleOwnerInfo ? setArticleOwner(articleOwnerInfo.name) : setArticleOwner("")
            })

    }

    const fetchArticle = () => {
        // console.log(`[ViewKB] FETCHING Article`);
        // console.log("[ViewKB] KB >>> article_id", article_id);
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${article_id}`)
            .then(res => res.json())
            .then((data) => {
                // console.log("[ViewKB] data (article)", data);
                
                setArticleInfo(data.article);
                setListFiles(data.article.files)
                data.article.owner ? fetchContacts(data.article.owner) : setArticleOwner("")

            
                if (data.content.blocks != null){
                    const content = {};
                    content.blocks = data.content.blocks
                    content.entityMap = data.content.entityMap
                    setEditorState(EditorState.createWithContent(convertFromRaw(content)))
                }
                else {
                    setEditorState(EditorState.createEmpty())
                }

                // const state = content
                //     ? EditorState.createWithContent(convertFromRaw(content))
                //     : EditorState.createEmpty();
                // setEditorState(state);
            })
    };

    React.useEffect( () => {
        fetchArticle();
    }, [] )

    React.useEffect( () => {
        if(articleInfo.category)
            fetchArticlesByCategory(articleInfo.category._id, 10);
    }, [articleInfo] )

    React.useEffect( () => {
        if(articleInfo.category)
            fetchAllArticles();
    }, [articleInfo] )

    const Download_file = (data, data1) => {
    // console.log(data)
    axios({
        url: `${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile?id=${data}`,
        method: 'POST',
        responseType: 'blob',
        }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
    
        fileLink.href = fileURL;
        fileLink.setAttribute('download', data1);
        document.body.appendChild(fileLink);
        fileLink.click();
        });
    }

    const Approve = () => {
        // console.log("trying to approve")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/Approve?articleId=${article_id}&username_id=${username}&isApproved=true`,{
        method: 'PUT', 
        headers: myHeaders,
        redirect: "follow"
        })
        .then(response => {
        if(response.status >= 200 && response.status <299) {history.push('/kb');    
            return enqueueSnackbar(t('Article has approved.'), { variant: 'success' });
        } 
        })
        .catch((error) => {
        console.error('1111Error:', error);
        if(error) {  
            return enqueueSnackbar(t('Article has fail to approve.'), { variant: 'error' });
        } 
        }) 
    }

      const Reject = () => {
        // console.log("trying to UNapprove")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/Approve?articleId=${article_id}&username_id=${username}&isApproved=false`,{
          method: 'PUT', 
          headers: myHeaders,
          redirect: "follow"
        })
        .then(response => {
            if(response.status >= 200 && response.status <299) {history.push('/kb')   
                return enqueueSnackbar(t('Article has rejected.'), { variant: 'success' });
            } 
        })
        .catch((error) => {
          console.error('1111Error:', error);
          if(error) {    
                return enqueueSnackbar(t('Article has fail to reject.'), { variant: 'error' });
            } 
        }) 
    }



    return (
        <Grid container>
            <Container maxWidth="xl" className={classes.container} spacing={1}>
                <PageHeader
                title={t('Approving Article')}
                subtitle={t('Reviewing the newly created article by supervisor')}
                />
            </Container>
            <Grid item xs={12}>
                <Card className={classes.view_card}>
                    <CardContent className={classes.medium}>
                        
                        {/* Article title, tag, bookmark */}
                        <Box display="flex" flexDirection="row" >
                            <List className={classes.title_}>
                                <Typography variant="h5">{articleInfo.title}</Typography>
                            </List>
                           <List>
                               <Box display="flex" flexDirection="row" className={classes.tags}>
                                   <LocalOfferOutlinedIcon/> {articleInfo.tags}
                                </Box>
                            </List>
                        </Box>

                        {/* Article caption */}
                        <List>
                            <Typography variant="subtitle1"> {articleInfo.caption} </Typography>
                        </List>


                       {/* Article owner, version, created at,
                            Article score, status, followers
                          */}
                        <div>
                            <List className={classes.details}>
                                {t("Owner")}: {articleOwner} | {t('Version')}: {articleInfo.version} | {t('Created at')}: {new Date(articleInfo.dateCreated).toLocaleString()}  | 
                                Start Publish: {new Date(articleInfo.startPublish).toLocaleDateString()} | End Publish: {new Date(articleInfo.endPublish).toLocaleString()}
                            </List>
                            <List className={classes.details}>
                                {t('Score')}: {articleInfo.score} | {t('Status')}: {articleInfo.kb_status} | {t('Followers')}: {articleInfo.followers ? articleInfo.followers.length : 0}
                            </List>
                        </div>

                        
                        <Box className={classes.all} display="flex" flexDirection="row">
                            
                            {/* Article editor  */}
                            <div className={classes.content_area} >
                                <NewEditor 
                                    editorState={editorState}
                                    toolbarHidden={true}
                                    toolbar={{
                                        image: {
                                          alignmentEnabled: false,
                                        }
                                    }}
                                />
                            </div>

                            <div className={classes.extraSpace}></div>

                            {/* Article Attach files */}
                            <Box className={classes.side_card} >
                                <Box fontWeight="fontWeightBold">{t('List of files')}</Box>
                                {listFiles && listFiles.map((row) => (
                                    <React.Fragment key={row.id}>
                                       <Box display="flex" flexDirection="row" margin="1%">
                                            <Typography>{row.file_name}</Typography>
                                            <IconButton onClick={() => Download_file(row.id, row.file_name)} color="primary">
                                                <GetAppOutlinedIcon/>
                                            </IconButton>
                                        </Box>
                                    </React.Fragment>
                                ))}
                                <Divider />
                            </Box>

                        </Box>


                        <Box className={classes.checking} display="flex" flexDirection="row">
                            <ThemeProvider theme={theme}>
                                <Button variant="contained" onClick={Approve} color="primary"><CheckOutlinedIcon/>{t('Approve')}</Button>
                                <Button color="secondary" onClick={Reject} variant="contained"><CloseOutlinedIcon/>{t('Reject')}</Button>
                            </ThemeProvider>
                        </Box>
                        
                      
                    </CardContent>
                </Card>
                
                   
               
            </Grid>

        </Grid>

    )
}
export default Approving;