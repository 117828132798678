/**
 * Selected Modules will assist with Allow/Disallow of Modules and Pages
 * 
 * Please use & apply on the:
 *    - React Router
 *    - Navigation Menu
 */

const DEBUG = false;
const debug = (...msg) => { if (DEBUG) console.log(...msg) };

// Default list of all Modules.
const allModulesDefault = {
  dashboard: true,
  tm: true,
  kb: true,
  contact: true,
  chat: true,
  email: true,
  reports: true,
  tts: true,
  tmConfig: true,
  substance: true,
  troubleshoot: true,
};
// DEFAULT MODULES SHOULD *ALL* BE ENABLED. Please DISABLE via ARRAY Below!

// DISABLED Modules - any names here will hide/disable pages/modules
// const stdDisabledModules = [];
const exatDisabledModules = ['dashboard', 'email', 'tmConfig', 'substance', 'troubleshoot'];
const ramaDisabledModules = ['tts', 'chat'];
const stdDisabledModules = exatDisabledModules; // <--- Currently STD is EXAT until we separate it.

const currentDisabledModules = () => {
  let disabledModules;
  switch(process.env.REACT_APP_BUILD_TARGET.toUpperCase()) {
    case 'STD' : disabledModules = stdDisabledModules; break;
    case 'EXAT': disabledModules = exatDisabledModules; break;
    case 'RAMA': disabledModules = ramaDisabledModules; break;
    default: disabledModules = stdDisabledModules;
  }
  return disabledModules;
};

// Updates and returns the selected Modules
const getSelectedModules = () => {
  let moduleList = { ...allModulesDefault };
  
  currentDisabledModules().map((name) => {
    if (moduleList[name]) {
      moduleList[name] = false;
    }
  });
  debug('moduleList', moduleList);

  return moduleList;
};
const selectedModules = getSelectedModules();

// DEBUG
// console.log(`selectedModules.email = ${selectedModules.email}`);

export default selectedModules;
