import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Left from '../elements/left';
import Right from '../elements/right';
// import PageHelmet from '../../TM/src/components/PageHelmet';
// import PageHeader from '../../TM/src/components/PageHeader';
// import { Container } from '@material-ui/core';
// import {  useSnackbar } from 'notistack';
// import axios from 'axios';
import EmailPagination from '../elements/EmailPagination';


const zIndex = 1500;
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  root: {
    // borderRadius: 4,
    zIndex,
    height: 600,
    display: 'flex',
    // overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);',
  },
  left: {
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    '@media (min-width: 864px)': {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  Pagination: {
    height: 50,
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    '@media (min-width: 864px)': {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  right:{
    flexGrow: 1,
    width: 650,
    flexBasis: 650,
    flexShrink: 0,
  }

}))


const EmailDialogContent = ({
  trackingId,
  emails,
  pageAll,
  pageNum,
  pageSize,
  changeRowsPerPageQuery,
  firstPage,
  backPage,
  nextPage,
  lastPage,
}) => {
  // const history = useHistory();
  const classes = useStyles();
  // const { t } = useTranslation();
  // const { enqueueSnackbar } =useSnackbar();
  const [boolReply, setBoolReply] = useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState();
  const [boolForward , setBoolForward] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const onSelectEmail = (email) => {
    setSelectedEmail(email)
    setBoolReply(false)
    setBoolForward(false)
  }

  const handleReply = (bool) => {
    setBoolReply(bool)
    setBoolForward(false)
    setAnchorEl(null);
  }

  const handleForward = () => {
    setBoolForward(true);
    setBoolReply(false)
    setAnchorEl(null);
  }

  const handleDiscard = () => {
    setBoolReply(false)
    setBoolForward(false)
  }

  const handleMoreOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMoreClose = () => {
    setAnchorEl(null);
  };




  // const fetchEmails = () => {
    
  //   // fetching emails with tracking id --> set as ticket id in the welcome email
  //   let url = `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/Emails/GetEmailsbyTracker?id=${trackingId}`;

  //   let config = {
  //     method: 'GET',
  //     timeout: 1000 * 3,
  //     headers: {
  //         "Content-Type": "application/json"
  //     },
  //   };

  //   axios( url, config )
  //     .then(response => {
  //       if (response.status == 200) {
  //         console.log('trigger', response.data)
  //         setEmails(response.data)
  //       }
  //     })
  //     .catch(err => {
  //       enqueueSnackbar(t('Fail to load emails.'), { variant: 'error' });
  //       console.log(err.message)
  //     });

  // }



  // useEffect( () =>  {
  //   fetchEmails()
  // }, [])







  return (

      // <PageHelmet title={t('email_page_title')}>
      //     <Container maxWidth="xl" className={classes.container}>
    // <Container
    //   // className={clsx({
    //   //   [classes.root] : true,
    //   //   [classes.stretch]: selectedEmail,
    //   // })}
    // >
    <Grid 
      className={clsx({
          [classes.root] : true,
          [classes.stretch]: selectedEmail,
      })}
    >

      {/* Email List*/}
      {/* <Left
        className={classes.left}
        emails={emails}
        onSelectEmail={onSelectEmail}
        pageAll={pageAll}
        pageNum={pageNum}
        pageSize={pageSize}
        changeRowsPerPageQuery={changeRowsPerPageQuery}
        firstPage={firstPage}
        backPage={backPage}
        nextPage={nextPage}
        lastPage={lastPage}
      /> */}
      {/* Email List*/}
      <Grid>
        <Left
          className={classes.left}
          emails={emails}
          onSelectEmail={onSelectEmail}

          pageAll={pageAll}
          pageNum={pageNum}
          pageSize={pageSize}
          changeRowsPerPageQuery={changeRowsPerPageQuery}
          firstPage={firstPage}
          backPage={backPage}
          nextPage={nextPage}
          lastPage={lastPage}

        />
        <EmailPagination
          className={classes.Pagination}
          pageAll={pageAll}
          pageNum={pageNum}
          pageSize={pageSize}
          changeRowsPerPageQuery={changeRowsPerPageQuery}
          firstPage={firstPage}
          backPage={backPage}
          nextPage={nextPage}
          lastPage={lastPage}
        />
      </Grid>

      {/* Selected email */}
      {selectedEmail && (
        <Right
          className={classes.right}
          selectedEmail = {selectedEmail}
          boolReply={boolReply}
          handleReply={handleReply}
          boolForward={boolForward}
          handleForward={handleForward}
          handleDiscard={handleDiscard}
          anchorEl={anchorEl}
          handleMoreOpen={handleMoreOpen}
          handleMoreClose={handleMoreClose}

        />
      )}

    </Grid>
    // </Container>
      // </PageHelmet>
      

  )
}

export default EmailDialogContent;