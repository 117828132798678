import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Container, Box } from "@material-ui/core";
import { Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import Screenpop from "./screenpop/Screenpop";
import Faq from "./FAQ/FAQ";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../TM/src/components/PageHeader";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../contexts/AppStore";
import selectedModules from "../../../SELECTED_MODULES/index";
import LoginUsers from "./LoginUsers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  whole: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // margin: "1%"
  },
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
}));

const Main = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(
    parseInt(localStorage.getItem("LS")) || 0
  );
  const history = useHistory();
  const { hasPermission } = useContext(AppContext);
  const [test, setTest] = React.useState(true);

  /* store the tabs index in local storage */
  React.useEffect(() => {
    localStorage.setItem("LS", value);

    if (hasPermission("announce_group_config_tab") == true) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [value]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <PageHeader
        title={t("troubleshoot")}
        subtitle={t("troubleshooting_issues_with_cream")}
      />
      <div className={classes.whole}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={t("screenpop")} {...a11yProps(0)} />
            <Tab label={t("faq")} {...a11yProps(1)} />
            <Tab label="Login Users" {...a11yProps(2)} />

          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Screenpop />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Faq />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LoginUsers />
        </TabPanel>
      </div>
    </Container>
  );
};
export default Main;
