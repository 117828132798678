import React from 'react';
import { Grid, Card, Divider, TextField, List, Box, Button, Typography, FormControl,  FormControlLabel, IconButton } from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AutocompleteCategory from './AutocompleteCategory';
import { Controller } from 'react-hook-form';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Switch from '@material-ui/core/Switch';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { toNumber } from 'lodash';
// import { commonValidationFunction } from './commonFunction';



const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    parallel_extra: {
        margin: 0,
        padding: 0,
        width: "65%",
        // '& > *': {
        //   margin: theme.spacing(1),
        // },
    },
    hoursMinutes: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    errorMsg : {
        color: "#f44336",
        marginTop: "1%",
        marginLeft: "1%"
    },
    hello: {
        margin: 0,
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}))

const keyboardButtonProps = { style: { marginRight: -14 } };


const Create = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { control, register, handleSubmit, errors, getValues } = useForm();
    const [tbList, set_tbList] = React.useState([])
    const [parentCategory, setParentCategory] = React.useState(null);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = React.useState(true);
    const [hint, setHint] = React.useState(t("notify to manager"))
    const [moment, setMoment] = React.useState([
        {
            momentDays: 0,
            momentHours: 0,
            momentMinutes: 0
        }
    ])

    const [TD_days, setTD_days] = React.useState(0)
    const [TD_hours, setTD_hours] = React.useState(0)
    const [TD_minutes, setTD_minutes] = React.useState(0)


    const THEME = createTheme({
        typography: {
            subtitle1: {
                "fontWeight": 700,
            },
        },
    });
    
    const handleChange = (event) => {
        setIsChecked(event.target.checked);

        if (event.target.checked == true)
        {
            setHint( t("notify to manager"))
        }

        else if (event.target.checked == false) {
            setHint(t("not notify to manager"))

        }
    };



    const handle = (type , e) => {
        if (type == 'days') {
            if (e.target.value) {
                const value = e.target.value;
                const setValue = value >= 0 ? value : TD_days;
                setTD_days(setValue);
            }
            else {
                setTD_days("")
            }
        }
        else if (type == 'hours') {
            if (e.target.value) {
                const value = e.target.value;
                const setValue = (value >= 0 && value < 24) ? value : TD_hours;
                setTD_hours(setValue);
            }
            else {
                setTD_hours("")
            }
        }
        else if (type == 'minutes') {
            if (e.target.value) {
                const value = e.target.value;
                const setValue = (value >= 0 && value < 60) ? value : TD_minutes;
                setTD_minutes(setValue);
            }
            else {
                setTD_minutes("")
            }
        }

    }

    const changeMomentDays = (index, e) => {

        let arr = [...moment]
        
        if (e.target.value) {
            const value = e.target.value;
            const setValue = value >= 0 ? value : 0;
            
            arr[index].momentDays = setValue
            setMoment(arr)
        }
        else {
            arr[index].momentDays = ""
            setMoment(arr)
        }
    }

    const changeMomentHours = (index, e) => {

        let arr = [...moment]
        
        if (e.target.value) {
            const value = e.target.value;
            const setValue = (value >= 0 && value < 24) ? value : 0;
            
            arr[index].momentHours = setValue
            setMoment(arr)
        }
        else {
            arr[index].momentHours = ""
            setMoment(arr)
        }
    
    }

    const changeMomentMinutes = (index, e) => {
        let arr = [...moment]
        
        if (e.target.value) {
            const value = e.target.value;
            const setValue = (value >= 0 && value < 60) ? value : 0;
            
            arr[index].momentMinutes = setValue
            setMoment(arr)
        }
        else {
            arr[index].momentMinutes = ""
            setMoment(arr)
        }
    }
    
    function handleNewRow(index) {
        const values = [...moment];
        setMoment([...moment, {         
            momentDays: 0,
            momentHours: 0,
            momentMinutes: 0
        }]);
    }

    function handleDeleteRow(index) {
        const values = [...moment];
        values.splice(index, 1);
        setMoment(values);
    }

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories`)
            .then(res => res.json())
            .then((data) => {
                set_tbList(data.data)
            })
    }, [])

    const cleanInValidDataInArray = () => {
        
        let cleanedMomentArray = [];
        let totalMilliSeconds = 0

        // Convert notifyMoment Array into milliseconds
        if (moment.length > 0) {
            for (let item of moment) {
                const days = item.momentDays != "" ?  60 * 60 * 24 * parseInt(item.momentDays) : 0
                const hours = item.momentHours != "" ? 60 * 60 * parseInt(item.momentHours) : 0
                const minutes = item.momentMinutes != "" ? 60 * parseInt(item.momentMinutes) : 0
                totalMilliSeconds = (days + hours + minutes)* 1000
                cleanedMomentArray.push(totalMilliSeconds)
            }
        }
    
        return cleanedMomentArray;
    }

    const getMilliSeconds = (data) => {
        let totalMilliSeconds_TargetDuration = 0;

        // TARGET DURATION milliseconds
        const days = data.days != "" ?  60 * 60 * 24 * parseInt(data.days) : 0
        const hours = data.hours != "" ? 60 * 60 * parseInt(data.hours) : 0
        const minutes = data.minutes != "" ? 60 * parseInt(data.minutes) : 0

        return totalMilliSeconds_TargetDuration = (days + hours + minutes)* 1000

    }


    const onSubmit = (data, e) => {

        console.log('before submit', data)

        // total miliseconds targetDuration
        const totalMilliSeconds_TargetDuration = getMilliSeconds(data)
        data.sla.targetDuration = totalMilliSeconds_TargetDuration
        console.log('targetduration miliseconds', data.sla.targetDuration)

        // total miliseconds notifyMoment
        data.sla.notifyMoments = cleanInValidDataInArray();


        // category input 
        data.parentId = parentCategory && parentCategory._id ? parentCategory._id : null;
        data.parent = parentCategory && parentCategory.path ? parentCategory.path : '//';



        // compare notifyMoment with targetDuration
        let NMgreaterThanTD = false
        if ( data.sla.notifyMoments.length > 0) {
            for (let item of data.sla.notifyMoments) {
                if (item >= data.sla.targetDuration) {
                    NMgreaterThanTD = true
                }
            }
        }

        console.log('submit', data)


        if (NMgreaterThanTD == false) {

            var raw = JSON.stringify(data);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories?sort=parent`, {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            })
            .then(response => {
                if (response.status == 201) {history.push('/config');    
                return enqueueSnackbar(t('Category has been created.'), { variant: 'success' });
            }
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar(t('Category has fail to create.'), { variant: 'error' })
            })

        }
        else if (NMgreaterThanTD == true) {
            enqueueSnackbar(t('Plese input notify moment less than target duration'), { variant: 'error' })
        }


    }

    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader
                title= {t("Create New Category")}
                subtitle={t("Create New Category")}
            />
            <Grid item xs={12}>
                <Card className={classes.card} >
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>  {t('Parent Name')}  </Typography>
                            <FormControl className={classes.parallel}>
                                <Controller
                                    name="parent"                                            
                                    className={classes.parallel}
                                    control={control}
                                    defaultValue={null}
                                    onChange={([, data]) => data}
                                    render={({ value, onChange }) => {
                                        return (
                                            <AutocompleteCategory
                                                fullWidth
                                                size="medium"
                                                defaultValue={value}
                                                onSelectChange={(e, data) => {
                                                    setParentCategory(data);
                                                    return onChange(data)
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Name')} * </Typography>
                            <TextField  
                                className={classes.parallel} 
                                inputRef={register({ required: true })} 
                                name="name" 
                                variant="outlined"
                                error={errors.name}
                                helperText={errors.name && t("Name field is required")}
                            >
                            </TextField>
                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('CSQ Name')}  </Typography>
                            <TextField  
                                className={classes.parallel} 
                                inputRef={register} 
                                name="csq" 
                                variant="outlined"
                            >
                            </TextField>
                        </Box>

                        <List/>
                            <Divider className={classes.partition}></Divider>
                        <List/>

                        <MuiThemeProvider theme={THEME}>
                            <Typography variant="subtitle1">
                                {t('Service Level Agreement')}
                            </Typography>
                        </MuiThemeProvider>


                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Target Duration')}  </Typography>
                                <Box display="flex" flexDirection="row" className={classes.hoursMinutes} >
                                    
                                    <List fullWidth>
                                        <TextField
                                            label= {t("Days")} 
                                            fullWidth
                                            inputRef={register} 
                                            name="days" 
                                            variant="outlined"
                                            onChange={(e) => handle('days', e)}
                                            defaultValue={TD_days}
                                            value={TD_days}
                                        />
                                    </List>

                                    <List fullWidth>
                                       
                                        <TextField
                                            label= {t("Hours")} 
                                            fullWidth
                                            name="hours"
                                            defaultValue={TD_hours}
                                            value={TD_hours}
                                            variant="outlined"
                                            onChange={(e) => handle('hours', e)}
                                            inputRef={register}
                                        /> 
                                           
                                    </List>
                                    <List fullWidth>

                                        <TextField
                                            label= {t("Minutes")} 
                                            fullWidth
                                            name="minutes"
                                            defaultValue={TD_minutes}
                                            value={TD_minutes}
                                            variant="outlined"
                                            onChange={(e) => handle('minutes', e)}
                                            inputRef={register}
                                        /> 
                                    </List>
                                </Box>

                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Notify Moment')}  </Typography>

                            <List className={classes.parallel_extra}>
                            {console.log(moment)}
                            {moment.map((val, index) => {
                                return (
                                    <Box display="flex" flexDirection="row" className={classes.hello}>
                                        {/* <Controller 
                                            name="momentDays"
                                            control={control}
                                            defaultValue={val.momentDays}
                                            value={val.momentDays}
                                            onChange={(e) => changeMomentDays(index, e)}
                                            render={({ value, onChange }) => {
                                                return ( */}
                                                    <TextField
                                                        label= {t("Days")} 
                                                        fullWidth
                                                        // type="number"
                                                        name="momentDays"
                                                        variant="outlined"
                                                        onChange={(e) => changeMomentDays(index, e)}
                                                        defaultValue={val.momentDays}
                                                        value={val.momentDays}
                                                        inputRef={register}
                                                    /> 
                                                    {/* )
                                            }}
                                            rules={{
                                                validate: () => {
                                                    return getValues('momentDays') >= 0
                                                }
                                            }}
                                        /> */}

                                        {/* <Controller 
                                            name="momentHours"
                                            control={control}
                                            value={val.momentHours}
                                            onChange={(e) => changeMomentHours(index, e)}
                                            defaultValue={val.momentHours}
                                            render={({ value, onChange }) => {
                                                return ( */}
                                                    <TextField
                                                        label= {t("Hours")} 
                                                        fullWidth
                                                        // type="number" 
                                                        name={'momentHours'}
                                                        variant="outlined"
                                                        onChange={(e) => changeMomentHours(index, e)}
                                                        value={val.momentHours}
                                                        defaultValue={val.momentHours}

                                                        inputRef={register}
                                                    /> 
                                                {/* )
                                            }}
                                            rules={{
                                                validate: () => {
                                                    return getValues('momentHours') >= 0 && getValues('momentHours') < 24
                                                }
                                            }}
                                        /> */}

                                        {/* <Controller 
                                            name="momentMinutes"
                                            control={control}
                                            value={val.momentMinutes}
                                            defaultValue={val.momentMinutes}

                                            onChange={(e) => changeMomentMinutes(index, e)}
                                            render={({ value, onChange }) => {
                                                return ( */}
                                                    <TextField
                                                        label= {t("Minutes")} 
                                                        fullWidth
                                                        // type="number" 
                                                        name='momentMinutes'
                                                        variant="outlined"
                                                        onChange={(e) => changeMomentMinutes(index, e)}
                                                        value={val.momentMinutes}
                                                        defaultValue={val.momentMinutes}
                                                        inputRef={register}
                                                    /> 
                                                    {/* )
                                            }}
                                            rules={{
                                                validate: () => {
                                                    return getValues('momentMinutes') >= 0 && getValues('momentMinutes') <= 59
                                                }
                                            }}
                                        /> */}                

                                        {
                                            moment.length !== 1 &&                                             
                                                <IconButton onClick={() => handleDeleteRow(index)}>
                                                    <DeleteForeverOutlinedIcon/>
                                                </IconButton>
                                        }
                                        {
                                            moment.length -1 == index &&
                                            <IconButton onClick={handleNewRow}>
                                                <AddBoxOutlinedIcon/>
                                            </IconButton>
                                        }
                                    </Box>
                                                                           
                               )
                            })}
                        </List>
                        </Box>


                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}> {t('Escalation')} : </Typography>
                            <FormControlLabel
                                inputRef={register}
                                control={
                                <Switch
                                    checked={isChecked}
                                    onChange={handleChange}
                                    name="sla.doEscalation"
                                    color="primary"
                                />
                                }
                                label={hint}
                            />
                        </Box>

                        <Box className={classes.btn_}>
                            <Button type="submit" color="primary" variant="contained"> {t('Create')}</Button>
                            <Button color="primary" variant="contained" onClick={() => history.push('/config')}> {t('Cancel')}</Button>
                        </Box>
                    </form>
                </Card>
            </Grid>
        </Container>    
        )
    }
export default Create;