import axios from 'axios';
import url from 'url';

const ELASTIC_SEATCH_ARTICLE_URL = url.resolve(process.env.REACT_APP_ELASTIC_SEARCH_FQDN, '/elasticsearch/knowledge-base/Article/_search');
const GET_ONE_ARTICLE_URL = url.resolve(process.env.REACT_APP_KB_BACKEND_FQDN, '/api/Article');
const PUT_INCREASE_ATTACH_TO_TICKET_URL = url.resolve(process.env.REACT_APP_KB_BACKEND_FQDN, '/api/Article/IncAttachedTicketCount');

export const getArticle = (articleId = '') => {
  return axios({
    url: `${GET_ONE_ARTICLE_URL}/${articleId}`,
    method: 'GET',
  });
};

export const putAttachCount = (queryString = '') => {
  return axios({
    url: `${PUT_INCREASE_ATTACH_TO_TICKET_URL}?${queryString}`,
    method: 'PUT',
  });
};

export const searchArticles = (keyword = '', categoryId = null) => {
  return axios({
    url: ELASTIC_SEATCH_ARTICLE_URL,
    method: 'POST',
    data: {
      query: {
        filtered: {
          ...(keyword && {
            query: {
              query_string: {
                query: `*${keyword.trim()}*`,
                fields: [
                  'title',
                  'text',
                  'caption',
                  'tags',
                ],
              },
            },
          }),
          ...(categoryId && {
            filter: {
              term: {
                'category._id': categoryId,
              },
            },
          }),
        },
      },
      sort: [
        {
          attachToTicketCount: {
            order: 'desc',
          },
        },
      ],
    },
  });
};
