import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Left from './elements/left';
import Right from './elements/right';
import PageHelmet from '../TM/src/components/PageHelmet';
import PageHeader from '../TM/src/components/PageHeader';
import { Container, Grid } from '@material-ui/core';
import {  useSnackbar } from 'notistack';
import axios from 'axios';
import EmailDialog from './components/EmailDialog';
import InstantReplyDialog from './components/InstantReplyDialog';
import WelcomeEmailDialog from './components/WelcomeEmailDialog';
import EmailPagination from './elements/EmailPagination';


const zIndex = 1500;
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  root: {
    // borderRadius: 4,
    zIndex,
    height: 600,
    display: 'flex',
    // overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);',
  },
      
  left: {
    // maxHeight: '92%',
    height: 550,
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    '@media (min-width: 864px)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  Pagination: {
    height: 50,
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    '@media (min-width: 864px)': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  right:{
    flexGrow: 1,
    width: 650,
    flexBasis: 650,
    flexShrink: 0,
  }

}))


const Email = () => {

  // const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } =useSnackbar();
  const [boolReply, setBoolReply] = useState(false);
  const [emails, setEmails] = React.useState([]);
  const [selectedEmail, setSelectedEmail] = React.useState();
  // const [bool, setBool] = React.useState(false);
  const [boolForward , setBoolForward] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [welcomeEmailTo, setWelcomeEmailTo] = React.useState();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [instantReplyOpen, setInstantReplyOpen] = React.useState(false);
  const [welcomeEmailOpen, setWelcomeEmailOpen] = React.useState(false);

  const [pageAllCount, setPageAllCount] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [messageId, setMessageId] = useState();
  const [trigger, setTrigger] = useState(true);

  const trackingId = '6135b4c25157e1001ccba0d4';
  const ticketId = '6135b4c25157e1001ccba0d4';
  const replyTo = 'kelloggy40@gmail.com';


  const onSelectEmail = (email) => {
    setSelectedEmail(email)
    setBoolReply(false)
    setBoolForward(false)
  }

  const handleReply = (bool) => {
    setBoolReply(bool)
    setBoolForward(false)
    setAnchorEl(null);
  }

  const handleForward = () => {
    setBoolForward(true);
    setBoolReply(false)
    setAnchorEl(null);
  }

  const handleDiscard = () => {
    setBoolReply(false)
    setBoolForward(false)
  }

  const handleMoreOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMoreClose = () => {
    setAnchorEl(null);
  };


  /* Get data to list */
  const fetchEmails = useCallback(() => {
      
    let url = `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/Emails?pageSize=${pageSize}&page=${pageNum}`;
    let config = {
      method: 'GET',
      timeout: 1000 * 3,
      headers: {
        "Content-Type": "application/json"
      },
    };

    axios( url, config )
      .then(response => {
        if (response.status === 200) {
          setEmails(response.data.data)
          setPageAllCount(response.data.totalPages)
          // console.log(" data ---- ", response.data.data)

        }
      })
      .catch(err => {
        enqueueSnackbar(t('Fail to load emails.'), { variant: 'error' });
        console.log(err.message)
      });

  }, [pageNum, pageSize, enqueueSnackbar, t])

  /* Use Effect */
  useEffect( () =>  {
    fetchEmails()
    console.log('selectedEmail ---- ', selectedEmail);
  }, [fetchEmails, selectedEmail, trigger])

  const handleDialog = (bool) => {
    setDialogOpen(bool)
  }

  const handleInstantReplyDialog = (bool) => {
    setInstantReplyOpen(bool)
  }

  const handleWelcomeEmailDialog = (bool) => {
    setWelcomeEmailOpen(bool)
  }

  // const handleChangePageQuery = (event, newPage) => {
  //   setPageNum(newPage);
  // };

  const handleChangeRowsPerPageQuery = (event) => {
    setPageSize(+event.target.value);
    setPageNum(1);
    setTrigger(!trigger);
  };

  const handleFirstPageButtonClick = (event) => {
    setPageNum(1);
    setTrigger(!trigger);
  };

  const handleBackButtonClick = (event) => {
    setPageNum(pageNum-1);
    setTrigger(!trigger);
  };

  const handleNextButtonClick = (event) => {
    setPageNum(pageNum+1);
    setTrigger(!trigger);
  };

  const handleLastPageButtonClick = (event) => {
    setPageNum(pageAllCount);
    setTrigger(!trigger);
  };


  return (

    <PageHelmet title={t('email_page_title')}>
      <Container maxWidth="xl" className={classes.container}>
        <PageHeader
          title={t('email_page_title')}
          subtitle={t('email_page_desc')}
          buttonName={"Email Dialog"}
          onClickButton={() => handleDialog(true)}
        />
    
        {/* Email Dialog */}
        {dialogOpen && (
          <EmailDialog
            dialogOpen={dialogOpen}
            handleDialog={handleDialog}
            trackingId={trackingId}
          />
        )}


        {/* Instant Reply Dialog */}
        {instantReplyOpen && (
          <InstantReplyDialog
            instantReplyOpen={instantReplyOpen}
            handleInstantReplyDialog={handleInstantReplyDialog}
            ticketId={ticketId}
            replyTo={replyTo}
          />
        )}


        {/* Welcome Email Dialog */}
        {welcomeEmailOpen && (
          <WelcomeEmailDialog
            welcomeEmailOpen={welcomeEmailOpen}
            handleWelcomeEmailDialog={handleWelcomeEmailDialog}
            ticketId={ticketId}
            replyTo={replyTo}
          />
        )}




        <Grid 
          className={clsx({
            [classes.root] : true,
            [classes.stretch]: selectedEmail,
          })}
        >
          {/* Email List*/}
          <Grid>
            <Left
              className={classes.left}
              emails={emails}
              onSelectEmail={onSelectEmail}

              // pageAll={pageAllCount}
              // pageNum={pageNum}
              // pageSize={pageSize}
              // changeRowsPerPageQuery={handleChangeRowsPerPageQuery}
              // firstPage={handleFirstPageButtonClick}
              // backPage={handleBackButtonClick}
              // nextPage={handleNextButtonClick}
              // lastPage={handleLastPageButtonClick}

            />
              <EmailPagination
                className={classes.Pagination}
                pageAll={pageAllCount}
                pageNum={pageNum}
                pageSize={pageSize}
                changeRowsPerPageQuery={handleChangeRowsPerPageQuery}
                firstPage={handleFirstPageButtonClick}
                backPage={handleBackButtonClick}
                nextPage={handleNextButtonClick}
                lastPage={handleLastPageButtonClick}
              />
          </Grid>

          {/* Selected email */}
          {selectedEmail && (
            <Right
              className={classes.right}
              selectedEmail = {selectedEmail}
              boolReply={boolReply}
              handleReply={handleReply}
              boolForward={boolForward}
              handleForward={handleForward}
              handleDiscard={handleDiscard}
              anchorEl={anchorEl}
              handleMoreOpen={handleMoreOpen}
              handleMoreClose={handleMoreClose}
            />
          )}
        </Grid>
      </Container>
    </PageHelmet>
  )
}

export default Email;