import React from 'react';
import PropTypes from 'prop-types';

const BlankLayout = ({ children }) => (
  <>
    {children}
  </>
);

BlankLayout.propTypes = {
  children: PropTypes.node,
};

BlankLayout.defaultProps = {
  children: undefined,
};

export default BlankLayout;
