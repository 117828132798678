import axios from 'axios';
import url from 'url';

const GET_SOME_TICKET_STATUS_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/ticket-statuses');
const GET_SOME_TICKET_CHANNEL_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/ticket-channels');
const GET_SOME_TICKET_PRIORITY_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/ticket-priorities');
const GET_SOME_CATEGORY_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/categories');
const GET_SOME_TEMPLATE_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/templates');
const GET_SOME_TEAM_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/teams');
const GET_ONE_TEAM_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/teams');
const GET_ONE_ROLE_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/roles');
const GET_SOME_CONFIG_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/configs');
const GET_SOME_PREFIX_URL = url.resolve(process.env.REACT_APP_ADMIN_BACKEND_FQDN, '/admin/api/v1/prefixes');

export const getTicketStatuses = () => {
  return axios({
    url: `${GET_SOME_TICKET_STATUS_URL}?sort=order`,
    method: 'GET',
  });
};

export const getTicketChannels = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_CHANNEL_URL}?${queryString}`,
    method: 'GET',
  });
};

export const getTicketPriorities = () => {
  return axios({
    url: `${GET_SOME_TICKET_PRIORITY_URL}?sort=order`,
    method: 'GET',
  });
};

export const getCategories = (queryString = '') => {
  return axios({
    url: `${GET_SOME_CATEGORY_URL}?sort=order,path&${queryString}`,
    method: 'GET',
  });
};

export const getTemplates = () => {
  return axios({
    url: `${GET_SOME_TEMPLATE_URL}`,
    method: 'GET',
  });
};

export const getTeams = () => {
  return axios({
    url: `${GET_SOME_TEAM_URL}`,
    method: 'GET',
  });
};

export const getRole = (roleId) => {
  return axios({
    url: `${GET_ONE_ROLE_URL}/${roleId}`,
    method: 'GET',
  });
};

export const getTeam = (teamId) => {
  return axios({
    url: `${GET_ONE_TEAM_URL}/${teamId}`,
    method: 'GET',
  });
};

export const getConfigs = () => {
  return axios({
    url: GET_SOME_CONFIG_URL,
    method: 'GET',
  });
};

export const getPrefixes = () => {
  return axios({
    url: GET_SOME_PREFIX_URL,
    method: 'GET',
  });
};
