import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import ws from '../modules/WebSock';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LanguageIcon from '@material-ui/icons/TranslateOutlined';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NavigationsMenu from '../components/NavigationsMenu';
import queryString from 'query-string'
import { AppContext } from '../contexts/AppStore';
import { DashboardAPI } from '../apis';
import { logger, helper } from '../modules/TM/src/common';
import { LoggedInDisplay, LoggedInDataDisplay } from "../components/LoggedInDisplay";
import BookmarksMenu from "../components/BookmarksMenu";
import NotificationsMenu from "../components/NotificationsMenu";
import AnnounceCarousel from '../components/AnnounceCarousel';
import BackIcon from '@material-ui/icons/ArrowBackOutlined';
import dotProp from 'dot-prop';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Box } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
import { now } from 'lodash';
import { underline } from 'colors';
import { SnackbarProvider, useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f1f5f9',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 250,
    width: `calc(100% - ${250}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginLeft: '36px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 250,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 60,
    [theme.breakpoints.up('sm')]: {
      width: 72,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const SecureLayout = ({ showBackButton, children }) => {
  const history = useHistory()
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [drawerExpand, setDrawerExpand] = React.useState(false);
  const [translateAnchorEl, setTranslateAnchorEl] = React.useState(null);
  const [getNewBookmarks, setGetNewBookmarks] = React.useState(true);
  const [getNewNotifications, setGetNewNotifications] = React.useState(true);
  const { loginUser, setLoginUser } = React.useContext(AppContext);

  // Side effect of componentDidMount.
  useEffect(() => {
    // Initialize the websocket module.
    ws.Startup(
      loginUser.username,
      loginUser.extension,
      loginUser._id,
    );
  }, [loginUser]);

  // Side effect of componentDidMount.
  useEffect(() => {

    // Renew session expire date every 1 minute.
    const intervalId = setInterval(() => {
      DashboardAPI.getSession()
        .catch((error) => {
          ws.Shutdown();
          setLoginUser(undefined);
          history.push('/login');
        });
    }, 60 * 1000);

    // Release the interval when unmount.
    return () => clearInterval(intervalId);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerExpand = () => {
    setDrawerExpand(true);
  };

  const handleDrawerClose = () => {
    setDrawerExpand(false);
  };

  const handleClickLogoutButton = () => {
    DashboardAPI.deleteSession()
      .then(() => {
        ws.Shutdown();
        history.push('/login');
      })
      .catch((err) => {
        logger.error('Unable to delete session from the sever.');
        logger.error(err);
      });
  }

  const handleOpenTranslationMenu = (e) => {
    setTranslateAnchorEl(e.currentTarget);
  }

  const handleCloseTranslationMenu = (e) => {
    setTranslateAnchorEl(null);
    const locale = e.target.getAttribute('value');
    i18n.changeLanguage(locale);
    helper.setCookie('locale', locale, 365);
  }

  const finishedGettingBookmarks = () => {
    // console.log("finishedGettingBookmarks");
    setGetNewBookmarks(false);
  }

  const finishedGettingNotifications = () => {
    // console.log("finishedGettingNotifications");
    setGetNewNotifications(false);
  }

  const LoginResponse = (pageName, data) => {
    // console.log("login ---> " + JSON.stringify(data, null, 2));

    if (pageName === "DASH") {
      // console.log("Directing message to DASH page");
    }
  };

  const ScreenPop = (pageName, data) => {
    console.log("screenpop ---> " + JSON.stringify(data, null, 2));

    if (pageName === "TM") {
      console.log("Directing message to DASH page");
      history.push('/tickets/create?channelKey=incoming-call&' + queryString.stringify(data.data));
    }
  };

  const kb_notify = (pageName, data) => {
    console.log("kb_notify ---> " + JSON.stringify(data, null, 2));


    /* Current Sub-Events sent from KB Module
        EventId: kb_notify
        - notify-follower-of-updated-comment
        - notify-follower-of-updated-bookmark
        - notify-approver-to-approve-article
        - notify-owner-of-approved-article
        - notify-owner-of-rejected-article
        - notify-follower-of-updated-article
    */

    // console.log("Tell Notification Button to Update itself!");
    setGetNewNotifications(true);

    if (pageName === "KB") {
      // console.log("Directing message to DASH page... Not Implemented Yet!!");
    }
    if (data.data["sub-event"] === "notify-follower-of-updated-bookmark") {
      // console.log("Tell Bookmark Button to Update itself!");
      setGetNewBookmarks(true);
    }
    // if (data.data["sub-event"] === "notify-approver-to-approve-article") {
    //   /* Realtime event, post-Realtime events will be received by Get Notifications */
    //   console.log("Just received 'notify-approver' event!");
    // }
  };

  const em_notify = (pageName, data) => {
    console.log("em_notify ---> " + data);
    setGetNewNotifications(true);
  };

  const warning_ticket_before_overdue_event = (pageName, data) => {
    // console.log("warning_ticket_before_overdue_event ---> " + JSON.stringify(data, null, 2));
    setGetNewNotifications(true);
  };

  const escalate_ticket_after_overdue_event = (pageName, data) => {
    // console.log("escalate_ticket_after_overdue_event ---> " + JSON.stringify(data, null, 2));
    setGetNewNotifications(true);
  };

  // const chat_message_event = (pageName, data) => {
  //   console.log("chat_message_event ---> " + JSON.stringify(data, null, 2));
  //   // setGetNewNotifications(true);
  //   enqueueSnackbar(`New chat message has arrived.`, { variant: 'success' });

  // };

  // const NewWork = (pageName, data) => {
  //   console.log("NEW WORK!! ---> " + pageName);

  //   if(pageName === "CHAT") {
  //     history.push('/chat');
  //   } else if(pageName === "DASH") {
  //     history.push('/dashboard');
  //   }
  // };

  const contact_creating = (pageName, data) => {
    console.log("contact_creating ---> " + JSON.stringify(data, null, 2));
    setGetNewNotifications(true);
  };

  /* Attach Callbacks for each eventId, when triggered will pass PageName */
  ws.Attach_callback("login", LoginResponse);
  ws.Attach_callback("screenpop", ScreenPop);
  ws.Attach_callback("kb_notify", kb_notify);
  ws.Attach_callback("em_notify", em_notify);
  ws.Attach_callback("warning_ticket_before_overdue_event", warning_ticket_before_overdue_event);
  ws.Attach_callback("escalate_ticket_after_overdue_event", escalate_ticket_after_overdue_event);
  // ws.Attach_callback("chat_message", chat_message_event);
  // ws.Attach_callback("new_work", NewWork);
  ws.Attach_callback("contact_notify", contact_creating);
  // ws.Send_msg( null, "Contact", "contact_notify")

  return (
    <div className={classes.root}>

      {/* Top Appbar */}
      <AppBar position="absolute" className={clsx(classes.appBar, drawerExpand && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>

          {/* Hamberger Menu */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerExpand}
            className={clsx(classes.menuButton, drawerExpand && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          {/* LeftArrow Button */}
          {showBackButton && (
            <IconButton
              color="inherit"
              onClick={() => history.goBack()}
            >
              <BackIcon />
            </IconButton>
          )}

          {/* App Title */}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          </Typography>

          {/* Logged In User Data */}
          {process.env.REACT_APP_BUILD_TARGET === 'RAMA' && <LoggedInDataDisplay/>}

          {/* Notification Menu */}
          <NotificationsMenu getNewNotifications={getNewNotifications} finishedGettingNotifications={finishedGettingNotifications} />

          {/* Bookmarks Menu */}
          <BookmarksMenu getNewBookmarks={getNewBookmarks} finishedGettingBookmarks={finishedGettingBookmarks} />

          {/* Translation Menu */}
          <Tooltip title={t("Change Language")}>
            <IconButton
              color="inherit"
              aria-haspopup="true"
              aria-controls="top-translation-menu"
              onClick={handleOpenTranslationMenu}>
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="top-translation-menu"
            anchorEl={translateAnchorEl}
            keepMounted
            open={Boolean(translateAnchorEl)}
            onClose={() => setTranslateAnchorEl(null)}
          >
            <MenuItem value="en" onClick={handleCloseTranslationMenu}>English</MenuItem>
            <MenuItem value="th" onClick={handleCloseTranslationMenu}>ภาษาไทย</MenuItem>
          </Menu>

          {/* Logged In Icon */}
          <LoggedInDisplay/>

          {/* Logout Menu */}
          <Tooltip title={t("Logout")}>
            <IconButton color="inherit" onClick={handleClickLogoutButton}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>

        </Toolbar>
      </AppBar>
      {/* /.Top Appbar */}

      {/* Left Drawer */}
      <Drawer
        variant="permanent"
        open={drawerExpand}
        classes={{
          paper: clsx(classes.drawerPaper, !drawerExpand && classes.drawerPaperClose),
        }}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <NavigationsMenu />
      </Drawer>
      {/* /.Left Drawer */}

      {/* Announce Carousel */}
      <AnnounceCarousel />

      {/* Content Wrapper */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {/* Page Content */}
        {children}
      </main>
      {/* /.Content Wrapper */}

    </div>
  );
};

SecureLayout.propTypes = {
  children: PropTypes.node,
  showBackButton: PropTypes.bool,
};

SecureLayout.defaultProps = {
  children: undefined,
  showBackButton: false,
};

export default SecureLayout;
