// import React from 'react';
import moment from 'moment';

const yearArray = (startYear) => {
  const st = startYear;
  const en = new Date().getFullYear();
  const arr = [];
  for(let i = st; i <= en; i += 1) {
    arr.push({id: i, year: i.toString()});
  };
  return arr;
};

const startMonthFormatTransform = (year, month) => {
  return moment()
    .set('year', year)
    .set('month', month)
    // .set('hour', hours)
    // .set('minute', minutes)
    .startOf('month')
    .format('YYYY-MM-DD HH:mm:ss');
};

const endMonthFormatTransform = (year, month) => {
  return moment()
    .set('year', year)
    .set('month', month)
    .endOf('month')
    .format('YYYY-MM-DD HH:mm:ss');
};

const startDateFormatTransform = (year, month, date, hour, minute, second) => {
  return moment()
    .set('year', year)
    .set('month', month)
    .set('date', date)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second)
    .add(-7, 'h')
    .format('YYYY-MM-DD HH:mm:ss');
};

const endDateFormatTransform = (year, month, date, hour, minute, second) => {
  return moment()
    .set('year', year)
    .set('month', month)
    .set('date', date)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second)
    .add(-7, 'h')
    .format('YYYY-MM-DD HH:mm:ss');
};

const startDateFormatToString = (year, month, date, hour, minute, second, milli) => {
  return moment()
    .set('year', year)
    .set('month', month)
    .set('date', date)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second)
    .set('millisecond', milli)
    .startOf('minute')
    .toISOString();
};

const endDateFormatToString = (year, month, date, hour, minute, second, milli) => {
  return moment()
    .set('year', year)
    .set('month', month)
    .set('date', date)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second)
    .set('millisecond', milli)
    .endOf('minute')
    // .add(-1, 'milliseconds')
    .toISOString();
};

const monthAll = [
  { id: 0, month: 'January' },
  { id: 1, month: 'February' },
  { id: 2, month: 'March' },
  { id: 3, month: 'April' },
  { id: 4, month: 'May' },
  { id: 5, month: 'June' },
  { id: 6, month: 'July' },
  { id: 7, month: 'August' },
  { id: 8, month: 'September' },
  { id: 9, month: 'October' },
  { id: 10, month: 'November' },
  { id: 11, month: 'December' },
];

const getMonthId = (monthName) => {
  const foundMonth = monthAll.find(item => item.month === monthName)
  return foundMonth.id
}

export { 
  yearArray,
  startMonthFormatTransform,
  endMonthFormatTransform,
  startDateFormatTransform,
  endDateFormatTransform,
  monthAll,
  getMonthId,
  startDateFormatToString,
  endDateFormatToString,
};
