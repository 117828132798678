import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, List, TextField, ListItem, Button, ListItemAvatar, ListItemText, MenuItem, Divider, IconButton, Card, FormControl, Select, InputLabel, CardHeader } from '@material-ui/core';
import PageHeader from '../../../../modules/TM/src/components/PageHeader';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SelectCategoryController from './SelectCategoryController';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../../contexts/AppStore';
import { useTranslation } from 'react-i18next';
import {  useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {CardContent, CardActions, Typography} from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Tooltip from '@material-ui/core/Tooltip';
import LocalLibrarySharpIcon from '@material-ui/icons/LocalLibrarySharp';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';


import Rating from '@material-ui/lab/Rating';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  body_table: {
    marginTop: "2%",
    marginLeft: "3%",
  },
  top: {
    marginLeft: "1%",
    marginRight: "1%"
  },
  btn_search: {
    marginTop: "1.5%",
    marginLeft: "1%",
    margin: theme.spacing(1),
  },
  search: {
    marginLeft: "1%",
    marginTop: "1.5%",
    marginBottom: "1.5%"
  },
  formControl1: {
    margin: theme.spacing(1),
    marginLeft: "1%",
    minWidth: 300,
  },
  formControl2: {
    margin: theme.spacing(1),
    marginLeft: "1%",
    minWidth: 300,
  },
  sort: {
    margin: "1%",
    marginTop: "2%",
    marginLeft: "40%",
    minWidth: 200
  },
  footer: {
    marginLeft: "1.5%",
    width: "100%"
  },
  body: {
    marginLeft: "1%",
    marginRight: "1%"
  },
  list_articles: {
    height: "100%",
    maxHeight: "100%",
    // overflow: "auto",
    marginTop: "20px"
  },
  list_item_text: {
    width: "35%",
    maxWidth: "35%"
  },
  tags: {
    width: "10%",
    maxWidth: "10%",
    textAlign: "left"
  },
  rate_: {
    width: "20%",
    maxWidth: "20%"
  },
  view: {
    margin: "1%"
  },
  follower: {
    margin: "1%"
  },
  up_: {
    margin: "1%"
  },
  down_: {
    margin: "1%"
  },
  findingMargin: {
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  Sort_formControl: {
    margin: theme.spacing(1),
    marginLeft: "1%",
    minWidth: 200,
  },
  sort_select: {
    margin: '16px 0'
  },
  cardcontent: {
    height: "40%",
    maxHeight: "40%"
  },
  cardheader: {
    height: "35%",
    maxHeight: "35%"
  },
  tooltip: {
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.black,

  },
  list: {
    listStyle: "none"
  },
  owner: {
    textAlign: "left"
  }

}));


// let filterCategory = undefined;


const Lists = (props) => {


  const { t } = useTranslation();
  const { hasPermission, loginUser } = React.useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const [articles, setArticles] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const keywordRef = useRef(null);
  const {  control } = useForm();
  // const [searchSuccess, setSearchSuccess] = React.useState()
  const [typing, setTyping] = React.useState(false);
  const sort_list = [
    {
      name: t("Most Popular"),
      value: "most popular",
    },
    {
      name: t("Most Viewed"),
      value: "most viewed"
    },
    {
      name: t("Alphabetical (A-Z)"),
      value: "A-Z"
    },
    {
      name: t("Alphabetical (Z-A)"),
      value: "Z-A"
    },
    {
      name: t("Last Modified"),
      value: "last modified"
    }

  ];

  const [sortValue, set_sortValue] = React.useState("just created");
  const [sortQuery, setSortQuery] = React.useState();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [contactList, setContactList] = React.useState([]);


  const isOwner = (article_obj) => (article_obj.owner && article_obj.owner === loginUser._id);
  const keyUpTimeOut = 1000;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (handleKeywordChange) {
        handleKeywordChange(keywordRef.current.value)
      };
    }, keyUpTimeOut);
    return () => clearTimeout(timeoutId);
  }, [typing]);

  const handleDeleteDialog = (deleteId) => {
    setDeleteId(deleteId)
    setDeleteOpen(true)
  }

  const handleDeleteDialogClose = () => {
      setDeleteOpen(false)
  }

  const filterArticles = (list) => {
    /* filter out the loginUser team category */

    if (loginUser.teamObj && loginUser.teamObj.categoryIDs && Array.isArray(loginUser.teamObj.categoryIDs)) {
      list = list.filter((article) => {
        if (!article.category) return true;
        return loginUser.teamObj.categoryIDs.findIndex((categoryID) => categoryID == article.category._id) >= 0;
      });
    }

    let filtered_list = list.filter((v) => {

      if (isOwner(v) && (v.isPublished == false && (v.kb_status == "draft" || v.kb_status == "waiting approval" || v.kb_status == "rejected" ? true : false)))
        return true;
      // Disable by Big please check the reason and eanble it again. 
      // if (isOwner(v) && v.isPublished == true)
      //   return true;
      if (hasPermission('article_approve') && v.kb_status === 'waiting approval')
        return true;      
      if ( v.isPublished == true && v.kb_status === "approve")
        return true;
      return false;
    });
    return filtered_list;
  };

  const handleSortChange = (e) => {
    console.log("trigger sort")
    set_sortValue(e.target.value)

    // console.log("selected category", selectedCategory)
    if (e.target.value == "most viewed") {
      
      const query = {
        openCount: {
          mode: "max", order: "desc"
        }
      }
      getArticlesElasticSearch(selectedCategory, query)
      setSortQuery(query)
    
    }
    else if (e.target.value == "most popular") {
      const query = {
        score: {
          mode: "max", order: "desc"
        }
      }
      getArticlesElasticSearch(selectedCategory, query)
      setSortQuery(query)
    }
    else if (e.target.value == "just created") {
      const query =  {
        dateCreated: {
          mode: "max", order: "desc"
        }
      }
      getArticlesElasticSearch(selectedCategory, query)
      setSortQuery(query)
    }
    else if (e.target.value == "last modified") {
      const query =  {
        dateModified: {
          mode: "max", order: "desc"
        }
      }
      getArticlesElasticSearch(selectedCategory, query)
      setSortQuery(query)
    }
    else if (e.target.value === 'A-Z') {
      const query =  {
        "title.raw" : {
          order: "asc"
        }
      }
      getArticlesElasticSearch(selectedCategory, query)
      setSortQuery(query)
    }
    else if (e.target.value === 'Z-A') {
      const query =  {
        "title.raw": {
          order: "desc"
        }
      }
      getArticlesElasticSearch(selectedCategory, query)
      setSortQuery(query)
    }

  }
  


  const handleCategoryChange = (category) => {
    console.log("trigger category")

    if (category) {
      setSelectedCategory(category);
      getArticlesElasticSearch(category, sortQuery);
    }
    else {
      fetchKB()
      setSelectedCategory(null)
      getArticlesElasticSearch(category, sortQuery);

    }
  }

  const handleKeywordChange = (event) => {
    console.log("trigger keyword")

    if (event.length > 0) {
      getArticlesElasticSearch(selectedCategory, sortQuery);
    } else if (selectedCategory || sortQuery){
      getArticlesElasticSearch(selectedCategory,sortQuery)
    }
    else {
      fetchKB()
    }
  }



  const setApproveBtnDisabled = (article_obj) => (article_obj.kb_status === 'approve' ? true : false);

  const setApproveBtnColour = (article_obj) => {
    if (article_obj.kb_status === 'waiting approval')
      return "secondary";
    if (article_obj.kb_status === 'approve')
      return "default";
    else
      return "primary";
  }

  const DeleteArticle = (article_id) => {
    // console.log(`[DeleteArticle] article_id = ${article_id}`);
    setDeleteOpen(false)


    let query = `${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${article_id}`
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(query, {
      method: 'DELETE',
      headers: myHeaders,
    })
      .then(res => {
        if ( res.status >= 200 && res.status <= 299) {
          fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/GetSome?limit=25`)
            .then(res => {
              if ( res.status >= 200 && res.status <= 299) {
                enqueueSnackbar(t('Article has been deleted.'), { variant: 'success' });
                return res.json()
              }
            })
            .then((data) => {
              // console.log("[DeleteArticle] Article: 1 (From KB API)", data);
              setArticles(filterArticles(data));
            })
        }
      })
      .catch((error) => {
        console.error('[DeleteArticle] Error:', error);
        enqueueSnackbar(t('Article has fail to delete.'), { variant: 'error' });
      })
  }

  const fetchElasticSearch =  (es_query_body) => {
    // console.log("checking what is the query", es_query_body)
    // Fetch post request to elastic search server.
    fetch( `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/knowledge-base/Article/_search?pretty&size=100`, {
    // fetch( `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/knowledge-base/Article/_search?pretty&size=100`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(es_query_body)
    })
      .then(res => res.json())
      .then(json => {
        // console.log("[fetchElasticSearch] Articles: MANY (From ES)", json.hits.hits);

        let converted_list = json.hits.hits.map((v) => {
          v._source.id = v._id; // Re-add the id (ES moved the _id to parent level)
          return v._source;
        });
        // console.log(`[fetchElasticSearch] converted_list`, converted_list);

        const filtered_articles = filterArticles(converted_list);
        setArticles(filtered_articles)
        postSearchKeyword(filtered_articles.length)
      })
      .catch((err) => {
        console.error('[fetchElasticSearch] Couldn\'t fetch psot request to elastic search server.');
        if (err) console.error(err);
      })
  };


  /*Insert keyword for report*/
  const postSearchKeyword = (numArticles) => {
  
      // console.log("CHECK Num Articles", numArticles)
      let searchSuccess = (numArticles > 0 )
      const keyword_body ={}
      keyword_body.keyword = keywordRef.current.value.trim()
      keyword_body.dateSearched = new Date()
      keyword_body.agentId = loginUser._id
      keyword_body.teamId = loginUser.team
      keyword_body.searchSuccess = searchSuccess
      // console.log("keyword", keyword_body)
  
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/InsertSearchKeyword`,{
        method: 'POST', 
        headers: myHeaders,
        body: JSON.stringify(keyword_body),
      })
      .then(response => response.json())
      .catch((error) => {
        console.error(error);
      })
  }



  const  getArticlesElasticSearch = async (filterCategory, sortQuery) => {

    console.log("sortQuery", sortQuery)
    console.log("filterCategory", filterCategory)


    let keyword = keywordRef.current.value;
    // Trim the keyword
    if (keyword) keyword = keyword.trim();

    const es_query_body = {
      query: {
        filtered: {
          ...(keyword && {
            query: {
              query_string: {
                query: `*${keyword}*`,
                fields: [
                  'title',
                  'text',
                  'caption',
                  'tags'
                ]
              }
            }

          }),
          ...(filterCategory && {
            filter: {
              term: {
                'category._id': filterCategory._id
              }
            }
          })
        }
      },
      ...(sortQuery && {
        sort : [
          sortQuery
        ]
      })
    }
    console.log(JSON.stringify(es_query_body))

    fetchElasticSearch(es_query_body)

  }

  const fetchKB = (filterCategory) => {
    fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/GetSome?limit=25`)
      .then(res => res.json())
      .then((data) => {

        /* Temporary code */
        setArticles(filterArticles(data));
      })
  }
  const fetchContacts = (articleOwnerId) => {
    fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`)
    .then((response) => response.json())
    .then((data) => {
        setContactList(data)
    })
  }

  React.useEffect(() => {
    let category;
    fetchKB(category);
    fetchContacts();

  }, [])

  // const searchingName = (ownerId) => {
  //  const result = contactList.find((element) => element._id == ownerId)
  //  const result2= result ? result.name : "Unknown"
  //  return result2
  // }

  return (
    <Container maxWidth="xl" className={classes.container}>
      {/* Page Header */}
      <PageHeader
        title={t('knowledge_base')}
        subtitle={t('Elastic search for searching articles')}
        buttonName={hasPermission('article_create') ? t("New Article") : null}
        onClickButton={() => history.push('/kb/create')}
      />

      {/* Card Toolbar */}
      <Card className={classes.findingMargin} >
        <Box display="flex">
          <FormControl className={classes.formControl1} >
            <TextField
              inputRef={keywordRef}
              style={{ margin: '16px 0' }}
              variant="outlined"
              label={t("Keyword")}
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              onKeyUp={(e) => setTyping(!typing)}
              // onChange={handleKeywordChange}
            >
            </TextField>
          </FormControl>
          <FormControl className={classes.formControl2} >
            <SelectCategoryController
              fieldName="category"
              className={classes.category_}
              onChangeTop={handleCategoryChange}
              fieldLabel={t("Category")}
              control={control}
            />
          </FormControl>
          <Box flexGrow={1} />
          <FormControl className={classes.Sort_formControl} >
            <InputLabel>{t("Order by")}</InputLabel>
            <Select
              // InputLabelProps={{
              //   shrink: true,
              // }}
              margin="dense"
              className={classes.sort_select}
              variant="outlined"
              value={sortValue}
              onChange={handleSortChange}>
                <MenuItem value="just created">{t("Latest article")}</MenuItem>
              {sort_list.map((sort_list) => (
                <MenuItem value={sort_list.value}>{sort_list.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Card>

      {/* Article List */}
      <Grid item xs={12} >
        <Card className={classes.list_articles}>
          {articles.map((val) => {
            return (
              <React.Fragment>
                  <List className={classes.root2} key={val.id}>
                    {/* <Tooltip
                      placement="top-start" 
                      arrow
                      className={classes.tooltip} 
                      title={
                        <>
                          <li className={classes.list}>{`Author: ${searchingName(val.owner)}`}</li>
                          <li className={classes.list}>{`Follower: ${val.followers.length}`}</li>
                          <li className={classes.list}>{`Created at: ${new Date(val.dateCreated).toLocaleString()}`}</li>
                          <li className={classes.list}>{`Status: ${val.kb_status}`}</li>
                        </>
                        }
                    > */}
                      <ListItem alignItems="flex-start">
                        
                        {/* Avatar Article */}
                        <ListItemAvatar>
                          <ReceiptOutlinedIcon></ReceiptOutlinedIcon>
                        </ListItemAvatar>
                        
                        {/* Article Title & Caption */}
                        <ListItemText
                          className={classes.list_item_text}
                          primary={val.title}
                          secondary={
                            <React.Fragment>
                              {val.caption}
                            </React.Fragment>
                          }
                        />

                        {/* Article View */}
                        <ListItemText className={classes.tags_}>
                         <Typography>
                            Views: {val.stats.openCount}
                          </Typography> 
                        </ListItemText>
                        
                        {/* Article Rate */}
                        {process.env.REACT_APP_BUILD_TARGET == 'STD' && (
                          <ListItemText className={classes.rate_}>
                            <Rating defaultValue={(val.score / 100) * 5} precision={0.1} readOnly />
                          </ListItemText>
                        )}
                

                        {/* View Button */}
                        <IconButton
                          id="view"
                          color="primary"
                          title={t("Read Article")}
                          disabled={!hasPermission('article_view')}
                          onClick={() => history.push({ pathname: 'kb/view', state: { article_id: val.id } })}
                        >
                          <VisibilityIcon />
                        </IconButton>

                        {/* Approve Button */}
                        <IconButton
                          disabled={!hasPermission('article_approve') || setApproveBtnDisabled(val)}
                          color={setApproveBtnColour(val)}
                          title={t("Approve Article")} onClick={() => history.push({ pathname: 'kb/approve', state: { article_id: val.id } })}
                        >
                          <BeenhereIcon />
                        </IconButton>

                        {/* Edit Button */}
                        <IconButton
                          disabled={!(hasPermission('article_edit') || isOwner(val))}
                          color="primary"
                          title={t("Edit Article")} onClick={() => history.push({ pathname: 'kb/editor', state: { article_id: val.id } })}
                        >
                          <EditIcon />
                        </IconButton>

                        {/* Delete Button */}
                        <IconButton
                          disabled={!(hasPermission('article_delete') || isOwner(val))}
                          color="primary"
                          title={t("Delete Article")} onClick={() => handleDeleteDialog(val.id)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </ListItem>
                    {/* </Tooltip> */}

                  </List>
                <Divider variant="inset" />
              </React.Fragment>
            )
          })
          }
          {/* Delete confirmation dialog */}
          <Dialog
            open={deleteOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleDeleteDialogClose}
          >
            <DialogTitle>{t('Are you sure you want to delete the article?')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('This will permanently delete the article.')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteDialogClose}  color="secondary">
                    {t('Cancel')}
                </Button>
                <Button onClick={() => DeleteArticle(deleteId)} color="primary">
                    {t('Delete')}
                </Button>
            </DialogActions>
          </Dialog>
    
        </Card>
      </Grid>

      
    </Container>
  )
}

export default Lists;
