import axios from 'axios';
import url from 'url';

const GET_SOME_JOURNEY_URL = url.resolve(process.env.REACT_APP_JM_BACKEND_FQDN, '/jm/api/v1/journeys');

export const getJourneys = (contactId = '') => {
  return axios({
    url: `${GET_SOME_JOURNEY_URL}?contact_id=${contactId}&sort=-created_at&limit=10`,
    method: 'GET',
  });
};
