import React from "react";
import {
  Button,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from './styles'


const CreateButton = ({buttonName, handleClickOpen}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip title={t("create contact")}>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        {buttonName}
      </Button>
    </Tooltip>
  )
}

export default CreateButton;