import axios from 'axios';
import url from 'url';

const LOAD_REPORT_URL = url.resolve(process.env.REACT_APP_JSREPORT_FQDN, '/api/report');

export const previewReport = (body) => {
  return axios({
    url: LOAD_REPORT_URL,
    method: 'POST',
    data: body,
  });
};

export const downloadReport = (body) => {
  return axios({
    url: LOAD_REPORT_URL,
    method: 'POST',
    responseType: 'blob',
    data: body,
  });
};
