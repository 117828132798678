import React from "react";

class Header extends React.Component {
	onToggle = event => {
		let value = event.target.value;
		this.props.onToggle(value);
	};

	render() {
		return (
			<select value={this.props.active} onChange={this.onToggle}>
				<option value="">Headers</option>
				{this.props.headerOptions.map(heading => {
					
					return <React.Fragment key={heading.label}>
							<option value={heading.style}>{heading.label}</option>;
						</React.Fragment>
				})}
			</select>
		);
	}
}

export default Header;
