/* eslint-disable no-underscore-dangle */
const asyncDelay = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const hitsToDocs = (hits) => {
  return hits.map((hit) => {
    hit._source._id = hit._id;
    return hit._source;
  });
};

export {
  hitsToDocs,
  asyncDelay,
};
