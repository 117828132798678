import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBoxOutlined';
import ArrowDownward from '@material-ui/icons/ArrowDownwardOutlined';
import Check from '@material-ui/icons/CheckOutlined';
import ChevronLeft from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRight from '@material-ui/icons/ChevronRightOutlined';
import Clear from '@material-ui/icons/ClearOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutlineOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import FilterList from '@material-ui/icons/FilterListOutlined';
import FirstPage from '@material-ui/icons/FirstPageOutlined';
import LastPage from '@material-ui/icons/LastPageOutlined';
import Remove from '@material-ui/icons/RemoveOutlined';
import SaveAlt from '@material-ui/icons/SaveAltOutlined';
import Search from '@material-ui/icons/SearchOutlined';
import ViewColumn from '@material-ui/icons/ViewColumnOutlined';

export default {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};