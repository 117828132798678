import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import {
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Typography, 
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
// import ScrollBar from 'react-perfect-scrollbar';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(-2),
    alignItems: "center",
    // position: "sticky",
    // bottom: "1rem",
  },
  input: {
    margin: '5px 10px 3px 3px',
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 5px 3px 3px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  text: {
    fontSize: 14,
  },
}));

const RowPerPageInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    margin: '3px 10px 3px 3px',
    borderRadius: 4,
    position: 'relative',
    // backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    width: 18
  },
}))(InputBase);

function EmailPagination(props) {
  const classes = useStyles1();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    className,
    pageAll,
    pageNum,
    pageSize,
    changeRowsPerPageQuery,
    firstPage,
    backPage,
    nextPage,
    lastPage,
  } = props;
  const numberMenu = [{_id:1, name:5}, {_id:2, name:10}, {_id:3, name:25}]

  return (
    <div className={className}>
      <Grid container className={classes.root} justify="flex-end">
        <Typography className={classes.text}>{'Rows per page:'}</Typography>
        <FormControl variant="filled">
          <Select
          input={<RowPerPageInput defaultValue={pageSize} onChange={changeRowsPerPageQuery}/>}
          defaultValue={pageSize}
          onChange={changeRowsPerPageQuery}
        >
          {numberMenu.map((val) => (
            <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
          ))}
        </Select>
        </FormControl>
        {/* <Select
          style={{
            margin: '1px 10px 3px 3px',
            borderRadius: 4,
            position: 'relative',
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '5px 5px 3px 3px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
          }}
          defaultValue={pageSize}
          onChange={handleChangeRowsPerPageQuery}
        >
          {numberMenu.map((val) => (
            <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
          ))}
        </Select> */}
        <Typography className={classes.text}> {t(` ${pageNum? pageNum : '-'} of ${pageAll? pageAll : '-'}`)}</Typography>
        <IconButton
          onClick={firstPage}
          disabled={pageNum === 1 || !pageNum}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={backPage}
          disabled={pageNum === 1 || !pageNum}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={nextPage}
          disabled={pageNum >= pageAll || !pageNum}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={lastPage}
          disabled={pageNum >= pageAll|| !pageNum}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Grid>
    </div>
    
  );
}

export default EmailPagination;