/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const TYPING_TIMEOUT = 750;

const TextFieldDebounce = ({ onStopTyping, ...restProps}) => {
  const [value, setValue] = useState('');
  const isMountedRef = useRef(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  // Side effect of DidMount and value state changes.
  useEffect(() => {

    // Ignore the componentDidMount effect
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }

    // Create a timer to execute the callback.
    const timeoutId = setTimeout(() => {
      onStopTyping(value);
    }, TYPING_TIMEOUT);

    // Kill the recent timer instance.
    return () => clearTimeout(timeoutId)

  }, [value]);
  
  return (
    <TextField 
      onChange={handleChange}
      {...restProps} 
    />
  )
}

TextFieldDebounce.propTypes = {
  onStopTyping: PropTypes.func
}

TextFieldDebounce.defaultProps ={
  onStopTyping: () => {}
}

export default TextFieldDebounce