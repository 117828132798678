import React from 'react';

// eslint-disable-next-line react/prop-types
const FieldHelper = ({ message }) => {
  return (
    <small
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '2px 8px 0 8px',
        fontSize: '0.70rem',
        display: 'block',
      }}
    >
      {message}
    </small>
  );
};

export default FieldHelper;
