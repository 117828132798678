import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  block: {
    marginTop: '-92px',
    fontSize: '1rem',
    textAlign: 'center',
    display: 'inline-block',
  },
  button: {
    marginTop: '10px',
  },
}));

const ForbiddenPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <p>403 Forbidden</p>
        <p>{t('You don\'t have permission to access this.')}</p>
        <Button 
          variant="contained" 
          color="secondary"
          className={classes.button}
          onClick={() => history.push('/')}
        >
          {t('general_homepage')}
        </Button>
      </div>
    </div>
  )
}

ForbiddenPage.propTypes = {
}

export default ForbiddenPage;
