import axios from 'axios';
import url from 'url';
import { logger } from '../common';

const GET_ONE_TICKET_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets');
const GET_SOME_TICKET_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets');
const POST_CREATE_TICKET_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets');
const GET_ONE_ACTIVITY_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/activities');
const GET_SOME_ACTIVITY_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/activities');
const POST_CREATE_ACTIVITY_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/activities');
const PUT_UPDATE_ACTIVITY_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/activities');
const DELETE_REMOVE_ACTIVITY_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/activities');
const PUT_UPDATE_TICKET_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets');
const DELTE_UPDATE_TICKET_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets');
const GET_SOME_TICKET_LOG_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/ticket-logs');
const POST_CREATE_TICKET_LOG_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/ticket-logs');
const POST_MERGE_REQUEST_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/merge-request');
const POST_COMPLAINT_REQUEST_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/complaint-request');
const PUT_UPDATE_MANY_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/update-many');
const GET_GENERATE_YTD_MTD_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/rama-gen-ytd-mtd');
const GET_EXPORT_EXCEL_URL = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/rama-export-excel');
const GET_FOLLOWUP_PLAN = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/rama-followup-plan');
const PUT_ADD_TODAY_DISPATCH = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/rama-add-today-dispatch');
const PUT_INCREASE_TODAY_FOLLOWUP_COUNT = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/rama-increase-today-followup-count');
const PUT_INCREASE_TODAY_FOLLOWUP_LAB_COUNT = url.resolve(process.env.REACT_APP_TM_BACKEND_FQDN, '/tm/api/v1/tickets/rama-increase-today-followup-lab-count');

export const getUnreadTickets = (userId) => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?unread_id=${userId}&fields=_id&limit=99`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getRamaUnreadTickets = (userId) => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?custom.patient_sequence=1&unread_id=${userId}&custom.ytd_status.reason=New,Open,Close&fields=_id&limit=99`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getAllTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getRamaAllTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?populate=true&custom.patient_sequence=1&${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getMyTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getRamaMyTickets = (userId, queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?populate=true&custom.patient_sequence=1&agent._id=${userId}&custom.ytd_status.reason=New,Open,Close&${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getTeamTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getStudyTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getRamaStudyTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?populate=true&custom.patient_sequence=1&is_study=true&${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getRamaPoolTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?populate=true&custom.patient_sequence=1&custom.ytd_status.reason=New,Open,Close&${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getTickets = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getTicket = (ticketId) => {
  return axios({
    url: `${GET_ONE_TICKET_URL}/${ticketId}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const createTicket = (payload) => {
  return axios({
    url: POST_CREATE_TICKET_URL,
    method: 'POST',
    withCredentials: true,
    data: payload,
  });
};

export const updateTicket = (ticketId, payload) => {
  return axios({
    url: `${PUT_UPDATE_TICKET_URL}/${ticketId}`,
    method: 'PUT',
    withCredentials: true,
    data: payload,
  });
};

export const updateTicketBeta = (ticketId, payload) => {
  return axios({
    url: `${PUT_UPDATE_TICKET_URL}/${ticketId}`,
    method: 'PUT',
    withCredentials: true,
    data: payload,
  });
};

export const removeTicket = (ticketId) => {
  return axios({
    url: `${PUT_UPDATE_TICKET_URL}/${ticketId}`,
    method: 'PUT',
    withCredentials: true,
    data: { is_active: false },
  });
};

export const deleteTicket = (ticketId) => {
  return axios({
    url: `${DELTE_UPDATE_TICKET_URL}/${ticketId}`,
    method: 'DELETE',
    withCredentials: true,
  });
};

export const getActivities = (queryString = '') => {
  return axios({
    url: `${GET_SOME_ACTIVITY_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const getActivity = (activityId) => {
  return axios({
    url: `${GET_ONE_ACTIVITY_URL}/${activityId}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const createActivity = (payload) => {
  return axios({
    url: POST_CREATE_ACTIVITY_URL,
    method: 'POST',
    withCredentials: true,
    data: payload,
  });
};

export const updateActivity = (activityId, payload) => {
  return axios({
    url: `${PUT_UPDATE_ACTIVITY_URL}/${activityId}`,
    method: 'PUT',
    withCredentials: true,
    data: payload,
  });
};

export const removeActivity = (activityId) => {
  return axios({
    url: `${DELETE_REMOVE_ACTIVITY_URL}/${activityId}`,
    method: 'DELETE',
    withCredentials: true,
  });
};

export const mergeRequest = (fromId, toId, fromCode, toCode) => {
  return axios({
    url: POST_MERGE_REQUEST_URL,
    method: 'POST',
    withCredentials: true,
    data: {
      fromId,
      toId,
      fromCode,
      toCode,
    },
  });
};

export const complaintRequest = (ticketId) => {
  return axios({
    url: `${POST_COMPLAINT_REQUEST_URL}?ticketId=${ticketId}`,
    method: 'POST',
    withCredentials: true,
  });
};

export const complaintCancel = (ticketId) => {
  return axios({
    url: `${POST_COMPLAINT_REQUEST_URL}?ticketId=${ticketId}`,
    method: 'DELETE',
    withCredentials: true,
  });
};

export const getTicketLogs = (queryString = '') => {
  return axios({
    url: `${GET_SOME_TICKET_LOG_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
  });
};

export const createTicketLog = (payload) => {
  return axios({
    url: POST_CREATE_TICKET_LOG_URL,
    method: 'POST',
    withCredentials: true,
    data: payload,
  });
};

export const updateMany = (ticketIds, payload) => {
  return axios({
    url: `${PUT_UPDATE_MANY_URL}/${ticketIds}`,
    method: 'PUT',
    withCredentials: true,
    data: payload,
  });
};

export const searchAvailableSameCaseTicketsByYTD = (currentYTD = '', keyword = '') => {

  // Escape regex special characters.
  keyword = keyword.replace('-', '.');

  // Build the reuqest URL string.
  let requestURL = `${GET_SOME_TICKET_URL}?fields=custom`;
  requestURL += currentYTD ? `&custom.same_case.ytd_code!=${currentYTD}` : '';
  requestURL += keyword ? `&custom.ytd_code=/${keyword}/i` : '';

  // Return the executed axios promise.
  return axios({
    url: requestURL,
    method: 'GET',
    withCredentials: true,
  });
};

export const searchAvailableSameCaseTicketsByMTD = (currentMTD = undefined, keyword = '') => {

  // Escape regex special characters.
  keyword = keyword.replace('/', '.');
  keyword = keyword.replace('-', '.');

  // Build the reuqest URL string.
  let requestURL = `${GET_SOME_TICKET_URL}?fields=custom`;
  requestURL += currentMTD ? `&custom.same_case.mtd_code!=${currentMTD}` : '';
  requestURL += keyword ? `&custom.mtd_code=/${keyword}/i` : '';

  // Return the executed axios promise.
  return axios({
    url: requestURL,
    method: 'GET',
    withCredentials: true,
  });
};

export const generateYtdMtd = () => {
  return axios({
    url: GET_GENERATE_YTD_MTD_URL,
    method: 'GET',
    withCredentials: true,
  });
};

export const getRamaExportExcel = (queryString = '') => {
  return axios({
    url: `${GET_EXPORT_EXCEL_URL}?${queryString}`,
    method: 'GET',
    withCredentials: true,
    responseType: 'blob',
    timeout: 1000 * 60 * 10,
  });
};

export const getRamaFollowupPlan = (queryString = '') => {
  return axios({
    url: GET_FOLLOWUP_PLAN,
    method: 'GET',
    withCredentials: true,
  });
};

export const checkDuplicateYtd = (newYtd) => {
  return axios({
    url: GET_SOME_TICKET_URL,
    method: 'GET',
    withCredentials: true,
    params: {
      'custom.ytd_code': newYtd,
      'custom.patient_sequence': 1,
      fields: {
        _id: 1,
      },
    },
  });
};

export const checkDuplicateMtd = (newMtd) => {
  return axios({
    url: GET_SOME_TICKET_URL,
    method: 'GET',
    withCredentials: true,
    params: {
      'custom.mtd_code': newMtd,
      'custom.patient_sequence': 1,
      fields: {
        _id: 1,
      },
    },
  });
};

export const addTodayDispatch = (ticketId, userId) => {
  return axios({
    url: PUT_ADD_TODAY_DISPATCH,
    method: 'PUT',
    withCredentials: true,
    data: { ticketId, userId },
  });
};

export const increaseTodayFollowupCount = (ticketId) => {
  return axios({
    url: PUT_INCREASE_TODAY_FOLLOWUP_COUNT,
    method: 'PUT',
    withCredentials: true,
    data: { ticketId },
  });
};

export const increaseTodayFollowupLabCount = (ticketId) => {
  return axios({
    url: PUT_INCREASE_TODAY_FOLLOWUP_LAB_COUNT,
    method: 'PUT',
    withCredentials: true,
    data: { ticketId },
  });
};
