import React, { useState, useEffect, useContext, useRef } from 'react';
import { 
  Container,
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { ContactHeader } from './ContactHeader';
import EditDialog from './EditDialog';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import { AppContext } from '../../../../contexts/AppStore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePaginationActions from '../components/TablePaginationActions';
import DropdownContactPoint from '../../../TM/src/pages/RAMA/partials/DropdownContactPoint'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  autoCom: {
    margin: "16px 0"
  },
  cellWidth: {
    maxWidth: "30vh",
  },
  ty: {
    margin: "3%",
  },
  body_table: {
    maxHeight: "100%",
    overflow: "auto",
    marginTop: "20px"
  },
  findingMargin: {
    paddingLeft: "14px",
    paddingRight: "14px"
  },
  paginationActions: {
    marginRight: "5%"
  }
}));

function Contacts(props) {
  const { t } = useTranslation();
  const { hasPermission } = useContext(AppContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allContacts, set_allContacts] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [contactAllCount, setContactAllCount] = useState(1);
  const [pageAllCount, setPageAllCount] = useState(1);
  const [pagerToggleReFresh, setPagerToggleReFresh] = useState(true);
  const [contactType, setContactType] = useState([ {_id: 1, name: "1"}, ]);
  const [gender, setGender] = useState([ {_id: 1, name: "1"}, ]);
  const [addrProvince, setAddrProvince] = useState([ {_id: 1, name: ""}, ]);
  const [addrRegion, setAddrRegion] = useState([ {_id: 1, name: ""}, ]);
  const [filterContactPoint, setFilterContactPoint] = useState(null);
  const [parentIdContacts] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [isSearchES, setIsSearchES] = useState(false);
  const [searchESText, setSearchESText] = useState();
  const [searchESTextContactPoint, setSearchESTextContactPoint] = useState();

  /* State of Search Box inputRef */
  const [typing, setTyping] = useState(false);
  const keyUpTimeOut = 1000;
  const keywordRef = useRef(null);

  /* State of Pagination when using any Search Box */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [RowsPerPageTrigger, setRowsPerPageTrigger] = useState(false);
  const [countESTotal, setCountESTotal] = useState(0);

  /* Function for get contact type and gender */
  const getContactTypeAndGender = () => {
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs`)
    .then((res) => res.json())
    .then((data) => {
      const resJson = data.data;
      const contactTypeData = resJson.filter(n => n.groupName === "Contact Type");
      const genderData = resJson.filter(n => n.groupName === "Sex");
      setContactType(contactTypeData);
      setGender(genderData);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  var filterUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  /* Function for get Province and Region */
  const getProvinceAndRegion = () => {
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/custom-rama/api/v1/provinces`)
    .then((res) => res.json())
    .then((data) => {
      const resJson = data.data;
      setAddrProvince(resJson);
      let filteredRegion = resJson.map(res => res.regionName);
      filteredRegion = filteredRegion.filter(filterUnique);
      let regionJsonObject = [];
      for(let i = 0; i < filteredRegion.length; i++) {
        regionJsonObject.push({_id: i, name: filteredRegion[i]});
      }
      setAddrRegion(regionJsonObject);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  /* 
  Functions for CONTACT Dialog
  */
  const refreshContactTable = () => {
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  const handleDeleteDialog = (deleteId) => {
    setDeleteId(deleteId);
    setDeleteOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteOpen(false);
  };
  /* Ending Line of Functions */

  /* 
  Functions for CONTACT LIST table pagination
  When NO any word in SEARCH BOX
  */
  const handleFirstPageButtonClick = (event) => {
    setPageNum(1);
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  const handleBackButtonClick = (event) => {
    setPageNum(pageNum-1);
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  const handleNextButtonClick = (event) => {
    setPageNum(pageNum+1);
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  const handleLastPageButtonClick = (event) => {
    setPageNum(pageAllCount);
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  const handleChangeRowsPerPageQuery = (event) => {
    setPageSize(+event.target.value);
    setPageNum(1);
    setPagerToggleReFresh(!pagerToggleReFresh);
  };
  /* Ending Line of Functions */

  /* 
  Functions for CONTACT LIST table pagination
  When HAVE any word in SEARCH BOX
  */
  const lastPage = Math.ceil(countESTotal / rowsPerPage) - 1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let pageNumber = newPage
    if (searchESText) {
      elasticSearchInputData(searchESText, pageNumber, rowsPerPage);
    } else if (searchESTextContactPoint) {
      elasticSearchInputData(searchESTextContactPoint, pageNumber, rowsPerPage);
    }
    // elasticSearchInputData(searchESText, pageNumber, rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPageTrigger(!RowsPerPageTrigger);
    setPage(0);
  };

  const handleFirstPage = (event) => {
    handleChangePage(event, 0);
  };

  const handleBackPage = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextPage = (event) => {
    handleChangePage(event, page + 1);
  };

  const handleLastPage = (event) => {
    handleChangePage(event, lastPage);
  };
  /* Ending Line of Functions */

  /* Function for CONTACT POINT */
  const handleOnChangeContactPointField = (searchText) => {
    if (searchText) {
      setSearchESText();
      elasticSearchInputData(searchText, 0, rowsPerPage);
      setIsSearchES(searchText.length > 0);
    } else {
      setIsSearchES(false);
      refreshContacts();
    }
  }

  /* Checking word in CONTACT POINT Search Box */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchESTextContactPoint) {
        handleOnChangeContactPointField(searchESTextContactPoint);
      }
    }, keyUpTimeOut);
    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchESTextContactPoint, RowsPerPageTrigger]);

  /* Function of ES for Search box */
  function elasticSearchInputData(searchText, pageNumber, rowsPerPage) {

    // let keyword = searchContact;
    let keyword = searchText;
    keyword = keyword.replace('.', ' ');
    keyword = keyword.replace('.', ' ');
    keyword = keyword.replace('-', ' ');
    keyword = keyword.replace('-', ' ');

    let from = 0;
    let size = rowsPerPage;

    //NOTE: Material UI pagination start from 0
    from = rowsPerPage * pageNumber;

    // EDIT a Contact
    // Search for Parent <--- find by name, get the _id
    // Set Parent in Contact for EDIT <--- Contact.parentId = _id
    const input_query = {
      from: from,
      size: size,
      query: {
        filtered: {
          query: {
            query_string: {
              query: `*${keyword}*`,
              fields: [
                "name",
              ]
            }
          },
          filter: {
            bool: {
              must: []
            }
          }
        }
      }
    }

    // Filter parentId 
    // let contactPointId = filterContactPoint ? filterContactPoint._id : undefined
    // // Avoid elastic-search no support undefined value
    // if (contactPointId) {
    //   input_query.query.filtered.filter.bool.must.push({
    //     term: {
    //       parentId: contactPointId
    //     }
    //   })
    // }

    esGetSearchData(input_query);
  }

  const esGetSearchData = (input_query) => {
    const query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`;
    console.log('query', query);

    fetch(query, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input_query)
    })
      .then(res => res.json())
      .then((data) => {
        setCountESTotal(data.hits.total)
        let converted_list = data.hits.hits.map((v) => {
          v._source._id = v._id;
          return v._source;
        });
        set_allContacts(converted_list);
      });
  }

  /* Function for Checking word in Search Box */
  const handleEsTextFieldChange = (event) => {
    if (event.value.length > 0) {
      setSearchESTextContactPoint();
      setFilterContactPoint(null);
      setSearchESText(event.value);
      elasticSearchInputData(event.value, 0, rowsPerPage);
      setIsSearchES(event.value.length > 0);
    } else {
      setIsSearchES(false);
      refreshContacts();
    }
  }

  /* Checking word in Search Box */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (handleEsTextFieldChange) {
        handleEsTextFieldChange(keywordRef.current);
      }
    }, keyUpTimeOut);
    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typing, RowsPerPageTrigger]);

  // Execute when DidMount and filterContactPoint changes and 
  useEffect(() => {
    getContactTypeAndGender();
    getProvinceAndRegion();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchContacts = () => {
    fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person?page=${pageNum}&pageSize=${pageSize}`)
      .then((response) => response.json())
      .then((data) => {
        setContactAllCount(data.totalContacts);
        setPageAllCount(data.totalPages);
        set_allContacts(data.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
  };

  /* REFRESH Contact list */
  const refreshContacts = () => {
    if (isSearchES === false) {
      fetchContacts();
    } else {
      return
    }
  };

  const handleOnCloseDialog = () => {
    refreshContacts();
    setPagerToggleReFresh(!pagerToggleReFresh);
  };

  /* Check that no text in Search Box */
  useEffect(() => {
    if (isSearchES === false) {
      fetchContacts();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagerToggleReFresh]);

  /* REFRESH Search Box every 10 seconds */
  useEffect(() => {
    refreshContacts();  // For immediate refresh between timer events
    const refreshContactsTimer = setInterval(() => {
      // refreshContacts();
      if (isSearchES === false) {
        fetchContacts();
      }
    }, 10000);
    return () => {
      clearInterval(refreshContactsTimer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchES, pagerToggleReFresh]);

  /* Delect Contact in list */
  const handleDelete = (delete_id) => {
    setDeleteOpen(false)
    let query = `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${delete_id}`
    var raw = JSON.stringify(delete_id);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch(query, {
      method: 'Delete',
      headers: myHeaders,
      body: raw,
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          setPagerToggleReFresh(!pagerToggleReFresh);
          enqueueSnackbar(t('Contact has been deleted.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to delete.'), { variant: 'error' });

      })
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <ContactHeader
        contactPage={'contact'}
        title={t('contacts_page_title')}
        subtitle={t('contacts_page_subtitle')}
        buttonName={hasPermission('contact_create') ? t('contacts_page_new') : null}
        triggerToRefresh={refreshContactTable}
        parentIdOptions={parentIdContacts}
        allContacts={allContacts}
        contactTypeList={contactType}
        genderList={gender}
        addrProvince={addrProvince}
        addrRegion={addrRegion}
      />

      <Card className={classes.findingMargin}>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              onChange={(e) => e.target.value ? setIsSearchES(true) : setIsSearchES(false)}
              inputRef={keywordRef}
              onKeyUp={(e) => setTyping(!typing)}
              label={t("Search contacts")}
              className={classes.autoCom}
              margin="normal"
              variant="outlined"
              InputProps={{
                type: t('contacts_page_search'), startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <DropdownContactPoint
              value={filterContactPoint}
              margin="normal"
              label={t('general_contact_point')}
              onChange={(event, contact) => {
                if (contact) {
                  setFilterContactPoint(contact);
                  setSearchESTextContactPoint(contact ? contact.name : '');
                  setIsSearchES(true);
                } else {
                  setFilterContactPoint(null);
                  setIsSearchES(false);
                }
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid item xs={12}>
        <Card className={classes.body_table}>
          <Table>

            {/* Table Head */}
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="body2">{t('contact_person_name')}</Typography></TableCell>
                <TableCell><Typography variant="body2">{t('contact_email')}</Typography></TableCell>
                <TableCell><Typography variant="body2">{t('contact_number')}</Typography></TableCell>
                <TableCell><Typography variant="body2">{t('contact_address')}</Typography></TableCell>
                <TableCell><Typography variant="body2">{t('Action')} </Typography></TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {allContacts.map((val) => (
                <React.Fragment key={val._id}>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" flexDirection="row">
                        {/* <Avatar style={{backgroundColor : randomColor()}} alt={val.name} src="/broken-image.jpg" /> */}
                        <Avatar alt={val.name} src="/broken-image.jpg" />
                        <Typography className={classes.ty}>{val.lastName? val.firstName+ " " +val.lastName : val.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.CW_email}>
                      <Typography variant="subtitle2">
                        {(val.email) ? val.email[0] : ""} 
                      </Typography>
                      <Typography variant="subtitle2">
                        {(val.email) ? val.email[1] : "" }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {(val.phone) ? val.phone[0] : "" }
                      </Typography>
                      <Typography variant="subtitle2">
                        {(val.phone) ? val.phone[1] : "" }
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellWidth}>
                      <Typography variant="subtitle2">
                        {val.address}
                      </Typography>
                    </TableCell>

                    <TableCell>

                      {/* Edit Button */}
                        <EditDialog
                          contactPage={'contact'}
                          contactId={val._id}
                          triggerToRefresh={refreshContactTable}
                          parentIdOptions={parentIdContacts}
                          open={openEditDialog}
                          handleOpen={setOpenEditDialog}
                          allContacts={allContacts}
                          contactTypeList={contactType}
                          genderList={gender}
                          addrProvince={addrProvince}
                          addrRegion={addrRegion}
                          onCloseDialog={handleOnCloseDialog}
                        />

                      {/* Delete Button */}
                      <IconButton
                        disabled={!hasPermission('contact_delete')}
                        onClick={() => handleDeleteDialog(val._id)}
                      >
                        <DeleteForeverOutlinedIcon />
                      </IconButton>

                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
              
            </TableBody>
          </Table>

          {/* Table Pagination */}
          <Card>
            <Grid justify="flex-end" className={classes.paginationActions}>
              <TablePaginationActions
                count={isSearchES ? countESTotal : contactAllCount}
                pageAll={isSearchES ? lastPage : pageAllCount}
                pageNum={isSearchES ? page : pageNum}
                pageAllLabel={isSearchES ? lastPage+1 : pageAllCount}
                pageNumLabel={isSearchES ? page+1 : pageNum}
                firstPage={isSearchES ? 0 : 1}
                pageSize={isSearchES ? rowsPerPage : pageSize}
                handleFirstPageButtonClick={isSearchES ? handleFirstPage : handleFirstPageButtonClick}
                handleBackButtonClick={isSearchES ? handleBackPage : handleBackButtonClick}
                handleNextButtonClick={isSearchES ? handleNextPage : handleNextButtonClick}
                handleLastPageButtonClick={isSearchES ? handleLastPage : handleLastPageButtonClick}
                handleChangeRowsPerPageQuery={isSearchES ? handleChangeRowsPerPage : handleChangeRowsPerPageQuery}
              />
            </Grid>
          </Card>

          {/* Delete confirmation dialog */}
          <Dialog
            open={deleteOpen}
            keepMounted
            onClose={handleDeleteDialogClose}
          >
            <DialogTitle>{t("Are you sure you want to delete the contact?")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("This will permanently delete the contact.")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="secondary">
                {t("Cancel")}
              </Button>
              <Button onClick={() => handleDelete(deleteId)}  color="primary">
                {t("Delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </Grid>

    </Container>
  )
}

export default Contacts;
