import React from 'react';
import { useHistory } from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AppContext } from '../contexts/AppStore';
import BookmarksIcon from '@material-ui/icons/BookmarksOutlined';
import ClickToCallIcon from '@material-ui/icons/PhoneInTalkOutlined';
import TicketsIcon from '@material-ui/icons/LocalOfferOutlined'
import KnowledgeBaseIcon from '@material-ui/icons/ReceiptOutlined';
import UnknownIcon from '@material-ui/icons/FiberManualRecord';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 320,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      // backgroundColor: theme.palette.primary.main,
      // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      //   color: theme.palette.common.white,
      // },
    },
  },
}))(MenuItem);

let bookmark_test_data = [
  {
    id: "T-001",
    type: "ticket",
    title: "Ticket #1234-abc-001 (2020-10-10)"
  },
  {
    id: "T-002",
    type: "ticket",
    title: "Ticket #1234-abc-002 (2020-10-10)"
  },
  {
    id: "T-003",
    type: "ticket",
    title: "Ticket #1234-abc-003 (2020-10-10)"
  },
  {
    id: "C-001",
    type: "call",
    title: "Click-to-Call #(9)083456789 (2020-10-10)"
  },
  {
    id: "C-002",
    type: "call",
    title: "Click-to-Call #(9)083456111 (2020-10-10)"
  },
  {
    id: "K-001",
    type: "zzzknowledge-base",
    title: "Article #1234-abc-001 (2020-10-10)"
  },
  {
    id: "K-002",
    type: "Knowledge-base",
    title: "Article #1234-abc-002 (2020-10-10)"
  },
  {
    id: "K-003",
    type: "knowledge_base",
    title: "Article #1234-abc-003 (2020-10-10)"
  },
  {
    id: "K-004",
    type: "knowledge-base",
    title: "Article #1234-abc-004 (2020-10-10)"
  },
  {
    id: "K-005",
    type: "knowledge-base",
    title: "Article #1234-abc-005 (2020-10-10)"
  },
  {
    id: "K-006",
    type: "knowledge-base",
    title: "Article #1234-abc-006 (2020-10-10)"
  },
  {
    id: "K-007",
    type: "knowledge-base",
    title: "Article #1234-abc-007 (2020-10-10)"
  },
  {
    id: "K-008",
    type: "knowledge-base",
    title: "Article #1234-abc-008 (2020-10-10)"
  },
  {
    id: "K-009",
    type: "knowledge-base",
    title: "Article #1234-abc-009 (2020-10-10)"
  },
  {
    id: "K-010",
    type: "knowledge-base",
    title: "Article #1234-abc-010 (2020-10-10)"
  },
  {
    id: "K-011",
    type: "knowledge-base",
    title: "Article #1234-abc-011 (2020-10-10)"
  },
  {
    id: "K-012",
    type: "knowledge-base",
    title: "Article #1234-abc-012 (2020-10-10)"
  },
  {
    id: "K-013",
    type: "knowledge-base",
    title: "Article #1234-abc-013 (2020-10-10)"
  },
  {
    id: "K-014",
    type: "knowledge-base",
    title: "Article #1234-abc-014 (2020-10-10)"
  }
];

export default function BookmarksMenu({ getNewBookmarks, finishedGettingBookmarks }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bookmarks, setBookmarks] = React.useState(bookmark_test_data);
  const { loginUser } = React.useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchBookmarks = (getBookmarks) => {
    // Skip this if get bookmarks change to false.
    if (!getBookmarks) return;

    // Print flag of refresh bookmark list.
    console.log(`FETCHING new Bookmarks >>> ${getBookmarks}`);

    fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/person/bookmarks?id=${loginUser._id}`)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return [];
        }
      })
      .then(data => {
        setBookmarks(data);
      })
      .catch(err => {
        console.log(err);
      });
    
    // Runs the callback if props exist.
    // TODO: Here may not be really finish.
    if (finishedGettingBookmarks) {
      finishedGettingBookmarks();
    }
  }

  // When getNewBookmarks props changes.
  React.useEffect(() => {
    fetchBookmarks(getNewBookmarks);
  }, [getNewBookmarks]);

  return (
    <div>
      <Tooltip title={t("Bookmark List")}>
        <IconButton
          color="inherit"
          onClick={handleClick}
        >
          <BookmarksIcon /> 
        </IconButton>
      </Tooltip>

      {(bookmarks && bookmarks.length > 0) && (
        <StyledMenu
          id="bookmarks-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
        >
          {bookmarks.map((v, i) => {
            return (
              <StyledMenuItem
                key={i}
                onClick={() => {
                  handleClose();
                  for (let key in Object.keys(bookmarks)) {
                    if (bookmarks[key].title === v.title) {
                      let type = bookmarks[key].type.toLowerCase();
                      let _id = bookmarks[key].id;

                      if (type === 'ticket') {
                        history.push(`/${type}/update/${_id}`);
                      } else if (type === 'article') {
                        let state = {
                          article_id: _id
                        };
                        history.push('/kb/view', state);
                      } else if (type === 'call') {
                        history.push('/tickets/all');
                      } else {
                        history.push('/tickets/all');
                      }
                    }
                  }

                }}
              >
                <ListItemIcon style={{ minWidth: 40 }}>
                  {(v.type.toLowerCase() === 'ticket' && <TicketsIcon fontSize="small" />)
                    || (v.type.toLowerCase() === 'article' && <KnowledgeBaseIcon fontSize="small" />)
                    || (v.type.toLowerCase() === 'call' && <ClickToCallIcon fontSize="small" />)
                    || <UnknownIcon fontSize="small" />
                  }
                </ListItemIcon>
                <Typography variant="inherit" noWrap>{v.title}</Typography>
              </StyledMenuItem>
            );
          })
          }
        </StyledMenu>
      )}
    </div>
  );
}