import React from 'react';
import {Grid, Card, Snackbar, TextField, CardContent, List, Box, Button,Typography, FormControl, NativeSelect, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm} from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputColor from 'react-input-color';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    card: {
        margin: "1%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%",
        marginRight: "18%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    }

}))


const Edit = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    // const [count, setCount] = useState(0)
    const [color, setColor] = React.useState({});
    const [colorHexCode, setColorHexCode] = React.useState('#000000');
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [tbList, set_tbList] = React.useState([]);
    const [state, setState] = React.useState({
        gilad: false,
        jason: false,
        antoine: false,
      });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const { gilad, jason, antoine } = state;

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    function handleClick  () {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const onSubmit= (data,e) => {
        console.log(data)
        var raw = JSON.stringify(data);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-statuses/${props.location.state.val._id}`,{
            method: 'PUT', 
            headers: myHeaders,
            body: raw,
        })
        .then(response => {
            // let data = response.text()
            if (response.status >=200 && response.status <=299){history.push('/config');    
            return enqueueSnackbar(t('Status has been updated.'), { variant: 'success' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Status has fail to update.'), { variant: 'error' })
        }) 
    }

    return (
        <Grid container spacing={1} >
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title= {t("Edit Status")}
                    subtitle={t("Edit Status")}
                />
            </Container>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>                           
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}> {t('Reason')} * </Typography>
                                <TextField 
                                    fullWidth 
                                    className={classes.parallel} 
                                    inputRef={register({ required: true})} 
                                    name="reason" 
                                    defaultValue={props.location.state.val.reason} 
                                    variant="outlined"
                                    error={errors.reason}
                                    helperText={errors.reason && t("Reason field is required")}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row"><Typography className={classes.middle}> {t('State')} * </Typography>
                                <Autocomplete
                                    className={classes.parallel}
                                    //options={tbList}
                                    defaultValue={props.location.state.val.state}
                                    options={['open', 'complete', 'close']}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => 
                                        <TextField  
                                            inputRef={register({ required: true})} 
                                            name="state" 
                                            {...params} 
                                            variant="outlined" 
                                            error={errors.state}
                                            helperText={errors.state && t("State field is required")}
                                        />}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('Color')} : </Typography>                              
                                <div className="Color">    
                                    <InputColor 
                                        initialValue={props.location.state.val.color}
                                        color={color}
                                        //onChange={setColor}
                                        onChange={e => setColor(e.hex)} 
                                        //onChange={e => setColor(e.target.value)}
                                        placement="right"      
                                        />
                                    <TextField 
                                        className={classes.parallel} 
                                        inputRef={register({ required: true})} 
                                        value={color} 
                                        name="color" 
                                        variant="outlined" 
                                        visibled="true"
                                    ></TextField>
                                </div>                       
                            </Box> 
                            <Box className={classes.btn_}>
                                <Button type="submit"  color="primary"  variant="contained" > {t('Save')}</Button>
                                <Button color="primary" variant="contained" onClick={() => history.push('/config')}>{t('Cancel')}</Button>
                            </Box>
                        </form>
                    </CardContent>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <   Alert onClose={handleClose} severity="success" onClick={() => history.push('/config')} >
                            {t('The status had been successfully updated!')}
                        </Alert>
                    </Snackbar>
                </Card>
            </Grid>
        </Grid>
    )
}
export default Edit;