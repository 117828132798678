import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, List, TextField, ListItem, Button, ListItemAvatar, TableFooter, TablePagination, TableHead, TableRow, ListItemText, MenuItem, Divider, IconButton, Card, FormControl, Select, InputLabel, CardHeader, ListItemSecondaryAction, TableCell, TableBody, Table } from '@material-ui/core';
import PageHeader from '../../TM/src/components/PageHeader';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../contexts/AppStore';
import { useTranslation } from 'react-i18next';
import {  useSnackbar } from 'notistack';
import {CardContent, CardActions, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import OpacityIcon from '@material-ui/icons/Opacity';
import axios from 'axios';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';






const useStyles = makeStyles((theme) => ({
  itemText1: {
    width: "20%",
    maxWidth: '20%'
  },
  itemText2: {
    width: "25%",
    maxWidth: '25%'
  }
}));

const SearchResult = ({
  ingredients, 
  totalHits, 
  onPageChange,
  onItemDeleted,
  prepareElasticSearchQuerySEARCH, 
  keyword, 
  keywordType, 
  runElasticSearchQuery, 
  esFilterOpen, 
  fetchIngredients, 
  dbSubTotalHits
}) => {
    const { t } = useTranslation();
    const { hasPermission, loginUser } = React.useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
    const [deleteId, setDeleteId] = React.useState()
    const [selectedSubstance, setSelectedSubstance] = React.useState()
    const [selectedGroupTypeObj, setSelectedGroupTypeObj] = React.useState()
    const [selectedObj, setSelectedObj] = React.useState()
    const [selectedPhysicalTypeName, setSelectedPhysicalTypeName] = React.useState()
    const [selectedUsageTypeName, setSelectedUsageTypeName] = React.useState()
    


    const handleChangePage = (event, newPage) => {
      // Update the pagination component.
      setPage(newPage);
      // Tell the parent current page changed.
      onPageChange(newPage);
    }

    const handleChangePageES = (event, newPage) => {
      setPage(newPage);
      let pageNumber = newPage

      if (esFilterOpen == false) {
        prepareElasticSearchQuerySEARCH(keyword, keywordType, pageNumber, rowsPerPage)
      }
      else {
        runElasticSearchQuery(pageNumber, rowsPerPage)
      }
    }
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };




    const fetchCategoryFamilyChemical = (selectedSubstanceObj) => {
      if(selectedSubstanceObj.group) {

        const result = fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/${selectedSubstanceObj.group._id}`)
        .then(res => res.json())
        .then((data) => {
            return data
        })
        return result
      }
    }

    const fetchGroupType = (groupType, typeId) => {

      let url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs/?groupName=${groupType}`;
      axios( url )
      .then(response => {
          if (response.status == 200) {
              if (groupType == 'Physical Type') {
                     const result = response.data.data.filter((val) => val._id == typeId)
                     if (result[0]) { 
                      setSelectedPhysicalTypeName(result[0].name)
                     }
              }
              else {
                const result = response.data.data.filter((val) => val._id == typeId)
                if (result[0]) { 
                  setSelectedUsageTypeName(result[0].name)
                }
              }
          }
      })
      .catch(err => {
          console.log(err.message)
      });

    }

    const handleDialogOpen = async (selectedSubstanceObj) => {
      setDialogOpen(true)
      const result = await fetchCategoryFamilyChemical(selectedSubstanceObj)
      setSelectedGroupTypeObj(result)
      setSelectedSubstance(selectedSubstanceObj)

      if (selectedSubstanceObj.physical_type) {
        fetchGroupType('Physical Type', selectedSubstanceObj.physical_type )

      }

      if (selectedSubstanceObj.usage_type) {
        fetchGroupType('Usage Type', selectedSubstanceObj.usage_type)
      }
    }

    const handleDialogClose = () => {
      setDialogOpen(false)
    }

    const handleDeleteDialogOpen = (deleteIngredientObj) => {
      setDeleteDialogOpen(true)
      setDeleteId(deleteIngredientObj._id)
      
    }

    function handleDeleteDialogClose () {
      setDeleteDialogOpen(false)

      const requestUrl = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/ingredients/${deleteId}`;
      const requestHeaders = new Headers();
      requestHeaders.append('Content-Type', 'application/json');
  
      fetch(requestUrl, {
        method: 'Delete',
        headers: requestHeaders,
      })
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            onItemDeleted(deleteId)
            enqueueSnackbar('Ingredient has been deleted.', { 
              variant: 'success' 
            });
          }
        })
        .catch((error) => {
          console.error('');
          enqueueSnackbar(t('Ingredient has fail to delete.'), { 
            variant: 'error' 
          });
        });
    }

    const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false)
    }

    let start = 0;
    let end = rowsPerPage;

    return (
          <>

              {/* Headers */}
              <List>
              <ListItem>
                <ListItemText className={classes.itemText1}>
                  <ul style={{listStyle: "none"}}>
                    <li>{t("Ingredient Name")}</li>
                  </ul>
                </ListItemText>
                <ListItemText className={classes.itemText2}>
                  <ul style={{listStyle: "none"}}>
                    <li>{t("Ingredient Group")}</li>
                  </ul>
                </ListItemText>
                <ListItemText className={classes.itemText2}>
                  <ul style={{listStyle: "none"}}>
                    <li>{t("Advice Info")}</li>
                  </ul>
                </ListItemText>
                <ListItemText className={classes.itemText2}>
                  <ul style={{listStyle: "none"}}>
                    <li>{t("Update Info")}</li>
                  </ul>
                </ListItemText>
              </ListItem>
            </List>


            <List>
              {ingredients.slice(start, end).map((ingredient) => {
                return (
                <React.Fragment key={ingredient._id}>
                  <ListItem onClick={() =>  handleDialogOpen(ingredient)} button alignItems="flex-start">
                    
                    <ListItemText
                      className={classes.itemText1}
                      primary={
                        <ul style={{listStyle: "none"}}>
                          <li> {ingredient.name}</li>
                        </ul>
                      }
                    >
                    </ListItemText>
      
                    <ListItemText className={classes.itemText2}>
                        <ul style={{listStyle: "none"}}>
                          <li>Category - {(ingredient.group && ingredient.group.group) ? ingredient.group.group.category_name : "N/A"}</li>
                          <li>Family - {(ingredient.group && ingredient.group.group) ? ingredient.group.group.family_name : "N/A" }</li>
                          <li>Chemical - {(ingredient.group && ingredient.group.group) ? ingredient.group.group.chemical_name : "N/A" }</li>
                        </ul>
                    </ListItemText>

                    <ListItemText className={classes.itemText2}>
                       {(ingredient.advice_info) ? ingredient.advice_info : ""}
                    </ListItemText>


                    {/* Update info */}
                    <ListItemText className={classes.itemText2}>
                      <ul style={{listStyle: "none"}}>
                        <li>Updated at: {ingredient.updated_at ? new Date(ingredient.updated_at).toDateString() : ""}</li>
                        <li>Updated by: {ingredient.updated_by_name ? ingredient.updated_by_name : ""}</li>
                      </ul>
                    </ListItemText>
                  
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => history.push({ pathname: '/ingredient/edit', state: { ingredient: ingredient } })} color="primary">
                        <EditIcon />
                      </IconButton>
      
                      <IconButton onClick={() => handleDeleteDialogOpen(ingredient)} color="primary">
                        <DeleteForeverOutlinedIcon  />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider/>
                </React.Fragment>
              )})}
              <TableFooter>
                  <TablePagination
                      rowsPerPageOptions={['-', 100]}
                      count={totalHits}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                  />           
              </TableFooter>
          </List>

      
            {/* Delete Ingredient Dialog */}
            <Dialog
              open={deleteDialogOpen}
              keepMounted
              onClose={handleDeleteDialogCancel}
            >
              <DialogTitle>{t('Are you sure you want to delete the ingredient?')}</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      {t('This will permanently delete the ingredient.')}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleDeleteDialogCancel}  color="secondary">
                      {t('Cancel')}
                  </Button>
                  <Button onClick={handleDeleteDialogClose} color="primary">
                      {t('Delete')}
                  </Button>
              </DialogActions>
            </Dialog>
  
          </>
)}
  
export default SearchResult;
  