import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
import PageHelmet from '../../TM/src/components/PageHelmet';
import PageHeader from '../../TM/src/components/PageHeader';
import { Container, DialogActions, Button, Typography, IconButton } from '@material-ui/core';
import {  useSnackbar } from 'notistack';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Index from './EmailDialogContent.js'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';



const zIndex = 1500;
const useStyles = makeStyles((theme) => ({

}))



const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});
  
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});



const WelcomeEmailDialog = ({welcomeEmailOpen, handleWelcomeEmailDialog, ticketId, replyTo}) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } =useSnackbar();
    const [welcomeEmailTo, setWelcomeEmailTo] = React.useState()



    const sendWelcomeEmail = () => {


        const bodyData = {
            to: replyTo,
            from: process.env.REACT_APP_DEFAULT_EMAIL,
            subject: '',
            text_body: "สวัสดีค่ะ นี่คือข้อความจากทางศูนย์พิษวิทยา โรงพยาบาลรามาธิบดี หากท่านต้องการสอบถามรายละเอียดหรือปรึกษาข้อมูลเพิ่มเติม สามารถส่งข้อความพร้อมแนบรูปหรือเอกสาร ผ่านอีเมลนี้ได้เลยค่ะ ขอบคุณที่ใช้บริการค่ะ",
            sent: false,
            tracking_id: ticketId,
            status: "Welcome",
            attachments: []
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_EMAIL_BACKEND_FQDN}/api/Emails`, {
            method: 'POST', 
            headers: myHeaders,
            body: JSON.stringify(bodyData)
        })
        .then((response) => {
            if (response.status >= 200 && response.status <=299) {
                return enqueueSnackbar('Welcome email send successfully', {variant: 'success'})
            }
        })
        .catch(function (error) {
            console.log(error)
            enqueueSnackbar('Welcome email fail to send', {variant: 'error'})
        });


    } 

    // useEffect( () =>  {
    //     fetchEmails()
    // }, [])



    return (
        <div>

            <Dialog 
                open={welcomeEmailOpen}
                onClose={() => handleWelcomeEmailDialog(false)}
            >
                <DialogTitle
                    onClose={() => handleWelcomeEmailDialog(false)}
                >
                    Email
                </DialogTitle>

                <DialogContent>
                    <Button 
                        color="primary"
                        variant='contained'
                        onClick={() => sendWelcomeEmail()}
                    >
                        Send Welcome Email
                    </Button>

                </DialogContent>

            </Dialog>

            


        </div>
        

    )
}

export default WelcomeEmailDialog;