import React, { useEffect } from 'react';
import { Grid, Card, Divider, IconButton, TextField, CardContent, List, Box, Button, Typography, FormControl, FormControlLabel, Slider  } from '@material-ui/core';
import PageHeader from '../../modules/TM/src/components/PageHeader';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Controller } from 'react-hook-form';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {DropzoneDialog} from 'material-ui-dropzone'
import { DropzoneArea } from 'material-ui-dropzone';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import { KeyboardDateTimePicker, validate } from '@material-ui/pickers';
import { date } from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';



const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    example: {
        color:"#bdbdbd"
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    errorMsg : {
        color: "#f44336",
        marginTop: "1%",
        marginLeft: "1%"
    },
    deletePrompt: {
        justifyContent: 'center',
        display: 'flex',
        padding: theme.spacing(1, 0, 0, 0)
    }

}))

const keyboardButtonProps = { style: { marginRight: -14 } };

const Edit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { control, register, handleSubmit, errors , getValues} = useForm({
        defaultValues: {
            lang: 'th-TH',
        }
    });
    // const [count, setCount] = useState(0)
    const { enqueueSnackbar } =useSnackbar();
    const {t} = useTranslation();
    // const [cards, setCards] = React.useState([6])
    const [openUpload, setOpenUpload] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    // const [value, setValue]= React.useState("")
    const [uploadOpen, setUploadOpen] = React.useState(false);
    const { register: registerFile, handleSubmit: handleFileSubmit } = useForm();
    const [startDate, setStartDate] = React.useState();
    const [expireDate, setExpireDate] = React.useState();
    const [path, setPath] = React.useState();
    const [uploadBtnEnable, setUploadBtnEnable] = React.useState(true);
    const [dynamicPaths, setDynamicPaths] = React.useState([]);
    const [fileName , setFileName] = React.useState();
    const [pathValueForDelete, setPathValueForDelete] = React.useState()

    {/* Fetch Dynamic Paths */}
    useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/configs` )
        .then(res => res.json())
        .then((data) => {
            var lastConfig = data.data[data.data.length - 1]
            setDynamicPaths(lastConfig.tts_dynamic_paths)
        })
    }, [])


    {/* Delete prompt */}
    const handleDeletePrompt =  () => {

        if (pathValueForDelete) {
            const data = {
                path: pathValueForDelete
            }
            var raw = JSON.stringify(data)
            
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            fetch(`${process.env.REACT_APP_TEXTTOSPEECH_BACKEND_FQDN}/deletePrompt`,{
                method: 'DELETE', 
                headers: myHeaders,
                body: raw,
            })
            .then(response => {
                let result = response.json();
                /* 
                    NOTE: when running on server 404 will appear here (no need to fix)
                */
                if (response.status == 200) {
                    enqueueSnackbar('Success: Successfully deleted', {variant: 'success'})
                }
                else if (response.status == 404) {
                    enqueueSnackbar('Success: No prompt file exist to delete', {variant: 'success'})
                } else {
                    throw new Error("Error from Server: " + result.message);
                }
            })
            .catch((error) => {
                /* 
                   NOTE: when running on dev 404 will apppear here due to CORS error (no need to fix)
                */
                console.error('Error:', error)
                enqueueSnackbar(t('Delete Prompt failed'), { variant: 'error' });
            })   
        }
        else {
            return enqueueSnackbar(t('Please input the IVR path to delete the prompt'), { variant: 'error' });
        }


    }


    {/* Submit and download */}
    const onSubmit = ( data) => {
        console.log("data", data)

        // check selected path and set the fileName
        const findFileName = dynamicPaths.find((element) => element.value == data.path)
        setFileName(findFileName.fileName)
        
        const downloadBody = {
            text : data.text,
            lang: data.lang,
            path: data.path
        }
        setStartDate(new Date(data.startPeriod))
        setExpireDate(new Date(data.endPeriod))
        setPath(data.path)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        axios({
            url: `${process.env.REACT_APP_TEXTTOSPEECH_BACKEND_FQDN}/api/v1/new-tts-prompt`,
            method: 'POST',
            data: downloadBody,
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
        
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${data.text}.wav`);
            document.body.appendChild(fileLink);
        
            fileLink.click();
            if (response.status >=200 && response.status <= 299) {
            enqueueSnackbar(t('Successfully downloaded!'), { variant: 'success' });

            }
            setUploadBtnEnable(false)

        }).catch((error) => {
            console.log(error)
            enqueueSnackbar(t('Download fail!') , { variant: 'error' });
        });

    }

    const handleOpen = (index) => {
       setOpen(true)
    }

    function handleClose () {
      setOpen(false)
    }
    


    {/* Upload file */}
    const handleUpload =  async ( files) => {
        setFiles(files)
        // console.log("PAth", path)
        console.log("FILES", files[0])
        var formData= new FormData();
        formData.append("file", files[0], fileName);
        formData.append("StDate", `${startDate.toISOString()}`);
        formData.append("EndDate", `${expireDate.toISOString()}`);
        formData.append("path", path );
        
        console.log("FORM DATA", formData)
        await fetch(`${process.env.REACT_APP_TEXTTOSPEECH_BACKEND_FQDN}/uploadScheduleEN`,{
            method: 'POST', 
            // headers: myHeaders,
            body: formData,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status == 200){
                enqueueSnackbar(t('Successfully uploaded!.'), { variant: 'success' });
                setOpen(false)
            }
        })
        .catch((error) => {
            console.error('Error:', error)
            enqueueSnackbar(t('Upload fail!.'), { variant: 'error' });
        })
    }

    


    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader
                title=  {t('Text to speech')}
                subtitle={t('Text to speech for supervisor')}
            />
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>

                        {/* Delete exisitng IVR prompt */}
                        {console.log(pathValueForDelete)}
                        <Box display="flex" flexDirection="row" className={classes.deletePrompt}>
                            <Alert
                                severity='info'
                                action={
                                <IconButton color="inherit" size="small" onClick={() => handleDeletePrompt()}>
                                    <DeleteOutlineOutlinedIcon/>
                                </IconButton>
                            }
                            >
                                To delete the existing prompt in IVR, click on the Delete Icon with the selected IVR path
                            </Alert>
                        </Box>


                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>

                            {/* Dynamic Path */}
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('IVR Path * ')}  </Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                        control={control}
                                        name="path"
                                        rules={{required:true}}
                                        render={({onChange, ref}) => (
                                            <Select 
                                                onChange={(e) => {
                                                    onChange(e.target.value)
                                                    setPathValueForDelete(e.target.value)
                                                }}
                                                variant="outlined"
                                                displayEmpty
                                                inputRef={ref}
                                            >
                                                {dynamicPaths.map((val) => (
                                                    <MenuItem value={val.value}>{val.name} </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.path && (<span className={classes.errorMsg}>{t('Path field is required')}</span>)}
                                </FormControl>
                            </Box>
        
                            {/* Start date */}
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('Start period *')}  </Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                        className={classes.textField}
                                        name="startPeriod"
                                        control={control}
                                        defaultValue={new Date()}
                                        rules={{
                                            validate: () =>{
                                                return getValues("startPeriod") > new Date() && getValues("endPeriod") > getValues("startPeriod") && getValues("endPeriod") !== getValues("startPeriod")
                                            }
                                        }}
                                        as={
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            margin="dense"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="DD/MM/YYYY HH:mm"
                                            KeyboardButtonProps={keyboardButtonProps}
                                            InputAdornmentProps={{ position: 'end' }}
                                        />
                                        }
                                    />
                                    {errors.startPeriod && (<span className={classes.errorMsg}>{t('Please enter the valid date')}</span>)}
                                </FormControl> 
                            </Box>


                            {/* Expire date */}
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('Expire period *')}  </Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                        className={classes.textField}
                                        name="endPeriod"
                                        control={control}
                                        defaultValue={new Date()}
                                        rules={{
                                            validate: () =>{
                                                return getValues("endPeriod") > new Date() && getValues("endPeriod") > getValues("startPeriod") && getValues("endPeriod") !== getValues("startPeriod")
                                            }
                                        }}
                                        as={
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            margin="dense"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="DD/MM/YYYY HH:mm"
                                            KeyboardButtonProps={keyboardButtonProps}
                                            InputAdornmentProps={{ position: 'end' }}
                                        />
                                        }
                                    />
                                    {errors.endPeriod && (<span className={classes.errorMsg}>{t('Please enter the valid date')}</span>)}
                                </FormControl>
                            </Box>



                            {/* Language */}
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('Language / Locale')}  </Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                        name="lang"
                                        as={
                                            <Select 
                                                variant="outlined"
                                                displayEmpty
                                                value="th-TH"
                                            >
                                                <MenuItem value="th-TH">ไทย (ประเทศไทย)</MenuItem>
                                                <MenuItem value="en-US">English (United States)</MenuItem>
                                            </Select>
                                        }
                                        control={control}
                                        defaultValue=""
                                    />
                                </FormControl>
                            </Box>

                            {/* Text */}
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('Enter text *')}  </Typography>
                                <FormControl className={classes.parallel}>
                                    <TextField
                                        inputRef={register({required: true})}
                                        name="text"
                                        label= {t('Text')}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                    />
                                    {errors.text && (<span className={classes.errorMsg}>{t('Text field is required')}</span>)}
                                </FormControl>
                            </Box>

                            {/* Save/Download btn and Upload btn */} 
                            <Box className={classes.btn_}>
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    type="submit"
                                    startIcon={<SaveIcon/>}
                                > {t('Submit')}
                                </Button>
                                <Button 
                                    onClick={() => handleOpen()} 
                                    variant="contained" 
                                    color="secondary" 
                                    startIcon={<CloudUploadIcon/>} 
                                    disabled={uploadBtnEnable}
                                >
                                    {t('Upload')}
                                </Button>
                                <DropzoneDialog
                                    open={open}
                                    onSave={(files) => handleUpload(files)}
                                    acceptedFiles={['audio/wav']}
                                    showPreviews={true}
                                    // maxFileSize={5000000}
                                    onClose={handleClose}
                                />   
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </Grid>                           
        </Container>
    )
    
    
}
export default Edit;