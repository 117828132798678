import React from 'react';
import {Grid, Card, TextField, List, Box, Button, Typography, FormControl, FormGroup, FormControlLabel, Checkbox, InputBase } from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm} from "react-hook-form";
import {  useSnackbar } from 'notistack';
import permissions from './Role.json';
import { useTranslation } from 'react-i18next';


const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    card: {
        margin: "1%"
    },
    parallel: {
        width: "70%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%",
        marginRight: "18%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    boxes: {
        margin: "2%",
        width: "100%"
    },
    spaces: {
        marginLeft: "3%"
    },
    list: {
        width: "100%"
    },
    inputBase : {
        width: "70%",
    }

}))


const Create = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    // const [count, setCount] = useState(0)
    const [open, setOpen] = React.useState(false);
    const [tbList, set_tbList] = React.useState([])
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [checked, setChecked] = React.useState();



    const handleChange = ( event) => {
        permissions.permissions.forEach(val => {
            if (val.name == event.target.name){
            setChecked({ ...checked, [val.name]: event.target.checked });
            }
        });
    };


    const onSubmit = (data,e) => {
      console.log(data); 

      var body = {
        name: data.name || '',
        desc: data.desc || '',
        permissions: [],
      }

      for (let i = 0; i < data.permissions.length; i++) {
        const element = data.permissions[i];
        if (element.name !== false) {
            body.permissions.push({
                name: element.name,
                desc: element.desc
            })
        }
      }
      console.log("body", body)

      var raw = JSON.stringify(body);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then((response) => {
          if (response.status >=200 && response.status <=299){
            history.push('/config');    
            enqueueSnackbar(t('Role has been created.'), { variant: 'success' });
          }
          
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Role has fail to create.'), { variant: 'error' })
      }) 
    }

    return (
        <Grid container spacing={1} >
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title= {t("Create New Role")}
                    subtitle= {t("Create new role and permission")}
                />
            </Container>
            <Grid item xs={12}>
                <Card className={classes.card} >
                 
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}> {t('Type of Role')} * </Typography>
                                <TextField 
                                    fullWidth 
                                    className={classes.parallel} 
                                    inputRef={register({ required: true})} 
                                    name="name" 
                                    variant="outlined"
                                    error={errors.name}
                                    helperText={errors.name && t("Name field is required")}
                                />
                            </Box>

                            <Box display="flex" flexDirection="row">
                              
                                <Typography  className={classes.middle}> {t('Permissions')}  </Typography>
                                <List className={classes.parallel}>
                                {console.log(permissions.permissions)}
                                {
                                  permissions.permissions.map ((val, index) => (
                                    // <Box display="flex" flexDirection="row" className={classes.boxes}>
                                    //   <input name={`permissions[${index}].name`} type='checkbox' value={val.name} ref={register}/>
                                    //   <div className={classes.spaces}><Typography>{val.desc}</Typography></div>
                                    //   <input name={`permissions[${index}].desc`} type='hidden' value={val.desc} ref={register}/>
                                    // </Box>


                                    <Box display="flex" flexDirection="row" >
                                        <FormControl className={classes.formControl}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    value={val.name}
                                                    control={
                                                        <Checkbox 
                                                            defaultChecked={false}
                                                            checked={checked} 
                                                            onChange={handleChange}
                                                            name={`permissions[${index}].name`}
                                                        />
                                                    }                                                
                                                    name={`permissions[${index}].name`}
                                                    inputRef={register}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <InputBase
                                            value={val.desc}
                                            className={classes.inputBase}
                                            inputProps={{ 
                                                'aria-label': 'naked',         
                                                readOnly: true,
                                            }}
                                            inputRef={register}
                                            name={`permissions[${index}].desc`}
                                        />
                                        <InputBase
                                            type="hidden"
                                            value={val.desc}
                                            inputProps={{ 
                                                'aria-label': 'naked',         
                                                readOnly: true,
                                            }}
                                            inputRef={register}
                                            name={`permissions[${index}].desc`}
                                        />
                                    </Box>
                                
                                  ))
                                }
                                </List>
                            </Box>

                            <Box className={classes.btn_}>
                                <Button type="submit"  color="primary"  variant="contained"> {t('Create')}</Button>
                                <Button color="primary" variant="contained" onClick={() => history.push('/config')}> {t('Cancel')}</Button>
                            </Box>
                        </form>
                    
                      
                        
                    
                </Card>
            </Grid>
        </Grid>
    )
}
export default Create;