/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import url from 'url';
import { logger } from '../../TM/src/common';

// Please avoid using ?pretty in code.
const ELASTIC_SEARCH_SUBSTANCES_URL = url.resolve(process.env.REACT_APP_ELASTIC_SEARCH_FQDN, '/elasticsearch/cream.ingredients/_search');

// Search ingredients using ElasticSearch.
export const searchIngredients = (
  keywordName = '',
  filterCategoryName = '',
  filterFamilyName = '',
  filterChemicalName = '',
  rowsPerPage = 100,
  rowsOffset = 0,
) => {

  // Escape the ElasticSearch special characters.
  keywordName = keywordName.replace(/\//g, '//');
  filterCategoryName = filterCategoryName.replace(/\//g, '//');
  filterFamilyName = filterFamilyName.replace(/\//g, '//');
  filterChemicalName = filterChemicalName.replace(/\//g, '//');

  const queryPayload = {
    from: rowsOffset,
    size: rowsPerPage,
    query: {
      bool: {
        must: [],
      },
    },
  };

  if (keywordName) {
    queryPayload.query.bool.must.push({
      query_string: {
        fields: ['name'],
        query: `*${keywordName}*`,
      },
    });
  }

  if (filterCategoryName) {
    queryPayload.query.bool.must.push({
      match_phrase: {
        'group.group.category_name': filterCategoryName,
      },
    });
  }

  if (filterFamilyName) {
    queryPayload.query.bool.must.push({
      match_phrase: {
        'group.group.family_name': filterFamilyName,
      },
    });
  }

  if (filterChemicalName) {
    queryPayload.query.bool.must.push({
      match_phrase: {
        'group.group.chemical_name': filterChemicalName,
      },
    });
  }

  return axios({
    url: ELASTIC_SEARCH_SUBSTANCES_URL,
    method: 'POST',
    data: queryPayload,
  });
};
