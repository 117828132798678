import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from './styles'

export const Title = ({ title, }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Typography className={classes.title} variant="h5">{title}</Typography>
    </Grid>
  )
}

export const Subtitle = ({ subtitle, }) => {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.subtitle}>{subtitle}</Typography>
  )
}