import React from 'react';
import STDContacts from './STDContacts'
import RAMAContacts from './RAMAContacts'

const Contacts = () => {
  return (
    <div>
    {process.env.REACT_APP_BUILD_TARGET === 'STD' && (<STDContacts />)}
    {process.env.REACT_APP_BUILD_TARGET === 'RAMA' && (<RAMAContacts />)}
    </div>
  )
}

export default Contacts