import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useTranslation } from "react-i18next";

export default function Edit({
  url,
  edit_id,
  edit_from,
  edit_to,
  edit_ext,
  edit_phone,
  edit_csq,
  toSetTrigger
}) {
  const history = useHistory();
  const [trigger, setTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [fromName, setFromName] = useState(edit_from);
  const [toName, setToName] = useState(edit_to);
  const [ext, setExt] = useState(edit_ext);
  const [phone, setPhone] = useState(edit_phone);
  const [csq, setCsq] = useState(edit_csq);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bodyData = {
    from: fromName,
    to: toName,
    ext: ext,
    phone: phone,
    csq: csq,
  };

  const handleEnterAndClose = async(id) => {
    await setOpen(false);
    var raw = await JSON.stringify(bodyData);
    var myHeaders = await new Headers();
    await myHeaders.append("Content-Type", "application/json");
    await fetch(`${url}/tsm/api/v1/screenpop/${id}`, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
    }).then(() => console.log("Data is successfully updated."))
    .catch(error => console.log(error))
    await toSetTrigger()
  };

  const handleOnChange = async (func, setTo) => {
    await func(setTo);
    await console.log(setTo);
  };

  return (
    <>
      <Tooltip title={t("edit")}>
        <IconButton
          variant="contained"
          onClick={handleClickOpen}
          //   onClick={() =>
          //     history.push({
          //       pathname: "/config/users/edit",
          //       state: { userId: val._id },
          //     })
          //   }
        >
          <EditOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("edit")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update to this row, please re-enter your from name, to name,
            Ext.number, phone and CSQ.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="name"
            label={t("screenpop_from")}
            type="text"
            value={fromName}
            onChange={(e) => handleOnChange(setFromName, e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="name"
            label={t("screenpop_to")}
            type="text"
            value={toName}
            onChange={(e) => handleOnChange(setToName, e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="name"
            label={t("screenpop_ext")}
            type="number"
            value={ext}
            onChange={(e) => handleOnChange(setExt, e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="name"
            label={t("screenpop_phone")}
            type="number"
            value={phone}
            onChange={(e) => handleOnChange(setPhone, e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="name"
            label={t("screenpop_csq")}
            type="text"
            value={csq}
            onChange={(e) => handleOnChange(setCsq, e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={() => handleEnterAndClose(edit_id)} color="primary">
            {t("enter")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
