import React, {useRef} from 'react';
import { Grid, TablePagination, IconButton, Typography, Table, TableHead, Button, TableBody, TableRow, TableCell} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PageHeader from '../PageHeader'; 
import {DropzoneDialog} from 'material-ui-dropzone';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    top: {
        marginLeft: "1%",
        marginRight: "1%",
    },
    formControl: {
        marginLeft: "1%"
    },
    btn_search: {
        marginLeft: "1.5%",
        height: "3%",
        marginTop: "0.5%"
    },
    table: {
        minHeight: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        marginLeft: "1%",
        marginRight: "1%"
    },
    ty: {
        margin: "3%",
      }
 
}));



function ConfigCategory(props) {
    const history = useHistory();
    const classes= useStyles();
    const [tbList, set_tbList] = React.useState([]);
    const tableRef = useRef(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("");
    const [targetDurations, setTargetDurations] = React.useState([])
    const [isLoading, setIsloading] = React.useState(true)


    const handleDeleteDialog = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    {/* upload csv file */}
    // const handleSave =  ( files) => {
    //     console.log(files)

    //     var formData= new FormData();
    //     formData.append("file", files[0]);

    //     fetch(`${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/admin/api/upload/categories/import-csv`,{
    //         method: 'POST', 
    //         // headers: myHeaders,
    //         body: formData,
    //         redirect: 'follow'
    //     })
    //     .then(response => {
    //         if (response.status == 201){
    //             enqueueSnackbar('Successfully uploaded!.', { variant: 'success' });
    //             // setOpen(false)
    //         }
    //     })
    //     .catch((error) => {
    //         console.error('Error:', error)
    //         enqueueSnackbar('Upload fail!.', { variant: 'error' });
    //     })

        
    // }

    // const handleDownLoad = () => {
    //     var exportedFilenmae = "Person" + '.csv' || 'export.csv';

    //     var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    //     if (navigator.msSaveBlob) {
    //         navigator.msSaveBlob(blob, exportedFilenmae);
    //     } else {
    //         var link = document.createElement("a");
    //         if (link.download !== undefined) { // feature detection
    //             // Browsers that support HTML5 download attribute
    //             var url = URL.createObjectURL(blob);
    //             link.setAttribute("href", url);
    //             link.setAttribute("download", exportedFilenmae);
    //             link.style.visibility = 'hidden';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }

    //     }
    // }




    const handleDelete = ( delete_id) => {
        setDeleteOpen(false)
        console.log(delete_id) 
        let query= `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories/${delete_id}`
        var raw = JSON.stringify(delete_id);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        fetch(query ,{
            method: 'Delete', 
            headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status >=200 && response.status <=299){
             enqueueSnackbar(t('Category has been deleted.'), { variant: 'success' });
             fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories`)
                .then(res => res.json())
                .then((data) => {
                    console.log("TB_list")
                    console.log(data)
                    set_tbList(data.data)
            })
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Category has fail to delete.'), { variant: 'error' })
        })
        // window.location.reload(false); 
} 

    const convertFromMiliSeconds = (data) => {

        const ONE_MIN_IN_MS = 1000 * 1;
        const ONE_HOUR_IN_MS = ONE_MIN_IN_MS * 60 * 60 * 1;
        const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24 * 1;

        for (let item of data) {
            let originalTargetDuration = item.sla.targetDuration
            const days_ = parseInt(originalTargetDuration / ONE_DAY_IN_MS)
            const MILI_LEFT_AFTER_DAYS = (originalTargetDuration - (days_ * ONE_DAY_IN_MS))

            const hours_ = parseInt(MILI_LEFT_AFTER_DAYS / ONE_HOUR_IN_MS)
            const MILI_LEFT_AFTER_HOURS = (MILI_LEFT_AFTER_DAYS - (hours_ * ONE_HOUR_IN_MS))

            const minutes_ = MILI_LEFT_AFTER_HOURS / (60*1000)

            targetDurations.push({
                days: days_,
                hours: hours_,
                minutes: minutes_
            })
        }

    }
  
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories`)
            .then(res => res.json())
            .then((data) => {
                set_tbList(data.data)

                convertFromMiliSeconds(data.data)
                setIsloading(false)

            })
    } , [])

    if(isLoading == true) {
        return <></>
    }


 
    return (
        <Grid container spacing={1} className={classes.whole}>
         <Container maxWidth="xl" className={classes.container}>
            <PageHeader
                title= {t("Config Categories")}
                subtitle= {t("Managing category")}
                buttonName=  {t("New Category")}
                onClickButton={()=> history.push('/config/Category/create')}
                // onClickUpload={() => handleOpen()}
                // onClickDown={() => handleDownLoad()}
            />
            {/* <DropzoneDialog
                open={open}
                onSave={(files) => handleSave(files)}
                acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}                showPreviews={true}
                maxFileSize={5000000}
                onClose={handleClose}
            /> */}
         </Container>    
        <Grid item xs={12} className={classes.table}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="15%"><Typography variant="body2"> {t('Name')}</Typography></TableCell>
                        <TableCell width="10%"><Typography variant="body2"> {t('Level')}</Typography></TableCell>
                        <TableCell width="20%"><Typography variant="body2"> {t('Parent Name')}</Typography></TableCell>
                        <TableCell width="10%"><Typography variant="body2"> {t('Target Duration')}</Typography></TableCell>
                        <TableCell width="15%"><Typography variant="body2"> {t('Actions')}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {tbList && tbList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, index) => (
                <React.Fragment key={val._id}>
                    <TableRow>
                        <TableCell><Typography>{val.name}</Typography></TableCell>
                        <TableCell><Typography>{val.level}</Typography></TableCell>
                        <TableCell><Typography>{val.parent ? val.parent.substring(val.parent.lastIndexOf('//') + 2) : ''}</Typography></TableCell>
                        <TableCell>
                            <Typography> 
                                {targetDurations.length> 0 && targetDurations[page * rowsPerPage+ index].days} {t('days')} {targetDurations[page * rowsPerPage+ index].hours} {t('hours')} {targetDurations[page * rowsPerPage+ index].minutes} {t('minutes')}
                            </Typography>                      
                        </TableCell> 
                        <TableCell>
                            <IconButton onClick={()=> history.push({pathname: '/config/Category/edit', state: {val: val}})} >
                                <EditOutlinedIcon color="primary"/>
                            </IconButton> 
                            <IconButton onClick={() => handleDeleteDialog(val._id)}><DeleteForeverOutlinedIcon color="primary"  /></IconButton>       
                        </TableCell>
                    </TableRow>
                </React.Fragment>
                ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tbList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* Delete confirmation dialog */}
            <Dialog
                open={deleteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteDialogClose}
            >
                <DialogTitle>{t('Are you sure you want to delete the category?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('This will permanently delete the category.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}  color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={() => handleDelete(deleteId)} color="primary">
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>                    
        </Grid>
    )
}

export default ConfigCategory;
