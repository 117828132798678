/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import url from 'url';

const SEARCH_RECORD_URL = url.resolve(process.env.REACT_APP_RECORDER_BACKEND_FQDN, '/v1/search/records');

export const searchRecord = (extension, customerMumber, startTalkAt) => {
  return axios({
    url: `${SEARCH_RECORD_URL}`,
    timeout: 30000,
    method: 'POST',
    data: {
      extension,
      customer_number: customerMumber,
      start_talk_at: startTalkAt,
    },
  });
};
