import axios from 'axios';
import url from 'url';

const POST_UPLOAD_FILE_URL = url.resolve(process.env.REACT_APP_FILE_BACKEND_FQDN, 'api/UploadFile');
const POST_DOWNLOAD_FILE_URL = url.resolve(process.env.REACT_APP_FILE_BACKEND_FQDN, 'api/DownloadFile');
const DELETE_FILE_URL = url.resolve(process.env.REACT_APP_FILE_BACKEND_FQDN, 'api/DownloadFile/DeletebyId');

export const postUploadFile = (formData) => {
  return axios({
    url: POST_UPLOAD_FILE_URL,
    method: 'POST',
    data: formData,
  });
};

export const postDownloadFile = (fileId) => {
  return axios({
    url: `${POST_DOWNLOAD_FILE_URL}?id=${fileId}`,
    method: 'POST',
    responseType: 'blob',
  });
};

export const deleteFileById = (fileId) => {
  return axios({
    url: `${DELETE_FILE_URL}?id=${fileId}`,
    method: 'DELETE',
  });
};

export const getFileURL = (fileId) => {
  return `${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile?id=${fileId}`;
};
