import React from 'react';
import {Grid, Card, Snackbar, TextField, CardContent, List, Box, Button, Typography, FormControl, NativeSelect, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm} from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputColor from 'react-input-color';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';




const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    card: {
        margin: "1%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%",
        marginRight: "18%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    }


}))


const Create = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    // const [count, setCount] = useState(0)
    const [open, setOpen] = React.useState(false);
    // const [state, setState] = React.useState();
    const [tbList, set_tbList] = React.useState([])
    const [per, set_per] = React.useState([])
    const [state, setState] = React.useState({});
    const [check, set_check] = React.useState();
    const [color, setColor] = React.useState({});
    const [colorHexCode, setColorHexCode] = React.useState('#000000');
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();


    // const handleChange = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.checked });
    // };
    const { allow_create_article, allow_edit_article , allow_delete_article} = state;




     function Alert(props) {
         return <MuiAlert elevation={6} variant="filled" {...props} />;
     }
     function handleClick  () {
         setOpen(true);
     };
     const handleClose = (event, reason) => {
         setOpen(false);
     };

    const onSubmit= (data,e) => {
    //   console.log(data); 
        var raw = JSON.stringify(data);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-statuses`,{
            method: 'POST', 
            headers: myHeaders,
            body: raw,
        })
        .then(response => {
            if (response.status >=200 && response.status <=299){history.push('/config');    
            return enqueueSnackbar(t('Status has been created.'), { variant: 'success' });
        }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Status has fail to create.'), { variant: 'error' });
        }) 

    }

    return (
        <Grid container spacing={1} >
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title= {t("Create New Status")}
                    subtitle= {t("Create New Status")}
                />
            </Container>
            <Grid item xs={12}>
                <Card className={classes.card} >
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>                                                        
                        <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}> {t('Reason')} * </Typography>
                            <TextField 
                                className={classes.parallel} 
                                inputRef={register({ required: true})} 
                                name="reason" 
                                variant="outlined"
                                error={errors.reason}
                                helperText={errors.reason && t("Reason field is required")}
                            />
                        </Box>
                        <Box display="flex" flexDirection="row"><Typography className={classes.middle}> {t('State')} * </Typography>
                            <Autocomplete
                                className={classes.parallel}
                                options={[
                                    { state: 'open' },
                                    { state: 'complete' },
                                    { state: 'close' },
                                ]}
                                getOptionLabel={(option) => option.state}
                                renderInput={(params) => 
                                    <TextField 
                                        inputRef={register({required: true})} 
                                        name="state" 
                                        {...params} 
                                        variant="outlined" 
                                        error={errors.state}
                                        helperText={errors.state && t("State field is required")}
                                    />}
                            />
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography className={classes.middle}>  {t('Color')} : </Typography>                              
                            <div className="Color">    
                                <InputColor initialValue="#5e72e4" color={color} onChange={e => setColor(e.hex)}  placement="right" />
                                    <TextField 
                                        className={classes.parallel} 
                                        inputRef={register({ required: true})} 
                                        value={color} 
                                        name="color" 
                                        variant="outlined" 
                                        visibled="true"
                                    ></TextField>
                            </div>                       
                        </Box> 
                        <Box className={classes.btn_}>
                            <Button type="submit"  color="primary"  variant="contained"> {t('Create')}</Button>
                            <Button color="primary" variant="contained" onClick={() => history.push('/config')}> {t('Cancel')}</Button>
                        </Box>
                    </form>             
                </Card>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" onClick={() => history.push('/config')}> 
                     {t('The status had been successfully created!')}
                  </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    )
}
export default Create;