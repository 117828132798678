import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import {
  Divider,
  List,
} from '@material-ui/core';
// import List from '@material-ui/core/List';
// import SearchIcon from '@material-ui/icons/Search';
import ScrollBar from 'react-perfect-scrollbar';
// import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
// import { Icon, responsiveFontSizes, TextField } from '@material-ui/core';
import ListItemEmail from './ListItemEmail';
// import {  useSnackbar } from 'notistack';
// import PropTypes from 'prop-types';
// import EmailPagination from './EmailPagination';


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      // maxHeight: '80%',
    },
    searchInput: {
      // flexGrow: 1,
    },
}))

const Left = ({
  className,
  emails,
  onSelectEmail,
  pageAll,
  pageNum,
  pageSize,
  changeRowsPerPageQuery,
  firstPage,
  backPage,
  nextPage,
  lastPage,
  ...rest
}) => {
    // const history = useHistory();
    const classes = useStyles();
    // const { t } = useTranslation();
    // const { enqueueSnackbar } = useSnackbar();


  return (
    
    // eslint-disable-next-line no-sequences
    <div className={clsx(classes.root), className} {...rest}>
      <ScrollBar>
        {/* <Toolbar>
          <Input
            disableUnderline
            style={{width: 500}}
          />
          <IconButton edge="end">
            <SearchIcon />
          </IconButton>
        </Toolbar> */}
        {/* <Divider /> */}


        {/* Email list */}
        <List >
          {emails.map((email) => (
            <React.Fragment key={email.Id}>

            <ListItemEmail
              email={email}
              onSelectEmail={onSelectEmail}
            />
            <Divider/>
            </React.Fragment>
          ))}
        </List>
      </ScrollBar>
    </div>
    
  );
};



export default Left;