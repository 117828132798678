import React from 'react';


function FileDisplay (props) {
    

    return (
       <div className="image-container" ><img  alt="" src={props.val} width="200" border="0 px" textDecoration="none" outline="none" /> </div>

    )
}
export default FileDisplay;
