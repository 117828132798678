import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, List, TextField, MenuItem, Divider, IconButton, Card, FormControl, Select, InputLabel, CardHeader, FormControlLabel, FormHelperText, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {  useSnackbar } from 'notistack';
import {CardContent, CardActions, Typography} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
// import SearchResult from './Pages/SearchResult'
import PageHeader from '../Pages/PageHeader'; 
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogIngSearchResult from './DialogIngSearchResult'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';







const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  searchCard: {
    padding: "3%",
 
  },
  dividerGrid: {
    marginTop: "2%"
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  firstTextField: {
    paddingBottom: '1%'
  }
  // gridContainer: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   flexDirection: 'column'
  // }


}));


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogAddIngredient = ({dialogAddIngredientOpen, handleAddIngredient, handleSelectedIngredientChips}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const chooseOption = 'choose';
  const notAvailable = 'N/A';

  const [ingredients, setIngredients] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [families, setFamilies] = React.useState([]);
  const [chemicals, setChemicals] = React.useState([]);

  const [selectedCategory, setSelectedCategory] = React.useState(chooseOption);
  const [selectedFamily, setSelectedFamily] = React.useState(chooseOption);
  const [selectedChemical, setSelectedChemical] = React.useState(chooseOption);


  const [typing3, setTyping3] = React.useState(false);

  const keywordRef3 = useRef(null);

  const [totalHits, setTotalHits] = React.useState(0)
  const [esOpen, setESOpen] = React.useState(false)
  const [esFilterOpen, setESFilterOpen] = React.useState(false)
  const [dbSubTotalHits, setDBSubTotalHits] = React.useState(0)

  const [keyword, setKeyword] = React.useState()
  const [keywordType, setKeywordType] = React.useState()

  const keyUpTimeOut = 1000;

 

  React.useEffect(() => {
    fetchCategories();
    fetchIngredients(1, 100);
  }, [])



  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (handleKeywordChange) {
        handleKeywordChange(keywordRef3.current, 'ingredient')
      }
    }, keyUpTimeOut);

    return () => clearTimeout(timeoutId);
  }, [typing3]);


  const handleKeywordChange = (event, keywordType) => {
    // console.log('[handleKeywordChange] event.value =', event.value);
    if (event && event.value.length > 0) {
      setKeyword(event.value)
      setKeywordType(keywordType)
      prepareElasticSearchQuerySEARCH(event.value, keywordType, 0, 100);
    } else {
      setESOpen(false)
      fetchIngredients(1, 100);
    }
  }


  const fetchElasticSearch = (es_query_body) => {

    fetch( `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream.ingredients/_search?pretty`, {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(es_query_body)
    })
      .then(res => res.json())
      .then(json => {

        console.log(json.hits.total)
        setTotalHits(json.hits.total)

        let converted_list = json.hits.hits.map((v) => {
          v._source._id = v._id;
          return v._source;
        });
        console.log("ELASTICSEARCH data=", converted_list);
        setIngredients(converted_list)
      })
      .catch((err) => {
        console.error('Elastic search failed');
        if (err) console.error(err);
      })


  }

  const prepareElasticSearchQuerySEARCH = (dataName, keywordType, pageNumber, rowsPerPage) => {
    
    setESFilterOpen(false)
    setESOpen(true)
    

    let fields = []
    if (keywordType == 'ingredient' ) fields.push('name')

    let from = 0
    let size = rowsPerPage

    //NOTE: Material UI pagination start from 0
    from = rowsPerPage * pageNumber
    size = from + rowsPerPage

    // console.log('fromTO', from, size)
  
    const getReadySearch = {
      from: from,
      size: size,
      query: {
        query_string: {
          query: `*${dataName}*`,
          fields
        }
      }
    }

    console.log("[prepareElasticSearchQuerySEARCH] getReadySearch =", JSON.stringify(getReadySearch));

    fetchElasticSearch(getReadySearch);
  }


  const runElasticSearchQuery = (pageNumber, rowsPerPage) => {

    if (selectedCategory !== chooseOption || selectedFamily !== chooseOption || selectedChemical !== chooseOption) {
      setESOpen(true)
      setESFilterOpen(true)
    }
    else {
      setESFilterOpen(false)

    }


    let from = 0
    let size = rowsPerPage

    //NOTE: Material UI pagination start from 0
    from = rowsPerPage * pageNumber
    size = from + rowsPerPage

    // console.log('[runElasticSearchQuery] Ingredient =', selectedIngredient);
    // console.log('[runElasticSearchQuery] Category =', selectedCategory);
    // console.log('[runElasticSearchQuery] Family =', selectedFamily);
    // console.log('[runElasticSearchQuery] Chemical =', selectedChemical);

    let must_match = [];
    if (selectedCategory !== chooseOption) {
      must_match.push({
        match: {
          "group.group.category_name": selectedCategory
        }
      });
    }

    if (selectedFamily !== chooseOption) {
      must_match.push({
        match: {
          "group.group.family_name": selectedFamily
        }
      });
    }

    if (selectedChemical !== chooseOption) {
      must_match.push({
        match: {
          "group.group.chemical_name": selectedChemical
        }
      });
    }

    const es_query_body = {
      from: from,
      size: size,
      query: {
        bool: { must: must_match }
      }
    };

    console.log('[runElasticSearchQuery] es_query_body = ', es_query_body);

    fetchElasticSearch(es_query_body);
  }

  // Update ElasticSearch when the following values change
  React.useEffect(() => {
    runElasticSearchQuery(0, 100);
  }, [selectedCategory, selectedFamily, selectedChemical]);


  const deleteIngredient = (deleteId) => {
    let query= `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/ingredients/${deleteId}`;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch(query, {
        method: 'Delete',
        headers: myHeaders,
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299 ) {
          fetchIngredients(1, 100);
          enqueueSnackbar('Ingredient has been deleted.', { variant: 'success' });
        }
      })
    .catch((error) => {
        console.error('Error [deleteIngredient]:', error);
        enqueueSnackbar(t('Ingredient has fail to delete.'), { variant: 'error' });
    });
  }

  const fetchIngredients = (pageNumber, rowsPerPage) => {

    let from = 0
    let size = rowsPerPage

    //NOTE: Material UI pagination start from 0
    from = rowsPerPage * pageNumber
    size = from + rowsPerPage

    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/ingredients?limit=${size}&offset=${from}`)
      .then(res => res.json())
      .then((data) => {
        setDBSubTotalHits(data.total)
        setIngredients(data.data);
      });
  }

  const fetchCategories = () => {
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=2&sort=name`)
      .then(res => res.json())
      .then((data) => {
        // console.log("CATEGORIES data=", data.data);
        setCategories(data.data);
      });
  };

  const fetchFamilies = (data) => {
    const encodedCategory = encodeURIComponent(data);
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=3&group.category_name="${encodedCategory}"&sort=name`)
      .then(res => res.json())
      .then((data) => {
        // console.log("FAMILIES data=", data.data);
        setFamilies(data.data);
      });
  };

  const fetchChemcials = (data, categoryName) => {
    const encodedCategory = encodeURIComponent(categoryName);
    const encodedFamily = encodeURIComponent(data);
    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=4&group.category_name="${encodedCategory}"&group.family_name="${encodedFamily}"&sort=name`)
      .then(res => res.json())
      .then((data) => {
        // console.log("CHEMICALS data=", data.data);
        setChemicals(data.data)
      });
  };

  const resetFamilyDropDown = () => {
    setSelectedFamily(chooseOption);
    setFamilies([]);
  };
  const resetChemicalDropDown = () => {
    setSelectedChemical(chooseOption);
    setChemicals([]);
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
    resetFamilyDropDown();
    resetChemicalDropDown();

    if (event.target.value && event.target.value !== chooseOption)
      fetchFamilies(event.target.value);
  };

  const handleChangeFamily = (event) => {
    setSelectedFamily(event.target.value);
    resetChemicalDropDown();

    if (event.target.value && event.target.value !== chooseOption)
      fetchChemcials(event.target.value, selectedCategory);
  };

  const handleChangeChemical = (event) => {
    setSelectedChemical(event.target.value);
  };

  const handleSelectedIngredient = (ingredientObj) => {

  }


 

  return (
    <Dialog
        open={dialogAddIngredientOpen}
        fullWidth
        maxWidth='lg'
        disableBackdropClick
        onClose={() => handleAddIngredient(false)}

    >
    

      <DialogTitle
        onClose={() => handleAddIngredient(false)}
      >
        Add Ingredients
      </DialogTitle>

      <DialogContent>
            {/* Ingredient */}
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount"> {t("Search Ingredients")}</InputLabel>
                  <OutlinedInput
                    startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                    inputRef={keywordRef3}
                    fullWidth
                    variant="outlined"
                    label={t("Search Ingredients")}
                    margin="dense"
                    onKeyUp={(e) => setTyping3(!typing3)}
                  />
              </FormControl>
            </Grid>

            

            
            {/* Category */}
            {/* <Grid item xs={3}>
              <FormControl variant="filled" className={classes.formControl}>
                <Select
                  id="substance-select-category-filled"
                  value={selectedCategory}
                  margin="dense"
                  variant="outlined"
                  defaultValue={chooseOption}
                  onChange={handleChangeCategory}
                >
                  <MenuItem value={chooseOption} key={chooseOption}>
                    <em>{t("Choose a Category")}</em>
                  </MenuItem>

                  {categories && categories.map((category) => (
                    <MenuItem value={category.name} key={category.name}>
                      <em>{category.name}</em>
                    </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid> */}

            {/* Family */}
            {/* <Grid item xs={3}>
              <FormControl variant="filled" className={classes.formControl}>
                <Select
                  id="substance-select-family-filled"
                  value={selectedFamily}
                  margin="dense"
                  variant="outlined"
                  defaultValue={chooseOption}
                  onChange={handleChangeFamily}
                >
                  <MenuItem value={chooseOption} key={chooseOption}>
                    <em>{selectedCategory && selectedCategory !== chooseOption ? "Choose a Family" : notAvailable}</em>
                  </MenuItem>

                  {families && families.map((family) => (
                      <MenuItem value={family.name} key={family.name}>
                        <em>{family.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}
            
            {/* Chemical */}
            {/* <Grid item xs={3}>
              <FormControl variant="filled" className={classes.formControl}>
                <Select
                  id="substance-select-chemical-filled"
                  value={selectedChemical}
                  margin="dense"
                  variant="outlined"
                  defaultValue={chooseOption}
                  onChange={handleChangeChemical}
                >
                  <MenuItem value={chooseOption} key={chooseOption}>
                    <em>{selectedFamily && selectedFamily !== chooseOption ? "Choose a Chemical" : notAvailable}</em>
                  </MenuItem>

                  {chemicals && chemicals.map((chemical) => (
                      <MenuItem value={chemical.name} key={chemical.name}>
                        <em>{chemical.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}

          
          <Grid item xs={12} className={classes.dividerGrid}>            
            <Divider></Divider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{t('Search results')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <DialogIngSearchResult
              ingredients={ingredients} 
              deleteIngredient={deleteIngredient}
              totalHits={totalHits}
              esOpen={esOpen}
              prepareElasticSearchQuerySEARCH={prepareElasticSearchQuerySEARCH}
              keyword={keyword}
              keywordType={keywordType}
              runElasticSearchQuery={runElasticSearchQuery}
              esFilterOpen={esFilterOpen}
              fetchIngredients={fetchIngredients}
              dbSubTotalHits={dbSubTotalHits}
              handleSelectedIngredientChips={handleSelectedIngredientChips}
              handleAddIngredient={handleAddIngredient}

            />
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAddIngredient(false)} variant='contained' color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAddIngredient;
