/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/InboxOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import BlackTooltip from './BlackTooltip'
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import { AppContext } from '../contexts/AppStore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import { logger } from '../modules/TM/src/common';
import { TmAPI } from '../modules/TM/src/apis';
import { get } from 'dot-prop';
import ws from '../modules/WebSock';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import selectedModules from '../modules/SELECTED_MODULES';
import OpacityIcon from '@material-ui/icons/Opacity';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LiveHelpIcon from '@material-ui/icons/LiveHelpOutlined';
import BubbleChartIcon from '@material-ui/icons/BubbleChartOutlined';


const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('sm')]: {
      padding: 6,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 12,
    },
  },
  listItem: {
    padding: 8,
    borderRadius: 6,
  },
  listItemIcon: {
    marginLeft: 4,
  },
}))

const NavigationsMenu = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation();
  const { loginUser, hasPermission } = useContext(AppContext);
  const [subMenuReportEl, setSubMenuReportEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [refreshUnread, setRefreshUnread] = useState(true);
  const currentPath = window.location.pathname;

  // The callback of refresh navigation menu unread ticket event.
  const refreshUnreadEventCallback = (eventId, notification) => {
    // Fix "Notification Module" doesn't automatic filter user id
    if (!notification.data) return;
    if (notification.data.user_id !== loginUser._id) return;
    setRefreshUnread(true);
  };

  // Attach refresh unread tickets event to websocket (Wait for server-side ordering).
  ws.Attach_callback('refresh_navigation_menu_unread_ticket_event', refreshUnreadEventCallback);

  // This code block will execute whenever refreshUnread changes.
  // NOTE: Default refreshUnread state by true on refresh page one time.
  useEffect(() => {
    // Avoid double fetch if still working.
    if (refreshUnread === false) return;

    // Fetch ticket.unread_id equal to loginUser.
    if (process.env.REACT_APP_BUILD_TARGET === 'RAMA') {
      TmAPI.getRamaUnreadTickets(loginUser._id)
        .then((res) => setUnreadCount(get(res, 'data.data', []).length))
        .catch((err) => logger.error(get(err, 'response.data', '')))
        .finally(() => setRefreshUnread(false));
    } else {
      TmAPI.getUnreadTickets(loginUser._id)
        .then((res) => setUnreadCount(get(res, 'data.data', []).length))
        .catch((err) => logger.error(get(err, 'response.data', '')))
        .finally(() => setRefreshUnread(false));
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUnread])

  // Handle click nested reports event.
  const handleClickSubMenu = (path) => {
    setSubMenuReportEl(null);
    history.push(path);
  }

  return (
    <React.Fragment>
      <List className={classes.list}>

        {/* Dashboard */}
        {hasPermission('dashboard_menu') && 
          selectedModules.dashboard &&
        (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/dashboard"
            selected={currentPath === '/dashboard'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_dashboard')}  placement="right">
                <DashboardOutlinedIcon className={classes.listItemIcon}/>
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_dashboard')} />
          </ListItem>
        )}

        {/* All Tickets */}
        {hasPermission('all_tickets_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/tickets/all"
            selected={
              currentPath === '/tickets/all'
              || currentPath === 'tickets/create'
              || currentPath === 'tickets/update'
            }
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_all_tickets')} placement="right">
                <LocalOfferIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_all_tickets')} />
          </ListItem>
        )}


        {/* Pool Tickets */}
        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/tickets/pool"
            selected={currentPath === '/tickets/pool'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_pool_tickets')} placement="right">
                <LayersOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_pool_tickets')} />
          </ListItem>
        )}

        {/* My Tickets */}
        {hasPermission('my_tickets_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/tickets/me"
            selected={
              currentPath === '/tickets/me'
              || currentPath === '/'
            }
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_my_tickets')} placement="right">
                <Badge badgeContent={unreadCount} color="error">
                  <InboxIcon className={classes.listItemIcon} />
                </Badge>
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_my_tickets')} />
          </ListItem>
        )}

        {/* Team Tickets */}
        {process.env.REACT_APP_BUILD_TARGET == 'STD' && hasPermission('team_tickets_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/tickets/team"
            selected={currentPath === '/tickets/team'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_team_tickets')} placement="right">
                <LayersOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_team_tickets')} />
          </ListItem>
        )}

        {/* Study Tickets */}
        {hasPermission('study_tickets_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/tickets/study"
            selected={currentPath === '/tickets/study'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_study_tickets')} placement="right">
                <DoneAllOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_study_tickets')} />
          </ListItem>
        )}

        {/* Contacts */}
        {!(process.env.REACT_APP_BUILD_TARGET == 'RAMA') && hasPermission('contacts_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/contacts"
            selected={currentPath === '/contacts'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_contacts')} placement="right">
                <GroupOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_contacts')} />
          </ListItem>
        )}

        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && hasPermission('contacts_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/contacts/contactpoint"
            selected={currentPath === '/contacts/contactpoint'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_contacts_contactpoint')} placement="right">
                <HomeWorkOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_contacts_contactpoint')} />
          </ListItem>
        )}

        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && hasPermission('contacts_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/contacts/caller"
            selected={currentPath === '/contacts/caller'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_contacts_caller')} placement="right">
                <GroupOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_contacts_caller')} />
          </ListItem>
        )}

        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && hasPermission('contacts_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/contacts/patient"
            selected={currentPath === '/contacts/patient'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_contacts_patient')} placement="right">
                <HotelOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_contacts_patient')} />
          </ListItem>
        )}

        {/* Knowledge Base */}
        {hasPermission('knowledge_base_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/kb"
            selected={currentPath === '/kb'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_knowledge_base')} placement="right">
                <ChromeReaderModeOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_knowledge_base')} />
          </ListItem>
        )}

        {/* Chat */}
        {selectedModules.chat && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/chat"
            selected={currentPath === '/chat'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_chats')} placement="right">
                <ChatOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_chats')} />
          </ListItem>
        )}

        {/* Chat-Queue for Forviz - Remove this after PRINC Demo */}
        {selectedModules.chat && 
          process.env.REACT_APP_CHAT === 'EXTERNAL' &&
          (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/chat_queue"
            selected={currentPath === '/chat_queue'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_chats')} placement="right">
                <QueueOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_chats')} />
          </ListItem>
        )}

        {/* IVR Prompt */}
        {selectedModules.tts && hasPermission('IVR_menu') &&
          // Remove this after PRINC Demo
          process.env.REACT_APP_CHAT === 'INTERNAL' &&
          // Remove this after PRINC Demo
          (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/text_to_speech"
            selected={currentPath === '/text_to_speech'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_text_to_speech')} placement="right">
                <RecordVoiceOverOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_text_to_speech')} />
          </ListItem>
        )}

        {/* Email */}
        {selectedModules.email && hasPermission('email_menu') && (
          <ListItem
            className={classes.listItem}
            button
            component={Link}
            to="/email"
            selected={currentPath === '/email'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_email')} placement="right">
                <EmailOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_email')} />
          </ListItem>
        )}

        {/* Substances */}
        {selectedModules.substance && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/substance"
            selected={currentPath === '/substance'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_substance')}  placement="right">
                <OpacityIcon className={classes.listItemIcon}/>
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_substance')} />
          </ListItem>
        )}

        {/* Ingredients */}
        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/ingredients"
            selected={currentPath === '/ingredients'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_ingredients')}  placement="right">
                <BubbleChartIcon className={classes.listItemIcon}/>
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_ingredients')} />
          </ListItem>
        )}


        {/* Troubleshoot */}
        {hasPermission('early_preview') && selectedModules.troubleshoot && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/troubleshoot"
            selected={currentPath === '/troubleshoot'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_troubleshoot')}  placement="right">
                <LiveHelpIcon className={classes.listItemIcon}/>
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_troubleshoot')} />
          </ListItem>
        )}

        {/* Reports */}
        {hasPermission('reports_menu') && (
          <ListItem
            button
            className={classes.listItem}
            onClick={(e) => setSubMenuReportEl(e.currentTarget)}
            selected={(
              currentPath === '/ticket-summary-by-category-report'
              || currentPath === '/ticket-summary-stats-report'
              || currentPath === '/ticket-problem-summary-report'
              || currentPath === '/overdue-sla-detail-report'
              || currentPath === '/outgoing-call-details-report'
              || currentPath === '/callback-details-report'
              || currentPath === '/ivr-menu-summary-report'
              || currentPath === '/survey-csq-summary-report'
              || currentPath === '/survey-agent-summary-report'
              || currentPath === '/kb-summary-report'
              || currentPath === '/kb-detail-report'
              || currentPath === '/kb-most-active-report'
              || currentPath === '/kb-keyword-summary-report'
              || currentPath === '/kb-keyword-report'
              || currentPath === '/kb-dashboard-report'
            )}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_reports')} placement="right">
                <BarChartOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_reports')} />
          </ListItem>
        )}

        {/* Settings */}
        {hasPermission('settings_menu') && (
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/config"
            selected={currentPath === '/config'}
          >
            <ListItemIcon>
              <BlackTooltip title={t('navigation_settings')} placement="right">
                <TuneOutlinedIcon className={classes.listItemIcon} />
              </BlackTooltip>
            </ListItemIcon>
            <ListItemText primary={t('navigation_settings')} />
          </ListItem>
        )}

      </List>

      {/* SubMenu Reports */}
      <Menu
        keepMounted
        anchorEl={subMenuReportEl}
        open={Boolean(subMenuReportEl)}
        onClose={() => setSubMenuReportEl(null)}
        getContentAnchorEl={null}
      > 
        {process.env.REACT_APP_BUILD_TARGET == 'STD' && (
          <>
            <MenuItem onClick={() => handleClickSubMenu('/ivr-menu-summary-report-monthly-by-date')}>{t('1.navigation_ivr_menu_summary_report_monthly_by_date')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/csq_summary_report')}>{t('2.navigation_csq_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/ivr-menu-summary-report-by-time-interval')}>{t('3.navigation_ivr_menu_summary_report_by_time_interval')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/csq_summary_report_by_time_interval')}>{t('4.navigation_csq_summary_report_by_time_interval')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/ivr_menu_summary_report_by_monthly_compare')}>{t('5.navigation_ivr_menu_summary_report_by_monthly_compare')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/agent_login-out_duration_and_stage_summary')}>{t('6.navigation_agent_login-out_duration_and_stage_summary')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/ticket-summary-by-category-report')}>{t('7.navigation_ticket_summary_by_category_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/ticket-summary-stats-report')}>{t('8.navigation_ticket_summary_stats_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/ticket-problem-summary-report')}>{t('9.navigation_ticket_problem_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/overdue-sla-detail-report')}>{t('10.navigation_overdue_sla_detail_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/outgoing-call-details-report')}>{t('11.navigation_outgoing_call_details_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/callback-details-report')}>{t('12.navigation_callback_details_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/ivr-menu-summary-report')}>{t('13.navigation_ivr_menu_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/survey-csq-summary-report')}>{t('14.navigation_survey_csq_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/survey-agent-summary-report')}>{t('15.navigation_survey_agent_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/kb-summary-report')}>{t('16.navigation_kb_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/kb-detail-report')}>{t('17.navigation_kb_detail_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/kb-most-active-report')}>{t('18.navigation_kb_most_active_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/kb-keyword-summary-report')}>{t('19.navigation_kb_keyword_summary_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/kb-keyword-report')}>{t('20.navigation_kb_keyword_report')}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/kb-dashboard-report')}>{t('21.navigation_kb_dashboard_report')}</MenuItem>
          </>
        )}
        {process.env.REACT_APP_BUILD_TARGET == 'RAMA' && (
          <>
            <MenuItem onClick={() => handleClickSubMenu('/type_of_inquiry')}> {`01. Number and types of the inquires`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/profession_of_interlocutors')}> {`02. Professions of the interlocutors who called the Ramathibodi Poison Center`.substring(0,30) }</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/regions_and_locations')}> {`03. Regions and locations of the interlocutors who contacted the Ramathibodi Poison Center`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/type_of_information')}> {`04. The types of information requested from the Ramathibodi Poison Center by the interlocutors for managing the poison exposure patients`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/list_of_substances')}> {`05. List of substances involved in human poison exposure classified by groups of categorized substances`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/common_types_of_pesticides')}> {`06. Common types of substance related to human poison exposure as categorized by major chemical groups`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/no_of_substances')}> {`07. Number of substance(s) involved in human poison exposure`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/routes_of_exposure')}> {`08. Routes of exposure of substances involved in human poison exposure`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/circumstances_of_human_poison')}> {`09. Circumstances of human poison exposure`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/no_of_death_cases')}> {`10. Number of death cases in each circumstance of human poison exposure`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/distribution_of_age_range')}> {`11. The distribution of age range and gender of poison exposure Patients`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/circumstances_in_human')}> {`12. Circumstances of human poison exposure in different age range groups`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/number_of_death_cases')}> {`13. Number of death cases in each age range group`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/final_medical_outcome')}> {`14. The final medical outcome among different initial risk groups`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/the_initial_severity_of_patients')}> {`15. The Initial severity of patients exposed to each category of poisons`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/final_severity_of_patient')}> {`16. The final severity of patients exposed to each category of poisons`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/number_of_death_cases_in_each_category')}> {`17. Number of death cases in each category of toxic substance after exposure`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/final_medical_outcome_of_patient')}> {`18. The final medical outcome of patients exposed to the differect poisons`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/summary_of_death')}> {`19. Summary of death case due to poison exposures`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/summary_tickets_group_patient_privilage')}> {`20. Summary tickets group by privilage of Patient`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/summary_tickets_group_working_hours')}> {`21. Summary tickets group by working hours`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/export_tickets_data')}> {`22. Export tickets data to excel-follow up data included`.substring(0,30)}</MenuItem>
            <MenuItem onClick={() => handleClickSubMenu('/export_patients_data')}> {`23. Export patients data to excel-follow up data included`.substring(0,30)}</MenuItem>


          
          </>
        )}
        </Menu>

    </React.Fragment>
  )
}

export default NavigationsMenu
