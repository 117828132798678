import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ title, children }) => (
  <>
    <Helmet>
      <title>
        {title}
      </title>
    </Helmet>
    {children}
  </>
);

PageHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

PageHelmet.defaultProps = {
  children: undefined,
};

export default PageHelmet;
