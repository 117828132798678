import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import dashboardTranslationEN from './locales/en/translation.json';
import dashboardTranslationTH from './locales/th/translation.json';
import tmTranslationEN from '../modules/TM/src/i18next/locales/en/translation.json';
import tmTranslationTH from '../modules/TM/src/i18next/locales/th/translation.json';
import conTranslationEN from '../modules/CONTACT/src/i18next/locales/en/translation.json';
import conTranslationTH from '../modules/CONTACT/src/i18next/locales/th/translation.json';
import kbTranslationEN from '../modules/KB/src/i18next/locales/en/translation.json';
import kbTranslationTH from '../modules/KB/src/i18next/locales/th/translation.json';
import configTranslationEN from '../modules/CONFIG/src/i18next/locales/en/translation.json';
import configTranslationTH from '../modules/CONFIG/src/i18next/locales/th/translation.json';
import reportEN from '../modules/REPORT/src/i18next/locales/en/translation.json';
import reportTH from '../modules/REPORT/src/i18next/locales/th/translation.json';
import textToSpeedEN from '../modules/TEXT_TO_SPEECH/i18next/locales/en/translation.json';
import textToSpeedTH from '../modules/TEXT_TO_SPEECH/i18next/locales/th/translation.json';
import chatTranslationEN from '../modules/CHAT/INTERNAL/i18next/locales/en/translation.json';
import chatTranslationTH from '../modules/CHAT/INTERNAL/i18next/locales/th/translation.json';
import externalChatTranslationEN from '../modules/CHAT/EXTERNAL/i18next/locales/en/translation.json';
import externalChatTranslationTH from '../modules/CHAT/EXTERNAL/i18next/locales/th/translation.json';
import emailTranslationEN from '../modules/EMAIL/i18next/locales/en/translation.json';
import emailTranslationTH from '../modules/EMAIL/i18next/locales/th/translation.json';
import substanceTranslationEN from '../modules/Substance/i18next/locales/en/translation.json';
import substanceTranslationTH from '../modules/Substance/i18next/locales/th/translation.json';
import ingredientTranslationEN from '../modules/INGREDIENT/i18next/locales/en/translation.json';
import ingredientTranslationTH from '../modules/INGREDIENT/i18next/locales/th/translation.json';
import dashboardPageTranslationEN from '../modules/Dashboard/i18next/locales/en/translation.json';
import dashboardPageTranslationTH from '../modules/Dashboard/i18next/locales/th/translation.json';
import troubleshootTranslationEN from '../modules/Troubleshoot/src/i18next/locales/en/translation.json';
import troubleshootTranslationTH from '../modules/Troubleshoot/src/i18next/locales/th/translation.json';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
let combinedTranslationEN = {};
let combinedTranslationTH = {};

// Assigns each modules translation to the combined variables if exists.
if (dashboardTranslationEN) combinedTranslationEN = Object.assign(dashboardTranslationEN, combinedTranslationEN);
if (dashboardTranslationTH) combinedTranslationTH = Object.assign(dashboardTranslationTH, combinedTranslationTH);
if (tmTranslationEN) combinedTranslationEN = Object.assign(tmTranslationEN, combinedTranslationEN);
if (tmTranslationTH) combinedTranslationTH = Object.assign(tmTranslationTH, combinedTranslationTH);
if (conTranslationEN) combinedTranslationEN = Object.assign(conTranslationEN, combinedTranslationEN);
if (conTranslationTH) combinedTranslationTH = Object.assign(conTranslationTH, combinedTranslationTH);
if (kbTranslationEN) combinedTranslationEN = Object.assign(kbTranslationEN, combinedTranslationEN);
if (kbTranslationTH) combinedTranslationTH = Object.assign(kbTranslationTH, combinedTranslationTH);
if (configTranslationEN) combinedTranslationEN = Object.assign(configTranslationEN, combinedTranslationEN);
if (configTranslationTH) combinedTranslationTH = Object.assign(configTranslationTH, combinedTranslationTH);
if (reportEN) combinedTranslationEN = Object.assign(reportEN, combinedTranslationEN);
if (reportTH) combinedTranslationTH = Object.assign(reportTH, combinedTranslationTH);
if (textToSpeedEN) combinedTranslationEN = Object.assign(textToSpeedEN, combinedTranslationEN);
if (textToSpeedTH) combinedTranslationTH = Object.assign(textToSpeedTH, combinedTranslationTH);
if (chatTranslationEN) combinedTranslationEN = Object.assign(chatTranslationEN, combinedTranslationEN);
if (chatTranslationTH) combinedTranslationTH = Object.assign(chatTranslationTH, combinedTranslationTH);
if (externalChatTranslationEN) combinedTranslationEN = Object.assign(chatTranslationEN, combinedTranslationEN);
if (externalChatTranslationTH) combinedTranslationTH = Object.assign(chatTranslationTH, combinedTranslationTH);
if (emailTranslationEN) combinedTranslationEN = Object.assign(emailTranslationEN, combinedTranslationEN);
if (emailTranslationTH) combinedTranslationTH = Object.assign(emailTranslationTH, combinedTranslationTH);
if (substanceTranslationEN) combinedTranslationEN = Object.assign(substanceTranslationEN, combinedTranslationEN);
if (substanceTranslationTH) combinedTranslationTH = Object.assign(substanceTranslationTH, combinedTranslationTH);
if (ingredientTranslationEN) combinedTranslationEN = Object.assign(ingredientTranslationEN, combinedTranslationEN);
if (ingredientTranslationTH) combinedTranslationTH = Object.assign(ingredientTranslationTH, combinedTranslationTH);

if (dashboardPageTranslationEN) combinedTranslationEN = Object.assign(dashboardPageTranslationEN, combinedTranslationEN);
if (dashboardPageTranslationTH) combinedTranslationTH = Object.assign(dashboardPageTranslationTH, combinedTranslationTH);
if (troubleshootTranslationEN) combinedTranslationEN = Object.assign(troubleshootTranslationEN, combinedTranslationEN);
if (troubleshootTranslationTH) combinedTranslationTH = Object.assign(troubleshootTranslationTH, combinedTranslationTH);

const resources = {
  en: {
    translation: combinedTranslationEN,
  },
  th: {
    translation: combinedTranslationTH,
  },
};

i18next
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en',
    resources,
    keySeparator: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
