import url from 'url';
import axios from 'axios';

const GET_CURRENT_SESSION_URL = url.resolve(process.env.REACT_APP_DASH_BACKEND_FQDN, '/dashboard/api/v1/sessions');
const DELETE_CURRENT_SESSION_URL = url.resolve(process.env.REACT_APP_DASH_BACKEND_FQDN, '/dashboard/api/v1/sessions');
const POST_AUTH_LOCAL_URL = url.resolve(process.env.REACT_APP_DASH_BACKEND_FQDN, '/dashboard/api/v1/auth/local');

const postAuthLocal = (username, password, extension) => axios({
  url: POST_AUTH_LOCAL_URL,
  withCredentials: true,
  method: 'POST',
  data: {
    username,
    password,
    extension,
  },
});

const getSession = () => axios({
  url: GET_CURRENT_SESSION_URL,
  withCredentials: true,
  method: 'GET',
});

const deleteSession = () => axios({
  url: DELETE_CURRENT_SESSION_URL,
  withCredentials: true,
  method: 'DELETE',
});

export {
  postAuthLocal,
  getSession,
  deleteSession,
};

