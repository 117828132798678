import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import { Typography,  Accordion,AccordionDetails, AccordionSummary, Box, List, Button, Grid } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        flexBasis: '30%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '20%',
        flexShrink: 0,
    },
    text: {
        width: "500px",
        maxWidth: "500px"
    },
    hide: {
        '& > *': {
            margin: theme.spacing(1),
          },
    },
}))

const Announce = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [announceList, set_announceList] = React.useState([]);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("");

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements`)
        .then(res => res.json())
        .then((data) => {
            console.log(data.data)
            set_announceList(data.data)
        })
    }, [])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };  

    const handleDeleteDialog = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }

    const Delete_announce = (announce_id) => {
        setDeleteOpen(false)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements/${announce_id}` ,{
            method: 'Delete', 
            headers: myHeaders,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status ===204){
                enqueueSnackbar(t('Announcement has been deleted.'), { variant: 'success' })
                fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/announcements`)
                .then(res => res.json())
                .then((data) => {
                    set_announceList(data.data)
                })
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Announcement has fail to delete.'), { variant: 'error' })
        })
    }

    return (
   
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader 
                    title= {t("Config Announcements")}
                    subtitle= {t("Configuration for announcements")}
                    buttonName=  {t("New Announcement")}
                    onClickButton={()=> history.push('/config/announce/create')}
                />
                <Grid container spacing={1}>
                    <Grid item xs ={12}>
                    {announceList && announceList.map((val) => (
                        <Accordion expanded={expanded === 'panel1' + val._id} onChange={handleChange('panel1' + val._id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" className={classes.heading}>{val.name}</Typography>
                                <Typography className={classes.secondaryHeading}> Expires On: {new Date (val.expiresOn).toDateString()} </Typography>
                                <Typography className={classes.secondaryHeading}> Audience: {val.audienceType} </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box display="flex" flexDirection="row" className={classes.hide}>
                                    <List className={classes.text}><Typography >{val.message}</Typography></List>
                                    <List>
                                        <Button 
                                            className={classes.edit_}
                                            variant="contained" 
                                            color="secondary"
                                            onClick={()=> history.push({pathname: '/config/announce/edit', state: {announce_id: val._id}})}
                                        >
                                            <EditOutlinedIcon/>
                                        </Button>
                                    </List>
                                    <List>
                                        <Button
                                            variant="contained" 
                                            color="secondary"
                                            onClick={() =>handleDeleteDialog(val._id)}
                                        >
                                            <DeleteForeverOutlinedIcon/>
                                        </Button>
                                    </List>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    {/* Delete confirmation dialog */}
                    <Dialog
                        open={deleteOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                    >
                        <DialogTitle>{t('Are you sure you want to delete the announcement?')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('This will permanently delete the announcement.')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} color="secondary">
                                {t('Cancel')}
                            </Button>
                            <Button onClick={() => Delete_announce(deleteId)}  color="primary">
                                {t('Delete')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </Grid>
                </Grid>
            </Container>
    )
}
export default Announce;
