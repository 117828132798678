import React from 'react';
import { makeStyles,  createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import {  Grid, Box,  List, Button, Card, Divider, Snackbar, TextField, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PageHeader from '../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import { EditorState, RichUtils, convertToRaw } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createUndoPlugin from 'draft-js-undo-plugin';
import BlockStyleTool, { getBlockStyle } from "./BlockStyleTool";
import createImagePlugin from 'draft-js-image-plugin';
import {useForm, Controller} from "react-hook-form";
import { useHistory } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { blue, orange } from '@material-ui/core/colors';

import SelectCategoryController from './SelectCategoryController';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Editor as NewEditor } from 'react-draft-wysiwyg';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { AppContext } from '../../../../contexts/AppStore';
import AutocompleteCategory from './AutocompleteCategory';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {DropzoneDialog} from 'material-ui-dropzone'
import StdAttachmentList from '../../../TM/src/components/STD/AttachmentList';
import RamaAttachmentList from '../../../TM/src/components/RAMA/AttachmentList';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import * as FileAPI from '../../../TM/src/apis/FileAPI';
import { get, set } from 'lodash';




const useStyles = makeStyles((theme) => ({
  container: {
      marginTop: "1%"
  },
  whole_card: {
      marginLeft: "10%",
      marginRight: "10%",
      height: "100%",
  },
  btn: {
    '& > *': {
        margin: theme.spacing(1),
      },
      marginTop: "2%",
      marginLeft: "40%"
  },
  Un: {
    marginLeft: "0.5%",
  },
  Re: {
    marginLeft: "0.5%"
  },
  parallel: {
    width: "50%",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  form_: {
    width: "100%",
    height: "100%"
  },
  errorMsg : {
    color: "#f44336",
    marginTop: "1%",
    marginLeft: "1%"
  },
  upload: {
    margin: theme.spacing(1)
  }

  // box_ : {
  //   height: "300px",
  //   overflow: "auto"
  // },

}));

// let rows = []
const CreateKB = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, control, errors } = useForm();
  const { register: registerFile, handleSubmit: handleFileSubmit } = useForm();
  const [rows, set_rows] = React.useState([]);

  const [list_files, setlist_files] = React.useState(rows);
  const [editorState, setEditorState] = React.useState();
  const [article_id, setArticle_id] = React.useState();
  const [contentState, setContentState] = React.useState(undefined);
  const {enqueueSnackbar} = useSnackbar();
  const { loginUser } = React.useContext(AppContext);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [attachments, setAttachments] = React.useState([])
  const [isShowDropzone, setIsShowDropzone] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);




  const theme = createTheme({
    palette: {
      primary: blue,
      secondary: orange
    }
  })




  const onSubmit = (data, e) => {
    // console.log("files", attachments)

    let attachmentsKB = []
    attachments.map((attachment) => {
      attachmentsKB.push({
        id : attachment.id,
        file_name : attachment.file_name
      })
    })
    console.log(attachmentsKB)

    var all_data = {}
    all_data.article = data
    all_data.article.owner = loginUser._id
    all_data.article.files = attachmentsKB

    {/* check the content is empty */}
    if (editorState != undefined) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      all_data.content = rawContentState

      const plain_text_state = editorState.getCurrentContent();
      const plain_text = plain_text_state.getPlainText();
      all_data.article.text = plain_text
    }
    else {
      all_data.content = {}
      all_data.article.text = ""
    }
    var raw = JSON.stringify(all_data);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article`,{
      method: 'POST', 
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    })
    .then(response => {
      if (response.status <= 200 && response.status <= 299){history.push('/kb')
          return  enqueueSnackbar(t('Article has been created.'), { variant: 'success' });
      }
      })
    .catch((error) => {
      console.error('1111Error:', error);
      if (error){
        return enqueueSnackbar(t('Article has fail to create.'), { variant: 'error' });
      }
    }) 

  }
 

  const handleOpen = (index) => {
    setOpen(true)
  }

  function handleClose () {
    setOpen(false)
  }

 
  const handleUploadAttachment = (files) => {
    const file1 = get(files, '0', undefined);
    const formData = new FormData();
    formData.append('_file', file1);

    FileAPI.postUploadFile(formData)
      .then(({ data }) => {
        const addedAttachments = [...attachments, data];
        setAttachments(addedAttachments)
        // handleFieldChange('attachments', addedAttachments);
        setIsUploading(false);
        setIsShowDropzone(false);
      })
      .catch((err) => {
        setIsUploading(false);
        setIsShowDropzone(false);
        // logger.error('Unable to upload file to server.');
        // if (err) logger.error(err);
      });
  };

  const handleDeletedAttachment = (fileId) => {
    const removedAttachments = attachments.filter((e) => e.id !== fileId);
    setAttachments(removedAttachments)
    // handleFieldChange('attachments', removedAttachments);
  };
  



    return (
      <Container maxWidth="xl" className={classes.container}>
        <PageHeader
            title={t('Create New Article')}
            subtitle={t('New articles for the KB')}>
        </PageHeader>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Card className={classes.whole_card}>
              <CardContent>
                <form id="form_id" autoComplete="off" className={classes.form_} onSubmit={handleSubmit(onSubmit)} >
                  <List>
                    <TextField 
                      fullWidth 
                      variant="outlined" 
                      name="title" 
                      label={t("Article title *")} 
                      inputRef={register({required: true})}
                    />
                    {errors.title && (<span className={classes.errorMsg}>{t('The title field is required')}</span>)}
                  </List>
                  <List>
                    <TextField 
                      fullWidth 
                      variant="outlined" 
                      name="caption" 
                      label={t("Caption_Description")} 
                      inputRef={register}
                    />
                    </List>
                  <List>
                    <Controller
                      name="category"
                      control={control}
                      onChange={([, data]) => data}
                      render={({ value, onChange }) => {
                        return (
                          <AutocompleteCategory
                            fullWidth
                            size="small"
                            defaultValue={value}
                            fieldLabel={t("Category")}
                            onSelectChange={(e, data) => onChange(data)}
                          />
                        );
                      }}
                    />
                  </List>
                  <Controller
                    name="version"
                    control={control}
                    defaultValue={1} 
                    render={(props) => (
                      <TextField 
                        className={classes.parallel} 
                        InputProps={{readOnly: true}} 
                        type="number"  
                        defaultValue={1} 
                        variant="outlined" 
                        name="version" 
                        label={t("Version")} 
                      />
                    )}
                  />
                  <TextField 
                    className={classes.parallel}   
                    variant="outlined" 
                    name="tags[0]" 
                    label={t("Tags")} 
                    inputRef={register}
                  />
                  <List/>
                  <NewEditor 
                    editorStyle={{minHeight: "400px", maxHeight: "450px", overflow: "auto"}}
                    editorState={editorState}
                    onContentStateChange={setContentState}
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      image: {
                        alignmentEnabled: false,
                        previewImage: true,
                        alt: { present: false }, 
                        uploadCallback: (file) => {
                          return new Promise((resolve, reject) => {
                            var formdata = new FormData();
                            formdata.append("_file", file, file.name);
                      
                            var requestOptions = {
                              method: 'POST',
                              body: formdata,
                              redirect: 'follow'
                            };
                      
                            fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/UploadFile`, requestOptions)
                              .then(response => response.json())
                              .then(fileDoc => {
                                resolve({ 
                                  data: { 
                                    link: `${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/DownloadFile?id=` + fileDoc.id
                                  }
                                })
                              })
                              .catch(error => {
                                if (error) console.error(error);
                                reject(error);
                              });
                          });
                        }, 
                      },
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                      },
                    }}
                  />
                </form>

                <List className={classes.upload}>
                  <DropzoneDialog
                    open={isShowDropzone}
                    Icon={UploadIcon}
                    onDrop={handleUploadAttachment}
                    onClose={() => setIsShowDropzone(false)}
                    acceptedFiles={[]}
                    filesLimit={100}
                    maxFileSize={10485760}
                    showPreviews={false}
                    showAlerts={false}
                    cancelButtonText=""
                    dialogTitle={t('general_upload_file')}
                    dropzoneText={t('Drag and drop a file here.')}
                    submitButtonText={isUploading ? t('general_uploading') : ''}
                    onDropRejected={() => {
                      enqueueSnackbar(t('Your file exceeds the maximum upload size (10MB).'), {
                        variant: 'error',
                      });
                    }}
                  />
                </List>
                
                {/* Rama's AttachmentList */}
                {process.env.REACT_APP_BUILD_TARGET === 'RAMA' && (
                  <RamaAttachmentList
                    attachments={attachments}
                    onUpload={() => setIsShowDropzone(true)}
                    onDeleted={handleDeletedAttachment}
                  />
                )}

                {/* Standard's AttachmentList */}
                {process.env.REACT_APP_BUILD_TARGET !== 'RAMA' && (
                  <StdAttachmentList
                    attachments={attachments}
                    onUpload={() => setIsShowDropzone(true)}
                    onDeleted={handleDeletedAttachment}
                  />
                )}
      
                <Box className={classes.btn} >
                  <Button variant="contained" type="submit" form="form_id" color="primary"> {t('Create')} </Button>
                  <Button variant="contained" color="primary"  onClick={() => history.push('/kb')}> {t('Cancel')} </Button>
                </Box>
                
              </CardContent>
            </Card>
          </Grid>  
        </Grid>
      </Container>

    )
}
export default CreateKB;
