import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography, 
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import 'yup-phone';
import { useSnackbar } from 'notistack';
import { useStyles, SelectionField } from './styles';

const contactTypeOption = ['Patient (คนไข้ )']

const contactTypeOption2 = contactTypeOption.map((val, index) => (
  {key: index, value: val}
))

const typeList = [
  'Human',
  'Animal',
  'Unknown',
]

const subTypeList = [
  'Thai', 'Foreigner',
]

const PatientDialog = (props) => {
  const { open, onCancel } = props;
  const { enqueueSnackbar } =useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const [prefixList, setPrefixList] = useState([ {_id: 1, name: ""}, ]);
  const [gender, setGender] = useState([ {_id: 1, name: "1"}, ]);
  const [existingPid, setExistingPid] = useState([]);
  const [isDuplicatePid, setIsDuplicatePid] = useState(false);
  const [isNotSucceedPid, setIsNotSucceedPid] = useState(false);
  const [isNotFullDigitPid, setIsNotFullDigitPid] = useState(false);

  // Get data for Prefix List
  const getPrefix = async () => {
    return fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
    .then((res) => res.json())
    .then((data) => {
      let prefixList = []
      for(let i = 0; i < data.data.length; i++) {
        prefixList.push({_id: i, name: data.data[i].prefix})
      }
      setPrefixList(prefixList)
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  /* Get gender list */
  const getContactTypeAndGender = () => {
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs`)
    .then((res) => res.json())
    .then((data) => {
      const resJson = data.data;
      const genderData = resJson.filter(n => n.groupName === "Sex");
      console.log('genderData ', genderData);
      setGender(genderData);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }

  /* Phone validation */
  yup.addMethod(yup.string, 'validatePhone', function () {
    return this.test('test-phone', 'Invalid phone number format.', (value) => {
      if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
      return true;
    })
  })

  /* Validation fields */
  const yupSchema = yup.object().shape({
    "phone[1]": yup.array().of(yup.string().validatePhone()),
    "firstName": yup.string().required(),
  });

  const { register, handleSubmit, errors, control } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(yupSchema),
  });

  /* initial trigger to refresh */
  useEffect(() => {
    if (props.triggerToRefresh) props.triggerToRefresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [props.contactId])

  /* initialize open dialog */
  useEffect(() => {
    if (open) {
      getPrefix();
      getContactTypeAndGender();
    }
  } , [open])

  /* Close dialog */
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      setExistingPid([]);
      setIsDuplicatePid(false);
      setIsNotSucceedPid(false);
      setIsNotFullDigitPid(false);
      props.onChangePatientData(props.defaultPatientData);
    }
  };

  /* search existing pid */
  function searchExistingPid(searchPid) {
    const input_query = {
      query: {
        filtered: {
          query: {
            query_string: {
              query: `*${searchPid}*`,
              fields: [
                "CustomData.pid"
              ]
            }
          },
          filter: {
            bool: {
              must: [
                { term: { "isActive": true } },
              ],
            }
          }
        }
      }
    }
    esGetSearchPid(input_query);
  }

  const esGetSearchPid = (input_query) => {
    const query = `${process.env.REACT_APP_ELASTIC_SEARCH_FQDN}/elasticsearch/cream/person/_search?pretty`;

    fetch(query, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(input_query)
    })
      .then(res => res.json())
      .then((data) => {
        let pid_list = data.hits.hits.map((v) => {
          v._source._id = v._id;
          return v._source;
        });
        setExistingPid(pid_list);
        // setIsDuplicatePid(pid_list.length > 0)
      });
  }

  /* check duplicated pid by search existing pid */
  const checkDuplicatedPid = async (event) => {
    const searchPid = await event.target.value;
    searchExistingPid(searchPid)
    props.onChangePatientData({
      ...props.patientData,
      CustomData: { ...props.patientData.CustomData, pid: searchPid }
    })
  }

  /* Save function */
  const onSubmit = (data,e) => {
    const arrangedName = data.lastName ? data.firstName + " " + data.lastName : data.firstName
    const arrangedData = { ...data, isActive: true, name: arrangedName }
    const { pid } = arrangedData.CustomData;
    var raw = JSON.stringify(arrangedData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (existingPid.length > 0) {
      setIsDuplicatePid(true);
      return;
    };

    if (pid.includes(',') || pid.includes('.') || pid.includes(' ')) {
      setIsNotSucceedPid(true);
      return;
    };

    if (pid && pid.length !== 13) {
      setIsNotFullDigitPid(true);
      return;
    };

    if (props.submit === 'create') {
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299){  
          handleCancel();
          props.triggerToRefresh();
          return enqueueSnackbar('Contact has been created.', { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to create.'), { variant: 'error' });
      })
    } else {
      fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${props.contactId}`,{
        method: 'PUT', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {   
          handleCancel();
          props.triggerToRefresh();
          return enqueueSnackbar(t('Contact has been updated.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Contact has fail to update.'), { variant: 'error' });
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Container>
        <DialogTitle className={classes.titleForm} id="form-dialog-title">
          {props.submit === 'create' ? t('create_patient_title') : t('edit_patient_title')}
        </DialogTitle>
        <form className={classes.form_} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            {/* Prefix */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('prefix')}</Typography>
              <Grid>
                {open && props.patientData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {prefixList && prefixList.map((option) => (
                            <MenuItem key={option._id} value={option.name}>{option.name}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="title_prefix"
                      control={control}
                      defaultValue={props.submit === 'create'? '' : props.patientData ? (
                        props.patientData.title_prefix
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* First Name */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_firstName')} *</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="firstName"
                  inputRef={register} 
                  value={props.patientData ? props.patientData.firstName : ''}
                  onChange={(e) => props.onChangePatientData({ ...props.patientData, firstName: e.target.value })}
                />
                {errors.firstName && (<div className={classes.errorMsg}> {t('first_name_field_is_required')} </div>)}
              </Grid>
            </Grid>

            {/* Last Name */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_lastName')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="lastName"
                  inputRef={register}
                  value={props.patientData ? props.patientData.lastName : ''}
                  onChange={(e) => props.onChangePatientData({ ...props.patientData, lastName: e.target.value })}
                />
                {errors.lastName && (<div className={classes.errorMsg}> {t('last_name_field_is_required')} </div>)}
              </Grid>
            </Grid>

            {/* Position */}
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('contact_type')}</Typography>
              <Grid>
                {open && props.patientData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {contactTypeOption2.map((option) => (
                            <MenuItem key={option.key} value={option.value}>{option.value}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.contactType"
                      control={control}
                      defaultValue={props.submit === 'create'? "Patient (คนไข้ )" : props.patientData ? (
                        props.patientData.CustomData ? props.patientData.CustomData["contactType"] : ''
                      ) : ''}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
              
            {/* Gender */}  
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('gender')}</Typography>
              <Grid>
                {open && props.patientData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {gender && gender.map((option) => (
                            <MenuItem key={option._id} value={option.name}>{option.name}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="gender"
                      control={control}
                      defaultValue={props.submit === 'create'? 'Unknown' : props.patientData ? (
                        props.patientData.gender
                      ) : 'Unknown'}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* PID */}  
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('person_pid')}</Typography>
              <Grid>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  name="CustomData[pid]"
                  inputRef={register}
                  type="number"
                  value={props.patientData ? props.patientData.CustomData.pid : ''}
                  onChange={(e) => checkDuplicatedPid(e)}
                />
                {isDuplicatePid && (<div className={classes.errorMsg}> {t('PID could not be duplicated')} </div>)}
                {isNotSucceedPid && (<div className={classes.errorMsg}> {t('PID could not be conbined with comma, dot or space')} </div>)}
                {isNotFullDigitPid && (<div className={classes.errorMsg}> {t('PID must be 13 digits.')} </div>)}
              </Grid>
            </Grid>

            {/* Type */}  
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('patient_type')}</Typography>
              <Grid>
                {open && props.patientData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {typeList && typeList.map((val, index) => (
                            <MenuItem key={index} value={val}>{val}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.type"
                      control={control}
                      defaultValue={props.submit === 'create'? 'Human' : props.patientData ? (
                        props.patientData.CustomData ? props.patientData.CustomData["type"] : ''
                      ) : 'Human'}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Sub Type */}  
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography  className={classes.middle}>{t('patient_subtype')}</Typography>
              <Grid>
                {open && props.patientData && (
                  <FormControl className={classes.parallel}>
                    <Controller
                      fullWidth
                      as={
                        <Select input={<SelectionField />}>
                          {subTypeList && subTypeList.map((val, index) => (
                            <MenuItem key={index} value={val}>{val}</MenuItem>
                          ))}
                        </Select>
                      }
                      name="CustomData.subtype"
                      control={control}
                      defaultValue={props.submit === 'create'? 'Thai' : props.patientData ? (
                        props.patientData.CustomData ? props.patientData.CustomData["subtype"] : ''
                      ) : 'Thai'}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">Save</Button>
            <Button onClick={handleCancel} color="primary">Cancel</Button>
          </DialogActions>
        </form>
        
      </Container>
    </Dialog>
  )
}

export default PatientDialog;