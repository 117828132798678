import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    alignItems: "center",
  },
  input: {
    margin: '5px 10px 3px 3px',
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 5px 3px 3px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    pageNum,
    pageNumLabel,
    pageSize,
    pageAll,
    pageAllLabel,
    firstPage,
    handleFirstPageButtonClick,
    handleBackButtonClick,
    handleNextButtonClick,
    handleLastPageButtonClick,
    handleChangeRowsPerPageQuery
  } = props;
  const numberMenu = [{_id:1, name:5}, {_id:2, name:10}, {_id:3, name:25}]

  return (
    <Grid container className={classes.root} justify="flex-end">
      <Typography>{t(`Rows per page`)}</Typography>
      <Select
        style={{
          margin: '5px 10px 3px 3px',
          borderRadius: 4,
          position: 'relative',
          border: '1px solid #ced4da',
          fontSize: 16,
          padding: '5px 5px 3px 3px',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
        }}
        defaultValue={pageSize}
        onChange={handleChangeRowsPerPageQuery}
      >
        {numberMenu.map((val) => (
          <MenuItem key={val._id} value={val.name}>{val.name}</MenuItem>
        ))}
      </Select>
      <Typography> {t(`Page ${pageNumLabel} of ${pageAllLabel}`)}</Typography>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={pageNum === firstPage}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={pageNum === firstPage}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={pageNum >= pageAll}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={pageNum >= pageAll}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Grid>
  );
}

export default TablePaginationActions