import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { AdminAPI } from '../../../../modules/TM/src/apis';
import { logger } from '../../../../modules/TM/src/common';
import { AppContext } from '../../../../contexts/AppStore';
// import { filter } from 'lodash';

const useStyles = makeStyles(() => ({
  marginNormal: {
    marginTop: '16px',
    marginBottom: '8px',
  },
}));

// eslint-disable-next-line no-unused-vars
const SelectCategoryController = ({
  control, fieldName, fieldLabel, defaultValue, onChangeTop
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = expand && options.length === 0;
  const { loginUser } = React.useContext(AppContext);


  // Similar to componentDidMount and componentDidUpdate.
  useEffect(() => {
    let display = true;

    const onFetchSuccess = (body) => {
      console.log(body)
      if (display === true) {
        // filterCategory(body.data)

        setOptions(filterCategory(body.data));
      }
    };

    const onFetchFailure = (err) => {
      logger.error(err);
    };

    // Avoid double fetch if the 1st fetch in-progress.
    if (loading === false) {
      return undefined;
    }

    // Fetch contacts when expand dropdown & options is empty.
    AdminAPI.getCategories()
      .then((res) => onFetchSuccess(res.data))
      .catch((err) => onFetchFailure(err));

    // Stop the subscription when the component unmounts.
    return () => {
      display = false;
    };

    // Only re-run the effect if loading changes.
  }, [loading]);

  useEffect(() => {
    // Release options & menu components when dropdown closes.
    if (expand === false) {
      setOptions([]);
    }
    // Only re-run the effect if expand changes.
  }, [expand]);

  /*filter category according to team */
  const filterCategory = (Option) => {
    Option = Option.filter((categoryList) => {
      return loginUser.teamObj.categoryIDs.findIndex((categoryID) => categoryID == categoryList._id) >= 0
    }) 
    return Option
  }

  return (
    
    <Controller
      onChange={([, data]) => {
        console.log("options after filter", options)
        return data;
      }}
      defaultValue={defaultValue}
      name={fieldName}
      control={control}
      render={({ onChange, ...props }) => (
        <Autocomplete
          {...props}
          className={classes.marginNormal}
          fullWidth
          size="small"
          open={expand}
          onOpen={() => {
            setExpand(true);
          }}
          onClose={() => {
            setExpand(false);
          }}
          onChange={(event, data) => {
            if (onChange) onChange(data);
            if (onChangeTop) onChangeTop(data);
          }}
          getOptionSelected={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => {
            let indents = '';
            let level = option.level - 1;
            for (let i = 0; i < level; i++) {
              indents += '\u00A0\u00A0\u00A0';
            }
            return `${indents}${option.name}`;
          }}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={fieldLabel}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ marginRight: '-28px' }}
                      />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

SelectCategoryController.propTypes = {
  fieldLabel: PropTypes.string,
  defaultValue: PropTypes.object,
  onChangeTop: PropTypes.func,
  control: PropTypes.object,
  fieldName: PropTypes.string,
};

SelectCategoryController.defaultProps = {
  fieldLabel: undefined,
  onChangeTop: undefined,
  defaultValue: undefined,
  control: undefined,
  fieldName: '',
};

export default SelectCategoryController;
