import React, {useRef} from 'react';
import {
    Grid,
    Typography,
    IconButton,
    Box,
    Table,
    Button,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    TableFooter,
    Divider,
    TextField,
    Slide,
    Tooltip,
    Select,
    FormControl,
    MenuItem
  } from "@material-ui/core";import { makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { RestaurantOutlined } from '@material-ui/icons';
import { within } from '@testing-library/react';
import { OutlinedInput } from '@material-ui/core';




const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    timer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    }
 
}));



const  LoginUsers = (props) => {
    const history = useHistory();
    const classes= useStyles();
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [loginUsersIP, setLoginUsersIP] = React.useState([])
    const [obj, setObj] = React.useState([])
    const [time, setTime] = React.useState(3000)
    const [timeoutBool, setTimeoutBool] = React.useState(false)


    const fetchData = () => {
        let url = `${process.env.REACT_APP_TROUBLESHOOT_BACKEND_FDQN}/tsm/api/v1/login-users`;

        axios.get( url )
            .then(response => {
                if (response.status == 200) {

                    console.log(response.data)
                    const propertyKeys = Object.keys(response.data);
                    const propertyValues = Object.values(response.data);

                    setLoginUsersIP(propertyKeys)
                    setObj(propertyValues)
                    
                }
            })
            .catch(err => {
                enqueueSnackbar(t('Fail to load login users.'), { variant: 'error' });
                // console.log(err.message)
            });
    }

 

    React.useEffect(() => {

        // initial page load
        fetchData()

        const timeout = setTimeout(() => {

            fetchData()
            setTimeoutBool(!timeoutBool)


          }, time);
          return () => clearTimeout(timeout);
        
    }, [timeoutBool]);



    const onChangeTimer = (e) => {

        setTime(parseInt(e.target.value) * 1000)

    }

 

    return (
        <Grid container spacing={1} className={classes.whole}>
            <Container maxWidth="xl" className={classes.container}>
                
                <Grid container>
                    <Grid item xs={5}>
                        <TextField
                            variant="outlined"
                            size="small"
                            disabled={true}
                            label="Search login users"
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={7} className={classes.timer}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel >{t("Update timer")}</InputLabel>
                                    <Select
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        defaultValue="3"
                                        onChange={(e) => onChangeTimer(e)}
                                    >
                                        <MenuItem value="3">{t("3 second")}</MenuItem>
                                        <MenuItem value="5">{t("5 second")}</MenuItem>
                                        <MenuItem value="10">{t("10 second")}</MenuItem>
                                        <MenuItem value="15">{t("15 second")}</MenuItem>
                                        <MenuItem value="20">{t("20 second")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    
                </Grid>


                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Username
                                </TableCell>
                                <TableCell>
                                    Extension
                                </TableCell>
                                <TableCell>
                                    IP 
                                </TableCell>
                                <TableCell>
                                    Login at 
                                </TableCell>
                                <TableCell>
                                    UserID (DB) 
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        
                         
                        <TableBody>
                            {obj.map((val, index) => (
                                <TableRow>
                                    <TableCell>{val.username}</TableCell>
                                    <TableCell>{val.ext}</TableCell>
                                    <TableCell>{loginUsersIP[index] ? loginUsersIP[index].slice(7, loginUsersIP[index].length): ""}</TableCell>
                                    <TableCell>{val.login_time_ms}</TableCell>
                                    <TableCell>{val.user_id}</TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>

               


                </Grid>
   
            </Container>
       
                        
        </Grid>
    )
}

export default LoginUsers;
