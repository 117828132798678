import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {Grid, Card, Box,  Button,  TextField, Typography, Divider, FormControl, MenuItem, Select} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';




const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "50%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "15%",
        maxWidth: "15%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    },
    errorMsg : {
      color: "#f44336"
    },
    star: {
      color: "#f44336"
    }
}))

// const schema = yup.object().shape({
//   email : yup.array().of(
//     yup.object().shape({
//       email: yup.string()
//         .required('Email is required')
//     })
//   )
// })

const Users = (props) => {
    const classes=useStyles();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = React.useState();
    const [teamList, set_teamList] = React.useState([]);
    const [roleList, set_roleList] = React.useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    const prefixName = [
      {_id: 1, prefix: "Mr."}, 
      {_id: 2, prefix: "Miss"}, 
      {_id: 3, prefix: "Mrs."}
    ]

    const [prefixList, set_prefixList] = React.useState(prefixName);

    const shifts = [
      {
        name: "Morning shift (1AM - 9AM)",
        value: "Morning shift (1AM - 9AM)"
      },
      {
        name: "Afternoon shift (9AM - 5PM)",
        value: "Afternoon shift (9AM - 5PM)"
      },
      {
        name: "Night shift (5PM - 1AM)",
        value: "Night shift (5PM - 1AM)"
      }
    ]

    yup.addMethod(yup.string, 'validatePhone', function () {
      return this.test('test-phone', 'Invalid phone number format.', (value) => {
        if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
        return true;
      })
    })

    const yupSchema = yup.object().shape({
      phone: yup.array().of(yup.string().validatePhone()),
        // 'phone[0]': yup.number().required("This field is required"),
      name: yup.string().required(),
      roleId: yup.string().required(),
      team: yup.string().required(),
      username: yup.string().required(),
      password: yup.string().required()
    });

    const { register, handleSubmit, errors, control } = useForm({
      reValidateMode: 'onChange',
      resolver: yupResolver(yupSchema),
    });

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    /*fetching list of prefixes for dropdown*/
    React.useEffect(() => {
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
          .then(res => res.json())
          .then((data) => {
            set_prefixList(data.data)
          })
    } , [])

    React.useEffect(() => { 
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams` )
          .then(res => res.json())
          .then((data) => {
            set_teamList(data.data)
          })
    } , [])

    React.useEffect(() => { 
      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles` )
          .then(res => res.json())
          .then((data) => {
            set_roleList(data.data)
          })
    } , [])

    const onSubmit = (data,e) => { 
        // console.log("check if the role go in", data);
        var raw = JSON.stringify(data);
        console.log("data", raw)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`,{
          method: 'POST', 
          headers: myHeaders,
          body: raw,
        })
        .then(response => {
          // let data = response.text()
          if (response.status >=200 && response.status <=299){history.push('/config')
            return enqueueSnackbar(t('User has been created.'), { variant: 'success' });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar(t('User has fail to create.'), { variant: 'fail' })
        }) 
   
      }


    return (
          <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("Manage Users")}
                subtitle={t("Configuration for users")}
            />
            <Grid item xs={12} className={classes.top}>
              <Card className={classes.card}>
                <form onSubmit={handleSubmit(onSubmit)}  className={classes.form_}>

                  {/* Prefixes */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}> {t('prefix')}</Typography>
                    <FormControl className={classes.parallel}>
                      <Controller
                        fullWidth
                        variant="outlined"
                        as={
                          <Select>
                            {prefixList.map((prefixList) => (
                              <MenuItem key={prefixList._id} value={prefixList.prefix}>{prefixList.prefix}</MenuItem>
                            ))}
                          </Select>
                        } 
                        name="title_prefix"
                        control={control}
                        defaultValue=""
                      />
                      {errors.title_prefix &&  <span className={classes.errorMsg}>{t('Prefix field is required')}</span>}
                    </FormControl>
                  </Box>

                  {/* Agent name */}
                  <Box display="flex" flexDirection="row">
                    <Typography  className={classes.middle}>{t('Agent name')} * </Typography>
                    <div className={classes.parallel}>
                      <TextField 
                        fullWidth
                        inputRef={register({ required: true})} 
                        name="name" 
                        variant="outlined"
                      ></TextField>
                      {errors.name &&  <span className={classes.errorMsg}>{t('Name field is required')}</span>}
                    </div>
                  </Box>

                  {/* Employee code */}
                  <Box display="flex" flexDirection="row">
                    <Typography  className={classes.middle}>{t('Employee code')}  </Typography>
                    <div className={classes.parallel}>
                      <TextField 
                        fullWidth
                        inputRef={register} 
                        name="CustomData.employeeCode"
                        variant="outlined"
                      ></TextField>
                    </div>
                  </Box>

                  {/* Role */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}> {t('Role')} * </Typography>
                    <FormControl className={classes.parallel}>
                      <Controller
                        as={
                          <Select>
                            {roleList.map((roleList) => (
                              <MenuItem value={roleList._id}>{roleList.name}</MenuItem>
                            ))}
                          </Select>
                        } 
                        name="roleId"
                        control={control}
                        defaultValue=""
                      />
                      {errors.roleId &&  <span className={classes.errorMsg}>{t('Role field is required')}</span>}
                    </FormControl>
                  </Box>

                  {/* Team */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Team')} * </Typography>
                    <FormControl className={classes.parallel}>
                        <Controller
                          as={
                            <Select>
                              {teamList.map((teamList) => (
                                <MenuItem value={teamList._id}>{teamList.name}</MenuItem>
                              ))}
                            </Select>
                          } 
                          name="team"
                          control={control}
                          defaultValue=""
                      />
                      {errors.team &&  <span className={classes.errorMsg}>{t('Team field is required')}</span>}
                    </FormControl>
                  </Box>

                  {/* Agent shift */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Agent shift')} </Typography>
                    <FormControl className={classes.parallel}>
                        <Controller
                          as={
                            <Select>
                              {shifts.map((shift) => (
                                <MenuItem value={shift.value}>{shift.name}</MenuItem>
                              ))}
                            </Select>
                          } 
                        name="CustomData.shift"
                        control={control}
                        defaultValue=""
                      />
                    </FormControl>
                  </Box>

                  {/* Mobile Phone */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Mobile phone')}</Typography>
                    <div className={classes.parallel} >
                      <TextField 
                        fullWidth
                        inputRef={register} 
                        name="phone[0]" 
                        variant="outlined"
                      ></TextField>
                      {errors.phone && errors.phone[0] && <span className={classes.errorMsg}>Please enter a valid phone number</span>}
                    </div>
                  </Box>

                  {/* Work phone */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Work phone')}  </Typography>
                    <div className={classes.parallel} >
                      <TextField 
                        fullWidth
                        inputRef={register} 
                        name="phone[1]" 
                        variant="outlined"
                      ></TextField>
                      {errors.phone && errors.phone[1] && <span className={classes.errorMsg}>Please enter a valid phone number</span>}
                    </div>
                  </Box>

                  {/* Home phone */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Home phone')}  </Typography>
                    <div className={classes.parallel} >
                      <TextField 
                        fullWidth
                        inputRef={register} 
                        name="phone[2]" 
                        variant="outlined"
                      ></TextField>
                      {errors.phone && errors.phone[2] && <span className={classes.errorMsg}>Please enter a valid phone number</span>}
                    </div>
                  </Box>

                  {/* Address */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Address')} : </Typography>
                    <TextField 
                      fullWidth
                      className={classes.parallel} 
                      inputRef={register} 
                      name="address[0]" 
                      variant="outlined"
                    ></TextField>
                  </Box>

                  {/* Email */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Email')} : </Typography>
                    <TextField 
                      fullWidth
                      className={classes.parallel} 
                      inputRef={register} 
                      name="email[0]" 
                      variant="outlined"
                      type="email"
                    ></TextField>
                  </Box>

                  {/* Date of Birth */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}>{t('Date of Birth')} : </Typography>
                    <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.parallel}
                        margin="normal"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        inputRef={register}
                        name= "dob"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>

                  {/* Position */}
                  <Box display="flex" flexDirection="row">
                      <Typography className={classes.middle}>{t('Position')}</Typography>
                      <div className={classes.parallel}>
                        <TextField 
                          fullWidth
                          inputRef={register({ required: false })} 
                          name="position" 
                          type="text" 
                          variant="outlined"
                        ></TextField>
                      </div>
                  </Box>
                  {/* <Divider/> */}

                  {/* Username */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}> {t('Username')} * </Typography>
                      <div className={classes.parallel} >
                      <TextField 
                        fullWidth
                        inputRef={register} 
                        name="username" 
                        variant="outlined"
                      ></TextField>
                      {errors.username &&  <span className={classes.errorMsg}>{t('Username field is required')}</span>}
                    </div>
                  </Box>

                  {/* Password */}
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.middle}> {t('Password')} * </Typography>
                    <div className={classes.parallel} >
                      <TextField 
                        fullWidth
                        inputRef={register} 
                        name="password" 
                        type="password" 
                        variant="outlined"
                      ></TextField>
                      {errors.password &&  <span className={classes.errorMsg}>{t('Password field is required')}</span>}
                    </div>
                  </Box>

                  {/* Buttons */}
                  <Box className={classes.btn_}>
                      <Button type="submit"  color="secondary"  variant="contained">{t('Create')}</Button>
                      <Button color="secondary" variant="contained" onClick={() => history.push('/config')}>{t('Cancel')}</Button>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Container>
           
    )
}
export default Users;
