import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { AdminAPI, ContactAPI } from '../modules/TM/src/apis';

// Loads required app resources hook
const useLoadResources = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Side effect of ComponentDidMount
  useEffect(() => {
    window.ticketStatuses = [];
    window.ticketChannels = [];
    window.ticketPriorities = [];
    window.categories = [];
    window.templates = [];
    window.teams = [];
    window.configs = [];
    window.users = [];

    Promise.all([
      AdminAPI.getTicketStatuses(),
      AdminAPI.getTicketChannels(),
      AdminAPI.getTicketPriorities(),
      AdminAPI.getCategories(),
      AdminAPI.getTemplates(),
      AdminAPI.getTeams(),
      AdminAPI.getConfigs(),
      ContactAPI.getAllAgents(),
    ])
      .then((res) => {
        window.ticketStatuses = get(res, '0.data.data', []);
        window.ticketChannels = get(res, '1.data.data', []);
        window.ticketPriorities = get(res, '2.data.data', []);
        window.categories = get(res, '3.data.data', []);
        window.templates = get(res, '4.data.data', []);
        window.teams = get(res, '5.data.data', []);
        window.configs = get(res, '6.data.data', []);
        window.users = get(res, '7.data', []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Unable to get configurations from server.');
        console.error(err);
      });

  }, []);

  // App resources loading status
  return { isLoading };
};

// Serve as default exports
export default useLoadResources;
