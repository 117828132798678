import React from 'react';
import {  Grid, Box,  Button,
    List,  Card, CardHeader, CardContent, TextField, Divider, Avatar, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import {useForm, Controller} from "react-hook-form";
import { makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

const useStyles = makeStyles((theme)=> ({
    btn: {
        marginTop: "5%",
        textAlign: "center",
        '& > *': {
            margin: theme.spacing(1),
          },
    },
    container: {
        marginTop: "1%"
    },
    detailCard: {
        marginTop: "1%",
        marginLeft: "38%",
        textdecoration: "none"
    },
    orange: {
        margin: "auto",
        marginBottom: "3%",
        width: theme.spacing(7),
        height: theme.spacing(7),    
    },
    errorMsg : {
        color: "#f44336",
        marginTop: "1%",
        marginLeft: "1%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "50%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "15%",
        maxWidth: "15%"
    },
    card_:{
        marginLeft: "45%",
      },
 
}))


function Edit (props) {
    const history = useHistory();
    const classes= useStyles();
    const [contactData, set_contactData] = React.useState();
    const {t}= useTranslation();
    const { enqueueSnackbar } =useSnackbar();
    const [isLoading, setLoading] = React.useState(true);
    const article_id = props.location.state.contact_id
    const [selectedDate, setSelectedDate] = React.useState();
    
    const prefixName = [
        {_id: 1, prefix: "Mr."}, 
        {_id: 2, prefix: "Miss"}, 
        {_id: 3, prefix: "Mrs."}
      ]
  
    const [prefixList, set_prefixList] = React.useState(prefixName);

    /*fetching list of prefixes for dropdown*/
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
            .then(res => res.json())
            .then((data) => {
              set_prefixList(data.data)
            })
      } , [])

    /* Phone validation */
    yup.addMethod(yup.string, 'validatePhone', function () {
        return this.test('test-phone', 'Invalid phone number format.', (value) => {
          if (value) return this.phone('th').isValidSync(value) || value.length === 4 || value.length === 5;
          return true;
        })
    })
    const yupSchema = yup.object().shape({
        // phone: yup.array().of(yup.string().validatePhone()),
        name: yup.string().required(),
    });
    const { register, handleSubmit, errors, control } = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(yupSchema),
    });
  
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const onSubmit = (data,e) => { 
        var raw = JSON.stringify(data);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${article_id}`,{
            method: 'PUT', 
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status >= 200 && response.status <= 299){history.push('/contacts');    
               return enqueueSnackbar(t('Contact has been updated.'), { variant: 'success' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Contact has fail to update.'), { variant: 'error' });
        }) 
    }

    const fetchContact = () => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${article_id}`)
            .then(res => res.json())
            .then((data) => {
                if (data){
                    console.log("data", data)
                    set_contactData(data)
                    if(data.dob) {
                        setSelectedDate(new Date(`${data.dob}`))
                    }
                    setLoading(false)
                }else {
                    return console.log("fetch one contact is empty")
                } 
            })
            .catch((error) => {
                console.log(error)
        })
    }

    React.useEffect(() => {
       fetchContact()
    } , [])

    if(isLoading=== true){
        return (<></>)
    }

    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader
                title={t('edit_contact_page_title')}
                subtitle={t('edit_contact_page_subtitle')}
            />
            <Grid item xs={12} className={classes.top}>
                <Card className={classes.card}>
                    <CardHeader className={classes.header} title={t('edit_contact_details_form')}></CardHeader>
                    <Divider/>
                    <CardContent>
                        <form id="create_form"  onSubmit={handleSubmit(onSubmit)} className={classes.form_}>
                            {/* <Avatar alt={contactData.name}  src="/broken-image.jpg"  className={classes.orange}></Avatar> */}
                            {/* Prefixes */}
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('prefix')}</Typography>
                                <FormControl className={classes.parallel}>
                                    <Controller
                                    fullWidth
                                    variant="outlined"
                                    as={
                                        <Select>
                                        {prefixList.map((prefixList) => (
                                            <MenuItem key={prefixList._id} value={prefixList.prefix}>{prefixList.prefix}</MenuItem>
                                        ))}
                                        </Select>
                                    } 
                                    name="title_prefix"
                                    control={control}
                                    defaultValue={contactData.title_prefix}
                                    />
                                    {/* {errors.title_prefix &&  <span className={classes.errorMsg}>{t('Prefix field is required')}</span>} */}
                                </FormControl>
                            </Box>
                            {/* Name */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('person_name')} * </Typography>
                                <div className={classes.parallel}>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    label={t('person_name')} 
                                    inputRef={register} 
                                    name="name" 
                                    defaultValue={contactData.name}  
                                />
                                {errors.name && (<span className={classes.errorMsg}> {t('Name field is required')} </span>)}
                              </div>
                            </Box>

                            {/* Date of Birth */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('Date of Birth')}  </Typography>
                                <div className={classes.parallel}>
                                    <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            margin="normal"
                                            format="MM/dd/yyyy"
                                            value={selectedDate}
                                            inputRef={register}
                                            name= "dob"
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Box>

                            {/* Mobile Phone */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('Mobile phone')}  </Typography>
                                <div className={classes.parallel}>
                                    <Controller
                                        name="phone[0]"
                                        control={control}
                                        defaultValue={contactData.phone[0]}
                                        render={({value, onChange}) => {
                                            return ( 
                                            <TextField 
                                                fullWidth 
                                                variant="outlined" 
                                                label={t('Mobile phone')} 
                                                inputRef={register} 
                                                defaultValue={value}
                                                onChange={(e) => onChange(e.target.value)}
                                            />
                                        )}}
                                    />   
                                    {errors.phone && errors.phone[0] && <span className={classes.errorMsg}>  Please enter a valid phone number </span>}
                                </div>
                            </Box>

                            {/* Work Phone */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('Work phone')}   </Typography>
                                <div className={classes.parallel}>
                                    <Controller
                                        name="phone[1]"
                                        control={control}
                                        defaultValue={contactData.phone[1]}
                                        render={({value, onChange}) => {
                                            return ( 
                                            <TextField 
                                                fullWidth 
                                                variant="outlined" 
                                                label={t('general_work_phone')} 
                                                inputRef={register} 
                                                defaultValue={value}
                                                onChange={(e) => onChange(e.target.value)}
                                            />
                                        )}}
                                    />
                                    {errors.phone && errors.phone[1] && <span className={classes.errorMsg}> Please enter a valid phone number </span>}
                                </div>
                            </Box>

                            {/* Home Phone */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('general_home_phone')}  </Typography>
                                <div className={classes.parallel}>
                                    <Controller
                                        name="phone[2]"
                                        control={control}
                                        defaultValue={contactData.phone[2]}
                                        render={({value, onChange}) => {
                                            return ( 
                                            <TextField 
                                                fullWidth 
                                                variant="outlined" 
                                                label={t('general_home_phone')} 
                                                inputRef={register} 
                                                defaultValue={value}
                                                onChange={(e) => onChange(e.target.value)}
                                            />
                                        )}}
                                    />
                                    {errors.phone && errors.phone[2] && <span className={classes.errorMsg}> Please enter a valid phone number </span>}
                                </div>
                            </Box>

                                    
                            {/* Address */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('person_address')}   </Typography>
                                <div className={classes.parallel}>
                                    <TextField 
                                        fullWidth 
                                        variant="outlined" 
                                        label={t('person_address')} 
                                        inputRef={register} 
                                        name="address[0]" 
                                        defaultValue={contactData.address[0]}  
                                    />
                                </div>
                            </Box>

                            {/* Work Address */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('person_work_address')}  </Typography>
                                <div className={classes.parallel}>
                                    <TextField 
                                        fullWidth  
                                        variant="outlined"
                                        label={t('person_work_address')} 
                                        inputRef={register} 
                                        name="address[1]" 
                                        defaultValue={contactData.address[1]}  
                                    />
                                </div>
                            </Box>

                            {/* Personal Email */}
                            <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}>{t('person_personal_email')}   </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    label={t('person_personal_email')}
                                    inputRef={register} 
                                    name="email[0]" 
                                    defaultValue={contactData.email[0]} 
                                    type="email"
                                />
                            </div>
                            </Box>

                            {/* Work Email */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('person_work_email')}   </Typography>
                                <div className={classes.parallel}>
                                <TextField 
                                        fullWidth 
                                        variant="outlined" 
                                        label={t('person_work_email')} 
                                        inputRef={register} 
                                        name="email[1]" 
                                        defaultValue={contactData.email[1]} 
                                        type="email"
                                    />
                                </div>
                            </Box>

                            {/* Employee ID */}
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('person_employee_code')}   </Typography>
                                <div className={classes.parallel}>
                                <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={t('person_employee_code')}
                                        inputRef={register}
                                        name="CustomData.employeeCode"
                                        defaultValue={contactData.CustomData.employeeCode}
                                    />
                                </div>
                            </Box>
            
                        
                           
                            {/* <List>
                            <TextField 
                                fullWidth 
                                variant="outlined" 
                                label={t('Facebook PlatformId')} 
                                inputRef={register} 
                                name="CustomData.facebookPlatformId"
                                defaultValue={contactData.CustomData.facebookPlatformId}
                            />
                          </List>
                          <List>
                            <TextField 
                                fullWidth 
                                variant="outlined" 
                                label={t('Line PlatformId')} 
                                inputRef={register} 
                                name="CustomData.linePlatformId"
                                defaultValue={contactData.CustomData.linePlatformId}
                            />
                          </List> */}
                            <Box className={classes.btn} >
                                <Button variant="contained" type="submit"  color="primary">{t('Save')} </Button>
                                <Button variant="contained" color="primary"  onClick={() => history.push('/contacts')}> {t('btn_cancel')} </Button>
                            </Box> 
                        </form> 
                    </CardContent>
                </Card>
            </Grid> 
            </Container>


    )
}
export default Edit;
