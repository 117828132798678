import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PageHeader from '../PageHeader'; 
import Container from '@material-ui/core/Container';
import {
    Grid,
    Typography,
    IconButton,
    // Box,
    Table,
    Button,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    TableFooter
} from '@material-ui/core';
// import MaterialTable from 'material-table';
import {useHistory} from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import {DropzoneDialog} from 'material-ui-dropzone';


// const colors = require('colors');
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    card: {
        margin: "1%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "10%",
        maxWidth: "10%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "46%",
        marginBottom: "1%"
    },
    table: {
        minWidth: 250
    },
    top: {
        minHeight: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        marginLeft: "1%",
        marginRight: "1%"
    },
    ty: {
        margin: "3%",
    },
    roleId_: {
        width: "5%",
        maxWidth: "5%"
    },
    upload: {
        margin: theme.spacing(1)
    }
}))

const Users = (props) => {
    const classes=useStyles();
    // const tableRef = useRef(null);
    const history = useHistory();

    const [tbList, set_tbList] = React.useState([]);
    // const tableRef = useRef(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [empty, setEmpty] = React.useState([]);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [roles, setRoles] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("");
    const [refreshData, setRefreshData] = React.useState(false);
    
    // const hiddenUsers = (users) => {
    //     return users.filter(user => {
    //         // if (user.username === process.env.REACT_APP_POOL_USER) return false;
    //         return true;
    //     })
    // }

    const handleDeleteDialog = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const handleDelete = ( delete_id) => {

        setDeleteOpen(false)
        let query= `${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/${delete_id}`
        // var raw = JSON.stringify(delete_id);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        fetch(query ,{
            method: 'Delete', 
            headers: myHeaders,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status ===200){
                enqueueSnackbar('User has been deleted.', { variant: 'success' })
                setRefreshData(!refreshData)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('User has fail to delete.'), { variant: 'error' });
        })
    } 

  
    /* fetching all agents */
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllActiveAgents`)
            .then(res => res.json())
            .then((data) => {
                set_tbList(data)
            })
            .catch((error) => {console.log(error)})
    } , [refreshData])


    /* fetching roles for display */
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/roles`)
            .then(res => res.json())
            .then((data) => {                
                setRoles(data.data)
                setLoading(false)
            })
            .catch((error) => {console.log(error)})
    } , [])

    if (isLoading=== true){
        return (<></>)
    }


    return (
    // <Grid container spacing={1} className={classes.whole}>
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader 
                title= {t("users")}
                subtitle= {t("Managing users")}
                buttonName=  {t("New User")}
                onClickButton={()=> history.push('/config/users/create')}
     
            />
      
            <Grid container spacing={2}>
            <Grid item  xs={12} className={classes.top}>
                <div style={{width: 'auto', overflowX: 'scroll'}} >
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography >{t('Agent Name')}</Typography></TableCell>
                                <TableCell><Typography >{t('Role')}</Typography></TableCell>
                                <TableCell><Typography>{t('Email')}</Typography></TableCell>
                                <TableCell><Typography >{t('Phone')} </Typography></TableCell>
                                <TableCell><Typography >{t('Address')} </Typography></TableCell>
                                <TableCell><Typography >{t('Actions')} </Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tbList && tbList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val) => (
                                <TableRow key={val._id}>
                                    <TableCell>
                                        <Typography>{val.name}</Typography>
                                    </TableCell>
                                    {val.roleId && (
                                        roles.map((role) => {
                                            if (role._id === val.roleId) {
                                               return <TableCell><Typography>{role.name}</Typography></TableCell>
                                            }
                                          
                                        })
                                    )}
                                    {!val.roleId && (
                                        <TableCell></TableCell>
                                    )}
                                    <TableCell>
                                        <Typography>{(val.email) ? val.email[0] : ""} </Typography>
                                        <Typography>{(val.email) ? val.email[1] : ""} </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{(val.email) ? val.phone[0] : ""} </Typography>
                                        <Typography>{(val.email) ? val.phone[1] : ""} </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{val.address}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton  variant="contained"  onClick={()=> history.push({pathname: '/config/users/edit',  
                                            state: {userId: val._id} 
                                        })}>  
                                        <EditOutlinedIcon color="primary"/></IconButton> 
                                        <IconButton onClick={() => handleDeleteDialog(val._id)}><DeleteForeverOutlinedIcon color="primary"  /></IconButton>
                                    </TableCell> 
                                </TableRow>       
                            ))}                
                        </TableBody>
                        <TableFooter>
                            <TableRow>    
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    count={tbList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> 
                            </TableRow>
                        </TableFooter>
                    </Table> 

                    {/* Delete confirmation dialog */}
                    <Dialog
                        open={deleteOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                    >
                        <DialogTitle>{t('Are you sure you want to delete the user?')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('This will permanently delete the user.')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose}  color="secondary">
                                {t('Cancel'.green)}
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} olor="primary">
                                {t('Delete'.red)}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>             
            </Grid>   
        </Grid>         
        </Container>

    )
}
export default Users;
