import React from 'react';
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppStore';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import NotificationIcon from '@material-ui/icons/NotificationsOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationMenuItem from './NotificationMenuItem';
import { printRelativeTime } from '../modules/TM/src/common/helper';
import { logger, helper } from '../modules/TM/src/common';
import { get } from 'dot-prop';

// Please add handle display event_id below here
const HANDLED_DISPLAY_EVENT_IDs = [
  'warning_ticket_before_overdue_event',
  'escalate_ticket_after_overdue_event',
];

// Please add handle display data.sub-event below here
const HANDLED_DISPLAY_SUB_EVENT_IDs = [
  'notify-owner-of-approved-article',
  'notify-owner-of-rejected-article',
  'notify-follower-of-updated-article',
  'notify-follower-of-updated-comment',
  'notify-approver-to-approve-article',
  'notify-everyone-of-updated-article',
  'notify-everyone-of-inbound-email',
];

const { getCookie } = helper;

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
    maxWidth: 380,
    maxHeight: 400,
    outline: 'none',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  list: {
    padding: 0,
    margin: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    {...props}
  />
));

export default function NotificationsMenu({ getNewNotifications, finishedGettingNotifications }) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const { loginUser } = React.useContext(AppContext);
  const { t } = useTranslation();

  const currentLocale = getCookie('locale');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Exclude notification that shouldn't display (including badge number)
  // NOTE: If your notification data no owner please fix on server-side
  const filterNotifications = (notifications) => {
    const filteredNotifications = notifications.filter(notification => {
      const isOwner = notification.owner === loginUser._id;
      const notiStatus = notification.noti_status;
      const eventId = get(notification, 'event_id', null);
      const subEvent = get(notification, 'data.sub-event', null);
      const isMatchEventId = HANDLED_DISPLAY_EVENT_IDs.includes(eventId);
      const isMatchSubEventId = HANDLED_DISPLAY_SUB_EVENT_IDs.includes(subEvent);
      
      // Exclude if the notification owner isn't match
      if (!isOwner) return false;
      // Exclude if the notification status is consumed
      if (notiStatus === 'consumed') return false;
      // Exlcude if the notification event_id & sub-event isn't match 
      if (!isMatchEventId && !isMatchSubEventId) return false;

      // If pass all condition above is included
      // then you should handle display below
      return true;
    })
    return filteredNotifications;
  }
  
  const fetchNotifications = (getNotifications) => {
    if (!getNotifications) return;

    fetch(`${process.env.REACT_APP_NOTIFICATION_BACKEND_FQDN}/api/Notifications/SomeNotification/${loginUser._id}?api-version=2.0`)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return [];
        }
      })
      .then(data => {
        // Filter system and unhandle display notification name out
        const filteredNotifications = filterNotifications(data);
        // Sort newest notifications to above (Temporary fix backend pls)
        filteredNotifications.sort((a, b) => new Date(b.due_date) - new Date(a.due_date))
        // Update the notification list state to re-render list
        setNotifications(filteredNotifications);
      })
      .catch((err) => {
        logger.error('Unable to fetch notifications.')
        logger.error(err)
      })

    // Runs the callback if props exist.
    // TODO: Here may not be really finish.
    if (finishedGettingNotifications) {
      finishedGettingNotifications();
    }
  }

  const consumeOneNotification = (id) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    return fetch(`${process.env.REACT_APP_NOTIFICATION_BACKEND_FQDN}/api/Notifications/${id},${loginUser._id}?api-version=2.0`, {
      method: 'PUT',
      headers: myHeaders
    })
      .then(res => {
        if (res.status !== 200) {
          console.log(`HTTP Code was ${res.status} (${res.statusText})`);
        }
        return res.json();
      })
      .then(status => {
        if (status && status.noti_status === "consumed") {
          setNotifications(notifications.filter(item => item.alarm_id !== id));
        } else {
          console.log("Failed to consume Notification ---> noti_status = " + status.noti_status);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  const fetchOneArticle = (article_id, cb) => {
    console.log(`[NotificationTray] FETCHING Article >>> article_id (${article_id})`);

    fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${article_id}`)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(`[NotificationTray] HTTP Code was ${res.status} (${res.statusText})`);
          throw `Cannot get Article "${article_id}"`;
        }
      })
      .then((article) => {
        cb(article);
      })
      .catch(err => {
        console.log("[NotificationTray] ERROR during FETCH Article", err);
      });
  };

  React.useEffect(() => {
    fetchNotifications(getNewNotifications);
  }, [getNewNotifications]);

  const handleClickNotificationTicket = async (ticketId, alarmId) => {
    handleClose();
    await consumeOneNotification(alarmId);
    history.push(`/tickets/update/${ticketId}`);
  }

  const handleClickNotificationArticle = async (articleId, alarmId) => {
    handleClose();
    await consumeOneNotification(alarmId);
    console.log("article_id for view", articleId)
    history.push('/kb/view', { article_id: articleId });
  }

  const handleClickNotificationArticleApprove = async (articleId, alarmId) => {
    handleClose();
    await consumeOneNotification(alarmId);
    history.push('/kb/approve', { article_id: articleId });
  }

  return (
    <div>
      <Tooltip title={t("Notifications")}>
        <IconButton
          color="inherit"
          onClick={handleClick}
        >
          <Badge badgeContent={notifications.length} color="error">
            <NotificationIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      {(notifications && notifications.length > 0) && (
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
          autoFocus={false}
          disableAutoFocus={true}
        >
          <List className={classes.list}>
            {notifications.map((notification, index) => {

              const alarmId = get(notification, 'alarm_id', null);
              const eventId = get(notification, 'event_id', null);
              const subEvent = get(notification, 'data.sub-event', null);
              const ticketId = get(notification, 'data.ticket_id', null);
              const articleId = get(notification, 'data.article_id', null);
              const dueDateAt = get(notification, 'due_date', null);
              const notifyAt = printRelativeTime(dueDateAt);

              // Handle notification event "warning_ticket_before_overdue_event"
              if (eventId === 'warning_ticket_before_overdue_event') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${notification.data.ticket_code} ${currentLocale === 'th' ? ' ใกล้จะครบกำหนดแล้วคุณช่วยปิดมันได้ไหม?' : ' almost overdue, can you turn it close?'}`}
                    sender={notification.data.sender_name}
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationTicket(ticketId, alarmId)}
                  />
                )
              }

              // Handle notification event "warning_ticket_before_overdue_event"
              if (eventId === 'escalate_ticket_after_overdue_event') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${notification.data.ticket_code} ${currentLocale === 'th' ? ' เกินกำหนดระยะเวลาเป้าหมายโปรดคลิกเพื่อดู' : ' overdue SLA target please click to view it.'}`}
                    sender={notification.data.sender_name}
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationTicket(ticketId, alarmId)}
                  />
                )
              }

              // Handle notification event "em_notify"
              if (subEvent === 'notify-everyone-of-inbound-email') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={currentLocale === 'th' ? 'จดหมายใหม่เพิ่งมาถึงโปรดคลิกเพื่อดู' : 'A new mail just arrived please click to view.'}
                    sender="Email Service"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationTicket(ticketId, alarmId)}
                  />
                )
              }

              // Handle notification event "notify-owner-of-approved-article"
              if (subEvent === 'notify-owner-of-approved-article') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${currentLocale === 'th' ? 'ยินดีด้วยบทความของคุณได้รับการอนุมัติแล้ว' : 'Congratulations, your article has been approved.'}`}
                    sender="Knowledge Base"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationArticle(articleId, alarmId)}
                  />
                )
              }

              // Handle notification event "notify-owner-of-rejected-article"
              if (subEvent === 'notify-owner-of-rejected-article') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${currentLocale === 'th' ? 'บทความของคุณไม่ได้รับการอนุมัติคลิกเพื่อดู' : 'Your article is not approved click to view.'}`}
                    sender="Knowledge Base"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationArticle(articleId, alarmId)}
                  />
                )
              }

              // Handle notification event "notify-follower-of-updated-article"
              if (subEvent === 'notify-follower-of-updated-article') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${currentLocale === 'th' ? 'บทความที่คุณบันทึกไว้มีการแก้ไขคลิกเพื่อดู' : 'Your bookmarked article is edited, please click to view.'}`}
                    sender="Knowledge Base"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationArticle(articleId, alarmId)}
                  />
                )
              }

              // Handle notification event "notify-follower-of-updated-comment"
              if (subEvent === 'notify-follower-of-updated-comment') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${currentLocale === 'th' ? 'มีความคิดเห็นเกี่ยวกับบทความของคุณคลิกเพื่อดู' : 'There is a comment about your article, please click to view.'}`}
                    sender="Knowledge Base"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationArticle(articleId, alarmId)}
                  />
                )
              }

              // Handle notification event "notify-approver-to-approve-article"
              if (subEvent === 'notify-approver-to-approve-article') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${currentLocale === 'th' ? 'มีบทความกำลังรอการอนุมัติโปรดคลิกเพื่อดู' : 'An article is waiting for your approval, please click to view.'}`}
                    sender="Knowledge Base"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationArticleApprove(articleId, alarmId)}
                  />
                )
              }

              // Handle notification event "notify-everyone-of-updated-article"
              if (subEvent === 'notify-everyone-of-updated-article') {
                return (
                  <NotificationMenuItem
                    key={index}
                    title={`${currentLocale === 'th' ? 'มีบทความใหม่เพิ่งเผยแพร่คลิกเพื่อดู' : 'We have released new article, please click to view.'}`}
                    sender="Knowledge Base"
                    notifyAt={notifyAt}
                    onClick={() => handleClickNotificationArticle(articleId, alarmId)}
                  />
                )
              }

              // eslint-disable-next-line no-lone-blocks
              { logger.debug('This notification is unhandled is NotificationsMenu.jsx: ', notification) }

            })}
          </List>
        </StyledMenu>
      )}
    </div>
  );
}
