import React from 'react';
import { Box,  Button, TextField, Avatar } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useForm} from "react-hook-form";
import { AppContext } from '../../../../contexts/AppStore';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider, useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
    add_comment: {
        marginLeft: "1%",
        width: "40%"
    },
    post_comment: {
        marginTop: "1.5%",
        marginBottom: "1.5%",
        marginLeft: "1%"
    },
    post_comments: {
        marginTop: "3%",
        marginBottom: "3%",
        marginLeft: "3%"
    },
    form_comment: {
        width: "100%",
        height: "100%"
    },
    comment_text: {
        margin: "1%"
    }
}))

const Comments = (props) => {
    const classes= useStyles();
    const { register, handleSubmit } = useForm();
    const { register: registerEditComment , handleSubmit: handleEditComment} = useForm();
    const [comment, setComments] = React.useState([]);
    const [count , set_count] = React.useState(1);
    const [count_order, set_count_order] = React.useState(0);
    const [openEdit, set_openEdit] = React.useState(false);
    const { hasPermission, loginUser } = React.useContext(AppContext);
    const { t } = useTranslation();
    const {enqueueSnackbar} = useSnackbar();


    
    React.useEffect( () => {
        const article_id= props.article_id

        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/GetComments?id=${article_id}`)
            .then(res => res.json())
            .then((data) => {
            // console.log("fetching all comments", data)
             setComments(data)
            })
        
      } , []) 


    const onSubmit = (data, e) => {

     const value = {}
     value.userName = loginUser.username
     value.userId = loginUser._id
     value.text = data.text
     const articleId = props.article_id
     var raw = JSON.stringify(value);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
     fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/AddComment?articleId=${articleId}`,{
          method: 'POST', 
          headers: myHeaders,
          body: raw
        })
       .then(response => { 
          if (response.status ===200){
            fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${articleId}` )
            .then(res => res.json())
            .then((data) => {
              setComments(data.article.comments)
              enqueueSnackbar(t('Comment sucesssfully posted.'), { variant: 'success' });
            })
            
          }
          return response.json()
        })
        .catch((error) => {
          console.error('Error:', error)
          enqueueSnackbar(t('Comment fail to submit.'), { variant: 'error' });
          })
        e.target.reset();
    }

    const onSubmitEditComment =(data, e) => {

        set_openEdit(false)
        // console.log(data)
        const value = {}
        value.userName = "John"
        value.userId = loginUser._id
        value.text = data.text
        value.orderNum = Math.floor(data.orderNum)
        const articleId = props.article_id
        var raw = JSON.stringify(value);
        // console.log("final data", raw)
   
       var myHeaders = new Headers();
       myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/EditComment?articleId=${articleId}`,{
             method: 'PUT', 
             headers: myHeaders,
             body: raw
           })
          .then(response => { 
             if (response.status ===200){
                fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${articleId}` )
                .then(res => res.json())
                .then((data) => {
                  setComments(data.article.comments)
                  enqueueSnackbar(t('Comment sucessfully edited.'), { variant: 'success' });
                })
             }
            
           })
           .catch((error) => {
             console.error('Error:', error)
             enqueueSnackbar(t('Comment fail to edit.'), { variant: 'error' });
            })
    } 

    const Delete_comment = (data) => {
        const orderNum = data
        const articleID = props.article_id

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/DeleteComment?articleId=${articleID}&orderNum=${orderNum}`,{
            method: 'DELETE', 
            headers: myHeaders,
          })
         .then(response => { 
            if (response.status ===200){
                fetch(`${process.env.REACT_APP_KB_BACKEND_FQDN}/api/Article/${articleID}` )
                .then(res => res.json())
                .then((data) => {
                  setComments(data.article.comments)
                  enqueueSnackbar(t('Comment successfully deleted.'), { variant: 'success' });

                })
            }
            return response.json()
          })
          .catch((error) => {
              console.error('Error:', error)
              enqueueSnackbar(t('Comment fail to delete.'), { variant: 'error' });

            })      
    }

    const Edit = (data) => {
        set_openEdit(true)
        set_count(count-1)
        set_count_order(data)

    }




    return (
            <div>
                <Box display="flex" flexDirection="row" >
                    <Avatar alt={loginUser.username} src="/broken-image.jpg" className={classes.orange} />
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_comment}>
                    <TextField
                        className={classes.add_comment}
                        label={t("Add new comment")}
                        name="text"
                        inputRef={register}
                        multiline
                        rows={2}
                        variant="outlined"/>
                    <Button type="submit" className={classes.post_comment}  variant="contained" color="primary">{t('Post')}</Button>
                    </form>
                </Box>
                {comment && comment.map((listing, index) => (
                    <React.Fragment key={listing.orderNum}>                                    
                    <Box display="flex" flexDirection="row" margin="1%">
                        <Avatar src="/broken-image.jpg" className={classes.orange} />
                        <div className={classes.comment_text}>
                            {(count%2 !== 0 || index !== count_order)? 
                                listing.text   
                                : 
                                <Box display="flex" flexDirection="row">
                                    <form onSubmit={handleEditComment(onSubmitEditComment)} >
                                        {(openEdit == true ) ?
                                         <div>
                                                <TextField variant="outlined" inputRef={registerEditComment} name="text" defaultValue={listing.text}/> 
                                                <TextField type="hidden" inputRef={registerEditComment}  name="orderNum" defaultValue={listing.orderNum}></TextField>
                                                <TextField type="hidden" inputRef={registerEditComment}  name="index" defaultValue={index}></TextField>
                                                <Button type="submit"  className={classes.post_comments}  variant="contained" color="primary">{t('Update')}</Button>
                                            </div>
                                        :
                                            listing.text
                                        }
                                    </form>
                                </Box>
                            }
                           {(listing.userId == loginUser._id) ?
                            <div>
                                <Button onClick={() => Edit(index)}>{t('Edit')}</Button>
                                <Button onClick={() => Delete_comment(listing.orderNum)}>{t('Delete')}</Button>
                            </div>
                            :
                            <div/>
                           }
                        </div>
                    </Box>
                    </React.Fragment>
                    
                ))}
            </div>

    )
}
export default Comments;