/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { 
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import PageHelmet from "../../components/PageHelmet";
import { yearArray } from '../../components/dateTimeForReports';
import * as yup from "yup";
import moment from "moment";
import queryString from "query-string";

// Set the report url for this component here.
const THE_JASPER_REPORT_URL =
  process.env.REACT_APP_JASPER_REPORT_FQDN +
  '/jasperserver/rest_v2/reports/cream/5_IVR_Menu_Summary_Report';

// Intial material-ui overriding styles.
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  reportHead: {
    fontSize: "1.15rem",
    paddingBottom: "1.15rem",
  },
  formRow: {
    marginTop: "0.50rem !important",
    marginBottom: "0.75rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  fieldLabel: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
      paddingTop: "4px !important",
      paddingBottom: "0px !important",
    },
  },
  footerDivider: {
    marginBottom: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.25rem",
    },
  },
  previewIframe: {
    height: 640,
    width: "100%",
  },
}));

const yearAll = yearArray(2020);

const monthAll = [ 
  { id: 0, month: 'January' },
  { id: 1, month: 'February' },
  { id: 2, month: 'March' },
  { id: 3, month: 'April' },
  { id: 4, month: 'May' },
  { id: 5, month: 'June' },
  { id: 6, month: 'July' },
  { id: 7, month: 'August' },
  { id: 8, month: 'September' },
  { id: 9, month: 'October' },
  { id: 10, month: 'November' },
  { id: 11, month: 'December' },
];

const getMonthId = (monthName) => {
  const foundMonth = monthAll.find(item => item.month === monthName)
  return foundMonth.id
}

const REPORT05_IVRMenuSummaryReportByMonthlyCompare = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [radioValue, setRadioValue] = useState([1, 0, 0])

  /* Selection handle of all information or table only or chart only */
  const radioHandler = (event) => {
    const radioValue = event.target.value
    if(radioValue === 'all') {
      // console.log('select value is all');
      setRadioValue([1, 0, 0])
    } else if(radioValue === 'table') {
      // console.log('select value is table');
      setRadioValue([0, 1, 0])
    } else {
      // console.log('select value is chart');
      setRadioValue([0, 0, 1])
    }
  }

  // React hook form schema.
  const yupSchema = yup.object().shape({
  });

  // React hook form initialize.
  const { handleSubmit, control } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(yupSchema),
  });

  // Concat & return report sub title.
  const getReportSubtitle = (fromDate, toDate, isFile) => {
    if (!fromDate || !toDate) return "";
    const text = isFile ? "D MMM YYYY" : "D MMM YYYY HH:mm";
    return `${moment(fromDate).format(text)} - ${moment(toDate).format(text)}`;
  };

  // Concat & return report file name.
  const getReportFileName = (fromDate, toDate, fileExt) => {
    console.log(fromDate, toDate, fileExt);
    const title = t("ivr_menu_summary_report_monthly_by_date");
    const subtitle = getReportSubtitle(fromDate, toDate, true);
    return `${title} ${subtitle}.${fileExt}`;
  };

  // Handle click preview button event.
  const handleClickPreviewButton = () => {
    const callback = (data) => {
      data.template = "preview";
      data.fileExt = "html";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickExcelButton = () => {
    const callback = (data) => {
      data.template = "excel";
      data.fileExt = "xlsx";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click pdf button event.
  const handleClickPdfButton = () => {
    const callback = (data) => {
      data.template = "pdf";
      data.fileExt = "pdf";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle click excel button event.
  const handleClickWordButton = () => {
    const callback = (data) => {
      data.template = "word";
      data.fileExt = "docx";
      onSubmitForm(data);
    };
    handleSubmit(callback)();
  };

  // Handle form submit event.
  const onSubmitForm = async (formData) => {
    setPreviewUrl(null);
    setLoading(true);

    // Destructure parameters for report from the form.
    const { fromDate, toDate } = formData;

    // Create a filename & subtitle of report.
    const filename = getReportFileName(fromDate, toDate, formData.fileExt);

    // Concat output format to jasper report formaturl.
    let reportUrl = `${THE_JASPER_REPORT_URL}.${formData.fileExt}?`;

    // Shorter form data variables
    const { year_filter, month_filter } = formData

    // Adds the url parameters here (query string).
    // https://momentjs.com/docs/#/get-set/set/

    const selectedMonth = getMonthId(month_filter)
    reportUrl += queryString.stringify({
      stDate: moment()
        .set('year', year_filter)
        .set('month', selectedMonth)
        .startOf('month')
        .toISOString(),
      enDate: moment()
        .set('year', year_filter)
        .set('month', selectedMonth)
        .endOf('month')
        .toISOString(),
      lstDate: moment()
        .set('year', year_filter)
        .set('month', selectedMonth - 1)
        .startOf('month')
        .toISOString(),
      lenDate: moment()
        .set('year', year_filter)
        .set('month', selectedMonth - 1)
        .endOf('month')
        .toISOString(),
      all: radioValue[0],
      table: radioValue[1],
      chart: radioValue[2],
    });

    console.log(reportUrl);

    // Preview report or download report file?
    if (formData.template === "preview") {
      // Set the preview report url state.
      setLoading(false);
      setPreviewUrl(reportUrl);
    } else {
      // Download report file via JasperReport Server.
      setLoading(false);
      const link = document.createElement("a");
      link.href = reportUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <PageHelmet title={t("ivr_menu_summary_report_by_monthly_compare")}>
      <form>
        <Container maxWidth="xl" className={classes.container}>
          {/* Top Paper */}
          <Paper className={classes.paper}>
            {/* Report Name */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.reportHead}>
                  {t("ivr_menu_summary_report_by_monthly_compare")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.formRow}>
              <Grid item xs={12} md={2} className={classes.fieldLabel}>{t('Select Data to display')}</Grid>
              <Grid item xs={10} md={6} className={classes.fieldControl}>
                <FormControl component="fieldset">
                  <RadioGroup row name="row-radio-buttons-group" onChange={radioHandler} defaultValue="all">
                    <FormControlLabel value="all" control={<Radio />} label="Table and chart"/>
                    <FormControlLabel value="table" control={<Radio />} label="Table only"/>
                    <FormControlLabel value="chart" control={<Radio />} label="Chart only"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            {/* 2nd Row */}
            <Grid container spacing={2} className={classes.formRow}>
              <Grid item xs={12} md={2} className={classes.fieldLabel}>{t('general_choose_year')} *</Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="year_filter"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={'2021'}
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={value ? value : 'None'}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      {yearAll.map((year) =>
                        <MenuItem key={year.id} value={year.year}>{year.year}</MenuItem>
                      )
                      }
                    </Select>
                    )
                  }
                />
              </Grid>
              {/* Month Selection */}
              <Grid item xs={12} md={2} className={classes.fieldLabel}>
                {t("general_choose_month")} *
              </Grid>
              <Grid item xs={12} md={3} className={classes.fieldControl}>
                <Controller
                  name="month_filter"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={"January"}
                  render={({ onChange, value, name }) => (
                    <Select
                      className={classes.select}
                      variant="outlined"
                      value={value ? value : "January"}
                      name={name}
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    >
                      {monthAll.map((month) => (
                        <MenuItem key={month.id} value={month.month}>
                          {month.month}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
            {/* /.2nd Row */}
            
            {/* Divider */}
            <div className="mb-4">
              <Divider />
            </div>

            {/* Export Buttons */}
            <Grid container spacing={2}>
              <Grid item xs={12} className="text-right">
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickPreviewButton}
                  disabled={loading}
                >
                  {t("general_preview")}
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickExcelButton}
                  disabled={loading}
                >
                  {t("general_excel")}
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickPdfButton}
                  disabled={loading}
                >
                  {t("general_pdf")}
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClickWordButton}
                  disabled={loading}
                >
                  {t("general_word")}
                </Button>
                {loading && (
                  <Button variant="outlined" className="ml-2 text-center">
                    <CircularProgress size={22} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>

          {/* Bottom Paper */}
          {!loading && previewUrl && (
            <Paper className="mt-4">
              {/* Iframe Previewer */}
              <iframe
                frameBorder="0"
                src={previewUrl}
                className={classes.previewIframe}
              />
            </Paper>
          )}
        </Container>
      </form>
    </PageHelmet>
  );
};

export default REPORT05_IVRMenuSummaryReportByMonthlyCompare;
