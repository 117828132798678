import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import { useHistory } from 'react-router-dom';
// import { useTranslation } from "react-i18next";
// import moment from 'moment';
import NewCasesTable from './newCasesTable';
import FollowUpTable from './followUpTable'

import {
  Card,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  bgCard: {
    padding: "3%",
    // [theme.breakpoints.down("lg")]: { // eslint-disable-line no-useless-computed-key
    //   padding: "0%",
    // }
  },
  dataGrid: {
    flexGrow: 1,
    // margin: "2%",
    padding: "2%",
    // paddingBottom: theme.spacing(2),
  },
  tableGrid1: {
    width: "45%",
    marginTop: "1%",
    marginBottom: "1%",
    marginLeft: "1%",
    marginRight: "0%",
    paddingBottom: theme.spacing(2),
  },
  tableGrid2: {
    width: "45%",
    marginTop: "1%",
    marginBottom: "1%",
    marginRight: "1%",
    marginLeft: "0%",
    paddingBottom: theme.spacing(2),
  },
  // graph: {
  //   padding: "1%",
  //   height: 700,
  //   width: "70%",
  // },
}));

const DashboardPage = (props) => {
  // const { t } = useTranslation();
  const classes = useStyles();
  // const history = useHistory();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Card className={classes.bgCard}>

        <Grid
          className={classes.dataGrid}
          container
          item
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className={classes.tableGrid1}><NewCasesTable/></Grid>
          <Grid className={classes.tableGrid2}><FollowUpTable/></Grid>
          
        </Grid>
        
      </Card>
    </Container>
  );
};

export default DashboardPage;