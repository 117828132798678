/* eslint-disable eqeqeq */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  // Box,
  List,
  // TextField,
  ListItem,
  Button,
  // ListItemAvatar,
  TableFooter,
  TablePagination,
  TableHead,
  TableRow,
  ListItemText,
  // MenuItem,
  Divider,
  IconButton,
  // Card,
  FormControl,
  // Select,
  // InputLabel,
  // CardHeader,
  ListItemSecondaryAction,
  TableCell,
  TableBody,
  Table,
  RadioGroup,
  FormControlLabel,
  // FormLabel,
  Radio,
} from '@material-ui/core';
// import PageHeader from '../../TM/src/components/PageHeader';
// import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
// import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
// import BeenhereIcon from '@material-ui/icons/Beenhere';
// import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
// import { useForm } from 'react-hook-form';
// import { AppContext } from '../../../contexts/AppStore';
import { useTranslation } from 'react-i18next';
import {  useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import SearchIcon from '@material-ui/icons/Search';
// import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import OpacityIcon from '@material-ui/icons/Opacity';
// import axios from 'axios';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';






const useStyles = makeStyles((theme) => ({
  itemText1: {
    width: '20%',
    maxWidth: '20%'
  },
  itemText2: {
    width: '25%',
    maxWidth: '25%'
  },
  itemText3: {
    width: '18%',
    maxWidth: '18%'
  },
  itemEdit: {
    width: '8%',
    maxWidth: '8%'
  },
  itemRadio: {
    width: '8%',
    maxWidth: '8%',
    paddingRight: '1rem',
  },
  boxHeader: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const SearchResult = ({
  substances,
  totalHits,
  onPageChange,
  keyword,
  keywordType,
  runElasticSearchQuery,
  fetchProducts,
  dbSubTotalHits
}) => {
  const { t } = useTranslation();
  // const { hasPermission, loginUser } = React.useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
  // const [deleteId, setDeleteId] = React.useState()
  const [selectedSubstance, setSelectedSubstance] = React.useState()
  const [selectedGroupTypeObj, setSelectedGroupTypeObj] = React.useState()
  // const [selectedObj, setSelectedObj] = React.useState()
  // const [selectedPhysicalTypeName, setSelectedPhysicalTypeName] = React.useState()
  // const [selectedUsageTypeName, setSelectedUsageTypeName] = React.useState()
  // const [contacts, setContacts] = React.useState([]);
  


  // const fetchContacts = () => {
    
  // fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person`)
  // .then((response) => response.json())
  // .then((data) => {
  //   setContacts(data.data)
  // })
  // .catch((error) => console.log(error))


  // }

  // useEffect(() => {
  //   fetchContacts()
  // },[])

  const handleChangePage = (event, newPage) => {
    // Update the pagination component.
    setPage(newPage);
    // Tell the parent current page changed.
    onPageChange(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const fetchCategoryFamilyChemical = (selectedSubstanceObj) => {
    if(selectedSubstanceObj.group) {

      const result = fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/${selectedSubstanceObj.group._id}`)
      .then(res => res.json())
      .then((data) => {
          return data
      })
      return result
    }
  }

  // const fetchGroupType = (groupType, typeId) => {

  //   let url = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/customTMConfigs/?groupName=${groupType}`;
  //   axios( url )
  //   .then(response => {
  //       if (response.status == 200) {
  //           if (groupType == 'Physical Type') {
  //                   const result = response.data.data.filter((val) => val._id == typeId)
  //                   if (result[0]) { 
  //                   setSelectedPhysicalTypeName(result[0].name)
  //                   }
  //           }
  //           else {
  //             const result = response.data.data.filter((val) => val._id == typeId)
  //             if (result[0]) { 
  //               setSelectedUsageTypeName(result[0].name)
  //             }
  //           }
  //       }
  //   })
  //   .catch(err => {
  //       console.log(err.message)
  //   });

  // }

  const handleDialogOpen = async (selectedSubstanceObj) => {
    setDialogOpen(true)
    const result = await fetchCategoryFamilyChemical(selectedSubstanceObj)
    setSelectedGroupTypeObj(result)
    setSelectedSubstance(selectedSubstanceObj)

    // if (selectedSubstanceObj.physical_type) {
    //   fetchGroupType('Physical Type', selectedSubstanceObj.physical_type )

    // }

    // if (selectedSubstanceObj.usage_type) {
    //   fetchGroupType('Usage Type', selectedSubstanceObj.usage_type)
    // }
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  // const handleDeleteDialogOpen = (deleteSubstanceObj) => {
  //   setDeleteDialogOpen(true)
  //   setDeleteId(deleteSubstanceObj._id)

  // }

  
  // const deleteSubstance = () => {

  //   const bodyData = {
  //     isActive: false
  //   }
  //   var raw = JSON.stringify(bodyData);

  //   let query= `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/products/${deleteId}`;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   fetch(query, {
  //       method: 'PUT',
  //       headers: myHeaders,
  //       body: raw,
  //   })
  //     .then(response => {
  //       if (response.status >= 200 && response.status <= 299 ) {
  //         fetchProducts(1, 100);
  //         enqueueSnackbar('Substance has been deleted.', { variant: 'success' });
  //       }
  //     })
  //   .catch((error) => {
  //       console.error('Error [deleteSubstance]:', error);
  //       enqueueSnackbar(t('Substance has fail to delete.'), { variant: 'error' });
  //   });
  // }

  function handleDeleteDialogClose () {
    setDeleteDialogOpen(false)
    // deleteSubstance(deleteId)
  }

  const handleDeleteDialogCancel = () => {
    setDeleteDialogOpen(false)
  }



  let start = 0;
  let end = rowsPerPage;

  // if (esOpen === false) {
  //   start = page * rowsPerPage;
  //   end = start + rowsPerPage;
  // }

  

  const callAPI = (id, activeBool) => {

    const bodyData = { isActive: activeBool }

    var raw = JSON.stringify(bodyData);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch(`${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/products/${id}`, {
      method: 'PUT', 
      headers: myHeaders,
      body: raw,
    })
    .then(response => {
      if (response.status >=200 && response.status <=299) {
        // history.push('/substance');
        return enqueueSnackbar(t(`Active/Inactive has been edited to be ${activeBool}.`), { variant: 'success' });
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      enqueueSnackbar(t('Substance has fail to edit.'), { variant: 'error' });
    }) 

  }

  return (
  <>
    {/* Headers */}
    <List>
      <ListItem>
        <ListItemText className={classes.itemText1}>
          <ul style={{listStyle: "none"}}>
            <li>{t("Substance Name")}</li>
          </ul>
        </ListItemText>
        <ListItemText className={classes.itemText2}>
          <ul style={{listStyle: "none"}}>
            <li>{t("Substance Group")}</li>
          </ul>
        </ListItemText>
        <ListItemText className={classes.itemText1}>
          <ul style={{listStyle: "none"}}>
            <li>{t("Active Ingredient")}</li>
          </ul>
        </ListItemText>
        <ListItemText className={classes.itemText3}>
          <ul style={{listStyle: "none"}}>
            <li>{t("Update Info")}</li>
          </ul>
        </ListItemText>
        <ListItemText className={classes.itemEdit}>
          <ul style={{listStyle: "none"}}>
            <li>{t("Edit")}</li>
          </ul>
        </ListItemText>
        <ListItemText className={classes.itemRadio}>
          <ul style={{listStyle: "none"}}>
            <li>{t("Active/Inactive")}</li>
          </ul>
        </ListItemText>
      </ListItem>
    </List>


    {/* Substances list */}
    <List>
      {/* {console.log(contacts)} */}
      {substances.slice(start, end).map((substance) => {
        return (
        <React.Fragment key={substance._id}>
          <ListItem onClick={() =>  handleDialogOpen(substance)} button alignItems="flex-start">
            {/* <ListItemAvatar>
                <OpacityIcon/>
            </ListItemAvatar> */}
            
            {/* Name */}
            <ListItemText
              className={classes.itemText1}
              primary={
                <ul style={{listStyle: "none"}}>
                  <li>(EN): {substance.name}</li>
                  <li>(TH): {substance.trade_name_th}</li>
                </ul>
              }
            >
            </ListItemText>

            {/* Group */}
            <ListItemText className={classes.itemText2}>
              <ul style={{listStyle: "none"}}>
                <li>Category - {(substance.group && substance.group.group) ? substance.group.group.category_name : "N/A"}</li>
                <li>Family - {(substance.group && substance.group.group) ? substance.group.group.family_name : "N/A" }</li>
                <li>Chemical - {(substance.group && substance.group.group) ? substance.group.group.chemical_name : "N/A" }</li>
                <li>Physical Type - {(substance.physical_type && substance.physical_type.name) ? substance.physical_type.name : "N/A" }</li>
              </ul>
            </ListItemText>

            {/* Ingredients */}
            <ListItemText className={classes.itemText1}>
              <ul style={{listStyle: "none"}}>
                {substance.ingredient && substance.ingredient.map((ing, index) => 
                  <li>[{index+1}] {ing.name}</li>
                )}
              </ul>
            </ListItemText>

            {/* Update info */}
            <ListItemText className={classes.itemText1}>
              <ul style={{listStyle: "none"}}>
                <li>Updated at: {substance.updated_at ? new Date(substance.updated_at).toDateString() : ""}</li>
                <li>Updated by: {substance.updated_by_name ? substance.updated_by_name : ""}</li>

              </ul>
            </ListItemText>
          
            
            {/* Action */}
            <ListItemText>
              <Grid className={classes.itemEdit} style={{paddingTop: '1rem', paddingLeft: '2rem'}}>
                <IconButton
                  color="primary"
                  disabled={(substance.isActive == false) ? true : false }
                  onClick={() => history.push({
                    pathname: '/product/edit',
                    state: { substance: substance }
                  })
                }>
                  <EditIcon/>
                </IconButton>
              </Grid>
            </ListItemText>

            {/* Active or Inactive */}
            <ListItemSecondaryAction>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                  defaultValue={substance.isActive? "active":"inactive"}
                >
                  <FormControlLabel value="active" control={<Radio />} onClick={() => {
                    callAPI(substance._id, true);
                    // substance.isActive = true;
                  }}/>
                  <FormControlLabel value="inactive" control={<Radio />} onClick={() => {
                    callAPI(substance._id, false);
                    // substance.isActive = false;
                  }}/>
                </RadioGroup>
              </FormControl>
            </ListItemSecondaryAction>

          </ListItem>
          <Divider/>
        </React.Fragment>
      )})}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[100]}
          count={totalHits}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />           
      </TableFooter>

    </List>
  
    {/* View Substance Dialog */}
    {selectedSubstance && (
      <Dialog
        fullWidth
        maxWidth='sm'
        disableBackdropClick
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>
          <Typography variant="h6">
              Name (en): {selectedSubstance.name} <br/>
              Name (th): {selectedSubstance.trade_name_th}
          </Typography>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText>
            {/* { console.log("selectedSubstance=", selectedSubstance) } */}
            <List>Category - {selectedGroupTypeObj && selectedGroupTypeObj.group && ( selectedGroupTypeObj.group.category_name)}</List>
            <List>Family - {selectedGroupTypeObj && selectedGroupTypeObj.group && (selectedGroupTypeObj.group.family_name)}</List>
            <List>Chemical - {selectedGroupTypeObj && selectedGroupTypeObj.group && (selectedGroupTypeObj.group.chemical_name)}</List>
            <List>Physical type - {selectedGroupTypeObj && selectedSubstance.physical_type && (selectedSubstance.physical_type.name)}</List>
            <List>Usage type - {selectedGroupTypeObj && selectedSubstance.usage_type && (selectedSubstance.usage_type.name)}</List>
            <List>          
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ingredient Name</TableCell>
                    <TableCell>Advice Info</TableCell>
                  </TableRow>
                </TableHead> 
                  {selectedSubstance.ingredient && selectedSubstance.ingredient.map((val) => (
                    <TableBody>
                      <TableRow>
                        <TableCell>{val.name}</TableCell>
                        <TableCell>{val.advice_info}</TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </List>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant='contained' color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>

    )}



    {/* Delete Substance Dialog */}
    <Dialog
      open={deleteDialogOpen}
      // keepMounted
      onClose={handleDeleteDialogCancel}
    >
      <DialogTitle>{t('Are you sure you want to delete the substance?')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('This will permanently delete the substance.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteDialogCancel}  color="secondary">
          {t('Cancel')}
        </Button>
        <Button onClick={handleDeleteDialogClose} color="primary">
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>

  </>
)}
  
export default SearchResult;
  