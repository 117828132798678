import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as TmAPI from '../apis/TmAPI';
import * as ReportAPI from '../../../REPORT/src/apis/ReportAPI';
import * as JsReportAPI from '../../../REPORT/src/apis/JsReportAPI';
import { logger } from '../common';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'fixed',
    top: '45.0%',
    left: '47.5%',
  },
}));

const IncidentReportPage = () => {
  const classes = useStyles();
  const { paramId } = useParams();
  const [loading, setLoading] = useState(true);

  // This will get execute on initial render.
  useEffect(() => {
    setLoading(true);

    // Alway loading if open withoit ticket ID.
    if (!paramId && paramId !== 'undefined') return;

    // Fetch latest ticket info by ticket ID.
    // Fetch latest activities by ticket ID.
    Promise.all([
      ReportAPI.getTicketIncidentReportTemplate('pdf'),
      TmAPI.getTicket(paramId),
      TmAPI.getActivities(`ticket_id='${paramId}'&is_public=true&sort=created_at`),
    ])
      .then((arrOfRes) => {
        const template = arrOfRes[0].data;
        const ticket = arrOfRes[1].data;
        const activities = arrOfRes[2].data.data;

        // Download JsReport via JsReport Web API.
        JsReportAPI.downloadReport({
          template: {
            content: template,
            recipe: 'chrome-pdf',
            engine: 'handlebars',
          },
          data: {
            ticket,
            activities,
          },
        })
          .then((res) => {
            // To hide component loader.
            setLoading(false);
            // Create a hyperlink tag.
            const link = document.createElement('a');
            // Create a pdf blob from stream.
            const file = new Blob([res.data], { type: 'application/pdf' });
            // Set the href attribute by file URL.
            link.href = URL.createObjectURL(file);
            // Append to the DOM and perform click.
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            logger.error('Unable to download report from JsReport API.');
            logger.error(err);
          });

      })
      .catch((err) => {
        logger.error('Unable to get report data from server.');
        logger.error(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render component loader.
  if (loading) {
    return (
      <CircularProgress
        className={classes.loader}
        color="secondary"
      />
    );
  }

  // Render incident report.
  return (
    <div />
  );
};

export default IncidentReportPage;
