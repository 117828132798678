import React from 'react';
import {Grid, Card, Snackbar, TextField, CardContent, Box, Button,Typography} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {appendErrors, useForm} from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert';
import InputColor from 'react-input-color';
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';


const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    card: {
        margin: "1%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%",
        marginRight: "18%"
    },
    btn_: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginLeft: "40%",
        marginBottom: "1%"
    }

}))


const Edit = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const { t } = useTranslation();
    // const [count, setCount] = useState(0)
    const [color, setColor] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [tbList, set_tbList] = React.useState([]);
    const { enqueueSnackbar } =useSnackbar();

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-priorities`)
            .then(res => res.json())
            .then((data) => {
              set_tbList(data.data) 
            })
    } , [])

    const onSubmit= (data,e) => {
        // console.log(data)
        var raw = JSON.stringify(data);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-priorities/${props.location.state.val._id}`,{
            method: 'PUT', 
            headers: myHeaders,
            body: raw,
        })
        .then(response => {
            // let data = response.text()
            if (response.status >=200 && response.status <=299){history.push('/config');    
            return enqueueSnackbar(t('Priority has been updated.'), { variant: 'success' });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Priority has fail to update.'), { variant: 'error' })
        }) 
    }

    return (
        <Grid container spacing={1} >
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title=  {t("Edit Priority")}
                    subtitle=  {t("Edit Priority")}
                />
            </Container>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form_}>                           
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>  {t('Priority')} * </Typography>
                                <TextField 
                                    fullWidth 
                                    className={classes.parallel} 
                                    inputRef={register({ required: true})} 
                                    name="name" 
                                    defaultValue={props.location.state.val.name} 
                                    variant="outlined"
                                    error={errors.name}
                                    helperText={errors.name && t("Name field is required")}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Typography className={classes.middle}> {t('Color')}  </Typography>                              
                                <div className="Color">    
                                    <InputColor 
                                        initialValue={props.location.state.val.color}
                                        color={color}
                                        //onChange={setColor}
                                        onChange={e => setColor(e.hex)} 
                                        //onChange={e => setColor(e.target.value)}
                                        placement="right"      
                                    />
                                    <TextField className={classes.parallel} inputRef={register({ required: true})} value={color} name="color" variant="outlined" visibled="true"></TextField>
                                </div>                       
                            </Box> 
                            <Box className={classes.btn_}>
                                <Button type="submit"  color="primary"  variant="contained"> {t('Save')}</Button>
                                <Button color="primary" variant="contained" onClick={() => history.push('/config')}> {t('Cancel')}</Button>
                            </Box>
                        </form>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">
                                {t('The priority had been successfully updated!')}
                            </Alert>
                        </Snackbar>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
export default Edit;