import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { ReportAPI } from '../apis';
import { logger } from '../common';


const useStyles = makeStyles(() => ({
  loading: {
    marginRight: '-28px',
  },
}));

const AutocompleteIvrTeam = ({ margin, fieldLabel, onSelectChange, defaultValue, ...restProps }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(defaultValue);
  const loading = expand && options.length === 0;

  // Similar to componentDidMount and componentDidUpdate.
  useEffect(() => {
    let display = true;

    // Avoid double fetch if the 1st fetch in-progress.
    if (loading === false) {
      return undefined;
    }

    // Fetch data when expand dropdown & options is empty.
    ReportAPI.getUniqueArrayOfTeamNameFromIVR()
      .then((res) => {
        if (display) setOptions(res.data);
      })
      .catch((err) => {
        logger.error('Unable to get unique teams from server.');
        if (err) logger.error(err);
      });

    // Stop the subscription when the component unmounts.
    return () => {
      display = false;
    };

    // Only re-run the effect if loading changes.
  }, [loading]);

  useEffect(() => {
    // Release options & menu components when dropdown closes.
    if (expand === false) {
      setOptions([]);
    }
    // Only re-run the effect if expand changes.
  }, [expand]);

  // How to display each option (include selected)?
  const getOptionLabel = (option) => {
    return option;
  };

  // How to identify selected option?
  const getOptionSelected = (option, value) => {
    return option === value;
  };

  // Handle select, deselect events.
  const handleSelectChange = (e, data) => {
    setSelected(data);
    if (onSelectChange) {
      onSelectChange(e, data);
    }
  };

  // The right loading component.
  const rightAdornment = (InputProps) => (
    <React.Fragment>
      {loading && (
        <CircularProgress
          size={20}
          color="inherit"
          className={classes.loading}
        />
      )}
      {InputProps.endAdornment}
    </React.Fragment>
  );

  return (
    <Autocomplete
      {...restProps}
      loading={loading}
      options={options}
      open={expand}
      onOpen={() => setExpand(true)}
      onClose={() => setExpand(false)}
      onChange={handleSelectChange}
      value={selected}
      defaultValue={defaultValue}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      className={classes.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          margin={margin}
          label={fieldLabel}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: rightAdornment(params.InputProps),
          }}
        />
      )}
    />
  );
};

AutocompleteIvrTeam.propTypes = {
  margin: PropTypes.string,
  fieldLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  onSelectChange: PropTypes.func,
};

AutocompleteIvrTeam.defaultProps = {
  margin: 'none',
  fieldLabel: '',
  defaultValue: null,
  onSelectChange: undefined,
};

export default AutocompleteIvrTeam;
