import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select } from '@material-ui/core';
import { logger } from '../../TM/src/common';

const FETCH_CHEMICAL_URL = `${process.env.REACT_APP_CUSTOM_RAMA_BACKEND_FQDN}/custom-rama/api/v1/productGroups/?level=4&sort=name`

const SelectChemical = ({ 
  value, 
  onChange, 
  cascadeCategory, 
  cascadeFamily, 
  ...restProps 
}) => {
  const [chemicals, setChemicals] = useState([]);

  // Handle select change event.
  const handleChange = (event) => {
    onChange(event.target.value);
  }

  // Side effect of DidMount and cascadeCategory.
  useEffect(() => {

    // Not allow select chemical without cascadeCategory & cascadeFamily.
    if (!cascadeCategory || !cascadeFamily) {
      setChemicals([]);
      return;
    }

    // Safety build reuqest URL with parameters.
    let requestUrl = FETCH_CHEMICAL_URL;
    requestUrl += cascadeCategory ? `&group.category_name="${encodeURIComponent(cascadeCategory)}"` : '';
    requestUrl += cascadeFamily ? `&group.family_name="${encodeURIComponent(cascadeFamily)}"` : '';
    
    // Create a HTTP request to get families data.
    fetch(requestUrl)
      .then(res => res.json())
      .then((data) => setChemicals(data.data))
      .catch(error => logger.error(error)); 

  }, [cascadeCategory, cascadeFamily]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      {...restProps}
    >
      <MenuItem value="" key="chemical-option-default">
        <em>Choose a Chemical</em>
      </MenuItem>

      {chemicals.map((chemical, index) => (
        <MenuItem value={chemical.name} key={`chemical-option-${index}`}>
          <em>{chemical.name}</em>
        </MenuItem>
      ))}
    </Select>
  );
};

SelectChemical.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  cascadeCategory: PropTypes.string,
  cascadeFamily: PropTypes.string,
};

SelectChemical.defaultProps = {
  value: '',
  onChange: () => {},
  cascadeCategory: '',
  cascadeFamily: '',
};

export default SelectChemical;