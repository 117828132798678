import React from "react";
import {
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CallMadeIcon from "@material-ui/icons/CallMade";

export default function Calling({
  call_ext,
  call_phone,
  call_csq,
  call_dateTimeStart,
  toSetTrigger,
}) {
  const { t } = useTranslation();

  const callData = (ext, phone, csq, callStartTime) => {
    return JSON.stringify({
      ext: ext,
      phone: phone,
      csq: csq,
      callStartTimee: callStartTime,
    });
  };
  const fetchCalling = async (ext, phone, csq, callStartTime) => {
    var myHeaders = await new Headers();
    await myHeaders.append("Content-Type", "application/json");
    await fetch(`${process.env.REACT_APP_TROUBLESHOOT_BACKEND_FDQN}/uccx/screenpop`, {
      method: "POST",
      headers: myHeaders,
      body: callData(ext, phone, csq, callStartTime),
    })
      .then(() => console.log("Calling is successful."))
      .catch((error) => console.log(error));
    await toSetTrigger();
  };

  return (
    <>
      <Tooltip title={t("call")}>
        <IconButton
          variant="contained"
          onClick={() =>
            fetchCalling(call_ext, call_phone, call_csq, call_dateTimeStart)
          }
        >
          <CallMadeIcon color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
}
