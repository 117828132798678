import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../../../TM/src/components/PageHeader";
import Container from "@material-ui/core/Container";
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TablePagination,
  List,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1.5%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  top: {
    margin: "1%",
  },
  text: {
    width: "500px",
    maxWidth: "500px",
  },
  edit_: {
    textAlign: "right",
  },
  hide: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  edit_: {
    marginLeft: "50%",
  },
}));

const Users = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [tbList, set_tbList] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  //   const [deleteOpen, setDeleteOpen] = React.useState(false);
  //   const [deleteId, setDeleteId] = React.useState("");

  const defaultData = [
    {
      is_active: true,
      _id: 1,
      desc: "",
      name: "What part of the car is the laziest?",
      type: "",
      text: "The wheels, because they are always tired!",
    },
    {
      is_active: true,
      _id: 2,
      desc: "",
      name: "What kind of car does Mickey Mouse's wife drive?",
      type: "",
      text: "A minnie van!",
    },
  ];

  //   const handleDeleteDialog = (deleteId) => {
  //     setDeleteId(deleteId);
  //     setDeleteOpen(true);
  //   };

  //   const handleDeleteDialogClose = () => {
  //     setDeleteOpen(false);
  //   };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    // fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/templates`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     set_tbList(data.data);
    //     console.log(data.data);
    //   });
    set_tbList(defaultData);
  }, []);

  //   const DeleteDialog = (data) => {
  //     setDeleteOpen(false);
  //     var raw = JSON.stringify(data);
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");

  //     fetch(
  //       `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/templates/${data}`,
  //       {
  //         method: "DELETE",
  //         headers: myHeaders,
  //       }
  //     )
  //       .then((response) => {
  //         if (response.status >= 200 && response.status <= 299) {
  //           fetch(
  //             `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/templates`
  //           )
  //             .then((res) => res.json())
  //             .then((data) => {
  //               console.log("TB_list");
  //               console.log(data);
  //               set_tbList(data.data);
  //             });
  //           enqueueSnackbar(t("Template has been deleted."), {
  //             variant: "success",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         enqueueSnackbar(t("Template has fail to delete."), {
  //           variant: "error",
  //         });
  //       });
  //   };

  return (
    <div>
      <Container maxWidth="xl" className={classes.container}>
        <PageHeader
          title={t("faq")}
          subtitle={t("faq_center")}
          // buttonName=  {t("New Template")}
          // onClickButton={()=> history.push({pathname: '/config/template/create'})}
        />
      </Container>
      <div>
        {tbList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((val) => (
            <Accordion
              expanded={expanded === "panel1" + val._id}
              onChange={handleChange("panel1" + val._id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" className={classes.heading}>
                  {val.name}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {val.desc}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.hide}
                >
                  <List className={classes.text}>
                    <Typography>{val.text}</Typography>
                  </List>
                  {/* <List>
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: "/config/template/edit",
                          state: { val: val },
                        })
                      }
                      variant="contained"
                      className={classes.edit_}
                      color="secondary"
                    >
                      <EditOutlinedIcon />
                    </Button>
                  </List>
                  <List>
                    <Button
                      onClick={() => handleDeleteDialog(val._id)}
                      variant="contained"
                      className={classes.edit_}
                      color="secondary"
                    >
                      <DeleteForeverOutlinedIcon />
                    </Button>
                  </List> */}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tbList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {/* Delete confirmation dialog */}
        {/* <Dialog
          open={deleteOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDeleteDialogClose}
        >
          <DialogTitle>
            {t("Are you sure you want to delete the template?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("This will permanently delete the template.")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="secondary">
              {t("Cancel")}
            </Button>
            <Button onClick={() => DeleteDialog(deleteId)} color="primary">
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    </div>
  );
};
export default Users;
