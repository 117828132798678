import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { List, TextField, Input, CardActions, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {useForm, Controller} from "react-hook-form";
import { useSnackbar } from 'notistack';
import ScrollBar from 'react-perfect-scrollbar'
import {DropzoneDialog} from 'material-ui-dropzone'
import { FilterCenterFocusSharp } from '@material-ui/icons';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ForwardIcon from '@material-ui/icons/Forward';








const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    card: {
        border: "none", 
        boxShadow: "none", 
    },
    cardheader1: {
        paddingBottom: 0,
        position: 'relative'
    },
    cardheader2: {
        paddingBottom: '10px', 
        paddingTop: '10px',
        position: 'relative'

    },
    subheaderList: {
        listStyle: 'none',
        padding: 0,
        margin: 0
    },
    // cardContent: {
    //     paddingBottom: '5px',
    //     overflowY: "scroll",
    // }
    card2: {
        margin: '30px',
    },
    name: {
        width: '50px',
        maxWidth: '50px',
        padding: '0px'
    },
    errorMsg : {
        color: "#f44336",
        marginLeft: '1%'
    },
    btnSend: {
        marginRight: '3%'
    },
    btnAttach: {
        marginLeft: '5px'
    },
    box1: {
        width: '40%'
    },
    box2: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '70%'
    },
    attachments: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '50%'
    }

}))

const Right = ({className, selectedEmail, handleDiscard, handleReply }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { register, handleSubmit, errors, control } = useForm();
    const [to, setTo] = React.useState()
    const { enqueueSnackbar } =useSnackbar();
    const axios = require('axios')
    const [replyTo, setReplyTo] = React.useState()
    const [prepareAttachment, setPrepareAttachment] = React.useState([])
    const [attachments, setAttachments] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [editorState, setEditorState] = React.useState();
    const [contentState, setContentState] = React.useState(undefined);





    const handleAttachOpen = (index) => {
        setOpen(true)
    }
    
    function handleAttachClose () {
        setOpen(false)
    }

    // to display the forwarded text_body --> check  if selectedEmail.text_body is string or html tag
    // useEffect(() => {
        
    //         if (selectedEmail.text_body[0] == "<") {

    //             // const blocksFromHTML = convertFromHTML("<p>---- Forwarded Message ----</p>" + selectedEmail.text_body);
                
    //             const blocksFromHTML = convertFromHTML(selectedEmail.text_body);
    //             const htmlState = ContentState.createFromBlockArray(
    //                 blocksFromHTML.contentBlocks,
    //                 blocksFromHTML.entityMap,
    //             );                
                
    //             const state =  EditorState.createWithContent(htmlState)
    //             setEditorState(state)
    //         }
    //         else {
    //             // const content = ContentState.createFromText(`\n\n\n ---- Forwarded message ---- \n ${selectedEmail.text_body}`)
                
    //             const content = ContentState.createFromText(selectedEmail.text_body)
    //             const state =  EditorState.createWithContent(content)
    //             setEditorState(state)
    //         }
    // }, [selectedEmail])


    
    // extract email account only from (for e.g) -->  ""Kabi Shrestha" <kabi.shrestha10@gmail.com>"
    useEffect(() => {
        let extractReplyToEmail = [];

        for (let i= 0; i < selectedEmail.from.length; i++ ) {
            if ( selectedEmail.from[i] == '<') {
                
                while (selectedEmail.from[i] != '>') {
                    extractReplyToEmail.push(selectedEmail.from[i+1])
                    setReplyTo(extractReplyToEmail.join(''))
                    i++;
                }

            }
        } 
    },[selectedEmail])

    const onSubmitFile = (data) => {
        const formData= new FormData()
        formData.append("_file", data[0])

      
        fetch(`${process.env.REACT_APP_FILE_BACKEND_FQDN}/api/UploadFile`,{
                method: 'POST', 
                body: formData
            })
            .then(response => { 
                if (response.status ===200){
                enqueueSnackbar(t('File successfully uploaded.'), { variant: 'success' });      
                }
                return response.json()
            })
            .then(json_data => { 
                prepareAttachment.push({
                    id: json_data._id, 
                    file_name: json_data.file_name
                })
                setAttachments(prepareAttachment)
                setOpen(false)

            })
            .catch((error) => {
                console.error('Error:', error)
                enqueueSnackbar(t('File fail to upload.'), { variant: 'error' });        
            })

    }
    

    const onSubmitForward = (data, e) => {

        const attachmentsIDs = []
        attachments.map((val) => {
            attachmentsIDs.push({
                attachment_id : val.id
            })
        })

        const draftJsData = {}

        if (editorState != undefined) {
            draftJsData.toHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }

        const sendForwardEmailData = {
            to: data.to,
            cc: data.cc,
            from: process.env.REACT_APP_DEFAULT_EMAIL,
            subject: '',
            text_body: draftJsData.toHTML || "",
            sent: false,
            references: [selectedEmail.message_id],
            attachments: attachmentsIDs || [],
            status: "Forward"
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_EMAIL_BACKEND_FQDN}/api/Emails`, {
            method: 'POST', 
            headers: myHeaders,
            body: JSON.stringify(sendForwardEmailData)
        })
        .then((response) => {
            if (response.status >= 200 && response.status <=299) {
                e.preventDefault();
                e.target.reset();
                const emptyEditorState = EditorState.push(editorState, ContentState.createFromText(''));
                setEditorState(emptyEditorState)
                handleReply(false)
                return enqueueSnackbar('Email forwarded successfully', {variant: 'success'})
            }
        })
        .catch(function (error) {
            console.log(error)
            enqueueSnackbar('Email fail to forward', {variant: 'error'})
        });


    }

    


    return (
        <div className={clsx(classes.root, className)}>
            <Card className={classes.card2} variant='outlined' >
                <CardContent className={classes.cardContent}>
                    <form id="form_id" onSubmit={handleSubmit(onSubmitForward)} className={classes.form} autoComplete="off">
                        
                        <Box display="flex" flexDirection="row" >
                            <Input 
                                className={classes.name}
                                defaultValue='To:'
                                readOnly
                                disableUnderline
                            />
                            <Input 
                                fullWidth
                                disableUnderline 
                                name='to'
                                inputRef={register({required: true})}
                                className={classes.input}
                            />
                        </Box>
                        <Divider/>
                        {errors.to && (<span className={classes.errorMsg}>{t('To field is required')}</span>)}

                        <Box display="flex" flexDirection="row">
                            <Input 
                                className={classes.name}
                                defaultValue='Cc:'
                                readOnly
                                disableUnderline
                            />
                            <Input
                                name='cc'
                                fullWidth 
                                disableUnderline 
                                inputRef={register}
                                className={classes.input}
                            />
                        </Box>
                        <Divider/>

                        <DraftEditor 
                            editorStyle={{minHeight: "240px", maxHeight: "340px", overflow: "auto", width: '100%'}}
                            editorState={editorState}
                            onContentStateChange={setContentState}
                            onEditorStateChange={setEditorState}
                            toolbarStyle={{border: 'none', marginTop: '5px', bottom: '0'}}
                            toolbar={{
                                options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                            }}
                        />

                    </form>
                    
                    {attachments.map((attachment) => (
                        <div className={classes.attachments}>
                            <Typography>{attachment.file_name.substring(0, 10)} </Typography>
                            <IconButton><DeleteOutlineOutlinedIcon/></IconButton>
                        </div>
                
                    ))}


                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="row" className={classes.box1}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                className={classes.btnAttach}
                                onClick={() => handleAttachOpen()}
                            >
                                Attach
                            </Button>
                            <DropzoneDialog
                                open={open}
                                onSave={(files) => onSubmitFile(files)}
                                showPreviews={true}
                                maxFileSize={5000000}
                                onClose={handleAttachClose}
                                filesLimit='1'
                            />
                        </Box>

                        <Box className={classes.box2}>
                            <Button  
                                color="secondary"
                                variant="contained" 
                                className={classes.btnDiscard}
                                onClick={handleDiscard}
                            >
                                Discard
                            </Button>
                            <Button  
                                type='submit' 
                                form='form_id' 
                                variant="contained" 
                                color="secondary" 
                                className={classes.btnSend}
                            >
                                Send
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
      
        </div>

    )
}

export default Right;