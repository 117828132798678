import React from 'react';
import {Grid, Card, Select, MenuItem, TextField, Checkbox, FormControl, Divider, FormGroup, FormControlLabel, Typography, InputBase, List, Box, Button} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm, Controller} from "react-hook-form";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';



const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    form_team: {
        margin:"1%"

    },
    btn: {
        textAlign: "center",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "20%",
        maxWidth: "20%"
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    errorMsg : {
        color: "#f44336"
    },


}))


const Create = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    // const [count, setCount] = useState(0)
    // const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } =useSnackbar();
    const [categoriesList, setCategoriesList] = React.useState([]);
    const { t } = useTranslation();
    const [checked, setChecked] = React.useState();
    const [contacts, setContacts] = React.useState([]);
    const [ manager, setManager] = React.useState();
 

    const handleChange = (event) => {
        categoriesList.forEach(val => {
            if (val.name == event.target.name){
            setChecked({ ...checked, [event.target.name]: event.target.checked });
            }
        });
    };


    const handleContactChange = (e) => {
        setManager(e.target.value)
    }



    /*fetching list of categories */
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories`)
            .then(res => res.json())
            .then((data) => {
                setCategoriesList(data.data)    
            })
    } , [])

    /* fetching agent contact  */
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
            .then(res => res.json())
            .then((data) => {
                setContacts(data)  
            })
    } , [])



    const onSubmit= (data,e) => {
        console.log(data)

        var body = {
            name: data.name || '',
            desc: data.desc || '',
            user_id: data.user_id || '',
            categoryIDs: [],
        }
    
        for (let i = 0; i < data.categories.length; i++) {
            const element = data.categories[i];
            if (element.name !== false) {
                body.categoryIDs.push(element._id)
            }
        }
        // console.log("body", body)
        var raw = JSON.stringify(body);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams/${props.location.state.val._id}`,{
            method: 'PUT', 
            headers: myHeaders,
            body: raw,
        })
        .then(response => {
            // let data = response.text()
            if (response.status >=200 && response.status <=299) {history.push('/config');    
            return enqueueSnackbar(t('Team has been updated.'), { variant: 'success' });
        }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Team has fail to update.'), { variant: 'error' });
        }) 
    }

    return (
            <Container maxWidth='xl' className={classes.container}>
                <PageHeader
                    title= {t("Edit Teams")}
                    subtitle= {t("Expanding the teams in the company")}
                />
            <Grid item xs={12}>
                <Card className={classes.form_team} >
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form_} >
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('Team name')} * </Typography>
                                <TextField 
                                    className={classes.parallel}
                                    fullWidth 
                                    variant="outlined" 
                                    name="name" 
                                    inputRef={register({required: true})} 
                                    defaultValue={props.location.state.val.name} 
                                    label= {t("Team Name")}
                                    error={errors.name}
                                    helperText={errors.name && t("Name field is required")}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('Description')}</Typography>
                                <TextField
                                    className={classes.parallel}
                                    fullWidth
                                    label= {t("Description")}
                                    name="desc"
                                    error={errors.desc}
                                    inputRef={register({required: false})}
                                    helperText={errors.desc && t("Description is required")}
                                    defaultValue={props.location.state.val.desc}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                />
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Typography  className={classes.middle}>{t('Manager')} * </Typography>
                                <FormControl className={classes.parallel}   >
                                    <Controller
                                        name="user_id"
                                        inputRef={register}
                                        control={control}
                                        rules={{required: true}}
                                        defaultValue={props.location.state.val.user_id}
                                        as={
                                            <Select
                                                margin="dense"
                                                variant="outlined"
                                                value={manager}
                                                defaultValue={props.location.state.val.user_id}
                                                fullWidth
                                                onChange={handleContactChange}>
                                                {contacts && contacts.map((contact) => (
                                                    <MenuItem  value={contact._id}>{contact.name}</MenuItem>
                                                ))}
                                            </Select>
                                        }
                                    />
                                {errors.user_id &&  <span className={classes.errorMsg}>{t('Manager field is required')}</span>}

                                </FormControl>
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <div className={classes.middle}>
                                    <Typography>{t('Choose categories')}  </Typography>
                                    <FormHelperText>(Check categories which allow access to the team)</FormHelperText>
                                </div>
                                <List>
                                    {/* {console.log("props", categoriesList)} */}
                                    {categoriesList.map((val, index) => {
                                        let indents = '';
                                        let level = val.level - 1;
                                        for (let i = 0; i < level; i++) {
                                            indents += '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
                                        }
                                        const foundIndex = props.location.state.val.categoryIDs.findIndex((item)=> item == val._id);
                                        const isFound = foundIndex > -1;
                                        return (
                                            <div>
                                                {
                                                    (val.parent == "/" ? <Divider/>: null)

                                                }
                                                <Box display="flex" flexDirection="row">
                                                    <div>{indents}</div>
                                                    <FormControl className={classes.formControl}>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        defaultChecked={isFound}
                                                                        checked={checked} 
                                                                        onChange={handleChange}
                                                                        name={`categories.${[index]}.name`} 
                                                                    />
                                                                }
                                                                name={`categories.${[index]}.name`} 
                                                                inputRef={register}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                    <InputBase
                                                        value={val.name}
                                                        inputProps={{ 
                                                            'aria-label': 'naked',         
                                                            readOnly: true,
                                                        }}
                                                        name={`categories.${[index]}.names`} 
                                                    />
                                                    <InputBase
                                                        type="hidden"
                                                        value={val._id}
                                                        inputProps={{ 
                                                            'aria-label': 'naked',         
                                                            readOnly: true,
                                                        }}
                                                        inputRef={register}
                                                        name={`categories.${[index]}._id`} 
                                                    />
                                                </Box>
                                            </div>
                                        );
                                    })}
                                </List>
                            </Box>
                            
                            <Box className={classes.btn} >
                                <Button variant="contained"   type="submit"    color="primary"> {t('Save')} </Button>
                                <Button variant="contained" color="primary"  onClick={() => history.push('/config')}> {t('Cancel')} </Button>
                            </Box> 
                        </form>


                </Card>
            </Grid>
            </Container>
    )
}
export default Create;