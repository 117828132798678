import React, { useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Table,
  TableHead,
  Button,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../../../TM/src/components/PageHeader";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1.5%",
  },
  top: {
    marginLeft: "1%",
    marginRight: "1%",
  },
  formControl: {
    marginLeft: "1%",
  },
  btn_search: {
    marginLeft: "1.5%",
    // height: "3%",
    // marginTop: "0.5%",
  },
  table: {
    minHeight: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    marginLeft: "1%",
    marginRight: "1%",
  },
  ty: {
    margin: "3%",
  },
  root: {
    margin: 3,
  }
}));

function ConfigPrefix(props) {
  const history = useHistory();
  const {t}= useTranslation();
  const classes = useStyles();
  const [newPrefix, setNewPrefix] = React.useState("");
  const [tbList, set_tbList] = React.useState([]);
  const tableRef = useRef(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [trigger, setTrigger] = React.useState(false);

  /* GET data for table */
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`)
      .then((res) => res.json())
      .then((data) => {
        set_tbList(data.data);
      });
  }, [trigger]);

  const handleDeleteDialog = (deleteId) => {
    setDeleteId(deleteId);
    setDeleteOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteOpen(false);
  };

  const handleOnchange = (e) => {
    setNewPrefix(e.target.value)
  }

  /* POST data for create */
  const onSubmit = (newPrefix) => { 
    // const data = { prefix: newPrefix }
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const cleanPrefix = (text) => text.toLowerCase().replace(/[\s.]/g, '');
    const duplicatesArray = tbList.filter(prefixObj => cleanPrefix(prefixObj.prefix) === cleanPrefix(newPrefix));
    if (duplicatesArray.length > 0) {
      // enqueueSnackbar(t('Prefix does not allow duplicate values.'), { variant: 'fail' });
      enqueueSnackbar(t('prefix_duplicates_not_allowed'), { variant: 'fail' });
      return;
    };
    
    fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`, {
      method: 'POST', 
      headers: myHeaders,
      body: JSON.stringify({ prefix: newPrefix }),
    })
    .then(response => {
      if (response.status >=200 && response.status <=299){
        setTrigger(!trigger)
        history.push('/config')
        return enqueueSnackbar(t('prefix_has_been_created'), { variant: 'success' });
      }
    })
    .catch((error) => {
      setTrigger(!trigger)
      console.error('Error:', error);
      enqueueSnackbar(t('prefix_has_fail_to_create'), { variant: 'fail' })
    }) 
    setNewPrefix("")
  }

  /* DELETE data for remove prefix */
  const DeletePrefix = (data) => {
    setDeleteOpen(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(
      `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes/${data}`,
      {
        method: "DELETE",
        //   headers: myHeaders,
      }
    )
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          enqueueSnackbar(t("prefix_has_been_deleted"), { variant: "success" });
          fetch(
            `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/prefixes`
          )
            .then((res) => res.json())
            .then((data) => {
              set_tbList(data.data);
            });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        enqueueSnackbar(t("prefix_has_fail_to_delete"), { variant: "error" });
      });
  };

  return (
    <Grid container spacing={1} className={classes.whole}>
      <Container maxWidth="xl" className={classes.container}>
        <PageHeader
          title={t("title_prefix")}
          subtitle={t("description_prefix")}
          // buttonName={t("new_prefix")}
          // onClickButton={() => history.push("/config/role/create")}
        />
        <Grid container justify="flex-end" spacing={1}>

          <Typography className={classes.btn_search}> {t('enter_new_prefix')}</Typography>
          <TextField 
            // variant="outlined"
            className={classes.btn_search}
            defaultValue=""
            name="name"
            value={newPrefix}
            onChange={(e) => handleOnchange(e)}
          ></TextField>
          <Button 
            className={classes.btn_search}
            variant="outlined"
            color="secondary"
            onClick={() => onSubmit(newPrefix)}
          >{t("new_prefix")}</Button>
          
        </Grid>
  
      </Container>
      <Grid item xs={12} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2"> {t("type_of_prefix")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2"> {t("Actions")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tbList &&
              tbList.map((val) => (
                <React.Fragment key={val._id}>
                  <TableRow>
                    <TableCell>
                      <Typography>{val.prefix}</Typography>
                    </TableCell>
                    <TableCell>
                      {/* <IconButton
                        onClick={() =>
                          history.push({
                            pathname: "/config/role/edit",
                            state: { val: val },
                          })
                        }
                      >
                        <EditOutlinedIcon />
                      </IconButton> */}
                      <IconButton onClick={() => handleDeleteDialog(val._id)}>
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>

        {/* Delete confirmation dialog */}
        <Dialog
          open={deleteOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDeleteDialogClose}
        >
          <DialogTitle>
            {t("Are you sure you want to delete the role?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("This will permanently delete the role.")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="secondary">
              {t("Cancel")}
            </Button>
            <Button onClick={() => DeletePrefix(deleteId)} color="primary">
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default ConfigPrefix;
