import React from 'react';
import {Grid, Card, TextField, Typography, Checkbox, Select, MenuItem, FormControl, FormGroup, FormControlLabel, InputBase, List, Box, Button, Divider} from '@material-ui/core';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {useForm, Controller} from "react-hook-form";
import {  useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';

// import '../../../../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';


import 'react-checkbox-tree/lib/react-checkbox-tree.css';


const useStyles= makeStyles((theme) => ({
    container: {
        marginTop: "1%"
    },
    form_team: {
        margin:"1%"
    },
    btn: {
        textAlign: "center",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    form_: {
        marginLeft: "18%",
        marginTop: "3%",
        marginBottom: "3%"
    },
    parallel: {
        width: "60%",
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    middle: {
        margin: "1.5%",
        width: "16%",
        maxWidth: "16%"
    },
    boxes: {
        margin: "2%"
    },
    spaces: {
        marginLeft: "5%",
        width: "100%"
    },
    errorMsg : {
        color: "#f44336"
    },
    formhelperBox: {
        width: "30%",
        maxWidth: "30%"
    }

}))


const Create = (props) => {
    const classes= useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',

    });
    // const [count, setCount] = useState(0)
    const [tbList, set_tbList] = React.useState([]);
    const [categoriesList, setCategoriesList] = React.useState([]);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [checked, setChecked] = React.useState();
    const [contacts, setContacts] = React.useState([]);
    const [ manager, setManager] = React.useState();
 

    const handleChange = ( event) => {
        // console.log("event",event)
        categoriesList.forEach(val => {
            if (val.name == event.target.name){
            setChecked({ ...checked, [event.target.name]: event.target.checked });
            }
        });
    };

    const handleContactChange = (e) => {
        setManager(e.target.value)
    }

    /* fetching team */
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`)
            .then(res => res.json())
            .then((data) => {
              set_tbList(data.data)    
            })
    } , [])

    /*fetching list of categories */
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/categories`)
            .then(res => res.json())
            .then((data) => {
              setCategoriesList(data.data)  
            })
    } , [])

    /* fetching agent contact  */
    React.useEffect(() => { 
        fetch(`${process.env.REACT_APP_CONTACT_BACKEND_FQDN}/api/contacts/Person/GetAllAgents`)
            .then(res => res.json())
            .then((data) => {
                // console.log("data", data)
                setContacts(filterSupervisor(data))  
            })
    } , [])

    /* filtering supervisor from all contacts*/
    const filterSupervisor = (allContacts) => {
        // console.log("all contacts", allContacts)
        let onlySupervisor = allContacts.filter((val) => {
            if( val.roleId != "5f83c0fc3dc9196a51060807" && val.roleId != "5f82ad854c280e0ef58a2e33" ){
                return true
            }
            return false
        })
        return onlySupervisor
    }
    


    // const settingNodes = (categoryList) => {

    //     for ( let i = 1; i < 10; i ++ ){
    //         categoryList.map((val) => {
    //             if (val.level == i) {
    //                 nodes.push({
    //                     // value: val.name,
    //                     // label: val.name,
    //                     parent: val.parent,
    //                     level: val.level
    //                 })
    //             }
    //             console.log("parent", nodes)
    //         })
    //     }
    // }

    const onSubmit= (data,e) => {
    //   console.log(data);

      var body = {
        name: data.name || '',
        desc: data.desc || '',
        user_id: data.userId || '',
        categoryIDs: [],
      }
      console.log(body)

      for (let i = 0; i < data.categories.length; i++) {
        const element = data.categories[i];
        if (element.name !== false) {
            body.categoryIDs.push(element._id)
        }
      }
    //   console.log("body", body)

      var raw = JSON.stringify(body);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/teams`,{
        method: 'POST', 
        headers: myHeaders,
        body: raw,
      })
      .then(response => {
        if (response.status >=200 && response.status <=299) {history.push('/config');    
        return enqueueSnackbar(t('Team has been created.'), { variant: 'success' });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar(t('Team has fail to create.'), { variant: 'error' });
      }) 
    }



    const isSame = (name) => {
        tbList.map((val) => {
            if (name  !== val.name)
            {
                return true
            }
            else {
                return false
            }
        })
    }


    return (
        <Container maxWidth='xl' className={classes.container}>
            <PageHeader
                title= {t("Create New Teams")}
                subtitle= {t("Expanding the teams in the company")}
            />
            <Grid item xs={12} className={classes.top}>
                <Card className={classes.form_team} >
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form_} >
                        <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}>{t('Team name')} * </Typography>
                            <div className={classes.parallel}>
                                <TextField 
                                    fullWidth 
                                    variant="outlined"
                                    name="name" 
                                    inputRef={register({required: true})}
                                />
                                {errors.name &&  <span className={classes.errorMsg}>{t('Name field is required')}</span>}
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}>{t('Description')}</Typography>
                            <TextField
                                fullWidth
                                name="desc"
                                className={classes.parallel}
                                error={errors.desc}
                                inputRef={register({required: false})}
                                helperText={errors.desc && t("Description is required")}
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Typography  className={classes.middle}>{t('Manager')} * </Typography>
                            <FormControl className={classes.parallel}   >
                                <Controller
                                    name="userId"
                                    inputRef={register}
                                    rules={{required: true}}
                                    control={control}
                                    as={
                                        <Select
                                            margin="dense"
                                            variant="outlined"
                                            value={manager}
                                            fullWidth
                                            onChange={handleContactChange}>
                                            {contacts && contacts.map((contact) => (
                                                <MenuItem  value={contact._id}>{contact.name}</MenuItem>
                                            ))}
                                        </Select>
                                    }
                                />
                                {errors.userId &&  <span className={classes.errorMsg}>{t('Manager field is required')}</span>}

                            </FormControl>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className={classes.middle}>
                                <Typography>{t('Choose categories')}  </Typography>
                                <FormHelperText>{t('(Check categories which allow access to the team)')}</FormHelperText>
                            </div>
                            <List>
                            {categoriesList.map((val, index) => {
                                let indents = '';
                                let level = val.level - 1;
                                for (let i = 0; i < level; i++) {
                                    indents += '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
                                }
                                return (
                                    <div>
                                        {
                                            (val.parent == "/" ? <Divider/>: null)
                                        }
                                        <Box display="flex" flexDirection="row" className={classes.boxes}>
                                            <div>{indents}</div>
                                            <FormControl className={classes.formControl}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox 
                                                                defaultChecked={true}
                                                                checked={checked} 
                                                                onChange={handleChange}
                                                                name={`categories.${[index]}.name`} 
                                                            />
                                                        }                                                
                                                        name={`categories.${[index]}.name`} 
                                                        inputRef={register}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                            {/* <div>{indents}</div> */}
                                            <InputBase
                                                value={val.name}
                                                inputProps={{ 
                                                    'aria-label': 'naked',         
                                                    readOnly: true,
                                                }}
                                                inputRef={register}
                                                name={`categories.${[index]}.names`} 
                                            />
                                            <InputBase
                                                type="hidden"
                                                value={val._id}
                                                inputProps={{ 
                                                    'aria-label': 'naked',         
                                                    readOnly: true,
                                                }}
                                                inputRef={register}
                                                name={`categories.${[index]}._id`} 
                                            />
                                        </Box>
                                    </div>
                                )
                                
                            })}
                            </List>
                        </Box>
                        <Box className={classes.btn} >
                            <Button variant="contained" type="submit"   color="primary"> {t('Create')} </Button>
                            <Button variant="contained" color="primary"  onClick={() => history.push('/config')}> {t('Cancel')} </Button>
                        </Box> 
                    </form> 
                </Card>
            </Grid>
        </Container>

    )
}
export default Create;