import React, {useRef} from 'react';
import { Grid, TablePagination, IconButton, Typography, Button, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles} from '@material-ui/core/styles';
import PageHeader from '../../../../../modules/TM/src/components/PageHeader'; 
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles= makeStyles ((theme) => ({
    container: {
        marginTop: "1.5%"
    },
    top: {
        marginLeft: "1%",
        marginRight: "1%",
    },
    formControl: {
        marginLeft: "1%"
    },
    btn_search: {
        marginLeft: "1.5%",
        height: "3%",
        marginTop: "0.5%"
    },
    table: {
        minHeight: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        marginLeft: "1%",
        marginRight: "1%"
    },
    ty: {
        margin: "3%",
      }
 
}));

function ConfigPriority(props) {
    const history = useHistory();
    // const {t}= useTranslation();
    const classes= useStyles();
    const [tbList, set_tbList] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { enqueueSnackbar } =useSnackbar();
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("");

    const handleDeleteDialog = (deleteId) => {
        setDeleteId(deleteId)
        setDeleteOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteOpen(false)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleDelete = ( delete_id) => {
        setDeleteOpen(false)
        console.log(delete_id) 
        let query= `${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-priorities/${delete_id}`
        var raw = JSON.stringify(delete_id);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        fetch(query ,{
            method: 'Delete', 
            headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        })
        .then(response => {
            if (response.status >=200 && response.status <= 299){   
                enqueueSnackbar(t('Priority has been deleted.'), { variant: 'success' });
                fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-priorities`)
                    .then(res => res.json())
                    .then((data) => {
                        console.log("TB_list")
                        console.log(data)
                        set_tbList(data.data)
            })
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar(t('Priority has fail to delete.'), { variant: 'error' });
        })
        // window.location.reload(false); 
} 
  
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_ADMIN_BACKEND_FQDN}/admin/api/v1/ticket-priorities`)
            .then(res => res.json())
            .then((data) => {
                set_tbList(data.data)
            })
    } , [])

 
 
    return (
        <Grid container spacing={1} className={classes.whole}>
         <Container maxWidth="xl" className={classes.container}>
            <PageHeader
            title= {t("Config Priorities")}
            subtitle= {t("Managing priority")}
            buttonName=  {t("New Priority")}
            onClickButton={()=> history.push('/config/priority/create')}
            />
         </Container>    
        <Grid item xs={12} className={classes.table}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="body2"> {t('Priority')}</Typography></TableCell>
                        <TableCell><Typography variant="body2"> {t('Color')}</Typography></TableCell>
                        <TableCell><Typography variant="body2"> {t('Actions')}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tbList && tbList.map((val) => (
                    <React.Fragment key={val._id}>
                        <TableRow>
                            <TableCell><Typography>{val.name}</Typography></TableCell>
                            <TableCell>
                                <Typography>
                                    <div
                                        style={{
                                            width: 30,
                                            height: 20,
                                            marginBottom: 20,
                                            backgroundColor: val.color
                                    }}
                                    >
                                    </div>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={()=> history.push({pathname: '/config/priority/edit', state: {val: val}})} ><EditOutlinedIcon color="primary"/></IconButton> 
                                <IconButton onClick={() => handleDeleteDialog(val._id)}><DeleteForeverOutlinedIcon color="primary"  /></IconButton>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tbList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* Delete confirmation dialog */}
            <Dialog
                open={deleteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteDialogClose}
            >
                <DialogTitle>{t('Are you sure you want to delete the priority?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('This will permanently delete the priority.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="secondary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={() => handleDelete(deleteId)} color="primary">
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
                
        </Grid>                    
        </Grid>
    )
}

export default ConfigPriority;
